import React from "react";
import { Container } from "react-bootstrap";
import Tilt from "react-parallax-tilt";

const AboutDevelopment = ({ heading, data, content }) => {

 

  return (
    <section>
      <Container>
        <h1 className="heading "> {heading} </h1>
        <div className="grid_box mt-5">
          {data.map((item, index) => {
            return (
              <Tilt key={index}>
                <div className="box  text-center" >
                  <div className="img_box">
                    <img src={item.image} className="img-fluid" alt=""  loading="lazy" />
                  </div>

                  <div className="content">
                    <h2 className="my-3"> {item.title} </h2>
                    {
                      content ?  <p> {item[content]} </p> :   <p> {item.description} </p>
                    }
                   

                  </div>
                </div>
              </Tilt>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default AboutDevelopment;
