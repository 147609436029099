import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import DotNetCoreIcon from "../../../Images/icon/dotnet_ic.webp";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import KubernetesIcon from "../../../Images/icon/Kubernetes.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/Docker.svg";
import DockerImg from "../../../Images/HireDeveloper/Docker.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const Docker = () => {

  const homeData = {
    title: "Hire <span>Docker</span> Developers in India",
    description: `Empower your business with skilled Docker developers delivering scalable, secure, and highly available containerized solutions. 
      Leverage Docker’s containerization technology to build high-performance, cost-effective applications tailored to your business needs. 
      Our developers specialize in Docker container orchestration, microservices architectures, and seamless deployment across cloud platforms.
      With flexible hiring models and a client-first approach, we guarantee seamless collaboration and timely delivery. 
      Transform your ideas into reality with our Docker development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our in-house team brings over 5 years of experience in Docker container development and orchestration.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure the highest standards of security, scalability, and cost-efficiency in your Docker applications.",
      },
    ],
  };
  
  
  const hireTopDeveloperData = {
    heading: "Hire Top Docker Developers in India from Skyline Infosys",
    image: {
      src: DockerImg,
      alt: "DockerImg",
    },
    content: {
      description: `Looking to leverage the power of Docker for your business? At Skyline Infosys, we offer expert Docker developers who specialize in delivering scalable, secure, and highly efficient containerized solutions tailored to your business needs. From container orchestration to optimizing deployment pipelines, our team ensures seamless integration and robust management of your Docker applications.`,
      expertiseHeading: "Our Docker developers excel in:",
      expertisePoints: [
        "Designing and deploying scalable and secure containerized applications using Docker containers",
        "Implementing container orchestration with Docker Swarm and Kubernetes for efficient microservices management",
        "Building and optimizing Docker images and Docker Compose configurations for rapid application deployment",
        "Integrating Docker with CI/CD pipelines for automated testing, building, and deployment",
        "Optimizing container resources and managing multi-container architectures with Docker Compose and Docker Swarm",
        "Ensuring high levels of security and compliance with Docker security best practices, including image scanning and vulnerability assessment",
      ],
      closingNote: `Partner with us today to build scalable and secure containerized solutions using Docker. Hire dedicated Docker developers from Skyline Infosys to streamline your development lifecycle and drive your business forward with efficient, reliable containerization technologies.`,
    },
  };
  
  
  
  

  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Docker Combinations",
    description: `At Skyline Infosys, we specialize in integrating Docker with powerful back-end and front-end technologies to deliver scalable, secure, and high-performance containerized solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Docker + .NET Core",
        description: `Leverage the power of Microsoft’s .NET Core framework with Docker for building scalable, secure, and high-performance containerized applications. This combination is ideal for enterprise-level applications that need portability and efficient deployment across multiple environments.`,
        icon: DotNetCoreIcon,
      },
      {
        title: "Docker + React.js",
        description: `Integrate Docker with React.js for building modern, scalable web applications. Docker handles the containerization of the app, ensuring that it runs consistently across different environments, while React.js powers the dynamic user interface for a seamless user experience.`,
        icon: ReactIcon,
      },
      {
        title: "Docker + Node.js",
        description: `Docker provides the containerized environment for your Node.js backend, enabling scalable and efficient applications that can handle real-time data processing, API requests, and microservices architecture.`,
        icon: NodeJsIcon,
      },
      {
        title: "Docker + Kubernetes",
        description: `Use Docker containers in conjunction with Kubernetes to manage and orchestrate your containerized applications efficiently. This combination ensures seamless scaling, deployment, and management of microservices across a cloud environment.`,
        icon: KubernetesIcon,
      },
      {
        title: "Docker + Python/Django",
        description: `Build scalable, secure, and high-performance back-end systems by containerizing Python applications with Docker. This combination allows for faster deployments, better resource utilization, and easy scaling of applications powered by Django.`,
        icon: PythonIcon,
      },
      {
        title: "Docker + Angular",
        description: `Use Docker to containerize Angular applications, ensuring fast, efficient, and portable deployment across various environments. Angular handles the dynamic and interactive front-end, while Docker ensures consistency in different stages of deployment.`,
        icon: AngularIcon,
      },
      {
        title: "Docker + AI/ML",
        description: `Leverage Docker containers for deploying machine learning models built with popular frameworks like TensorFlow and PyTorch. Docker ensures easy scaling and consistent environment setup for AI/ML solutions.`,
        icon: AiIcon,
      },
      {
        title: "Docker + DevOps",
        description: `Combine Docker with DevOps tools to automate the application lifecycle. Docker containers make it easier to integrate continuous deployment, testing, and monitoring, streamlining the development and delivery process.`,
        icon: DevOpsIcon,
      },
    ],
  };
  
  
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Docker;
