import React from "react";
import Banner from "../../Component/Banner";
import CurrentVacancy from "./Components/CurrentVacancy";
import "./Career.css";
import ChooseUs from "./Components/ChooseUs";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import { Helmet } from "react-helmet";
import careerBg from "../../Images/Career/careerbG.jpg";

const Career = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Developer Jobs at Skyline Infosys</title>
        <meta
          name="description"
          content="Explore software developer roles at Skyline Infosys. Join us for a fulfilling career with growth opportunities."
        />
        <meta name="keywords" content="software developer jobs" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
          backGroundImg={careerBg}
          heading="CAREERS"
          content="Join Skyline Infosys and take your career to new heights with a team that’s passionate about innovation and excellence. We offer exciting opportunities to work on cutting-edge technologies, solve complex challenges, and grow professionally. Our employee-first culture values creativity, collaboration, and continuous learning. Be part of a workplace that fosters talent, rewards achievements, and empowers you to make a real impact. Explore a fulfilling career with us and shape the future of technology. Your journey starts here!"
          title="Quality Work."
          btnText="Consult Skyline"
        />
      </div>
      <CurrentVacancy />
      <ChooseUs />
      <MajorClient />
      <Testimonials />
    </div>
  );
};

export default Career;
