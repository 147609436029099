import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import java from "../../../Images/icon/Java.svg";
import mysql from "../../../Images/icon/mysql.svg";
import Python from "../../../Images/icon/Python.svg";
import dotnet from "../../../Images/icon/dotnet_ic.webp";
import Dynamics from "../../../Images/icon/Microsoft Dynamics.svg";
import mongoDb from "../../../Images/icon/mongo_db_ic.webp";
import magneto from "../../../Images/icon/magneto_ic.webp";
import oracle from "../../../Images/icon/oracle.svg";
import spark from "../../../Images/icon/spark.svg";
import Xamarion from "../../../Images/icon/Xamarion.svg";
import Scala from "../../../Images/icon/Scala.svg";

const VariousTechnologies = () => {
  const VariousTechnology = [
    java,
    mysql,
    Python,
    dotnet,
    Dynamics,
    mongoDb,
    magneto,
    oracle,
    spark,
    Xamarion,
    Scala,
  ];

  return (
    <section className="various_technologies">
      <Container>
        <h1 className="heading"> Diverse Expertise on Various Technologies</h1>
        <Row className="justify-content-center">
          <Col lg={10}>
            <p className="content_p py-4">
              Skyline Infosys can be your perfect choice for implementation of
              latest technologies that are more than buzz words these days, like
              big data, artificial intelligence, machine learning and much more.
              We also offer IT, database management, cloud implementation,
              development, consulting and many other services. Just name it and
              we have it.
            </p>
          </Col>
          <Col lg={10}>
            <div className="technologies d-flex flex-wrap justify-content-center">
              {VariousTechnology.map((obj , index) => {
                return (
                  <div className="box" key={index}>
                    <span>
                      <img src={obj} alt="java"  loading="lazy" />
                    </span>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VariousTechnologies;
