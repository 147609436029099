import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Ios/ios_banner.png";
import BannerImage1 from "../../../Images/Technology/Ios/ios_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const IosService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Tailored iOS App Development for Your Vision | Build Your Dream App
        </title>
        <meta
          name="description"
          content="
Skyline Infosys, a top iOS development agency, helps boost your business by targeting your exclusive audience with custom iOS apps."
        />
        <meta name="keywords" content="ios development agency" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="iOS App Development"
            content="Do you want to give a solid boost to your business by focusing on your exclusive target audience with iOS app development? Skyline Infosys is a leading iOS app development company that can make that happen for you."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore iOS App development Company"
          />
        </div>

        <DevelopmentInfo
          heading="iOS App Development"
          title="iOS Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                We offer top-quality iOS app development services across India.
                Our team of experienced iOS developers has the expertise to
                develop custom iOS applications for various industries,
                including healthcare, finance, e-commerce, and education. We use
                a customer-centric approach to develop iOS apps that meet the
                specific needs of our clients
              </p>
              <p>
                Our iOS development process starts with understanding our
                clients' requirements, designing and developing a prototype,
                testing the app for functionality and usability, and deploying
                the app to the Apple App Store. We use the latest technologies
                and tools to create high-quality iOS apps that are optimized for
                performance and user experience.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"ios"}
          heading="Why Should You Go For iOS App Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed iOS App Development? "
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                Developing an iOS app requires a combination of technical
                expertise, creativity, and a user-centric approach. At our iOS
                app development company, we follow a comprehensive approach to
                create visually appealing, engaging, and high-performance iOS
                apps that meet the specific needs of our clients.
              </p>
              <p>
                Our iOS app development process starts with understanding our
                clients' requirements, identifying the target audience, and
                analyzing the competition. Based on this research, we create a
                unique app concept that reflects the brand identity and meets
                the business objectives.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Framework",
            "Database",
            "Testing",
            "Project Management",
          ]}
          serviceName="Mobile Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default IosService;
