import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa";
import BatyrHojayev from "../../../Images/Testimonials/BatyrHojayev.png";
import Maffei from "../../../Images/Testimonials/Maffei.png";
import Daniel  from "../../../Images/Testimonials/Daniel.png";
import SserunjogiEmmanuel from "../../../Images/Testimonials/SserunjogiEmmanuel.png";
import Josh from "../../../Images/Testimonials/Josh.png";
import Felix from "../../../Images/Testimonials/Felix.png";
import nicky from "../../../Images/Testimonials/nicky.png";
import mark from "../../../Images/Testimonials/mark.png";
import HansDemen from "../../../Images/Testimonials/HansDemen.png";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";

const Testimonials = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    autoplay: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: TestimonialData.length + 1,
    slidesToScroll: 0,
    asNavFor: nav1,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    swipeToSlide: false,
    draggable: false,
    infinite: false,
    arrows: false,
    touchMove: false,
    swipe: false,
    preventDefaultSwipeX: true,
  };

  const [expanded, setExpanded] = useState(false);
  const maxLength = 300;

  const toggleExpanded = (id) => {
    setExpanded((prev) => (prev === id ? null : id));
  };

  return (
    <section className="testimonials position-relative mb-5">
      <Container>
        <h1 className="heading ">What Clients Are Saying About Us</h1>
        <Row className="mt-3  justify-content-center align-items-center">
          <Col lg={12} className="mb-5">
            <div className="content text-center ">
              <p>
                Our clients love working with us, and we take pride in the
                lasting relationships we build. From startups to established
                enterprises, we have partnered with businesses across various
                industries, delivering tailored solutions that meet their unique
                needs. Whether it’s crafting a visually stunning website,
                implementing seamless API integrations, or developing a robust
                mobile application, our team works tirelessly to exceed
                expectations.
              </p>
            </div>
          </Col>
          <Col lg={10} className="testimonials_box">
            <Slider
              {...settingsMain}
              className="main_testimonials"
              ref={(slider) => setNav1(slider)}
            >
              {TestimonialData.map((obj, index) => {
                return (
                  <div
                    className="client_say_box d-flex gap-4 w-100 h-100"
                    key={index}
                  >
                    <div className="img_box ">
                      {obj.content_type === "file" ? (
                        <iframe
                          loading="lazy"
                          src={`${obj.contentUrl.replace(
                            "watch?v=",
                            "embed/"
                          )}?autoplay=0`}
                          title={obj.name}
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <img
                          src={obj?.image}
                          alt="testimonials"
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div className="content d-flex justify-content-between flex-column gap-1">
                      <div>
                        <div className="quotes position-relative">
                          <RiDoubleQuotesL className="quotes1" />
                          <p className="mb-0">
                            {obj.content.length > maxLength &&
                            expanded !== index
                              ? `${obj.content.slice(0, maxLength)}...`
                              : obj.content}
                          </p>
                          <RiDoubleQuotesR className="quotes2" />
                        </div>

                        {obj.content.length > maxLength && (
                          <span
                            className="read-more-btn"
                            onClick={() => toggleExpanded(index)}
                          >
                            {expanded === index ? "Read Less" : "Read More"}
                          </span>
                        )}
                      </div>
                      <div className="name_info">
                        <h3 className="mb-0  text-end mt-1">{obj.name}</h3>
                        <div className="star text-end">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>

            <Slider
              {...settingsThumbs}
              ref={(slider) => setNav2(slider)}
              className="bottom_box mt-5 "
            >
              {TestimonialData.map((obj, index) => {
                return (
                  <div className="img_ring" key={index}>
                    <img src={obj.image} alt="client" loading="lazy" />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;

const TestimonialData = [
  {
    contentUrl: "https://www.youtube.com/watch?v=TGIKdLQjgIs",
    content:
      "I am Nikki John. Head of Technical Operations at Steer Education.  Steer has been working with Bavesh and his team of developers since 2016 and in my role I have worked directly with the team at Hashtag Emphasis since 2019 to provide the technical support for the Steer Tracking Mental Wellbeing Toolkit which is being used by schools and youngsters worldwide. Over the years we have developed a very close working relationship with an excellent and dedicated team and I've got to know everyone very well at a personal level. This has ensured continuity and also quality in terms of technical support. There have been some major and fantastic achievements with redevelopment and migration projects alongside the daily technical support and maintenance. Not forgetting the impact of the Covid epidemic and the opposed homeworking. This was managed very effectively. In 2021. Steer Education were awarded a betterward for leadership and management and this would not have been possible without the contribution of the team at Hashtag Emphasis. It recognized the powerful contribution we are making to education and young people's lives. Everyone at hashtag Emphasis has been amazing in their support. They are always flexible and willing to put in the extra effort as and when required to support steers's everevvolving business requirements.",
    content_type: "file",
    designation: "Active Skyline Client",
    image: nicky,
    name: "Nicky John, UK ",
  },
  {
    contentUrl: "https://www.youtube.com/watch?v=3-yM_67k7qA",
    content:
      "My company PROAXIOM is operating in multiple countries around the world in New Zealand. Australia and the UK and we've been working with Mayor and the team at hashtag emossis for approximately 18 months on several projects that we've had going with our clients. We have engaged them for both fronted and backend work and we've had a team of between eight to 12 hashtag team members working as part of proaxiom for that period for about 18 months. They have been fantastic working with us as a strategic partner and a contractor to deliver services to our clients. I've found them to be highly professional, very willing and able to meet our needs and would happily work with them going forward.",
    content_type: "file",
    designation: "Active Skyline Client",
    image: mark,
    name: "Mark Woodhouse, Australia",
  },
  {
    content:
      "Hello There. Mayur and his team deliver their work professionally and they know the technologies very well. They can complete a task on time with high quality work. I could always count on them to get the job done. They are very reliable and I really enjoyed working with them. I can highly recommend Skyline and I will certainly work with them again. Thank You.",
    content_type: "text",
    designation: "Active Skyline Client",
    image: HansDemen,
    name: "Hans Demen, Belgium",
  },
  {
    content:
      "It has been a pleasure working with Mayur and the Skyline team once more. They flawlessly completed the tasks according to our specifications. Their expertise in VueJS and Laravel projects is impressive, and their ability to tackle complex challenges with ease stands out. The team communicates effectively and ensures that all deadlines are met without compromising on quality. I’m always confident in their ability to deliver outstanding results. You won’t regret hiring them! Thank you, Mayur and team, for another job well done",
    content_type: "text",
    designation: "Active Skyline Client",
    image: BatyrHojayev,
    name: "Batyr Hojayev , Italy",
  },
  {
    content:
      "They will do the work the way you describe it to them. Mayur is friendly while maintaining a strong work ethic and delivers on time. I really commend their coding skills and quick analysis of the problem to generate the solution. Their attention to detail is outstanding, and they consistently go above and beyond to ensure quality. Communication is smooth, and they are always responsive to feedback. Working with Mayur’s team has been a seamless and rewarding experience, and I look forward to collaborating with them again",
    content_type: "text",
    designation: "Active Skyline Client",
    image: SserunjogiEmmanuel,
    name: "Sserunjogi Emmanuel , Uganda",
  },
  {
    content:
      "Mayur's team is exceptional! Their attention to detail, professionalism, and expertise made working with them a great experience. I was impressed with the quality of their services, and they were always responsive to our needs. Their dedication to delivering results on time truly sets them apart. I’m very happy with the work they've done and will certainly be using their services again in the future. Highly recommended !",
    content_type: "text",
    designation: "",
    image: Maffei,
    name: "Gianfranco Maffei, Italy",
  },

  {
    content:
      "I’m extremely satisfied with the work Skyline has delivered. The proactive enhancements and refinements they provided truly exceeded my expectations. I deeply appreciate their dedication and quality of service. Thank you, Skyline!",
    content_type: "text",
    designation: "",
    image: Daniel ,
    name: "Daniel , USA",
  },

  {
    content:
      " I had the pleasure of working with Skyline Infosys, a software development company run by CEO Mayur, on the development of a custom app for my company. I must say that the experience was fantastic and the results exceeded my expectations. From the initial planning stages to the final product, the team at Skyline Infosys was responsive, professional, and efficient. They were always willing to listen to our ideas and provide valuable feedback based on their expertise. The app they developed for us was top-notch, user-friendly, and streamlined our workflow, making our business more efficient. The development process was smooth, and the team kept us informed every step of the way, ensuring that we were satisfied with the progress. Mayur's leadership and communication skills were exceptional. He was always available to answer our questions and provided regular updates on the project's progress. His team's attention to detail and dedication to ensuring the app's functionality was outstanding. Overall, I would highly recommend Skyline Infosys to anyone looking for a reliable and experienced software development company. They delivered an outstanding product on time and within budget, and we are delighted with the results. Thanks again to Mayur and his team for their excellent work!",
    content_type: "text",
    designation: "",
    image: Josh,
    name: "Josh , Canada ",
  },

  {
    content:
      "They did an excellent job with on-time delivery. They quickly familiarized themselves with a highly complex codebase and enhanced it according to my specifications. I was kept informed about the progress through small preview videos. Truly professional!",
    content_type: "text",
    designation: "",
    image: Felix,
    name: "Felix , Germany",
  },
];
