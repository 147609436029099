import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Nodejs/node_banner.png";
import BannerImage1 from "../../../Images/Technology/Nodejs/node_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const NodeService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Skyline Infosys| Expert Node.js Development Services</title>
        <meta
          name="description"
          content="
We offer comprehensive Node.js development services, including migration, support, and maintenance, to build high-performing applications"
        />
        <meta name="keywords" content="node js development service" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
           backGroundImg={CommonBg}
            heading="Node Js Development"
            content="Leverage our End-to-end Node.js application development service to build high-performing applications with our best-in-class experts. We offer NodeJS web development services, including migration, support, and maintenance."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Node Js development Company"
          />
        </div>

        <DevelopmentInfo
          heading="NodeJS Development"
          title="Node Js Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we understand that every business has unique
                requirements when it comes to their web and mobile applications.
                With our Node.js development services, we aim to provide
                customized solutions that align with your business objectives.
                Our team of expert developers leverage the capabilities of
                Node.js to create scalable, high-performance, and secure
                applications that meet your specific needs. Whether you require
                a real-time application or a high-traffic web portal, our
                Node.js development services can help you achieve your goals
                efficiently and effectively. Contact us today to learn more.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"nodejs"}
          heading="Why Should You Go For Node Js Software Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Node JS Software Development?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                At our IT company in Surat, we take pride in providing top-notch
                services to our clients. Our team of experts comprises skilled
                developers who are well-versed in using full-stack frameworks to
                create customized web applications that meet the specific needs
                of your business. We value your time and understand that timely
                delivery of projects is crucial for your success. Hence, we work
                diligently to ensure that all projects are completed on time
                without compromising on quality. Our testing process is thorough
                and end-to-end to ensure that your customers have a seamless and
                enjoyable experience when using your product. With our
                commitment to excellence and customer satisfaction, we are
                confident that we can help you achieve your business goals.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default NodeService;
