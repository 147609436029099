import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { TechnicalLanguageData } from "../../../data";

const TechnicalLanguage = () => {
  const [activeTab, setActiveTab] = useState("Web Development");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="technical_language">
      <Container className="">
        <h1 className="heading">Our Technology</h1>

        <div className="tab_list my-5">
          <button
            className={activeTab === "Web Development" ? "active" : ""}
            onClick={() => handleTabClick("Web Development")}
          >
            Web Development
          </button>
          <button
            className={activeTab === "Api Integration" ? "active" : ""}
            onClick={() => handleTabClick("Api Integration")}
          >
            API Integration
          </button>
          <button
            className={activeTab === "JavaScript" ? "active" : ""}
            onClick={() => handleTabClick("JavaScript")}
          >
            JavaScript
          </button>
          <button
            className={activeTab === "DataBase" ? "active" : ""}
            onClick={() => handleTabClick("DataBase")}
          >
            DataBase
          </button>
          <button
            className={activeTab === "App Development" ? "active" : ""}
            onClick={() => handleTabClick("App Development")}
          >
            App Development
          </button>
        </div>

        <div className="tab_content">
          {TechnicalLanguageData.filter(
            (obj) => obj.category === activeTab
          ).map((obj, index) => (
            <div className="box" key={index}>
              <div className="img_box">
                <img src={obj.image} alt={obj.name}  loading="lazy" />
              </div>
              <h6>{obj.name}</h6>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default TechnicalLanguage;
