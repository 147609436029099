import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Firebase/firebase-service.png";
import BannerImage1 from "../../../Images/Technology/Firebase/Firebase-banner.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const FirebaseService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Firebase Database Solutions | Skyline Infosys</title>
        <meta
          name="description"
          content="Optimize your app's backend with Firebase database solutions. Seamlessly manage real-time data synchronization, scalability, and cloud integration."
        />
        <meta
          name="keywords"
          content="Firebase Database Development Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service technology">
        <div className="home ">
        <Banner
      backGroundImg={CommonBg}
      heading="Firebase Database"
      content="Skyline Infosys offers cutting-edge Firebase database solutions to streamline real-time data management and cloud-native app development. Leverage the power of Firebase for dynamic and scalable backend solutions."
      title="Development Company"
      btnText="Consult Skyline"
      subTitle="Explore Firebase Database Development Company"
    />
        </div>
        <DevelopmentInfo
    heading="Firebase Database"
    title="Firebase Database Services Across India"
    image={BannerImage}
    content={
      <>
        <p className="my-3">
          Firebase is a modern cloud-based database solution designed for
          real-time data synchronization, scalability, and seamless integration
          with mobile and web applications. At Skyline Infosys, we provide
          end-to-end Firebase database development services, helping businesses
          achieve efficient backend solutions tailored to their needs.
        </p>
        <p>
          Our Firebase services include real-time database design, integration,
          authentication, cloud function implementation, and performance
          optimization. We focus on building robust and scalable database
          systems that enable real-time updates, making them perfect for
          dynamic and user-centric applications.
        </p>
      </>
    }
  />

        <TechnologiesFeatures
          technology={"mysql"}
          data={technologyFeatureData}
          heading="Why Should You Choose Firebase Database?"
          />

<DevelopmentInfo
    heading=""
    title="How Do We Deliver Firebase Database Development?"
    image={BannerImage1}
    order={2}
    content={
      <>
        <p className="my-3">
          Skyline Infosys follows a structured process to deliver high-quality
          Firebase database solutions. Our team leverages Firebase's robust
          features, including real-time database, Firestore, and authentication,
          to create scalable and secure backend solutions. We ensure your app
          delivers seamless user experiences with fast and reliable real-time
          data updates.
        </p>
      </>
    }
  />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Database",
            "Frontend",
            "Backend",
            "Unit Testing",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default FirebaseService;
