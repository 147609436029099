import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AboutSkylineImg from "../../../Images/AboutUs/aboutskyline.jpg";
import slide1 from "../../../Images/AboutUs/slide1.jpg";
import slide2 from "../../../Images/AboutUs/slide2.jpg";
import slide3 from "../../../Images/AboutUs/slide3.jpg";
import slide4 from "../../../Images/AboutUs/slide4.jpg";
import slide5 from "../../../Images/AboutUs/slide5.jpg";
import slide6 from "../../../Images/AboutUs/slide6.jpg";

import Slider from "react-slick/lib/slider";
import { Link } from "react-router-dom";

const AboutSkyline = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section>
      <Container>
        <h1 className="heading">
          Experience Success Beyond Measures with Digital Transformation
        </h1>
        <Row className="justify-content-center experience-content mb-5">
          <Col lg={8} className="text-center my-4">
            <p>
              Empowering businesses with customized, high-level software and IT
              solutions from 2014, Skyline Infosys is one of the leading IT
              outsourcing companies in India. Our experts hold immense
              experience in offering value-driven software solutions to
              companies in various sectors.
            </p>
            <h6>Come, Partner with Us & Set Your Business Up for Success! </h6>
            <Link to="/contact" className="button mt-4">Let’s discuss now</Link>
          </Col>
        </Row>
      </Container>
      <Container className="">
        <Row className="justify-content-center align-items-center  position-relative about_skyline my-5">
          <Col lg={6}>
            <div className="content">
              <h1> About Skyline Infosys</h1>
              <p>
                Over more than 10+ years, Skyline Infosys is transforming the
                way businesses work by offering them cutting-edge IT services.
                We have enabled companies to reimagine their business bandwidth
                by adopting new digital possibilities. Our team specializes in
                exploring the latest tech trends to pave the way for
                unimaginable digital innovations. We curate our tech services
                after carefully understanding the demands and needs of the
                businesses.
              </p>
              <p>
                We offer a wide range of latest IT services to companies all
                around the world. Many organizations have tasted incredible
                success by adopting latest technologies and using cutting-edge
                software. If you want to jump on the bandwagon too, then here is
                your chance.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="content_img position-relative">
              <Slider {...settings}>
              <img src={slide2} alt="slide2" className="img-fluid"  loading="lazy" />
                <img
                  src={AboutSkylineImg}
                  alt="AboutSkylineImg"
                  className="img-fluid"
                />
                <img src={slide1} alt="slide1" className="img-fluid"  loading="lazy" />
                <img src={slide3} alt="slide3" className="img-fluid"  loading="lazy" />
                <img src={slide4} alt="slide4" className="img-fluid"  loading="lazy" />
                <img src={slide5} alt="slide5" className="img-fluid"  loading="lazy" />
                <img src={slide6} alt="slide6" className="img-fluid"  loading="lazy" />
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSkyline;
