import React from "react";
import Banner from "../../Component/Banner";
import PerfectPartner from "./Components/PerfectPartner";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactForm from "../Contact/Components/ContactForm";
import { Helmet } from "react-helmet";
import PartnershipBG from "../../Images/Partnership/partnershipBg.jpg";

const Partnership = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Be a Referral Partner| Earn with Skyline</title>
        <meta
          name="description"
          content="Be a Referral Partner| Earn with Skyline"
        />
        <meta name="keywords" content="Referral Partnership" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
          backGroundImg={PartnershipBG}
          heading="Channel Partnership"
          content="At Skyline Infosys ,we leverage cutting-edge technologies to empower your business with innovative, scalable solutions. Our team of skilled professionals works closely with you to understand your goals and deliver tailored strategies. From advanced software development to seamless integration, we ensure your business stays ahead of the curve. With a client-first approach, we prioritize your success at every step. Let us help you achieve exponential growth and unlock new
"
          title="Creative Plans."
          btnText="Consult Skyline"
        />
      </div>
      <PerfectPartner />
      <MajorClient />
      <Testimonials />
      <ContactForm />
    </div>
  );
};

export default Partnership;
