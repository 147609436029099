import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import HTMLIcon from "../../../Images/icon/html_5.webp";
import technologyIcon from "../../../Images/icon/python_ic.webp";
import PythonImg from "../../../Images/HireDeveloper/pythonHire.svg";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const PythonDeveloper = () => {

  const homeData = {
    title: "Hire <span>Python</span> Developers in India",
    description: `Empower your business with skilled Python developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in Python, Django, Flask, PostgreSQL, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Python experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Python development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  
  


  const hireTopDeveloperData = {
    heading: "Hire Top Python Developers in India from Skyline Infosys",
    image: {
      src: PythonImg, 
      alt: "PythonImg",
    },
    content: {
      description: `Are you looking to build highly scalable, robust, and secure applications with Python? At Skyline Infosys, 
        we offer expert Python developers with years of experience in building dynamic server-side applications, 
        RESTful APIs, and data-driven solutions. Our developers are highly skilled in Python frameworks like Django and Flask, 
        and work with popular databases like PostgreSQL and MySQL to deliver solutions that meet your business requirements. 
        Whether you need custom web applications, real-time data processing systems, or AI-powered solutions, 
        our team of experts can help turn your ideas into reality. We offer scalable, maintainable, and high-performance solutions 
        that are tailored to your specific needs.`,
      expertiseHeading: "Our Python developers excel in:",
      expertisePoints: [
        "Building secure, scalable, and high-performance web applications using Django and Flask",
        "Developing RESTful APIs with Python frameworks to support dynamic and efficient communication",
        "Optimizing Python applications for speed, scalability, and robustness, ensuring optimal performance",
        "Integrating Python applications with various databases, including PostgreSQL, MySQL, and MongoDB",
        "Implementing machine learning and data science solutions using libraries like Pandas, NumPy, and TensorFlow",
        
      ],
      closingNote: ``,
    },
  };
  
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Python Combinations",
    description: `At Skyline Infosys, we specialize in leveraging Python with a variety of front-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [

      {
        title: "Python",
        description: `A powerful back-end language for building scalable, efficient, and secure web applications. Python, with frameworks like Django or Flask, is known for its simplicity and readability. 
          It provides a robust environment for developing APIs, handling business logic, and managing databases. Ideal for building everything from simple applications to complex, large-scale systems.`,
      },
      {
        title: "Python + React.js",
        description: `A modern combination for building dynamic, full-stack applications. React.js handles the dynamic front-end, offering a fast, component-based UI, 
          while Python, typically with Flask or Django, powers the back-end, managing business logic, API creation, and database integration. Ideal for scalable, responsive applications.`,
        icon: ReactIcon,
      },
      {
        title: "Python + Vue.js",
        description: `A versatile stack for building interactive, scalable web applications. Vue.js offers a flexible, reactive front-end, while Python (with frameworks like Flask or Django) 
          ensures robust back-end functionality, including database integration and API management. Ideal for apps requiring dynamic user interfaces and real-time capabilities.`,
        icon: VueIcon,
      },
      {
        title: "Python + HTML",
        description: `A classic stack for server-rendered web applications. Python handles the back-end with frameworks like Django or Flask, offering routing, authentication, and database management, 
          while HTML is used to build the structure of static front-end pages. This combination is great for simple, content-focused websites or CMS-based applications.`,
        icon: HTMLIcon,
      },
      {
        title: "Python + Angular",
        description: `A powerful combination for building large-scale enterprise applications. Angular’s structured framework with two-way data binding works seamlessly with Python’s back-end (Django or Flask), 
          which handles business logic, database interactions, and authentication. Ideal for applications that require scalability, maintainability, and real-time features.`,
        icon: AngularIcon,
      },
      {
        title: "Python + Next.js",
        description: `This stack combines the server-side rendering and SEO-friendly features of Next.js with Python’s back-end logic and database management (via Django or Flask). 
          Perfect for building high-performance, SEO-optimized applications that require dynamic content and robust back-end services.`,
        icon: NextJsIcon,
      },
      {
        title: "Python + Firebase",
        description: `An excellent choice for serverless applications requiring real-time data syncing, user authentication, and storage. Firebase handles authentication, data syncing, and storage, 
          while Python (Django/Flask) provides custom server-side logic and APIs. Ideal for scalable, modern, and real-time applications.`,
        icon: FirebaseIcon,
      },
    ],
  };
  
  
  

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default PythonDeveloper;
