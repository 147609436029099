import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import KotlinIcon from "../../../Images/icon/Kotlin.svg";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import SpringBootIcon from "../../../Images/icon/Spring.png";
import RestApiIcon from "../../../Images/icon/RestApiIcon.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/react_native_ic.webp";
import ReactNativeAppImg from "../../../Images/HireDeveloper/ReactNative.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const ReactNativeDeveloper = () => {

  const homeData = {
    title: "Hire <span>React Native App</span> Developers in India",
    description: `Empower your business with skilled React Native developers delivering cutting-edge cross-platform mobile solutions. 
            Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
            in React Native, JavaScript, TypeScript, Redux, REST APIs, and more, ensuring exceptional results. With flexible hiring models and a client-first 
            approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our React Native app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Develop stunning, high-performance apps for iOS and Android using a single codebase with React Native.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers have over 5 years of expertise in cross-platform app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We provide seamless UI/UX design consistency across multiple platforms.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Get maintainable, secure code with full protection of your intellectual property.",
      },
    ],
  };
  
  
  
  
  

  // hire-top-developer-data.ts
  const hireTopDeveloperData = {
    heading: "Hire Top React Native App Developers in India from Skyline Infosys",
    image: {
      src: ReactNativeAppImg, // Update the path to the React Native app image
      alt: "ReactNativeAppImg",
    },
    content: {
      description: `Looking to create versatile, scalable, and high-performance cross-platform applications? At Skyline Infosys, 
        we offer expert React Native app developers who specialize in building custom solutions for businesses across industries. 
        From crafting visually appealing UIs to integrating advanced functionalities, our developers deliver exceptional results tailored to your business needs.`,
      expertiseHeading: "Our React Native app developers excel in:",
      expertisePoints: [
        "Developing cross-platform mobile applications using React Native and JavaScript",
        "Building visually appealing, responsive, and consistent UI/UX designs",
        "Integrating APIs, Firebase, RESTful services, and third-party SDKs",
        "Optimizing apps for performance, speed, and smooth multi-platform compatibility",
        "Utilizing React Native’s rich component library and state management for fast development cycles",
      ],
      closingNote: `Collaborate with us to bring your React Native app ideas to life. 
        Hire dedicated React Native app developers from Skyline Infosys to create high-quality, feature-rich applications that elevate your business.`,
    },
  };
  
  
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack React Native Combinations",
    description: `At Skyline Infosys, we specialize in combining React Native development with powerful back-end and front-end technologies to build high-performance, cross-platform mobile applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "React Native + Firebase",
        description: `A perfect combination for serverless applications with real-time data synchronization, user authentication, and push notifications. Firebase manages the back-end, while React Native ensures consistent UI across platforms.`,
        icon: FirebaseIcon,
      },
      {
        title: "React Native + Node.js",
        description: `Ideal for building scalable, real-time applications. Node.js handles server-side logic, while React Native offers a responsive and engaging mobile UI for both iOS and Android.`,
        icon: NodeJsIcon,
      },
      {
        title: "React Native + Python/Django",
        description: `A great stack for complex apps requiring powerful back-end logic, data analytics, or AI integrations. Django provides a secure and scalable back-end, while React Native delivers a smooth cross-platform UI.`,
        icon: PythonIcon,
      },
      {
        title: "React Native + AWS",
        description: `Leverage AWS services for cloud hosting, storage, and serverless architecture. Combine with React Native to create reliable, scalable, and high-performance mobile applications.`,
        icon: AWSIcon,
      },
      {
        title: "React Native + GraphQL",
        description: `Enhance data fetching efficiency using GraphQL in your React Native apps. This combination ensures faster response times, reduced overfetching, and improved data handling for cross-platform development.`,
        icon: GraphQLIcon,
      },
      {
        title: "React Native + REST APIs",
        description: `Integrate with RESTful APIs for smooth data exchange and enhanced functionality. React Native handles the UI, while REST APIs manage back-end communication, ensuring seamless app performance.`,
        icon: RestApiIcon,
      },
      {
        title: "React Native + Kotlin Multiplatform",
        description: `Use Kotlin Multiplatform to share business logic between Android and iOS, paired with React Native to deliver a high-quality, cross-platform app that maintains a native experience.`,
        icon: KotlinIcon,
      },
      {
        title: "React Native + Java Spring Boot",
        description: `Combine React Native with Java Spring Boot for enterprise-grade apps. Spring Boot powers the back-end with secure APIs and microservices, while React Native delivers a dynamic cross-platform mobile experience.`,
        icon: SpringBootIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
        <div className="ios_page">
        <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
        </div>
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default ReactNativeDeveloper;
