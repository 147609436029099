import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { blogData } from "./blogData";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

const BlogDetails = () => {
  const { id } = useParams();

  const blog = blogData?.find((b) => b.id === id);

  if (blog === undefined) {
    return (
      <div className="text-center no_found ">
        <h2>
          <b> No Blog Found </b>
        </h2>
      </div>
    );
  }

  return (
    <>
      {blog.id === "angularjs-vs-reactjs" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Angular vs React| Pick Your JavaScript Champion</title>
          <meta
            name="description"
            content="
Confused between Angular and React? Learn key differences, pros & cons of each JavaScript framework to pick the best for your project."
          />
          <meta name="keywords" content="angular js vs react js" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "advantages-of-php" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Discover the Advantages of PHP for Web Development</title>
          <meta
            name="description"
            content="
Discover PHP's advantages for web development: easy learning, cost-effectiveness, and scalability. See why it's popular for businesses & developers "
          />
          <meta name="keywords" content="Advantages of PHP" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "java-vs-kotlin" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Java vs Kotlin| Choosing the Right Mobile Dev Tool</title>
          <meta
            name="description"
            content="
Java vs Kotlin in the mobile dev ring! Explore the strengths & weaknesses of each language to choose the champion for your app. "
          />
          <meta name="keywords" content="java vs kotlin" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "java-vs-kotlin" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Java vs Kotlin| Choosing the Right Mobile Dev Tool</title>
          <meta
            name="description"
            content="
Java vs Kotlin in the mobile dev ring! Explore the strengths & weaknesses of each language to choose the champion for your app. "
          />
          <meta name="keywords" content="java vs kotlin" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "php-frameworks" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Master Web Dev| Top 5 PHP Frameworks in 2021</title>
          <meta
            name="description"
            content="
 Explore the top 5 PHP frameworks to boost your web development skills in 2021. Learn about Python, Kotlin, Java, JavaScript/Node.js, and TypeScript "
          />
          <meta name="keywords" content="php frameworks" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "best-programming-language-to-learn" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Best programming language to learn in 2024, Launch Your Career
          </title>
          <meta
            name="description"
            content="
Uncertain which programming language to learn? Explore our guide to the top languages for various career paths and future-proof your skillset. "
          />
          <meta name="keywords" content="best programming language to learn" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "react-native-vs-flutter" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>React Native vs Flutter| Choosing the Right Framework</title>
          <meta
            name="description"
            content="
React Native vs Flutter: Explore pros, cons, and key differences like language, UI, performance, and popularity to make an informed decision. "
          />
          <meta name="keywords" content="react native vs flutter" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "web-design-and-development" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Web Design vs. Development| Decoded</title>
          <meta
            name="description"
            content="
 Is it design or code that makes a website? Explore the distinct roles of web design and development, and how they work.  "
          />
          <meta name="keywords" content="web design and development" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      {blog.id === "coding-vs-programing" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coding vs Programming| Demystifying the Difference</title>
          <meta
            name="description"
            content="
 Coding vs. Programming: Learn the key differences between coding and programming. Master both skills and unlock your potential in the tech world.  "
          />
          <meta name="keywords" content="coding vs programming" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
      )}

      <div className="blog_details">
        <Container>
          <div className="blog_data">
            <div className="blog_heading">
              <h1> {blog?.title} </h1>
              <p> {blog.date} </p>
              <img src={blog.image} alt={blog.image} loading="lazy" />
            </div>
            <div className="blog_content">{parse(blog.content)}</div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BlogDetails;
