import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiArrowLongRight } from "react-icons/hi2";
import { FaCalendarDays } from "react-icons/fa6";
import { blogData } from "../blogData";

const Blogs = () => {
  return (
    <section className="our_blogs">
      <Container>
        <h1 className="heading"> Our Blogs </h1>

        <Row className="my-5">
          <Col lg={8}>
            {blogData.map((obj, index) => {
              return (
                <div className="blog_box mt-4 d-flex gap-3 " key={index}>
                  <div className="left_img">
                    <img src={obj?.image} alt=""   loading="lazy" />
                  </div>
                  <div className="right_content">
                    <span className="d-flex align-items-center">
                      {" "}
                      <FaCalendarDays /> {obj.date}
                    </span>
                    <h6> {obj.title} </h6>
                    <p>{obj.description}</p>
                    <div className="text-end see_more">
                      <Link to={`/blog/${obj.id}`}>
                        See More <HiArrowLongRight />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
          <Col lg={4}>
            <div className="blog_box popular_post  mt-4">
              <h4>Popular Post</h4>
              <ul className="p-0 mt-3">
                {blogData.map((obj, index) => {
                  return (
                    <li>
                      {" "}
                      <Link to={`/blog/${obj.id}`}> {obj.title} </Link>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Blogs;
