import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../../Images/AboutUs/slide5.jpg";
import img3 from "../../../Images/AboutUs/clientvisited.png";
import img2 from "../../../Images/AboutUs/slide2.jpg";
import Tilt from "react-parallax-tilt";


const BusinessOperation = () => {
  return (
    <section className="business_operation">
      <Container>
        <h1 className="heading">Simplify your Business Operations</h1>
        <Row className="justify-content-center">
          <Col lg={10}>
            <p className="content_p my-5">
              Skyline Infosys can be your perfect choice for implementation of
              latest technologies that are more than buzz words these days, like
              big data, artificial intelligence, machine learning and much more.
              We also offer IT, database management, cloud implementation,
              development, consulting and many other services. Just name it and
              we have it.
            </p>
          </Col>
        </Row>
        <Row className="operation_content  justify-content-center operation_content1 ">
          <Col md={6} lg={5}>
            <Tilt>
              <div className="img_box">
                <img src={img1} className="img-fluid" alt="img1"  loading="lazy" />
              </div>
            </Tilt>
          </Col>
          <Col md={6} lg={5} className="white_bg border1">
            <div className="content">
              <h3>
                Innovation & Excellence are at the core of everything that we do
              </h3>
              <h6>Capitalize on the Offerings of Digital Transformation</h6>
              <p>
                Revolutionize the way you do your business by adopting the
                latest digital innovations. Let us help you successfully turn
                your digital transformation caterpillar into butterfly with
                proven techniques, that actually work!
              </p>
            </div>
          </Col>
        </Row>
        <Row className="operation_content  justify-content-center operation_content2 ">
          <Col md={6} lg={5} className="white_bg border2">
            <div className="content">
              <h3>Our Team</h3>
              <h6>A Classical Blend of Experience and Expertise</h6>
              <p>
                Skyline Infosys has an inhouse team of professionals who are
                adept at various latest technologies. Our team is our backbone.
                They have both the expertise and experience to offer companies
                IT solutions that work wonders for them.
              </p>
            </div>
          </Col>
          <Col md={6} lg={5}>
          <Tilt> 
          <div className="img_box">
              <img src={img2} className="img-fluid" alt="img1"  loading="lazy" />
            </div>
          </Tilt>
            
          </Col>
        </Row>

        <Row className="operation_content  justify-content-center operation_content3 ">
          <Col md={6} lg={5}>
          <Tilt> 
            <div className="img_box">
              <img src={img3} className="img-fluid" alt="img3"   loading="lazy"/>
            </div>
            </Tilt> 
          </Col>

          <Col md={6} lg={5} className="white_bg border3">
            <div className="content">
              <h3>A moment of a pride when a client visited Our team.</h3>
              <p>
                We felt immense pleasure in welcoming our esteemed client from
                Australia for team visit at Skyline Infosys. We are proud to be
                associated with and will always do our best to add value to our
                collaboration.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BusinessOperation;
