import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/kotlin/Kotlin-service.png";
import BannerImage1 from "../../../Images/Technology/kotlin/Kotlin-banner.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const KotlinService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top-Tier Kotlin Development Services | Skyline Infosys</title>
        <meta
          name="description"
          content="
Skyline Infosys offers efficient Kotlin app development, perfect for high-performance and modern mobile solutions. Partner with us for your app success."
        />
        <meta name="keywords" content="Kotlin development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="Kotlin App Development"
            content="Looking to build a high-performing, secure, and modern mobile app? Kotlin is the perfect choice for Android app development. At Skyline Infosys, we provide end-to-end Kotlin app development services to bring your ideas to life."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Kotlin App Development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Kotlin Development"
          title="Kotlin Development Services Across India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                As a leading Kotlin app development company in India, we offer a
                comprehensive range of Kotlin development services to help
                businesses unlock the potential of modern Android applications.
                Our team of skilled Kotlin developers specializes in building
                high-performance, feature-rich, and visually captivating apps
                for Android platforms.
              </p>
              <p>
                Our Kotlin app development services include app design,
                development, testing, deployment, and maintenance. We leverage
                the latest tools and technologies to deliver innovative Kotlin
                apps that ensure a seamless user experience and optimized
                performance.
              </p>
            </>
          }
        />

        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"kotlin"}
          heading="Why Should You Go For Kotlin App Development?"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Develop Kotlin Apps?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                As a leading Kotlin app development company, we follow a
                streamlined process to create high-quality, feature-rich Kotlin
                apps that align with our clients' business goals and exceed
                their expectations.
              </p>
              <p>
                Our team of expert Kotlin developers leverages Kotlin's modern
                syntax, seamless Android integration, and extensive library
                support to deliver high-performance, secure, and scalable mobile
                applications optimized for the latest Android features and APIs.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We Work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Framework",
            "Database",
            "Testing",
            "Project Management",
          ]}
          serviceName="Mobile Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work "
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default KotlinService;
