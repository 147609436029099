import React, { useState } from "react";
import {Col, Container, Modal, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import { API_ENDPOINT } from "../../../config/config";
import coding from "../../../Images/icon/coding.png"
import { Vacancy } from "../../../data";
import NoVacancy from "../../../Images/Common/NoVacancy.png"

const CurrentVacancy = () => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const [position, setPosition] = useState("");
  const [imgErr, setImgErr] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (role) => {
    setShow(true);
    setPosition(role);
  };


  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const digitsOnly = (value) => /^\d+$/.test(value);

  const initialValues = {
    Name: "",
    Email: "",
    CurrentSalary: "",
    ExpectedSalary: "",
    LeadReceivedFrom: "",
    NoticePeriod: "",
    Contact: "",
    LinkedinLink: "",
    Experience: "",
    Note: "",
    image: null,
    position: position,
  };

  const validationSchema = Yup.object({
    Name: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Please enter your Name"),
    Email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your Email"),
    CurrentSalary: Yup.string()
      .min(2, "Too Short!")
      .max(10, "You can add maximum 10 digits!")
      .required("Please enter your Current Salary")
      .test("Digits only", "The field should have digits only", digitsOnly),
    ExpectedSalary: Yup.string()
      .min(2, "Too Short!")
      .max(10, "You can add maximum 10 digits!")
      .required("Please enter your Expected Salary")
      .test("Digits only", "The field should have digits only", digitsOnly),
    LeadReceivedFrom: Yup.string().required(
      "Please enter the Lead Received From"
    ),
    Contact: Yup.string()
      .matches(phoneRegex, "Please Enter a 10 Digit number!")
      .min(10, "Too Short!")
      .max(12, "Too Long!")
      .required("Please enter your Contact"),
    NoticePeriod: Yup.string().required("Please enter your Notice Period"),
    LinkedinLink: Yup.string().url("Please enter a valid URL"),
    Experience: Yup.string().required("Please enter your Experience"),
    Note: Yup.string().min(2, "Too Short!").required("Please enter your Note"),
  });

  const onSubmit = async (values, { resetForm }) => {

    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Email", values.Email);
    formData.append("CurrentSalary", values.CurrentSalary);
    formData.append("ExpectedSalary", values.ExpectedSalary);
    formData.append("LeadReceivedFrom", values.LeadReceivedFrom);
    formData.append("NoticePeriod", values.NoticePeriod);
    formData.append("Contact", values.Contact);
    formData.append("LinkedinLink", values.LinkedinLink);
    formData.append("Experience", values.Experience);
    formData.append("Note", values.Note);
    formData.append("image", file);
    formData.append("position", position);

    if (!file) {
      setImgErr(true);
      return;
    }

    try {
      await axios.post(
        `${API_ENDPOINT}/auth/career/createcareerrequest`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      swal({
        title: "Thank You",
        text: "Career Form Submitted Successfully",
        icon: "success",
        button: "Ok",
      });
      resetForm();
      handleClose();
    } catch (error) {
      console.error("Invalid Detail", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setImgErr(false);
  };


  return (
    <section className="vacancy">
      <Container>
        <h1 className="heading"> Current Opportunities </h1>
        <p className="sub_title text-center mt-3">
          Become a part of Skyline Infosys to give a right space to your Career.
        </p>

{
  Vacancy.length > 0 ?    <Row className="justify-content-center mt-5">
  <Col lg={10}>
    <Accordion className="dropdownlist">
      {Vacancy.map((obj, index) => (
        <Accordion.Item eventKey={index} key={index}>
          <Accordion.Header>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div>
                 {obj.role}
              </div>
              <button
                className="apply_now"
                onClick={() => handleShow(obj.role)}
              >
                Apply Now
              </button>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="job_content">
              <div className="d-flex align-items-center mb-3 gap-3">
                <h6 className="mb-0">Experience : </h6>
                <p className="mb-0">{obj.experience}</p>
              </div>
              <div className="d-flex align-items-center mb-3 gap-3">
                <h6 className="mb-0">Position : </h6>
                <p className="mb-0">{obj.position}</p>
              </div>
              <div className="d-flex align-items-center mb-3 gap-3">
                <h6 className="mb-0">Job Type : </h6>
                <p className="mb-0">{obj.Type}</p>
              </div>
              <div className="mb-2">
                <h6 className="mb-0">Job Description : </h6>
                <p className="mb-0 mt-2">{obj.description}</p>
              </div>
              <div className="mb-2 gap-3">
                <h6 className="mb-0">Job Requirements : </h6>
                <ul className="mt-2">
                  {obj.Requirements.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  </Col>
</Row> : <div className="text-center">

  <img src={NoVacancy} alt={NoVacancy}   loading="lazy"/>
</div>
}
      
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply for the Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Row>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Candidate Name*</label>
                      <Field type="text" name="Name" />
                      <ErrorMessage
                        name="Name"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Email*</label>
                      <Field type="email" name="Email" />
                      <ErrorMessage
                        name="Email"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Current Salary*</label>
                      <Field type="text" name="CurrentSalary" />
                      <ErrorMessage
                        name="CurrentSalary"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Expected Salary*</label>
                      <Field type="text" name="ExpectedSalary" />
                      <ErrorMessage
                        name="ExpectedSalary"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Lead Received From*</label>
                      <Field type="text" name="LeadReceivedFrom" />
                      <ErrorMessage
                        name="LeadReceivedFrom"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Notice Period*</label>
                      <Field type="text" name="NoticePeriod" />
                      <ErrorMessage
                        name="NoticePeriod"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Contact*</label>
                      <Field type="text" name="Contact" />
                      <ErrorMessage
                        name="Contact"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>LinkedIn Profile</label>
                      <Field type="text" name="LinkedinLink" />
                      <ErrorMessage
                        name="LinkedinLink"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Experience*</label>
                      <Field type="text" name="Experience" />
                      <ErrorMessage
                        name="Experience"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="input_box">
                      <label>Upload Resume*</label>
                      <input
                        type="file"
                        name="image"
                        onChange={(e) => {
                          handleFileChange(e);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      {imgErr && (
                        <div className="error">Please select your Resume!</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <div className="input_box">
                      <label>Note*</label>
                      <Field as="textarea" name="Note" />
                      <ErrorMessage
                        name="Note"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="submit_button text-end mt-4">
                  <button
                    type="reset"
                    className="close_btn"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    close
                  </button>

                  <button type="submit" className="apply_now">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </section>


  );
};

export default CurrentVacancy;
