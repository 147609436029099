import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Python/python-service.png";
import BannerImage2 from "../../../Images/Technology/Python/python_banner.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const PythonService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trustworthy Python Development Service| Skyline Infosys</title>
        <meta
          name="description"
          content="
Experience superior Python development services at Skyline Infosys, where we deliver robust and scalable solutions tailored to your business."
        />
        <meta name="keywords" content="Python development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="Python Development"
            content="Our Python Software Development Services encompass building web applications, APIs, and enterprise solutions using Python frameworks like Django, Flask, and FastAPI. Since 2005, we have been assisting clients in rapidly developing scalable, efficient, and cutting-edge Python-based solutions tailored to their unique business needs."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Python development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Python Development"
          title="Python Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we employ a proven methodology to create
                robust and scalable Python applications, delivering high-quality
                solutions to a diverse range of industries globally. Python has
                become one of the most versatile and in-demand programming
                languages, known for its simplicity, flexibility, and powerful
                ecosystem. We offer comprehensive Python Development Services to
                enhance the agility, usability, and performance of your
                applications. Additionally, we provide complete maintenance and
                support services to help you seamlessly integrate new features
                and functionalities into your Python-based solutions. Our team
                of skilled Python developers is dedicated to delivering
                innovative and reliable software tailored to meet your unique
                business requirements.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"java"}
          heading="Why Should You Go For Python Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Python App Development?"
          image={BannerImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, our team excels in Python development,
                combining modern UI/UX design principles with strategic product
                development to cater to clients across diverse industries. Our
                skilled Python developers and designers are experienced in
                crafting tailored solutions for businesses of all sizes,
                including those in banking, insurance, telecommunications,
                healthcare, and more. We leverage proven methodologies to
                deliver cost-effective Python solutions that address specific
                business requirements. Committed to delivering exceptional
                quality, we aim to exceed client expectations and foster
                long-term partnerships through outstanding service and ongoing
                support.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default PythonService;
