import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const Banner = ({
  heading,
  backGroundImg,
  content,
  title,
  btnText,
  subTitle,
  showInfo,
}) => {
  return (
    <>
      <div
        className="banner"
        style={{ backgroundImage: `url(${backGroundImg})` }}
      />

      {Array.from({ length: 11 }, (_, index) => (
        <span key={index} className="dots"></span>
      ))}

      <Container className="h-50">
        <Row className=" h-100">
          <Col lg={8}>
            <div className="content py-5">
              <h1> {heading} </h1>
              <TypeAnimation
                sequence={[title, 1000, "", 1000]}
                speed={50} 
                style={{ fontSize: "2em" }}
                repeat={Infinity} 
              />

              <p>{content}</p>

              <h4 className="mt-3 mb-4"> {subTitle} </h4>

              <Link to="/contact" className="mt-3 button">
                {btnText}
              </Link>
            </div>

            <div className="our_support_box">
              <div className="box text-center">
                <h4>
                  <CountUp start={0} end={100} duration={5} />+
                </h4>
                <h5>Success Job</h5>
              </div>
              <div className="box text-center">
                <h4>
                  {" "}
                  <CountUp start={0} end={50} duration={5} />+
                </h4>
                <h5>Developers Team</h5>
              </div>
              <div className="box text-center">
                <h4>
                  {" "}
                  <CountUp start={0} end={100} duration={5} />+
                </h4>
                <h5>Happy Clients</h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Banner;
