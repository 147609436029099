// CaseStudyData.js - Create a separate file for the case study data

import Image1 from "../../Images/CaseStudies/Realtime.jpg";
import Image2 from "../../Images/CaseStudies/Atithibhavan.jpg";
import Image3 from "../../Images/CaseStudies/Pinaheart.jpg";
import Image4 from "../../Images/CaseStudies/Steereducation.jpg";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { TbDeviceImacCog } from "react-icons/tb";
import { LiaSearchengin } from "react-icons/lia";
import { GrDeploy } from "react-icons/gr";

export const caseStudies = [
  {
    id: "realtime-conveyancer",
    title: "Realtime Conveyancer",
    description:
      "Realtime Conveyancer is a transaction management system designed to streamline the conveyancing process. The platform helps conveyancers reduce business risk, increase human connection, and drive business performance",
    image: Image1,
    path: "/case-studies/realtime-conveyancer",
  },

  {
    id: "Atithi-Bhavan",
    title: "Atithi Bhavan",
    description:
      "Atithi Bhavan connects travelers with religious and community accommodations like hotels, temples, Samaj Wadis, and Dharamshalas. We simplify bookings by providing direct property contacts and discount codes, ensuring affordable stays. Through a structured backend with admin and super admin roles, we ensure accurate listings and seamless communication between travelers and property owners, making bookings easier and more cost-effective.",
    image: Image2,
    path: "/case-studies/atithi-bhavan",
  },

  {
    id: "Steer-Education",
    title: "Steer Education",
    description:
      "STEER is the UK's longest-running tracker of student well-being, providing schools with peer-reviewed tools to improve student mental health since 2015. With nearly a decade of experience, STEER has become a trusted partner for schools across the country, helping educators identify mental health risks early and implement effective interventions.",
    image: Image4,
    path: "/case-studies/steer-education",
  },
  // {
  //   id: "Pinaheart",
  //   title: "Pinaheart",
  //   description:
  //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores obcaecati eos quod ex quo saepe ab. Corporis excepturi omnis iste corrupti ad maiores consequuntur fugiat. Necessitatibus temporibus consequatur aliquam quod.",
  //   image: Image3,
  //   path: "/case-studies/atithi-bhavan",
  // },
];

export const caseStudiesProcess = [
  {
    step: "Step 01",
    title: "Research",
    icon: <HiOutlineDocumentSearch />,
    description:
      "We dive deep into understanding your business goals, target audience, and technical requirements. Our research phase ensures a strategic foundation for your project.",
  },
  {
    step: "Step 02",
    title: "Development",
    icon: <TbDeviceImacCog />,
    description:
      "Our development phase focuses on creating robust, scalable, and efficient solutions tailored to your unique needs using the latest technologies and best practices.",
  },
  {
    step: "Step 03",
    title: "Testing",
    icon: <LiaSearchengin />,
    description:
      "We rigorously test every aspect of your project to ensure it meets the highest standards of quality, functionality, and performance, leaving no room for errors.",
  },
  {
    step: "Step 04",
    title: "Deploy",
    icon: <GrDeploy />,
    description:
      "Once tested and approved, we deploy your project seamlessly, ensuring smooth integration with your existing systems and providing ongoing support for a successful launch.",
  },
];
