/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import upwork from "../../../Images/Common/upwork-badge.webp";
import Google from "../../../Images/Common/google.png";
import Clutch from "../../../Images/Common/clutch-color.svg";
import Aws from "../../../Images/Common/aws-color.svg";
import microsoft from "../../../Images/Common/microsoft-silver.svg";
import Mamta from "../../../Images/Common/mamta.png";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  API_ENDPOINT,
  GOOGLE_RECAPTCHA_SITE_KEY,
} from "../../../config/config";

const ContactForm = ({ showAddress }) => {
  const captchaRef = useRef();

  // Validation schema using Yup
  const validationSchema = Yup.object({
    firstname: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("First Name is required."),
    lastname: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Last Name is required."),
    email: Yup.string()
      .email("Invalid email address!")
      .required("Email is required."),
    mobile: Yup.string().required("Mobile number is required."),
    country: Yup.string()
      .required("Country is required.")
      .test("String only", "The field should have string only!", (value) =>
        /^[a-zA-Z]*$/.test(value)
      ),
    message: Yup.string().min(2, "Too Short!").required("Message is required."),
  });

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    country: "",
    message: "",
  };

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false); // Track if there's a captcha error
  console.log(captchaToken, "captchaToken First");

  const handleRecaptchaChange = async (token) => {
    console.log(token, "token in handle change");
    setCaptchaToken(token);
  };
  const isCaptchaCompleted = (token) => !!token;

  const onSubmit = async (values, { resetForm }) => {
    // Check if the captcha is completed before submitting
    // if (!captchaToken) {
    //   setCaptchaError(true);
    //   return;
    // }

    try {
      const response = await fetch(
        `${API_ENDPOINT}/auth/contact/createcontact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
            token: captchaToken,
          }),
        }
      );

      const json = await response.json();
      if (json) {
        swal({
          title: "Thank You",
          text: "Your Form Submitted Successfully",
          icon: "success",
          button: "Ok",
        });

        setCaptchaToken(null); // Reset the captcha token
        // Reset the form and captcha
        resetForm({ values: initialValues });
      } else {
        console.error("Invalid Details");
      }
    } catch (error) {
      console.error("Submission error:", error);
      setCaptchaToken(null);
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }
  };

  return (
    <div className="contact_form">
      <Container>
        <h1 className="heading"> Let's Connect Here</h1>
        <div className="form_box my-md-5">
          <Row className="pb-lg-5">
            <Col lg={6}>
              <div className="content d-flex flex-column gap-3">
                <h5 className="mb-0">Book Free Consultation</h5>
                <p className="mb-0">
                  “ Get a 30-minute free consultation from a field expert.
                  Validate your idea for free and get a rough quote once you
                  complete this form. ”
                </p>

                <div className="estimates_box">
                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      1
                    </div>
                    <div>
                      <h3 className="mb-0">Fill up your details</h3>
                      <p>Get Custom Solutions, Recommendations, Estimates.</p>
                    </div>
                  </div>
                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      2
                    </div>
                    <div>
                      <h3 className="mb-0">What's next?</h3>
                      <p>
                        One of our Account Managers will contact you shortly.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex schedule  gap-4 align-items-center ">
                    <div className="img_box">
                      <img src={Mamta} alt="contactImg" />
                    </div>
                    <div>
                      <h3>Mamta J. </h3>
                      <p>Technology Consultant</p>
                    </div>
                  </div>
                </div>

                <Link
                  to="/free-consultation"
                  className="schedule_call_btn d-flex gap-2 align-items-center mt-4"
                >
                  <FaCalendarAlt className="shake-animate" />
                  Schedule a Call
                </Link>

                <div className="our_rating mt-4">
                  <img
                    className="upwork"
                    src={upwork}
                    alt={upwork}
                    loading="lazy"
                  />
                  <img src={Google} alt={Google} loading="lazy" />
                  <img src={Clutch} alt={Clutch} loading="lazy" />
                  <img src={Aws} alt={Aws} loading="lazy" />
                  <img src={microsoft} alt={microsoft} loading="lazy" />
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>First Name*</label>
                          <Field
                            type="text"
                            name="firstname"
                            placeholder="Enter Your First Name"
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>Last Name*</label>
                          <Field
                            type="text"
                            name="lastname"
                            placeholder="Enter Your Last Name"
                          />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>Email*</label>
                          <Field
                            type="text"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>Skype ID/Whatsapp No*</label>
                          <Field
                            type="text"
                            name="mobile"
                            placeholder="Skype ID/Whatsapp No"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <div className="input_box">
                          <label>Country</label>
                          <Field
                            type="text"
                            name="country"
                            placeholder="Enter Your Country Name"
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <div className="input_box">
                          <label>What's Your Project All About?</label>
                          <Field
                            as="textarea"
                            rows={5}
                            name="message"
                            placeholder="What's Your Project All About?"
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="input_box text-end">
                          {/* <ReCAPTCHA
                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                            ref={captchaRef}
                            onChange={handleRecaptchaChange}
                          /> */}
                          {captchaError && (
                            <div className="error-message">
                              Please complete the CAPTCHA.
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="mb-4 d-flex align-items-center justify-content-end"
                      >
                        <div className="input_box text-end">
                          <button
                            type="submit"
                            disabled={isSubmitting} // TO DO:: validate after captcha
                            // disabled={
                            //   isSubmitting || !isCaptchaCompleted(captchaToken)
                            // }
                            // className={`${
                            //   !isCaptchaCompleted(captchaToken)
                            //     ? "disable_btn"
                            //     : ""
                            // }`}
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

          {showAddress && (
            <div>
              <hr className="my-3 " />
              <Row className=" pt-lg-5 justify-content-center">
                <Col lg={12}>
                  <div className="contact_info d-flex justify-content-center">
                    <Row className="w-100 justify-content-center">
                      <Col lg={6} className="mb-3">
                        <div className="box">
                          <div className="d-flex gap-md-3 gap-1 flex-wrap">
                            <FaPhone />
                            <a href="tel:+919879170954">+91-987-917-0954</a> /
                            <a href="tel:+918128996966">+91-812-899-6966</a>
                          </div>
                          <div className="d-flex gap-md-3 gap-1">
                            <MdEmail />
                            <a href="sales@skylineinfosys.com ">
                              sales@skylineinfosys.com
                            </a>
                          </div>

                          <div className="d-flex gap-md-3 gap-1">
                            <FaLocationDot />
                            <a
                              href="https://maps.app.goo.gl/HaZXP5hLfdLxvZ8t5"
                              className="d-flex gap-md-3 gap-1"
                            >
                              B-805 to 809, IT Park, Opp. Gajera International
                              School (Digital Valley), Surat, Gujarat India
                            </a>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div className="map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9326057730145!2d72.8712654!3d21.234520900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3b445a16ef%3A0x13ef9f24ae995a3c!2sSkyline%20Infosys!5e0!3m2!1sen!2sin!4v1732601799623!5m2!1sen!2sin"
                            style={{
                              width: "100%",
                              height: "100%",
                              border: 0,
                            }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
