import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import Bannermage from "../../../Images/Technology/DotNet/dotnet_banner.png";
import Bannermage1 from "../../../Images/Technology/DotNet/dotnet_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const DotNetService = () => {
  return (
   <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Streamlined .NET Development Services | Skyline Infosys</title>
        <meta
          name="description"
          content="Looking for .NET solutions? Look no further. Our custom .NET development services deliver reliable, scalable solutions tailored to your needs."
        />
        <meta name="keywords" content=".net development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

    <div className="service technology">
      <div className="home ">
        <Banner
           backGroundImg={CommonBg}
          heading=".NET Development"
          content=".Net Core services that deliver reliable, scalable and process oriented solutions Develop mission-critical apps, portals, BI tools, chatbots CRM systems and a lot more with a full range of .Net Core development services."
          title="Development Company "
          btnText="Consult Skyline"
          subTitle="Explore .NET development Company"
        />
      </div>

      <DevelopmentInfo
        heading=".NET Development"
        title=".NET Development Services In all over India"
        image={Bannermage}
        content={
          <>
            <p className="my-3">
              We offer top-notch .NET development services throughout India,
              catering to the unique needs of businesses across various
              industries. Our team of experts is skilled in developing custom
              .NET applications that are scalable, reliable, and
              high-performing. We use the latest technologies and tools to
              create web applications, desktop applications, and mobile
              applications that meet your business requirements. Our .NET
              development services also include integration with third-party
              applications, database design and management, and migration to the
              latest .NET framework. With our focus on quality and customer
              satisfaction, we ensure that your project is delivered on time and
              within budget. Whether you're a startup or a large enterprise, we
              have the expertise to take your business to the next level with
              our .NET development services.
            </p>
          </>
        }
      />
      <TechnologiesFeatures
        technology={"dotnet"}
        data={technologyFeatureData}
        heading="Why Should You Go For .NET Development ?
"
      />

      <DevelopmentInfo
        heading=""
        title="How Do We Dynamic .NET Development?"
        image={Bannermage1}
        order={2}
        content={
          <>
            <p className="my-3">
              At our company, we use a dynamic approach to .NET development to
              create high-quality, customized applications for our clients. Our
              team of experts follows a well-defined process to ensure that the
              development process is smooth and efficient.
            </p>
            <p>
              First, we work closely with our clients to understand their
              business requirements and objectives. Based on this information,
              we create a detailed project plan that outlines the scope of the
              project, timeline, and budget.
            </p>
          </>
        }
      />

      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Backend",
          "Frontend",
          "Unit Testing",
          "Database",
          "Deployment",
          "Project Management",
          "CMS",
        ]}
        serviceName="Web Development"
      />
      <ServiceFromSkyline />
      <hr className="mb-0" />
      <AboutDevelopment
        heading="How we work"
        content="vuejs"
        data={ProjectPlanData}
      />
      <hr className="mb-0" />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
   </>
  );
};

export default DotNetService;
