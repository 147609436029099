import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../Images/Common/skyline-white.svg";
import { Link } from "react-router-dom";
import { MdLocalPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="justify-content-between">
          <Col xl={4} lg={4} md={5}>
            <div className="box1">
            <Link to="/"> 
            <img className="logo img-fluid" src={logo} alt="logo" />
            </Link>
              <p>
                Skyline Infosys is a global outsourcing partner that empowers
                small and medium-scale businesses with value-driven outsourcing
                web application development services. Our team thinks globally
                to deliver the best solutions for your outsourcing needs. Join
                hands with us and unlock your business's potential for growth
                and success.
              </p>
            </div>
          </Col>
          <Col lg={2} md={3}>
            <div className="box2 links_box">
              <h6> Company </h6>
              <ul className="link">
                <li>
                  <Link to="/about"> About Us</Link>
                </li>

                <li>
                  <Link to="/career"> career </Link>
                </li>
                <li>
                  <Link to="/blog"> blog </Link>
                </li>
                <li>
                  <Link to="/faq"> FAQ </Link>
                </li>
                <li>
                  <Link to="/portfolio"> Portfolio </Link>
                </li>
                <li>
                  <Link to="/life-skyline"> life-at-skyline </Link>
                </li>
                <li>
                  <Link to="/partnership"> partnership </Link>
                </li>
                <li>
                  <Link to="/contact"> Contact </Link>
                </li>
               
              </ul>
            </div>
          </Col>
          <Col lg={2} md={3}>
            <div className="box2 links_box">
              <h6> Service </h6>
              <ul>
                <li>
                  <Link to="/services/web-development"> Web Development </Link>
                </li>

                <li>
                  <Link to="/services/mobile-development">
                    {" "}
                    Mobile Development{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/services/custom-software"> Custom Software </Link>
                </li>
                <li>
                  <Link to="/services/qa-testing"> QA & Testing</Link>
                </li>
                <li>
                  <Link to="/services/staff-augmentation">
                    {" "}
                    Staff Augmentation{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/services/support-maintenance">
                    {" "}
                    Support & Maintenance{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/services/cloud">Cloud</Link>
                </li>
                <li>
                  <Link to="/services/devOps">DevOps</Link>
                </li>
                <li>
                  <Link to="/services/artificial-intelligence">
                    Artificial Intelligence
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={3} lg={4}>
            <div className="box2 contact">
              <h6> Contact Us</h6>
              <ul>
                <li>
                  <div className="icon">
                    <FaLocationDot />
                  </div>
                  <p>
                    B-805 to 809, IT Park, Opp. Gajera International School
                    (Digital Valley), Surat, Gujarat 394101
                  </p>
                </li>
                <li>
                  <div className="icon">
                    <MdLocalPhone />
                  </div>
                  <a href="tel:+918128996966"> +91-812-899-6966 </a>
                </li>
                <li>
                  <div className="icon">
                    <MdEmail />
                  </div>
                  <a href="mailto:info@skylineinfosys.com">
                    info@skylineinfosys.com
                  </a>
                </li>
                <li className="social">
                  <div className="media">
                    <a href="https://www.facebook.com/skylineinfosys">
                      <FaFacebook />
                    </a>
                  </div>
                  <div className="media">
                    <a href="https://www.linkedin.com/company/skylineinfosys/">
                      <FaLinkedin />
                    </a>
                  </div>
                  <div className="media">
                    <a href="https://www.instagram.com/skyline_infosys/">
                      <FaSquareInstagram />
                    </a>
                  </div>
                  <div className="media">
                    <a href="https://www.youtube.com/@SkylineInfo-jp2fv/featured">
                      <IoLogoYoutube />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <hr />

        <div className="d-flex bottom_footer align-align-items-center w-100 flex-wrap">
          <p  className="mb-0">
            ©2024 Skyline Infosys. All rights reserved
          </p>
          <ul className="p-0 m-0">
            <li>
              <Link to="/terms-and-conditions"> Terms and Conditions </Link>
              <Link to="/privacy-policy">Privacy Policy </Link>
              <Link>Site Map </Link>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
