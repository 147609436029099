import React from "react";
import Banner from "../../Component/Banner";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactForm from "../Contact/Components/ContactForm";
import Questions from "./Components/Questions";
import "./Faq.css";
import FAQBG from "../../Images/FAQ/fAQBg.jpg";

const Faq = () => {
  return (
    <div>
      <div className="home ">
        <Banner
          backGroundImg={FAQBG}
          heading="FAQ"
          content="Have questions about our services or technologies? Explore our FAQ section for instant answers to common queries. From understanding our processes to learning about our expertise, we’ve got you covered. Can’t find what you’re looking for? Reach out to our team for personalized assistance. We’re here to help you every step of the way!"
          title="Creative Plans."
          btnText="Consult Skyline"
        />
      </div>

      <Questions />

      <MajorClient />
      <Testimonials />
      <ContactForm />
    </div>
  );
};

export default Faq;
