import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Flutter/flutter_banner.png";
import BannerImage1 from "../../../Images/Technology/Flutter/flutter_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const FlutterService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Top-Tier Flutter App Development Services | Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Skyline Infosys offers efficient Flutter app development, perfect for cost-effective solutions. Partner with us for your app success"
        />
        <meta name="keywords" content="flutter development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
                   backGroundImg={CommonBg}

            heading="Flutter App Development"
            content="Do you want to develop an app that is cost-effective and efficient? If yes, then Flutter app development is the way to go. And Skyline Infosys is here to help you every step of the way."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Flutter App development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Flutter Development"
          title="Flutter Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                As a leading Flutter app development company in India, we offer
                a full range of Flutter app development services that help our
                clients leverage the power of this revolutionary technology. Our
                team of expert Flutter developers has the experience and
                expertise to create high-performance, feature-rich, and visually
                appealing mobile apps for both Android and iOS platforms.
              </p>
              <p>
                Our Flutter app development services include app design,
                development, testing, deployment, and maintenance. We use the
                latest tools and technologies to create cutting-edge Flutter
                apps that provide a seamless user experience across different
                devices and platforms
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"flutter"}
          heading="Why Should You Go For Flutter App Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Flutter App Development?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                As a leading Flutter app development company, we follow a
                streamlined process to develop high-quality and feature-rich
                Flutter apps that meet our clients' business objectives and
                exceed their expectations.
              </p>
              <p>
                Our team of expert Flutter developers uses the Dart programming
                language to develop the app, leveraging Flutter's rich set of
                pre-built widgets, libraries, and tools to create
                high-performance, feature-rich apps that are optimized for
                performance, security, and scalability.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Framework",
            "Database",
            "Testing",
            "Project Management",
          ]}
          serviceName="Mobile Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work "
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default FlutterService;
