import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import SpringBootIcon from "../../../Images/icon/Spring.png";
import RestApiIcon from "../../../Images/icon/RestApiIcon.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/Kotlin.svg";
import KotlinAppImg from "../../../Images/HireDeveloper/KotlinDevelopers.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const KotlinDeveloper = () => {

  const homeData = {
    title: "Hire <span>Kotlin App</span> Developers in India",
    description: `Leverage the power of Kotlin to build high-performance, modern Android applications with clean, maintainable code. 
              Our expert Kotlin app developers specialize in building scalable, secure, and efficient Android apps that meet your business needs. 
              With proficiency in Kotlin, Android SDK, Jetpack, and more, we provide top-tier Kotlin app development services. 
              Enjoy cost-effective solutions, flexible hiring models, and on-time delivery with Skyline Infosys!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Try our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Build modern, responsive, and high-performance Android apps using Kotlin and the latest Android technologies.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers have over 5 years of experience in Kotlin app development for native Android solutions.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure seamless integration with third-party APIs, libraries, and services for enhanced app functionality.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Get clean, maintainable, and secure code with full protection of your intellectual property.",
      },
    ],
  };
  
  
  

 const hireTopDeveloperData = {
  heading: "Hire Top Kotlin App Developers in India from Skyline Infosys",
  image: {
    src: KotlinAppImg, // Update the path to the Kotlin app image
    alt: "KotlinAppImg",
  },
  content: {
    description: `Looking to build high-performance, native Android applications? At Skyline Infosys, 
      we offer expert Kotlin app developers who specialize in creating custom Android mobile solutions for businesses across industries. 
      From crafting intuitive UIs to integrating advanced features, our developers deliver exceptional results, 
      leveraging Kotlin’s modern, concise, and efficient programming language for Android app development.`,
    expertiseHeading: "Our Kotlin app developers excel in:",
    expertisePoints: [
      "Building native Android applications using Kotlin and Android SDK",
      "Creating clean, intuitive, and responsive UI/UX designs for a seamless user experience",
      "Integrating APIs, Firebase, and third-party services for enhanced app functionality",
      "Optimizing apps for performance, speed, and battery efficiency on Android devices",
      "Leveraging Kotlin’s features like null safety, extension functions, and concise syntax for faster development cycles",
    ],
    closingNote: `Collaborate with us to bring your Android app ideas to life. 
      Hire dedicated Kotlin app developers from Skyline Infosys to build high-quality, scalable, and feature-rich Android applications that boost your business.`,
  },
};

  
  
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Kotlin Combinations",
    description: `At Skyline Infosys, we specialize in combining Kotlin app development with advanced back-end and front-end technologies to create scalable, high-performance native Android applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "Kotlin + Firebase",
        description: `A powerful combination for serverless Android applications with real-time data synchronization, user authentication, and push notifications. Firebase provides the back-end infrastructure, while Kotlin ensures smooth and responsive native Android UI.`,
        icon: FirebaseIcon,
      },
      
      {
        title: "Kotlin + Python/Django",
        description: `Perfect for building complex Android apps that require powerful back-end logic, data analysis, or AI integration. Django powers a secure and scalable back-end, while Kotlin provides a modern, user-friendly interface on Android.`,
        icon: PythonIcon,
      },
      {
        title: "Kotlin + AWS",
        description: `Leverage AWS services for scalable cloud solutions, including hosting, storage, and serverless architecture. Combined with Kotlin, this stack ensures reliable, high-performance Android applications.`,
        icon: AWSIcon,
      },
      {
        title: "Kotlin + GraphQL",
        description: `Enhance data fetching for your Android app with GraphQL. This combination ensures faster response times, reduced overfetching, and optimized data handling for native Android applications.`,
        icon: GraphQLIcon,
      },
      {
        title: "Kotlin + REST APIs",
        description: `Integrate seamlessly with RESTful APIs for data exchange and enhanced functionality. Kotlin handles the UI and app logic, while REST APIs manage back-end communication to ensure smooth performance.`,
        icon: RestApiIcon,
      },
      {
        title: "Kotlin + Kotlin Multiplatform",
        description: `Leverage Kotlin’s cross-platform capabilities with Kotlin Multiplatform to share business logic across both iOS and Android, ensuring a native-like experience on both platforms.`,
      },
      {
        title: "Kotlin + Java Spring Boot",
        description: `Combine Kotlin with Java Spring Boot for building enterprise-level Android apps. Spring Boot powers the back-end with secure APIs and microservices, while Kotlin provides a dynamic mobile experience for Android users.`,
        icon: SpringBootIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
        <div className="ios_page">
        <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
        </div>
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default KotlinDeveloper;
