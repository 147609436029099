import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import DotNetCoreIcon from "../../../Images/icon/dotnet_ic.webp";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import KubernetesIcon from "../../../Images/icon/Kubernetes.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/Azure.svg";
import AzureCloudImg from "../../../Images/HireDeveloper/AzureDevelopment.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const Azure = () => {

  const homeData = {
    title: "Hire <span>Azure App</span> Developers in India",
    description: `Empower your business with skilled Azure app developers delivering scalable and secure cloud-based solutions. 
          Leverage Azure’s robust cloud infrastructure for high-performance applications tailored to your business needs. 
          Our developers specialize in Azure services, cloud computing, app hosting, and integrating advanced features like AI, data analytics, and more. 
          With flexible hiring models and a client-first approach, we guarantee seamless collaboration and timely delivery. 
          Transform your ideas into reality with our Azure app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our in-house team brings over 5 years of experience in Azure cloud app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure the highest standards of security, scalability, and cost-efficiency in your cloud applications.",
      },
    ],
  };
  
  
  


  const hireTopDeveloperData = {
    heading: "Hire Top Azure Cloud Developers in India from Skyline Infosys",
    image: {
      src: AzureCloudImg, 
      alt: "AzureCloudImg",
    },
    content: {
      description: `Looking to harness the power of the cloud for your business? At Skyline Infosys, we offer expert Azure Cloud developers who specialize in building scalable, secure, and high-performance cloud solutions tailored to your business needs. From cloud migration to optimizing existing infrastructure, our team ensures seamless integration and robust cloud management for your applications.`,
      expertiseHeading: "Our Azure Cloud developers excel in:",
      expertisePoints: [
        "Designing and deploying secure and scalable cloud infrastructure using Microsoft Azure services",
        "Implementing cloud solutions with Azure App Services, Azure Functions, and Azure Kubernetes Service (AKS)",
        "Migrating on-premise infrastructure to Azure cloud with minimal downtime and optimized cost-efficiency",
        "Integrating AI, Machine Learning, and data analytics using Azure Cognitive Services and Azure ML",
        "Optimizing cloud resources with Azure’s cost management and performance monitoring tools",
        "Ensuring high levels of security and compliance with industry-leading security practices and Azure Security Center",
      ],
      closingNote: `Partner with us today to build scalable cloud solutions with Azure. Hire dedicated Azure Cloud developers from Skyline Infosys to maximize your cloud investment and drive your business to new heights with secure, optimized cloud services.`,
    },
  };
  

  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Azure Combinations",
    description: `At Skyline Infosys, we specialize in integrating Azure Cloud services with powerful back-end and front-end technologies to deliver scalable, secure, and high-performance cloud solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Azure + .NET Core",
        description: `Leverage the power of Microsoft’s .NET Core framework with Azure’s cloud services for scalable, secure, and high-performance applications. This combination is perfect for building enterprise-level applications with a robust back-end architecture hosted on Azure. `,
        icon: DotNetCoreIcon,
      },
      {
        title: "Azure + React.js",
        description: `Integrate Azure cloud services with React.js for building modern web applications. Azure can handle cloud hosting, databases, and serverless functions, while React.js powers the dynamic user interface for a seamless user experience.`,
        icon: ReactIcon,
      },
      {
        title: "Azure + Node.js",
        description: `Azure provides the cloud infrastructure, while Node.js serves as an efficient back-end for handling real-time data, APIs, and scalable applications. Together, they offer a robust full-stack solution for modern web apps.`,
        icon: NodeJsIcon,
      },
      {
        title: "Azure + Kubernetes",
        description: `Use Azure Kubernetes Service (AKS) to manage and orchestrate containerized applications with Azure’s cloud infrastructure. This combination ensures seamless deployment, scalability, and management of microservices-based architectures.`,
        icon: KubernetesIcon,
      },
      {
        title: "Azure + Python/Django",
        description: `Build scalable, secure, and high-performance back-end systems using Azure’s cloud services, integrated with Python's Django framework. This combination is ideal for data-intensive applications and AI-driven solutions.`,
        icon: PythonIcon,
      },
      {
        title: "Azure + Angular",
        description: `Combine Azure’s cloud platform with Angular to build dynamic, enterprise-grade web applications. Azure ensures reliable cloud hosting, storage, and databases, while Angular powers the front-end with an interactive, modern UI.`,
        icon: AngularIcon,
      },
      {
        title: "Azure + AI/ML",
        description: `Leverage Azure’s Cognitive Services and AI/ML tools to build intelligent, data-driven applications. Integrate these advanced capabilities with your app’s back-end, powered by Azure, for high-performance machine learning and AI-driven functionalities.`,
        icon: AiIcon,
      },
      {
        title: "Azure + DevOps",
        description: `Automate your application lifecycle using Azure DevOps tools. This combination allows seamless deployment, testing, and continuous integration, ensuring fast delivery and efficient cloud management for your projects.`,
        icon: DevOpsIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Azure;
