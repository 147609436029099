import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import CustomSoftwareImage from "../.../../../Images/Service/CustomSoftware/CustomSoftware.svg";
import { CustomSoftwareData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";

const CustomSoftware = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Custom Healthcare Software Development| Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Skyline Infosys provides custom healthcare software development services, tailored to your specific needs. Contact us for innovative solutions."
        />
        <meta name="keywords" content="custom healthcare software development" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="Custom Software"
            content="Are you looking for a trustworthy and inventive custom software development company? Then, Skyline Infosys is the answer! Our talented software engineers provide custom software development services that are personalized to your specific demands, goals, and brand identity."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Custom Software development Company"
          />
        </div>
        <DevelopmentInfo
          heading="Custom Software Development
"
          title="Top Custom Software Development Company"
          image={CustomSoftwareImage}
          content={
            <>
              <p className="my-3">
                Skyline is a frontrunner when it comes to providing custom
                software development services suited to your business's unique
                objectives, procedures, and needs. Our custom software
                development company will go far beyond your expectations in both
                features and quality.
              </p>
              <p>
                Our systematic methodology and client-friendly procedures allow
                businesses of all sizes to take advantage of and afford our
                top-tier custom software application development services. Let
                us put our business savvy and knowledge of the market to work
                for you so that you may take your ideas and grow your business.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Custom Software Development ?"
          data={CustomSoftwareData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "DevOps & Cloud",
            "Database",
            "Mobile",
            "Project Management",
            "CMS",
          ]}
          serviceName="Custom Software"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default CustomSoftware;
