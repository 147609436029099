import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Mongodb/mongodb_banner.png";
import BannerImage1 from "../../../Images/Technology/Mongodb/mongodb_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const MongodbService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hire Top MongoDB Developers | Skyline Infosys</title>
        <meta
          name="description"
          content="
Seeking top-notch MongoDB development services? Skyline Infosys offers customized solutions for businesses of all sizes."
        />
        <meta name="keywords" content="mongodb database" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
                   backGroundImg={CommonBg}

            heading="MongoDB Database"
            content="Solve pivotal database challenges with MongoDB development services Enlist managed MongoDB services to do the heavy lifting of your data management and lighten your load to focus on your apps, not your databases."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore MongoDB Database Company"
          />
        </div>

        <DevelopmentInfo
          heading="MongoDB Database"
          title="MongoDB Database Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                As a leading MongoDB database services provider in India, we
                offer comprehensive solutions for businesses of all sizes and
                domains. Our team of certified MongoDB developers has extensive
                experience in designing, developing, and deploying MongoDB-based
                solutions that are tailored to our clients' business
                requirements.We provide a wide range of MongoDB database
                services, including database design, installation and
                configuration, migration, maintenance, and support. Our experts
                work closely with our clients to understand their business
                requirements, and design and implement MongoDB-based solutions
                that are optimized for performance, scalability, and security.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"mongodb"}
          heading="Why Should You Go For MongoDB Database ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Create MongoDB Database Software?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                MongoDB is a popular NoSQL database software that is widely used
                for developing scalable and high-performance applications. It is
                a document-oriented database that stores data in flexible,
                semi-structured JSON-like documents, making it easy to work with
                and highly adaptable to changing business requirements.
              </p>
              <p>
                MongoDB is widely used in various industries, including
                healthcare, finance, e-commerce, and gaming, to develop
                high-performance, scalable, and reliable applications. As a
                flexible and scalable database software, MongoDB has become a
                popular choice among businesses of all sizes and domains looking
                for a modern and agile database solution.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Database",
            "Frontend",
            "Backend",
            "Unit Testing",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default MongodbService;
