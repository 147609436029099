import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Features from "../../../Images/Service/Features.webp";

const ServiceFromSkyline = () => {
  const countersData = [
    { count: 100, label: "Happy Clients" },
    { count: 150, label: "Projects" },
    { count: 100, label: " Success Job" },
  ];

  const featureItems = [
    "Futuristic Development Approach",
    "User-Centred Ideology",
    "Unmatched Performance Assurance",
    "Hassle-free Post-Launch Support & Maintenance",
    "Flexible Engagement Models",
    "Comprehensive Services - From Development to Hosting",
    "Robust Security Compliance",
    "Ready-to-Launch Solutions",
  ];

  return (
    <section className="skyline_service">
      <Container>
        <h1 className="heading ">
          Why Choose Mobile App Development Service From Skyline Infosys
        </h1>
        <Row className="justify-content-center">
          <Col lg={10}>
            <p className="sub_heading text-center">
              Crafting bespoke web application development services that
              resonate with customers, deliver measurable results, and keep you
              ahead of the curve in the dynamic digital landscape.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={5} className="d-flex justify-content-center">
            <ul className="p-0 m-0">
              {featureItems.map((item, index) => (
                <li key={index}>
                  <IoCheckmarkCircleSharp />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={5} className="d-flex justify-content-center">
            <img
              src={Features}
              alt={Features}
              className="img-fluid feature_img"
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-evenly my-5 flex-wrap gap-5 align-items-center">
          {countersData.map((item, index) => (
            <div
              key={index}
              className="box position-relative justify-content-center flex-column d-flex align-items-center"
            >
              <h4 className="d-flex justify-content-center flex-wrap align-items-center">
                <CountUp start={0} end={item.count} duration={25} />+
              </h4>
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ServiceFromSkyline;
