import React, { useState } from "react";
import { caseStudiesProcess } from "./CaseStudyData";
import { Col, Container, Row } from "react-bootstrap";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import { PiArrowBendDownRightBold } from "react-icons/pi";
import Image1 from "../../Images/CaseStudies/Realtime.jpg";
import { IoMdStar } from "react-icons/io";
import client from "../../Images/CaseStudies/client-benifit.jpg";

const RealtimeConveyancer = () => {
  const [tabName, setTabName] = useState("Business Needs");

  return (
    <div>
      <div className="case_study_details case_study">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11} className="case_content text-center">
              <h1>
                <span>Realtime Conveyancer</span>
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col lg={12}>
            <div className="industrial">
              <img src={Image1} alt={Image1} className="img-fluid" />
              <div className="industrial_content">
                <p>
                  Realtime Conveyancer is a transaction management system
                  designed to streamline the conveyancing process. The platform
                  helps conveyancers reduce business risk, increase human
                  connection, and drive business performance
                </p>
                <div className="d-flex flex-wrap align-items-center justify-content-between ">
                  <div>
                    <h4>Industry</h4>
                    <p>
                      <b>Real Estate - conveyancing</b>
                    </p>
                  </div>
                  <div>
                    <h4>Country</h4>
                    <p>
                      <b>Australia</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr className="m-0" />
      <div className="case_img_box">
        <Container>
          <div className="case_box position-relative">
            <img src={Image1} alt={Image1} />
          </div>
        </Container>
      </div>

      <hr className="m-0" />

      <Container>
        <Row className="case_content_box">
          <Col lg={12} className="px-3">
            <div className="case_content p-3">
              <h2 className="case_heading">About</h2>
              <p>
                {" "}
                Realtime Conveyancer is a powerful transaction management system designed to simplify and streamline the conveyancing process. By leveraging automation and secure document management, the platform enhances efficiency while reducing the risk of errors and delays. It fosters better collaboration through real-time updates and communication tools, ensuring transparency and compliance at every stage of the transaction. With a focus on increasing human connection, it enables seamless interactions between conveyancers, clients, agents, and legal professionals. Scalable for businesses of all sizes, Realtime Conveyancer helps drive performance, reduce business risks, and deliver faster, more reliable property settlements.

              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className=" my-5">
      <div className="process px-3">
      <h2 className="case_heading">Process</h2>
        <Row className="mt-4 justify-content-center">
          {caseStudiesProcess.map((step, index) => (
            <Col lg={3} md={4} sm={6} key={index} className="mb-3">
              <div className="card">
                <span>{step.step}</span>
                {step.icon}
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      </Container>

      <Container className=" my-5">
        <Row className="mt-5 client_benefits align-items-center">
          <Col md={6}>
            <h2 className="case_heading">Client Benefits</h2>
            <ul className="mt-4 p-0">
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Enhanced data security and compliance with data residency
                regulations.
              </li>
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Improved efficiency and reduced risk in the conveyancing process
              </li>
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Custom dashboard with comprehensive oversight
              </li>
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Personalizable interface for conveyancers and business owners.
              </li>
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Versatile reporting for better decision-making.
              </li>
              <li className="d-flex align-items-center gap-2">
                <IoMdStar />
                Compliant trust account management system
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <img src={client} alt={client} className="img-fluid" />
          </Col>
        </Row>
        <hr className="my-5" />

        <div className="challenges my-5">
          <h2 className="case_heading">Challenges </h2>
          <Row className="mt-4 justify-content-center">
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 1</span>
                <h5>Complex Data Management</h5>

                <p className="mb-1">
                  <b>Challenge</b> : Each matter involves multiple participants
                  with unique relationships and roles. The challenge was
                  designing a data structure that efficiently manages this
                  complexity.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Implemented a flexible database structure
                  that handles diverse participant types, relationships, and
                  nested data, reducing redundancy and maintaining clarity.
                </p>
              </div>
            </Col>
            <Col lg={4} className="border-left border_bottom border-right">
              <div className="card position-relative">
                <span> 2</span>
                <h5>Module Integration</h5>

                <p className="mb-1">
                  <b>Challenge</b> :Integrating multiple modules to ensure
                  seamless data flow, especially when managing data at both
                  organizational and matter levels
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Developed an interconnected system where
                  modules communicate effectively, with organization-level
                  settings overriding or supplementing matter-level data as
                  needed.
                </p>
              </div>
            </Col>
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 3</span>
                <h5>Role-Based Access Control</h5>

                <p className="mb-1">
                  <b>Challenge</b>: Implementing a role and permission system
                  that adapts to different organizations and users, ensuring
                  security without sacrificing flexibility
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Created a dynamic role and permission
                  structure that adjusts based on organization, user role, and
                  specific permissions, allowing tailored access to various
                  functionalities.
                </p>
              </div>
            </Col>
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 4</span>
                <h5>Jurisdictional Variations</h5>

                <p className="mb-1">
                  <b>Challenge</b> : Introducing new jurisdictions with distinct
                  rules required significant changes to the existing system.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Collaborated with multiple developers to
                  adapt each component to meet jurisdiction-specific
                  requirements, ensuring compliance and functionality
                </p>
              </div>
            </Col>
            <Col lg={4} className="border-left border_bottom border-right">
              <div className="card position-relative">
                <span> 5</span>
                <h5>Integration Issues</h5>

                <p className="mb-1">
                  <b>Challenge</b>:Integrating with PEXA and managing issues
                  related to the integration process.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Worked closely with the PEXA team to resolve
                  integration issues, ensuring reliable financial transactions
                  and smooth settlement processes
                </p>
              </div>
            </Col>
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 6</span>
                <h5>Template and Document Management</h5>

                <p className="mb-1">
                  <b>Challenge</b> : Handling dynamic, template-based documents
                  with merge fields across different organizations.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Developed a robust template management system
                  that supports organization-specific templates, allowing for
                  dynamic document creation based on merge fields and data from
                  the backend
                </p>
              </div>
            </Col>

            <Col lg={5} className="">
              <div className="card position-relative">
                <span> 7</span>
                <h5>Transaction Management and Reconciliation</h5>

                <p className="mb-1">
                  <b>Challenge</b> : Managing financial details, invoicing, and
                  reconciliation at both matter and organizational levels.
                </p>

                <p className="mb-1">
                  <b>Solution</b>:Implemented automated financial reconciliation
                  and robust financial management tools, ensuring accuracy in
                  transactions and trust balance sheets.
                </p>
              </div>
            </Col>
            <Col lg={5} className="border-left">
              <div className="card position-relative">
                <span> 8</span>
                <h5> Time Zone Management</h5>

                <p className="mb-1">
                  <b>Challenge</b>: Managing time zone differences for a global
                  user base, especially in time-sensitive financial
                  calculations.
                </p>

                <p className="mb-1">
                  <b>Solution</b> : Created a comprehensive time zone management
                  system that ensures accurate time-dependent data processing,
                  improving user experience and financial accuracy across
                  multiple time zones.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="process_tab_box">
          <Row className="justify-content-center">
            <Col lg={10} className="process_tab_list">
              <ul className="p-0">
                <li
                  onClick={() => setTabName("Business Needs")}
                  className={`${tabName === "Business Needs" ? "active" : ""}`}
                >
                  Business Needs
                </li>
                <li
                  onClick={() => setTabName("Objectives Achieved")}
                  className={`${
                    tabName === "Objectives Achieved" ? "active" : ""
                  }`}
                >
                  Objectives Achieved
                </li>
                <li
                  onClick={() => setTabName("Technologies Used")}
                  className={`${
                    tabName === "Technologies Used" ? "active" : ""
                  }`}
                >
                  Technologies Used
                </li>
              </ul>
            </Col>
            <Col lg={10} className="mt-4">
              {tabName === "Business Needs" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Transaction Management System (TMS):
                        </h6>
                        <p>
                          Enhances accuracy, clarity, speed, and flexibility in
                          managing transactions.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Pathway To Settlement (PTS) App
                        </h6>
                        <p>
                          Tracks steps from contract to keys, allowing secure
                          document signing and bank account detail submission.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Workflow Optimization
                        </h6>
                        <p>
                          Allows for the creation and tracking of processes for
                          best practices.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Document Management
                        </h6>
                        <p>
                          Facilitates receiving, customizing, and managing offer
                          and acceptance contracts.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Objectives Achieved" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <p className="p-0">
                          Successfully managed complex data relationships and
                          module integrations
                        </p>
                      </li>
                      <li>
                        <p className="p-0">
                          Implemented a dynamic role and permission management
                          system
                        </p>
                      </li>

                      <li>
                        <p className="p-0">
                          Seamlessly added new jurisdictions and managed
                          compliance.
                        </p>
                      </li>

                      <li>
                        <p className="p-0">
                          Developed efficient workflows and integrated with
                          external systems.
                        </p>
                      </li>
                      <li>
                        <p className="p-0">
                          Created a robust financial management system with
                          auto-finance features
                        </p>
                      </li>
                      <li>
                        <p className="p-0">
                          Ensured accurate time zone management for global users
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Technologies Used" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <p className="p-0 mb-2 mt-0">React Js, Typescript </p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Material ui </p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Node js, express js</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Postgresql</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Jest</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Sellenium</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Liquibase</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Swagger</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Micro service</p>
                      </li>
                      <li>
                        <p className="p-0 mb-2 mt-0">Objection JS with Knex</p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>

      <MajorClient />
      <Testimonials />
      {/* <ContactForm /> */}
    </div>
  );
};

export default RealtimeConveyancer;
