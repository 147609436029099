import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ChooseUs = () => {
  return (
    <div>
      <section className="choose_us">
        <Container>
          <h1 className="heading"> Why Choose Us?</h1>
          <Row className="justify-content-center my-5">
            <Col lg={10}>
              <div className="content d-flex flex-column gap-4  text-center">
                <p>
                  The team at Skyline Infosys celebrates the birthday of all the
                  employees and festivals too. Many of the major Indian and
                  International festivals are celebrated at our premises. We
                  love to see our employees participate in the celebrations and
                  enjoy themselves. There's a lot of fun and thrill to work at
                  Skyline Infosys.
                </p>
                <p>
                  The friendly and favorable work environment motivates the
                  employees to work harder. We can assure that we would never
                  let you down when you join hands with us. We are always there
                  to lend a helping hand and help you build effective career and
                  professional growth.
                </p>

                <div>
                  <h5 >
                    
                    <b>
                      
                      Join us and be a part of the team! You can Mail Your
                      Resume on
                    </b>
                  </h5>
                  <a
                    className="d-flex justify-content-center"
                    href="mailto:info@skylineinfosys.com"
                  >
                    info@skylineinfosys.com
                  </a>
                </div>
                <Link className="line_skyline" to="/life-skyline">
                  Life @ Skyline
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ChooseUs;
