import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { MdEmail } from "react-icons/md";
import swal from "sweetalert";
import "../../src/Style/HireModal.css";
import { FaPhoneAlt, FaUserAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiRotaryPhone } from "react-icons/gi";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../config/config";
import { RiMessengerFill } from "react-icons/ri";

const validationSchema = Yup.object({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Full Name is required."),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is required."),
  mobile: Yup.string()
    .matches(/^\d+$/, "Mobile number must be numeric.")
    .required("Mobile number is required."),
  requirement: Yup.string()
    .min(2, "Too Short!")
    .required("Requirement is required."),
});

const HireDeveloperModal = ({ handleClose, show }) => {
  const captchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaError, setCaptchaError] = useState(false);

  const handleRecaptchaChange = () => {
    const token = captchaRef.current.getValue();
    if (token) {
      setCaptchaToken(token);
    }
    setCaptchaError(false); // Reset error when captcha is completed
  };

  const initialValues = {
    fullName: "",
    email: "",
    mobile: "",
    requirement: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    try {
      //   const response = await fetch(`${API_ENDPOINT}/auth/contact/createcontact`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ ...values, token: captchaToken }),
      //   });
      // const json = await response.json();
      const json = true;
      handleClose();
      if (json) {
        swal({
          title: "Thank you!",
          text: " Your form has been successfully submitted. Our team will response you within 24 hours",
          icon: "success",
          button: "Ok",
        });

        resetForm({ values: initialValues });
        captchaRef.current.reset();
        setCaptchaToken("");
      } else {
        console.error("Invalid Details");
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="p-0">
          <div className="main_modal_box">
            <div className="left_side_box">
              <div className="contact_content">
                <h2>Contact Us</h2>

                <div className="detail_box mt-4">
                  <FaPhoneAlt />
                  <div>
                    <a href="tel:+918128996966">+91-812-899-6966</a>
                  </div>
                </div>
                <div className="detail_box mt-3">
                  <GiRotaryPhone />
                  <div>
                    <a href="tel:+912614514875">0261 4514875</a>
                  </div>
                </div>
                <div className="detail_box mt-3">
                  <MdEmail />
                  <div>
                    <a href="mailto:info@skylineinfosys.com">
                      info@skylineinfosys.com
                    </a>
                  </div>
                </div>
                <div className="detail_box mt-3">
                  <MdEmail />
                  <div>
                    <a href="mailto:sales@skylineinfosys.com">
                      sales@skylineinfosys.com
                    </a>
                  </div>
                </div>

                <div className="detail_box mt-3">
                  <FaLocationDot />
                  <div>
                    <a href="https://maps.app.goo.gl/HaZXP5hLfdLxvZ8t5">
                      {" "}
                      B-805 to 809, IT Park, Opp. Gajera International School
                      (Digital Valley), Surat, Gujarat 394101
                    </a>
                  </div>
                </div>

                <div className="map mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9326057730145!2d72.8712654!3d21.234520900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3b445a16ef%3A0x13ef9f24ae995a3c!2sSkyline%20Infosys!5e0!3m2!1sen!2sin!4v1732601799623!5m2!1sen!2sin"
                    title="map"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
            <div className="right_side_box position-relative">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {() => (
                  <FormikForm className=" ">
                    <h2>40-hour Trial, 100% Risk Free</h2>

                    <div className="input_box  mt-3">
                      <div className="position-relative">
                        <FaUserAlt />
                        <Field
                          type="text"
                          name="fullName"
                          placeholder="Enter Your Full Name"
                        />
                      </div>
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="input_box ">
                      <div className="position-relative">
                        <MdEmail />
                        <Field
                          type="text"
                          name="email"
                          placeholder="Enter Your Email"
                        />
                      </div>

                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="input_box position-relative">
                      <div className="position-relative">
                        <FaPhoneAlt />
                        <Field
                          type="text"
                          name="mobile"
                          placeholder="Enter Your Number"
                        />
                      </div>
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="input_box position-relative">
                      <div className="position-relative long_text">
                        <RiMessengerFill />
                        <Field
                          as="textarea"
                          name="requirement"
                          rows={4}
                          placeholder="How Can We Help  (Requirement)"
                        />
                      </div>
                      <ErrorMessage
                        name="requirement"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="recaptcha input_box position-relative">
                      {/* <ReCAPTCHA
                        sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                        ref={captchaRef}
                        onChange={handleRecaptchaChange}
                      /> */}
                      {captchaError && (
                        <div className="error">Please complete the CAPTCHA</div>
                      )}
                    </div>

                    <div className="input_box d-flex  gap-3 justify-content-center position-relative">
                      <button
                        onClick={handleClose}
                        type="submit"
                        className="submit cancel"
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        disabled={true}
                        // disabled={!captchaToken}
                        className="submit disable_btn"
                        // className={`submit ${
                        //   !captchaToken ? "disable_btn" : ""
                        // }`}
                      >
                        Submit
                      </button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HireDeveloperModal;
