import React from "react";
import Banner from "../../Component/Banner";
import "./blog.css";
import Blogs from "./Components/Blogs";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactForm from "../Contact/Components/ContactForm";
import { Helmet } from "react-helmet";
import BlogBg from "../../Images/Blogs/blogBg1.jpg";

const Blog = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs | Explore the latest Tech Trends</title>
        <meta
          name="description"
          content="Discover our blog's easy-to-read articles and explore the latest trends in IT from React vs Angular, PHP advantages, and more."
        />
        <meta name="keywords" content="Blogs" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="home ">
        <Banner
          backGroundImg={BlogBg}
          heading="Our blogs"
          content="is your go-to resource for the latest industry insights and trends. From technology tips to business growth strategies, we cover a wide range of topics. Our expert articles are designed to inspire, educate, and keep you ahead of the curve. Dive into our blog to explore ideas that drive innovation. Stay informed, stay inspired!"
          title="Work On Time."
          btnText="Consult Skyline"
       
        />
      </div>
      <Blogs />
      <MajorClient />
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Blog;
