import "./App.css";
import { Link, matchPath, Route, Routes, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Component/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Component/Footer";
import routes from "./config/routes";
import { useEffect, useState } from "react";
import ErrorPage from "./Pages/404Page";
import Loading from "./Component/Loading";

function App() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // Scroll to the top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowLoader(true); 
    const timer = setTimeout(() => setShowLoader(false), 1000);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Check if the current route is a 404 page (catch-all route)
  const is404Page = !routes.some((route) =>
    matchPath(route.path, location.pathname)
  );

  if (showLoader) {
    return (
     <Loading/>
    );
  }

  return (
    <div className="App animate__animated  animate__fadeIn ">
      {!is404Page && <Header />}
      <Routes>
        {/* Define all your routes here */}
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {!is404Page && <Footer />}
      {!is404Page && (
        <Link
          to="/free-consultation"
          className={`free_consultation ${scrolled ? "show" : ""}`}
        >
          Free Consultation
        </Link>
      )}
    </div>
  );
}

export default App;
