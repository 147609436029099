import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientVisit from "../../../Images/LifeSkyline/client_visit_team_lunch.webp";
import HealthFitness from "../../../Images/LifeSkyline/Health_Fitness_session.webp";
import PanipuriParty from "../../../Images/LifeSkyline/Panipuri_Party.webp";
import Office_Puja from "../../../Images/LifeSkyline/Office_Puja.webp";
import independenceDay from "../../../Images/LifeSkyline/77_independence_day.png";
import orsang_camp from "../../../Images/LifeSkyline/orsang_camp.png";
import company_funday from "../../../Images/LifeSkyline/company_funday.jpeg";
import Winter_special_picnic from "../../../Images/LifeSkyline/Winter_special_picnic.jpeg";
import picnic_diwali from "../../../Images/LifeSkyline/picnic_diwali.png";
import client_banner from "../../../Images/LifeSkyline/client_banner.jpeg";
import dinner_party from "../../../Images/LifeSkyline/dinner_party.png";
import star_award from "../../../Images/LifeSkyline/star_award.png";
import snacks_party from "../../../Images/LifeSkyline/snacks_party.jpeg";
import unity from "../../../Images/LifeSkyline/unity.png";
import august15 from "../../../Images/LifeSkyline/15_august.png";
import company_anniversary from "../../../Images/LifeSkyline/company_anniversary.png";
import holi_Celebration from "../../../Images/LifeSkyline/holi.png";
import WorkAnniversary from "../../../Images/LifeSkyline/parth_work_anni.png";
import TeamOuting from "../../../Images/LifeSkyline/TeamOuting.png";
import teamActivity from "../../../Images/LifeSkyline/team_activity.png";
import PotluckLunch from "../../../Images/LifeSkyline/PotluckLunch.jpg";
import Movie from "../../../Images/LifeSkyline/Movie.jpg";


const TimeLine = () => {
  const timeLineData = [
    {
      title: "Movie day at Skyline Infosys: Pushpa 2 – The Bold Adventure",
      image: Movie,
      description:
        "Skyline Infosys hosted an action-packed movie night featuring the thrilling blockbuster, *Pushpa 2*! Our team experienced intense drama, jaw-dropping action, and gripping storytelling. The excitement was palpable as we cheered for Pushpa’s bold journey. It was a cinematic experience that kept everyone on the edge of their seats!",
      date: "Dec 14th, 2024",
    },
    {
      title: "Savoring Togetherness: A Memorable Potluck Lunch !!",
      image: PotluckLunch,
      description:
        "We celebrated with an amazing Potluck Lunch filled with mouthwatering dishes, laughter, and unforgettable moments. Grateful for our incredible team that makes every gathering special. Here’s to great food and even better company. With a delightful spread of homemade treats and a buzz of joy in the air, the energy was truly uplifting",
      date: "Oct 11th , 2024",
    },
    {
      title: "Honored Client Visit & Team Lunch",
      image: ClientVisit,
      description:
        "We recently had the pleasure of hosting a client visit. After productive discussions and meaningful exchanges, we came together for a delicious meal, fostering stronger connections and camaraderie. It was a fantastic opportunity to engage with our clients in a relaxed setting, building relationships and enjoying great food together.",
      date: "Jul 8th , 2024",
    },
    {
      title: "Health & Fitness Extravaganza: Zumba, Aerobics, and fun!",
      image: HealthFitness,
      description:
        "Our Health & Fitness Extravaganza was a fantastic success! We kicked off with high-energy Zumba and invigorating aerobics. The session was filled with laughter, great music, and a chance to boost our wellness together. We wrapped up with a refreshing cool-down and shared interactive wellness tips. It was an enjoyable day that truly celebrated health, fitness, and team spirit!.",
      date: "Jun 29th , 2024",
    },
    {
      title: "Panipuri Party: A Flavorful Lunch Celebration!",
      image: PanipuriParty,
      description:
        "Our team enjoyed a delectable Panipuri Party, making lunch time a festive occasion! We indulged in an array of crispy, tangy, and spicy panipuri delights, creating a burst of flavors with every bite. It was a wonderful experience of sharing delicious food and good company, bringing us all together for a memorable and fun-filled meal!.",
      date: "Jun 22nd , 2024",
    },
    {
      title: "Celebrating Our New Office Inauguration with Puja Ceremony",
      image: Office_Puja,
      description:
        "On the occasion of our new office inauguration, we come together with our team to mark this special milestone. We’ll start with a traditional Puja ceremony to bless our new workspace and then enjoy a team lunch. as we celebrate this exciting new chapter with joy and togetherness!",
      date: "Mar 30th , 2024",
    },
    {
      title: "Celebrating 77th Independence Day",
      image: independenceDay,
      description:
        "On the 77th Independence Day, we celebrate the day with employees, organize a drawing competition and had Fun, give a speech on Freedom!.",
      date: "Aug 15th , 2023",
    },
    {
      title:
        "On the occasion of our 9th #anniversary we planned a Company trip on 8,9th July '23",
      image: orsang_camp,
      description:
        "'m delighted to share some incredible moments from our recent team trip! The sun, forest, mountains, and our happiness made it an unforgettable experience filled with cherished memories and strengthened our team's bonds like never before 😀 😃",
      date: "Jul 8th , 2023",
    },
    {
      title: "Saturday Funday Activity!!",
      image: company_funday,
      description:
        "The Fun Friday event was not just about playing games; it was also an opportunity for employees to socialize and connect with each other. The event had a relaxed and informal atmosphere, allowing employees to let their hair down and have some fun. They shared laughs, jokes, and bonded over their love for the games.",
      date: "Apr 29th , 2023",
    },
    {
      title: "Winter special picnic!",
      image: Winter_special_picnic,
      description:
        "Picnic season is back this winter! Our team attended our annual picnic at Dummas beach. A team that enjoys together, stays toy! Nothing better than playing games under blue skies and enjoying at beach. It was great to spend time outside with Skyline family members.",
      date: "Dec 31st , 2022",
    },
    {
      title: "Farmhouse Celebration!",
      image: picnic_diwali,
      description:
        "This is how we celebrated Diwali over the weekend. This farmhouse celebration was a lot of fun for us. It has enjoyable games, a Meggie party, a pool party, and other things. Spend unforgettable moments together.",
      date: "Oct 22nd , 2022",
    },
    {
      title: "A moment of a pride when a client visited Our team.",
      image: client_banner,
      description:
        "We felt immense pleasure in welcoming our esteemed client from Australia for team visit at Skyline Infosys. We are proud to be associated with and will always do our best to add value to our collaboration. Nothing makes us happier than meeting our clients! It was a pleasure to meet them in person. Their feedback motivates us to do better and helps us in growing higher! Thank you for believing in us! We are looking forward to sharing this collaboration for long.",
      date: "Apr 8th , 2022",
    },
    {
      title: "Dinner Party!",
      image: dinner_party,
      description:
        "IT'S DINNER TIME!! Skyline Infosys organizes a dinner party on special occasions. Here is a glimpse of our last dinner party we organized in the month of November. The team was treated to a special dinner with us to say thank you for all of their hard work and dedication.",
      date: "Nov 11th , 2021",
    },
    {
      title: "Rising Stars Of Skyline Infosys",
      image: star_award,
      description:
        "We believe that a well-appreciated employee leads to making the organization more productive and positive. On 3rd November we Organized to give awards to appreciate their hard work and dedication. Those are the shining stars of our organization and they seem so Happy by receiving awards that they deserve.",
      date: "Nov 4th , 2021",
    },

    {
      title: "Snacks Party!",
      image: snacks_party,
      description:
        "Snack party is also part of Skyline infosys. when it comes to snack party every member of the company comes together and enjoys the snack party. They take pleasure to have a snack together like a family.it looks like we all are committed with pizza ",
      date: "Oct 10th , 2021",
    },
    {
      title: "Team Outing",
      image: unity,
      description:
        "Relaxation is an important part of increasing the productivity of the team, so we decided to take a break and went on a trip to enjoy nature.We had the pleasure of hanging out with our team at the world's tallest statue Statue of Unity Official.We really enjoyed the beauty of this place and got a chance to know that how they developed the world's tallest statue in just 46 months of short period. We got a chance to create our lifetime memories with our team.Here are the glimpses of our Saturday outing to Statue of Unity Official.",
      date: "Sep 9th , 2021",
    },
    {
      title:
        "Some Unforgettable Moments Back Then #15thaugust's Celebration At #SkylineInfosys.",
      image: august15,
      description:
        "We have celebrated 15th august uniquely and it includes some attractive decorations, singing the national anthem, making canvas painting of our freedom fighter, making rangoli, and yes some snack party !! we really enjoyed celebrating our 75th independence day this way.",
      date: "Aug 15th , 2021",
    },
    {
      title: "Our Beginning Of 8th Year With More Hard Work And Success.",
      image: company_anniversary,
      description:
        "We celebrated our 7th corporate anniversary. company anniversary day is not just a day. It's also a day for appreciating your colleagues, staff, associates, bosses, CEO for all the support and efforts they put into the company.Your dedication and creativity can only be matched with an unquenchable thirst for success. On this special, Congratulations dears for all your efforts and accomplishments!.",
      date: "Jul 7th , 2021",
    },
    {
      title: "Holi Celebration!",
      image: holi_Celebration,
      description:
        "No color is as bright as the color of success...On 10 March 2020, our team celebrated Holi the festival of colors. the company celebrates every festival to keep the company's environment positive and cheerful and positive. happy employee leads the company to the door of success. that's all our company wants!! for us the happiness of our team and success matter the most.",
      date: "Mar 10th , 2021",
    },
    {
      title: "Work Anniversary!",
      image: WorkAnniversary,
      description:
        "A well-appreciated employee builds a ladder of company growth. we celebrated our team member's 5th work anniversary by appreciating their hard work, dedication, and his precious time that he gave to us. That's how we appreciate our employee's work anniversary.",
      date: "Mar 6th , 2021",
    },
    {
      title: "Team Outing!",
      image: TeamOuting,
      description:
        "Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results. We arranged a trip to Saputara Hill Station, one of the famous hill stations. What a wonderful day it was! we enjoyed the beautiful sunset there. The sunset, The atmosphere, The emotions were so beautiful and joyous.",
      date: "Jul 21st , 2020",
    },
    {
      title: "Team Activity!",
      image: teamActivity,
      description:
        "Team work begins by building trust!! On March 6, 2021, The Skyline Infosys family celebrated the first team building activity ever. What a day it was!. That day was not a day it was an emotions! We really enjoyed that day. An employee engagement activity makes the company atmosphere cheerful. Engaged employees, which leads to enthusiasm in their role and overall company growth. and this is all we want in our company.",
      date: "Mar 6th , 2020",
    },
  ];
  return (
    <section className="life_skyline">
      <Container>
        <h1 className="heading">Celebration at Skyline</h1>

        <Row className="mt-5 life_line_row px-lg-3 ">
          {timeLineData.map((obj, index) => {
            return (
              <Col lg={9} className="main_card mt-3" key={index}>
                <div className="lifeline_box d-flex gap-3 align-items-center">
                  <div className="img_box">
                    <img
                      src={obj.image}
                      alt={obj.image}
                      className="img-fluid w-100 h-100"
                    />
                  </div>
                  <div className="content_box">
                    <h1> {obj.date} </h1>
                    <h2> {obj.title} </h2>
                    <p>{obj.description}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default TimeLine;
