import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import DotNetCoreIcon from "../../../Images/icon/dotnet_ic.webp";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import KubernetesIcon from "../../../Images/icon/Kubernetes.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/GooglecloudIcon.png";
import GoogleCloudImg from "../../../Images/HireDeveloper/GoogleCloud.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const GoogleCloud = () => {

  const homeData = {
    title: "Hire <span>Google Cloud</span> Developers in India",
    description: `Empower your business with skilled Google Cloud developers delivering scalable, secure, and highly available cloud-based solutions. 
      Leverage Google Cloud's robust infrastructure to build high-performance, cost-effective applications tailored to your business needs. 
      Our developers specialize in Google Cloud services, cloud computing, app hosting, serverless architectures, and integrating advanced features like AI, machine learning, and data analytics.
      With flexible hiring models and a client-first approach, we guarantee seamless collaboration and timely delivery. 
      Transform your ideas into reality with our Google Cloud app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our in-house team brings over 5 years of experience in Google Cloud app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure the highest standards of security, scalability, and cost-efficiency in your Google Cloud applications.",
      },
    ],
  };
  
  
  
  
  


  const hireTopDeveloperData = {
    heading: "Hire Top Google Cloud Developers in India from Skyline Infosys",
    image: {
      src: GoogleCloudImg, 
      alt: "GoogleCloudImg",
    },
    content: {
      description: `Looking to leverage the power of Google Cloud for your business? At Skyline Infosys, we offer expert Google Cloud developers who specialize in delivering scalable, secure, and high-performance cloud solutions tailored to your business needs. From cloud migration to optimizing your infrastructure, our team ensures seamless integration and robust management of your applications on the Google Cloud platform.`,
      expertiseHeading: "Our Google Cloud developers excel in:",
      expertisePoints: [
        "Designing and deploying scalable and secure cloud infrastructure using Google Cloud services like Compute Engine, GKE, and Cloud Storage",
        "Implementing cloud solutions with Google Kubernetes Engine, App Engine, and Cloud Functions for serverless applications",
        "Migrating on-premise infrastructure to Google Cloud with minimal downtime and optimized cost-efficiency",
        "Integrating machine learning, data analytics, and AI using Google AI/ML services like TensorFlow, AutoML, and BigQuery",
        "Optimizing cloud resources with Google Cloud cost management tools and performance monitoring with Stackdriver",
        "Ensuring high levels of security and compliance using Google Cloud Identity, IAM, and other security best practices",
      ],
      closingNote: `Partner with us today to build scalable cloud solutions using Google Cloud. Hire dedicated Google Cloud developers from Skyline Infosys to optimize your cloud infrastructure and drive your business forward with secure, efficient cloud services.`,
    },
  };
  
  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Google Cloud Combinations",
    description: `At Skyline Infosys, we specialize in integrating Google Cloud services with powerful back-end and front-end technologies to deliver scalable, secure, and high-performance cloud solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Google Cloud + .NET Core",
        description: `Leverage the power of Microsoft’s .NET Core framework with Google Cloud’s services for building scalable, secure, and high-performance applications. This combination is ideal for enterprise-level applications requiring a robust back-end architecture hosted on Google Cloud.`,
        icon: DotNetCoreIcon,
      },
      {
        title: "Google Cloud + React.js",
        description: `Integrate Google Cloud services with React.js for building modern web applications. Google Cloud handles cloud hosting, databases, and serverless functions, while React.js powers the dynamic and responsive user interface for seamless user experiences.`,
        icon: ReactIcon,
      },
      {
        title: "Google Cloud + Node.js",
        description: `Google Cloud provides the infrastructure, while Node.js serves as the efficient back-end for real-time data processing, API handling, and scalable applications. This combination offers a powerful full-stack solution for modern web apps.`,
        icon: NodeJsIcon,
      },
      {
        title: "Google Cloud + Kubernetes",
        description: `Use Google Kubernetes Engine (GKE) to manage and orchestrate containerized applications with Google Cloud’s infrastructure. This combination ensures seamless deployment, scalability, and management of microservices-based architectures.`,
        icon: KubernetesIcon,
      },
      {
        title: "Google Cloud + Python/Django",
        description: `Build scalable, secure, and high-performance back-end systems using Google Cloud’s services integrated with Python’s Django framework. This combination is ideal for data-driven applications and AI-based solutions.`,
        icon: PythonIcon,
      },
      {
        title: "Google Cloud + Angular",
        description: `Combine Google Cloud’s platform with Angular to build dynamic, enterprise-grade web applications. Google Cloud ensures reliable cloud hosting, storage, and databases, while Angular powers the interactive and modern front-end UI.`,
        icon: AngularIcon,
      },
      {
        title: "Google Cloud + AI/ML",
        description: `Leverage Google Cloud’s AI and machine learning services, such as TensorFlow, AutoML, and BigQuery, to create intelligent, data-driven applications. Integrate these advanced capabilities with your application’s back-end for robust AI-driven functionalities.`,
        icon: AiIcon,
      },
      {
        title: "Google Cloud + DevOps",
        description: `Automate your application lifecycle using Google Cloud’s DevOps tools like Cloud Build, Cloud Functions, and Container Registry. This combination enables fast, reliable deployments and continuous integration, ensuring streamlined management of cloud-based projects.`,
        icon: DevOpsIcon,
      },
    ],
  };
  
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default GoogleCloud;
