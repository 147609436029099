import React, { useEffect, useRef } from "react";
import Banner from "../../Component/Banner";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactConsultation from "./Components/ContactConsultation";
import "./Consultation.css";
import { Helmet } from "react-helmet";
import ConsultationBg from "../../Images/Contact/free-consultationbG.jpg";

const Consultation = () => {
  const contactRef = useRef(null);


  useEffect(() => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>web development agency near me</title>
        <meta
          name="description"
          content="Skyline Infosys is your top choice for web development services near you. Contact us for value-driven outsourcing solutions."
        />
        <meta name="keywords" content="web development agency near me" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
          backGroundImg={ConsultationBg}
          heading="Free Consultation"
          content="Fill out the form below, and our team of experts will get in touch with you shortly.
We’re here to help you with any questions or concerns you may have.
Your inquiries are important to us, and we strive to provide quick assistance.
Simply provide the necessary details, and we’ll handle the rest.
Let us assist you in finding the perfect solution tailored to your needs."
          title="Work On Time. "
          btnText="Consult Skyline"
          subTitle=""
        />
      </div>
      <div ref={contactRef}>
        <ContactConsultation showAddress={true} />
      </div>
      <MajorClient />
      <Testimonials />
    </div>
  );
};

export default Consultation;
