import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { caseStudies } from "../CaseStudyData";
import Pinaheart  from "../../../Images/CaseStudies/PinaheartC.jpg";


const CaseStudiesList = () => {
  return (
    <div className="case_list">
      <Container>
        <Row className="justify-content-between border-rights position-relative">
          {caseStudies.map((caseStudy) => (
            <Col md={6} className="mb-4 border-bottom" key={caseStudy.id}>
              <div className="box">
                <img
                  src={caseStudy.image}
                  alt={caseStudy.title}
                  className="img-fluid case_img"
                />
                <h4>{caseStudy.title}</h4>
                <p className="mt-2">{caseStudy.description}</p>
                <Link to={caseStudy.path}>
                  Learn More <FaArrowRightLong />
                </Link>
              </div>
            </Col>
          ))}
          <Col md={6} className="mb-4 border-bottom">
              <div className="box">
                <img
                  src={Pinaheart}
                  alt={Pinaheart}
                  className="img-fluid case_img"
                />
                
                <div className="pinaheart_content">
                <h4>Pinaheart</h4>
                <p className="mt-2">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores obcaecati eos quod ex quo saepe ab. Corporis excepturi omnis iste corrupti ad maiores consequuntur fugiat. Necessitatibus temporibus consequatur aliquam quod.</p>
                <Link to="#">
                  Learn More <FaArrowRightLong />
                </Link>
                </div>

              </div>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CaseStudiesList;
