import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../Images/Common/skylineLogo.svg";
import { Link, useLocation } from "react-router-dom";
import {
  IoIosArrowDown,
  IoIosCloud,
  IoLogoIonic,
  IoLogoNodejs,
} from "react-icons/io";
import {
  FaAngular,
  FaAppStoreIos,
  FaAws,
  FaCss3Alt,
  FaDocker,
  FaHtml5,
  FaJava,
  FaLaravel,
  FaPhoneAlt,
  FaPython,
  FaQuestionCircle,
  FaReact,
  FaVuejs,
} from "react-icons/fa";
import Inquiry from "../Pages/Contact/Components/Inquiry";
import "../Style/Header.css";
import { AiFillFilePpt, AiOutlineKubernetes } from "react-icons/ai";
import { GiCash, GiLifeBar } from "react-icons/gi";
import CompanyAnniversary from "../Images/Common/CompanyAnniversary.png";
import frontend from "../Images/Common/frontend.jpg";
import backend from "../Images/Common/backend.jpg";
import mobileApp from "../Images/Common/mobileApp.jpg";
import database from "../Images/Common/Database.jpg";
import ManagedDevOpsServices from "../Images/Service/DevOps/ManagedDevOpsServices.svg";
import { FaBuildingUser } from "react-icons/fa6";
import { ImUsers } from "react-icons/im";
import { TbBrandJavascript, TbBrandMysql } from "react-icons/tb";
import {
  SiDotnet,
  SiKotlin,
  SiMongodb,
  SiNuxtdotjs,
  SiPhp,
  SiSqlite,
} from "react-icons/si";
import { DiCodeigniter } from "react-icons/di";
import { BsAndroid2, BsFiletypeSql } from "react-icons/bs";
import { RiFlutterFill, RiNextjsFill } from "react-icons/ri";
import { BiLogoPostgresql } from "react-icons/bi";
import { MdLightbulbCircle, MdPolicy } from "react-icons/md";
import { IoLogoFirebase } from "react-icons/io5";
import { VscAzure } from "react-icons/vsc";
import HireDeveloperModal from "./HireDeveloperModal";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showOverly, setShowOverly] = useState(false);
  const location = useLocation();
  const [hoverMenu, setHoverMenu] = useState(false);

  const desktopView = window.innerWidth >= 991;

  const [showDropDown, setShowDropDown] = useState("Developers");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup listener on component unmount
    };
  }, []);

  useEffect(() => {
    if (showOverly) {
      document.body.classList.add("overlay-active");
    } else {
      setShowDropDown("");
      document.body.classList.remove("overlay-active");
    }

    // Cleanup the class on unmount if necessary
    return () => {
      document.body.classList.remove("overlay-active");
    };
  }, [showOverly]);

  // Function to check if the current link is active

  useEffect(() => {
    setShowDropDown("");
    setHoverMenu(false);
  }, [location.pathname]);

  const isActive = (path) => location.pathname === path;
  const ActiveLink = serviceDropdown.find(
    (obj) => obj.path === location.pathname
  );
  const ActiveTechnology = menuItems
    ?.flatMap((menu) => menu?.subItems)
    .find((subItem) => subItem?.to === location.pathname);
  const ActiveHireDev = HireDeveloper?.flatMap((menu) => menu?.subItems).find(
    (subItem) => subItem?.to === location.pathname
  );
  const CompanyActive = Company.find((obj) => obj.path === location.pathname);

  return (
    <>
      <div className={`header ${scrolled ? "scrolled" : ""}`}>
        <Navbar expand="lg" className={`${hoverMenu ? " hovered" : ""}`}>
          <Container>
            <Link to="/">
              <img className="logo" src={Logo} alt="Logo" />
            </Link>

            <Navbar.Toggle
              onClick={() => {
                setShowOverly(!showOverly);
              }}
              aria-controls="basic-navbar-nav"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto ">
                <Link
                  className={`dropdown_list ${
                    showDropDown === "company"
                      ? "show_dropdown_list show_menu"
                      : CompanyActive !== undefined
                      ? "active"
                      : ""
                  }`}
                  onMouseEnter={() => {
                    if (desktopView) {
                      setShowDropDown("company");
                    }

                    setHoverMenu(true);
                  }}
                  onMouseLeave={() => {
                    if (desktopView) {
                      setShowDropDown("");
                    }

                    setHoverMenu(false);
                  }}
                  onClick={() => {
                    setShowDropDown((prev) =>
                      prev === "company" ? "" : "company"
                    );
                  }}
                >
                  Company
                  <IoIosArrowDown className="down_arrow ms-1" />
                  <div className="mega_menu">
                    <div className="menu_box">
                      <div className="box list">
                        <h6>Overview</h6>
                        <ul className="p-0 m-0 mt-3">
                          {Company.map((item, index) => {
                            return (
                              <li key={index}> 
                                <Link
                                  className={`${
                                    CompanyActive?.path === item.path
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.path}
                                >
                                  {item.icon}
                                  {item.label}{" "}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div className=" right_box company  w-100">
                        <div className="box anniversary  ">
                          <h6>Celebrating</h6>
                          <div className="img_box mt-3">
                            <img
                              src={CompanyAnniversary}
                              alt="CompanyAnniversary"
                            />
                          </div>
                        </div>
                        <div className="box box_content">
                          <div>
                            <h1>
                              Let's grow together <br />
                              Partner with us
                            </h1>
                            <Link to="/contact" className="contact">
                              {" "}
                              Let's Connect{" "}
                            </Link>
                          </div>
                          {/* <img src={companycall} alt="companycall" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className={`dropdown_list ${
                    showDropDown === "Service"
                      ? "show_dropdown_list show_menu"
                      : ActiveLink !== undefined ||
                        ActiveTechnology?.to !== undefined
                      ? "active"
                      : ""
                  }`}
                  onMouseEnter={() => {
                    if (desktopView) {
                      setShowDropDown("Service");
                    }
                    setHoverMenu(true);
                  }}
                  onMouseLeave={() => {
                    if (desktopView) {
                      setShowDropDown("");
                    }
                    setHoverMenu(false);
                  }}
                  onClick={() => {
                    setShowDropDown((prev) =>
                      prev === "Service" ? "" : "Service"
                    );
                  }}
                >
                  Service
                  <IoIosArrowDown className="down_arrow ms-1" />
                  <div className="mega_menu service_menu">
                    <div className="menu_box">
                      <div className="box list">
                        <h6>Our services</h6>
                        <ul className="p-0 m-0 mt-3">
                          {serviceDropdown.map((item, index) => {
                            return (
                              <li key={index}>
                                <Link
                                  className={`${
                                    ActiveLink?.path === item.path
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.path}
                                >
                                  {item.icon}
                                  {item.label}{" "}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div className="right_box ms-lg-3  w-100">
                        <div className="technology_box  ">
                          <img src={frontend} alt="frontend" />
                          <h4>Front End Development</h4>
                          <ul>
                            {menuItems
                              .find(
                                (category) => category.label === "Front End"
                              )
                              ?.subItems.map((item ,) => (
                                <li key={item.label}>
                                  {item.icon}
                                  <Link
                                    className={`${
                                      ActiveTechnology?.to === item.to
                                        ? "active"
                                        : ""
                                    }`}
                                    to={item.to}
                                  >
                                    {" "}
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="technology_box ">
                          <img src={backend} alt="backend" />
                          <h4>Back End Development</h4>
                          <ul>
                            {menuItems
                              .find((category) => category.label === "Back End")
                              ?.subItems.map((item) => (
                                <li key={item.label}>
                                  {item.icon}
                                  <Link
                                    className={`${
                                      ActiveTechnology?.to === item.to
                                        ? "active"
                                        : ""
                                    }`}
                                    to={item.to}
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="technology_box  ">
                          <img src={mobileApp} alt="frontend" />
                          <h4>Mobile App Development</h4>
                          <ul>
                            {menuItems
                              .find((category) => category.label === "Mobile")
                              ?.subItems.map((item) => (
                                <li key={item.label}>
                                  {item.icon}
                                  <Link
                                    className={`${
                                      ActiveTechnology?.to === item.to
                                        ? "active"
                                        : ""
                                    }`}
                                    to={item.to}
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="technology_box  ">
                          <img src={database} alt="database" />
                          <h4>Database</h4>
                          <ul>
                            {menuItems
                              .find((category) => category.label === "Database")
                              ?.subItems.map((item) => (
                                <li key={item.label}>
                                  {item.icon}
                                  <Link
                                    className={`${
                                      ActiveTechnology?.to === item.to
                                        ? "active"
                                        : ""
                                    }`}
                                    to={item.to}
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className={`dropdown_list ${
                    showDropDown === "Developers"
                      ? "show_dropdown_list show_menu"
                      : ActiveHireDev !== undefined
                      ? "active"
                      : ""
                  }`}
                  onMouseEnter={() => {
                    if (desktopView) {
                      setShowDropDown("Developers");
                    }

                    setHoverMenu(true);
                  }}
                  onMouseLeave={() => {
                    setHoverMenu(false);
                    if (desktopView) {
                      setShowDropDown("");
                    }
                  }}
                  onClick={() => {
                    setShowDropDown((prev) =>
                      prev === "Developers" ? "" : "Developers"
                    );
                  }}
                >
                  Hire Developers
                  <IoIosArrowDown className="down_arrow ms-1" />
                  <div className="mega_menu  service_menu">
                    <div className="menu_box gap-0">
                      <div className="right_box   w-100">
                        <div className="technology_box  ">
                          <img src={frontend} alt="frontend" />
                          <h4>Front End Developer</h4>
                          <ul>
                            {HireDeveloper.find(
                              (category) => category.label === "Front End"
                            )?.subItems.map((item) => (
                              <li key={item.label}>
                                {item.icon}
                                <Link
                                  className={`${
                                    ActiveHireDev?.to === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.to}
                                >
                                  {" "}
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="technology_box ">
                          <img src={backend} alt="backend" />
                          <h4>Back End Developer</h4>
                          <ul>
                            {HireDeveloper.find(
                              (category) => category.label === "Back End"
                            )?.subItems.map((item) => (
                              <li key={item.label}>
                                {item.icon}
                                <Link
                                  className={`${
                                    ActiveHireDev?.to === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.to}
                                >
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="technology_box  ">
                          <img src={mobileApp} alt="frontend" />
                          <h4>Mobile App Developer</h4>
                          <ul>
                            {HireDeveloper.find(
                              (category) => category.label === "Mobile"
                            )?.subItems.map((item) => (
                              <li key={item.label}>
                                {item.icon}
                                <Link
                                  className={`${
                                    ActiveHireDev?.to === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.to}
                                >
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="technology_box  ">
                          <img src={ManagedDevOpsServices} alt="frontend" />
                          <h4> DevOps Team</h4>
                          <ul>
                            {HireDeveloper.find(
                              (category) => category.label === "devOps"
                            )?.subItems.map((item) => (
                              <li key={item.label}>
                                {item.icon}
                                <Link
                                  className={`${
                                    ActiveHireDev?.to === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                  to={item.to}
                                >
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="hire_developer_img">
                        <div className="hire_content">
                          <h3>
                            {" "}
                            <span>Hire</span> Dedicated <br /> Developers
                          </h3>
                          <p>
                            At <span>Skyline Infosys</span> , we offer dedicated
                            developers skilled in cutting-edge technologies to
                            bring your projects to life. Whether you need a web
                            developer, mobile app specialist, or full-stack
                            expert, our team is here to provide custom solutions
                            tailored to your business needs. Save time, reduce
                            costs, and ensure the success of your projects by
                            partnering with our seasoned professionals.
                          </p>
                          <button className="button" onClick={handleShow}>
                            {" "}
                            Hire Now{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/blog" className={isActive("/blog") ? "active" : ""}>
                  Blog
                </Link>

                <Link
                  to="/case-studies"
                  className={isActive("/case-studies") ? "active" : ""}
                >
                  Case Studies
                </Link>
                {/* <Link to="/career"></Link> */}

                <div
                  className="inquiry desktop position-relative"
                  onMouseEnter={() => {
                    setShowDropDown("inquiry");
                  }}
                  onMouseLeave={() => {
                    setShowDropDown("");
                  }}
                >
                  <FaPhoneAlt className="shake-animate" />
                  {showDropDown === "inquiry" && <Inquiry />}
                </div>

                <Link className="contact" to="/contact">
                  Let's Connect
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      {(showOverly || showDropDown === "inquiry" || hoverMenu) && (
        <div className="black_overly"></div>
      )}
      <HireDeveloperModal show={show} handleClose={handleClose} />
    </>
  );
};

export default Header;

const serviceDropdown = [
  { path: "/services/web-development", label: "Web Development" },
  { path: "/services/mobile-development", label: "Mobile Development" },
  { path: "/services/custom-software", label: "Custom Software" },
  { path: "/services/qa-testing", label: "QA & Testing" },
  { path: "/services/staff-augmentation", label: "Staff Augmentation" },
  { path: "/services/support-maintenance", label: "Support & Maintenance" },
  { path: "/services/cloud", label: "Cloud" },
  { path: "/services/devOps", label: "DevOps" },
  {
    path: "/services/artificial-intelligence",
    label: "Artificial Intelligence",
  },
];

const Company = [
  { path: "/about", label: "About Us", icon: <FaBuildingUser /> },
  { path: "/portfolio", label: "Portfolio", icon: <AiFillFilePpt /> },
  {
    path: "/life-skyline",
    label: "Life @ Skyline",
    icon: <GiLifeBar />,
  },

  {
    path: "/faq",
    label: "FAQ",
    icon: <FaQuestionCircle />,
  },
  { path: "/partnership", label: "Partnership", icon: <ImUsers /> },
  { path: "/career", label: "Career", icon: <GiCash /> },
  // { path: "/contact", label: "Contact", icon: <FaPhoneAlt /> },
  { path: "/privacy-policy", label: "Privacy Policy", icon: <MdPolicy /> },
];

const menuItems = [
  {
    label: "Front End",
    subItems: [
      {
        label: "React JS",
        to: "/technology/reactjs-development-services",
        icon: <FaReact />,
      },
      {
        label: "Vue JS",
        to: "/technology/Vuejs-development-services",
        icon: <FaVuejs />,
      },
      {
        label: "Angular JS",
        to: "/technology/angularjs-development-services",
        icon: <FaAngular />,
      },
      {
        label: "HTML5",
        to: "/technology/html5-development-services",
        icon: <FaHtml5 />,
      },
      {
        label: "Javascript",
        to: "/technology/Javascript-development-services",
        icon: <TbBrandJavascript />,
      },
      {
        label: "CSS3",
        to: "/technology/CSS3-web-development-services",
        icon: <FaCss3Alt />,
      },
    ],
  },
  {
    label: "Back End",
    subItems: [
      {
        label: "Node JS",
        to: "/technology/nodejs-development-services",
        icon: <IoLogoNodejs />,
      },
      {
        label: "Laravel",
        to: "/technology/laravel-web-development-services",
        icon: <FaLaravel />,
      },
      {
        label: "Python",
        to: "/technology/python-development-services",
        icon: <FaPython />,
      },
      {
        label: ".NET",
        to: "/technology/dot-net-development-services",
        icon: <SiDotnet />,
      },
      {
        label: "PHP",
        to: "/technology/php-web-development-services",
        icon: <SiPhp />,
      },
      {
        label: "Codeigniter",
        to: "/technology/codeigniter-development-services",
        icon: <DiCodeigniter />,
      },
      {
        label: "Java",
        to: "/technology/java-development-services",
        icon: <FaJava />,
      },
    ],
  },
  {
    label: "Mobile",
    subItems: [
      {
        label: "Android",
        to: "/technology/android-development-services",
        icon: <BsAndroid2 />,
      },
      {
        label: "iOS",
        to: "/technology/ios-development-services",
        icon: <FaAppStoreIos />,
      },
      {
        label: "Flutter",
        to: "/technology/flutter-development-services",
        icon: <RiFlutterFill />,
      },
      {
        label: "React Native",
        to: "/technology/react-native-development-services",
        icon: <FaReact />,
      },
      {
        label: "Ionic",
        to: "/technology/ionic-development-services",
        icon: <IoLogoIonic />,
      },
      {
        label: "Kotlin",
        to: "/technology/kotlin-development-services",
        icon: <SiKotlin />,
      },
    ],
  },
  {
    label: "Database",
    subItems: [
      {
        label: "MongoDB",
        to: "/technology/mongodb-services",
        icon: <SiMongodb />,
      },
      {
        label: "PostgreSQL",
        to: "/technology/postgresql-services",
        icon: <BiLogoPostgresql />,
      },
      {
        label: "MySQL",
        to: "/technology/mysql-services",
        icon: <TbBrandMysql />,
      },
      {
        label: "SQL",
        to: "/technology/sql-services",
        icon: <BsFiletypeSql />,
      },
      {
        label: "Firebase ",
        to: "/technology/firebase-services",
        icon: <IoLogoFirebase />,
      },
      {
        label: "SQLite ",
        to: "/technology/sql-lite-services",
        icon: <SiSqlite />,
      },
    ],
  },
];

const HireDeveloper = [
  {
    label: "Front End",
    subItems: [
      {
        label: "React JS",
        to: "/hire-react-developer",
        icon: <FaReact />,
      },
      {
        label: "Vue JS",
        to: "/hire-vue-developer",
        icon: <FaVuejs />,
      },
      {
        label: "Angular JS",
        to: "/hire-angular-developer",
        icon: <FaAngular />,
      },
      {
        label: "Next JS",
        to: "/hire-next-developer",
        icon: <RiNextjsFill />,
      },
      {
        label: "Javascript",
        to: "/hire-javascript-developer",
        icon: <TbBrandJavascript />,
      },
      {
        label: "Nuxt",
        to: "/hire-nuxt-developer",
        icon: <SiNuxtdotjs />,
      },
    ],
  },
  {
    label: "Back End",
    subItems: [
      {
        label: "Node JS",
        to: "/hire-node-developer",
        icon: <IoLogoNodejs />,
      },
      {
        label: "Laravel",
        to: "/hire-laravel-developer",
        icon: <FaLaravel />,
      },
      {
        label: "Python",
        to: "/hire-python-developer",
        icon: <FaPython />,
      },
      {
        label: ".NET",
        to: "/hire-dot-net-developer",
        icon: <SiDotnet />,
      },
      {
        label: "PHP",
        to: "/hire-php-developer",
        icon: <SiPhp />,
      },
      {
        label: "Codeigniter",
        to: "/hire-codeigniter-developer",
        icon: <DiCodeigniter />,
      },
      {
        label: "Java",
        to: "/hire-java-developer",
        icon: <FaJava />,
      },
    ],
  },
  {
    label: "Mobile",
    subItems: [
      {
        label: "Android",
        to: "/hire-android-developer",
        icon: <BsAndroid2 />,
      },
      {
        label: "iOS",
        to: "/hire-ios-developer",
        icon: <FaAppStoreIos />,
      },
      {
        label: "Flutter",
        to: "/hire-flutter-developer",
        icon: <RiFlutterFill />,
      },
      {
        label: "React Native",
        to: "/hire-react-native-developer",
        icon: <FaReact />,
      },
      {
        label: "Ionic",
        to: "/hire-ionic-developer",
        icon: <IoLogoIonic />,
      },
      {
        label: "Kotlin",
        to: "/hire-kotlin-developer",
        icon: <SiKotlin />,
      },
    ],
  },


  {
    label: "devOps",
    subItems: [
      {
        label: "Azure",
        to: "/hire-azure-developer",
        icon: <VscAzure />,
      },
      {
        label: "AWS",
        to: "/hire-aws-developer",
        icon: <FaAws />,
      },

      {
        label: "Google Cloud",
        to: "/hire-google-cloud-developer",
        icon: <IoIosCloud />,
      },
      {
        label: "Docker",
        to: "/hire-docker-developer",
        icon: <FaDocker />,
      },
      {
        label: "Kubernetes",
        to: "/hire-kubernetes-developer",
        icon: <AiOutlineKubernetes />,
      },
      {
        label: "CI/CD",
        to: "/hire-cicd-developer",
        icon: <MdLightbulbCircle />,
      },
    ],
  },
];
