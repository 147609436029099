import React from "react";
import Banner from "../../Component/Banner";
import OurPortfolio from "../Home/Components/OurPortfolio";
import "./portfolio.css";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactForm from "../Contact/Components/ContactForm";
import { Helmet } from "react-helmet";
import PortFolioBg from "../../Images/Portfolio/PortFolioBg.jpg";

const Portfolio = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | Beyond Catalog | Reflecting Dedication</title>
        <meta
          name="description"
          content="Explore Skyline Infosys' impressive web development portfolio showcasing a range of projects. See our expertise in action."
        />
        <meta name="keywords" content="web development portfolio" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
          backGroundImg={PortFolioBg}
          heading="Our Portfolio"
          content="showcases our expertise and creativity through diverse projects. From dynamic websites to innovative apps, we deliver high-quality solutions tailored to client needs. Each project reflects our dedication to excellence and cutting-edge technology. Browse through our work to see the impact we’ve made across various industries. Let our portfolio inspire your next big idea!"
          title="Work On Time."
          btnText="Consult Skyline"

        />
      </div>
      <OurPortfolio />
      <MajorClient />
      <Testimonials />
      <ContactForm />
    </div>
  );
};

export default Portfolio;
