import React, { useEffect, useRef } from "react";
import Banner from "../../Component/Banner";
import ContactForm from "./Components/ContactForm";
import "./contact.css";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import { Helmet } from "react-helmet";
import ContactBg from "../../Images/Contact/contactBg.jpg";

const Contact = () => {
  const contactRef = useRef(null);

  useEffect(() => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>web development agency near me</title>
        <meta
          name="description"
          content="Skyline Infosys is your top choice for web development services near you. Contact us for value-driven outsourcing solutions."
        />
        <meta name="keywords" content="web development agency near me" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="home ">
        <Banner
          backGroundImg={ContactBg}
          heading="CONTACT US"
          content="Contact Skyline Infosys for expert guidance and support. We’re committed to providing you with timely responses and tailored solutions. Share your requirements or questions, and we’ll ensure a seamless experience. Let’s connect and make things happen!"
          title="Work On Time. "
          btnText="Consult Skyline"
          subTitle=""
        />
      </div>
      <div ref={contactRef}>
        <ContactForm showAddress={true} />
      </div>

      <MajorClient />
      <Testimonials />
    </div>
  );
};

export default Contact;
