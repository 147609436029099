import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import ReactImage from "../../../Images/Technology/ReactJs/react_banner.svg";
import ReactImage2 from "../../../Images/Technology/ReactJs/react_banner2.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"

const ReactService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom ReactJS Development Services| Skyline Infosys</title>
        <meta
          name="description"
          content="
    Revolutionize your business with our cutting-edge ReactJS development services! We specialize in custom web applications for various industries "
        />
        <meta name="keywords" content="react js development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
             backGroundImg={CommonBg}
            heading="React Js Development"
            content="Revolutionize your business with our cutting-edge ReactJS development services! At Skyline Infosys, we specialize in crafting custom web applications that leverage the power of ReactJS to deliver unparalleled performance and user experience."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore React Js development Company"
          />
        </div>

        <DevelopmentInfo
          heading="React JS Development"
          title="React Js Development Services In all over India"
          image={ReactImage}
          content={
            <>
              <p className="my-3">
                Our React JS development services are tailored to meet the
                unique needs of each client. Whether you need a custom web
                application, a complex e-commerce platform, or a simple business
                website, our team of expert developers can deliver a solution
                that meets your specific requirements. We use the latest tools
                and technologies to ensure that your web application is not only
                high-performing but also secure and reliable. With our agile
                development approach, we ensure that your project is completed
                on time and within budget, without compromising on quality.
              </p>
              <p>
                {" "}
                Our team of skilled developers at Skyline Infosys understands
                that every business has unique requirements, which is why we
                offer customized React JS development services to meet your
                specific needs. We follow a collaborative approach to understand
                your business goals, target audience, and market trends to
                develop solutions that align with your business objectives. Our
                agile development process ensures that we deliver high-quality
                solutions on time and within budget. With our extensive
                experience in React JS, we can build applications that are
                scalable, reliable, and secure. Trust us to be your partner in
                developing top-notch web applications that give your business a
                competitive edge
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"reactjs"}
          heading="Why Should You Go For React Js Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Create Dynamic React JS Development?"
          image={ReactImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                Our React JS development team comprises skilled developers who
                have extensive experience in developing web applications using
                React JS. We understand that businesses require web applications
                that are not only visually appealing but also perform seamlessly
                across all devices. This is why we use the latest tools and
                technologies to ensure that our applications are responsive and
                optimized for maximum performance. We also prioritize
                communication with our clients to ensure that they are always
                up-to-date with the progress of their project. Our focus on
                teamwork, communication, and quality has helped us build
                long-lasting relationships with our clients, and we're committed
                to providing the same level of service to you. Partner with
                Skyline Infosys today and experience the power of React JS for
                yourself.
              </p>
              <p>
                Our team of React JS developers in India has a deep
                understanding of the latest technologies and techniques to build
                applications that are not only dynamic and interactive but also
                add significant financial value to your business. We work
                closely with our clients to understand their business goals and
                objectives to develop customized solutions that meet their
                specific needs. Our strategic approach to development ensures
                that we deliver solutions that are aligned with the aims and
                ambitions of your company. We focus on developing applications
                that engage and thrill users, providing them with a seamless
                user experience that enhances their overall experience..
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          data={ProjectPlanData}
          content="reactjs"
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default ReactService;
