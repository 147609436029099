import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import SoftwareTesting from "../.../../../Images/Service/QATesting/SoftwareTesting.png";
import { SoftwareTestingData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";


const QATesting = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Premier Software Testing Services| Skyline Infosys</title>
        <meta
          name="description"
          content="
We prioritize client experience and returns. With 10,000+ test cases and 1,000+ automation scripts, our software testing solutions are unparalleled."
        />
        <meta name="keywords" content="software testing company" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="QA & Software Testing"
            content="Enhancing client experience and increasing returns are priorities at our software testing company. With over 10,000 test cases and more than 1,000 automation scripts at our disposal, we have created an unmatched set of testing solutions."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Qa Testing Company"
          />
        </div>
        <DevelopmentInfo
          heading="Software Testing Company
"
          title="Top Software Testing Company"
          image={SoftwareTesting}
          content={
            <>
              <p className="my-3">
                Skyline Infosys takes great satisfaction in being the leading
                software testing services company. Professional testing services
                that focus on performance and excellence are what we specialize
                in, and that's what we aim to provide for our clients. It
                doesn't matter if you need manual or automated testing; we
                promise a smooth process that always results in happy customers.
              </p>
              <p>
                Have you exhausted all other avenues for enhancing the quality
                and performance of your IT application? Stop looking!
                Independent software testing services provided by Skyline
                Infosys are second to none. Forget about the time and money
                wasted on in-house research and testing. You may count on us for
                low-priced services that will improve the functionality of your
                IT program.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Testing Software Company ?"
          data={SoftwareTestingData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Automation Testing",
            "Manual Testing",
            "Security Testing",
            "Mobile App Testing",
            "Performance Testing",
            "API Testing",
          ]}
          serviceName="QA & Testing"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default QATesting;
