import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import WebDesign from "../../../Images/Common/WebDesign.png";
import MobileApps from "../../../Images/Common/MobileApps.png";
import QAesting from "../../../Images/Common/QAesting.png";
import DigitalMarketing from "../../../Images/Common/DigitalMarketing.png";
import AIMLDevelopment from "../../../Images/Common/AIMLDevelopment.png";
import Blockchain from "../../../Images/Common/Blockchain.png";
import Client from "../../../Images/Common/ClientApproach.jpeg";

import Tilt from "react-parallax-tilt";

const OurSpecializations = () => {
  const OurSpecializations = [
    {
      image: WebDesign,
      title: "Web Design & Development",
      description: `Transform your online presence with our stunning web designs.
            From conceptualization to implementation, we build websites that
            leave a lasting impression.`,
    },
    {
      image: MobileApps,
      title: "Mobile Apps Development",
      description: `Stay ahead of the curve with cutting-edge mobile apps. Our team of experts designs and develops apps that deliver seamless experiences on any device.`,
    },

    {
      image: AIMLDevelopment,
      title: "AI/ML Development Services",
      description: `
We harness Artificial Intelligence and Machine Learning to create smart solutions that streamline processes and improve customer experiences. Our expertise spans predictive analytics, natural language processing, and custom AI/ML models. `,
    },

    {
      image: DigitalMarketing,
      title: "Digital Marketing",
      description: `Reach your target audience with our digital marketing solutions. Our team of experts will help you connect with your clients and drive traffic to your website.`,
    },

    {
      image: Blockchain,
      title: "Blockchain Solutions",
      description: `We offer comprehensive blockchain development services to revolutionize the way businesses manage data and transactions. Our expertise includes creating secure, supply chain, and healthcare. Skyline Infosys ensures transparency, scalability, and trust in every solution.`,
    },

    {
      image: QAesting,
      title: "QA & Testing",
      description: `Ensure your every project's success with rigorous Testing. Our QA team rigorously tests each project to deliver bug-free solutions that meet highest standards.`,
    },
  ];

  return (
    <>
      <section className="our_specializations">
        <Container>
          <h1 className="heading" data-aos="fade-up">
            Our Specializations
          </h1>
          <div className="main_box my-5">
            {OurSpecializations.map((obj, index) => {
              return (
                <Tilt key={index}>
                  <div className="box h-100" data-aos="fade-up">
                    <div className="img_box">
                      <img
                        className="img-fluid"
                        src={obj.image}
                        alt={obj.image}
                      />
                    </div>
                    <div className="content_box">
                      <h2> {obj.title} </h2>
                      <p>{obj.description}</p>
                    </div>
                  </div>
                </Tilt>
              );
            })}
          </div>
        </Container>
      </section>
      <section className="client_approach">
        <Container className="">
          <div className="success_priority">
            <Row className=" justify-content-between">
              <Col lg={7} className="mb-3">
                <h3> Client-First Approach : Your Success is Our Priority !</h3>
                <p>
                  At Skyline Infosys, our clients come first. That's why we've
                  adopted a client-first approach to all that we do. From the
                  initial conversation to the final delivery, we at Skyline
                  Infosys work closely with our clients to ensure their success.
                  Our top priority is understanding your needs and goals and
                  delivering solutions that meet those needs and exceed your
                  expectations. With us, you can rest guaranteed that your
                  success is always at the forefront of everything we do.
                </p>
                <p>
                  We believe in fostering long-term relationships built on
                  trust, transparency, and communication. Our team takes the
                  time to listen, analyze, and collaborate, ensuring that every
                  decision is aligned with your vision. Our flexible solutions
                  are tailored to fit your unique challenges, allowing us to
                  adapt as your business grows and evolves. We leverage industry
                  best practices and cutting-edge technology to deliver
                  high-quality results, always with a focus on efficiency and
                  innovation. At Skyline Infosys, your satisfaction is not just
                  a goal—it's a commitment we uphold in every project we
                  undertake.
                </p>
              </Col>
              <Col lg={5}>
                <img src={Client} alt="Client" className="img-fluid" />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurSpecializations;
