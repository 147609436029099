import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import upwork from "../../../Images/Common/upwork-badge.webp";
import Google from "../../../Images/Common/google.png";
import Clutch from "../../../Images/Common/clutch-color.svg";
import Aws from "../../../Images/Common/aws-color.svg";
import { FaAward } from "react-icons/fa6";
import { PiFilesFill } from "react-icons/pi";
import { FaUsersGear } from "react-icons/fa6";
import microsoft from "../../../Images/Common/microsoft-silver.svg";
import { PiUsersThreeFill } from "react-icons/pi";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  // API_ENDPOINT,
  GOOGLE_RECAPTCHA_SITE_KEY,
} from "../../../config/config";
import CountUp from "react-countup";

const ContactConsultation = ({ showAddress }) => {
  const captchaRef = useRef();

  // Validation schema using Yup
  const validationSchema = Yup.object({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required(" Name is required."),

    email: Yup.string()
      .email("Invalid email address!")
      .required("Email is required."),
    mobile: Yup.string().required("Mobile number is required."),

    message: Yup.string().min(2, "Too Short!").required("Message is required."),
    services: Yup.array().min(1, "Please select at least one service."), // New validation rule for services
  });

  const initialValues = {
    fullName: "",
    email: "",
    mobile: "",
    country: "",
    message: "",
    services: [], // New field for selected services
  };

  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaError, setCaptchaError] = useState(false); // Track if there's a captcha error

  const handleRecaptchaChange = () => {
    const token = captchaRef.current.getValue();
    if (token) {
      setCaptchaToken(token);
    }
    setCaptchaError(false); // Reset error when captcha is completed
  };

  const onSubmit = async (values, { resetForm }) => {
    // Check if the captcha is completed before submitting
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    swal({
      title: "Thank You",
      text: "Your Form Submitted Successfully",
      icon: "success",
      button: "Ok",
    });
    resetForm({ values: initialValues });
    captchaRef.current.reset();
    setCaptchaToken("");

    // try {
    //   const response = await fetch(
    //     `${API_ENDPOINT}/auth/consultation`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         ...values,
    //         token: captchaToken,
    //       }),
    //     }
    //   );

    //   const json = await response.json();
    //   if (json) {
    //     swal({
    //       title: "Thank You",
    //       text: "Your Form Submitted Successfully",
    //       icon: "success",
    //       button: "Ok",
    //     });

    //     // Reset the form and captcha
    //     resetForm({ values: initialValues });
    //     captchaRef.current.reset();
    //     setCaptchaToken("");
    //   } else {
    //     console.error("Invalid Details");
    //   }
    // } catch (error) {
    //   console.error("Submission error:", error);
    // }
  };

  return (
    <div className="contact_form  consultation">
      <Container>
        <h1 className="heading"> FREE Consultation!</h1>
        <div className="form_box my-md-5">
          <Row className="pb-lg-5">
            <Col lg={6} className="mb-3">
              <div className="content d-flex flex-column gap-3">
                <p className="mb-0">
                  Let’s discuss your project requirements, explore innovative
                  solutions, and chart a path to success together—absolutely
                  free of charge!
                </p>

                <div className="estimates_box">
                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      <PiFilesFill />
                    </div>
                    <div>
                      <h3 className="mb-0">
                        <CountUp start={0} end={100} duration={10} />+ PROJECTS
                        DELIVERED
                      </h3>
                      <p>
                        Vast expertise in web & mobile application development.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      <FaAward />
                    </div>
                    <div>
                      <h3 className="mb-0">
                        {" "}
                        <CountUp start={0} end={4} duration={10} />+ AWARDS
                      </h3>
                      <p>
                        Our top-notch futuristic product outcomes have won us
                        numerous awards.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      <FaUsersGear />
                    </div>
                    <div>
                      <h3 className="mb-0">
                        {" "}
                        <CountUp start={0} end={50} duration={10} />+ EXPERTS
                      </h3>
                      <p>A power-packed team of experienced app developers</p>
                    </div>
                  </div>
                  <div className="d-flex time_line align-items-center ">
                    <div className="count d-flex align-items-center justify-content-center">
                      <PiUsersThreeFill />
                    </div>
                    <div>
                      <h3 className="mb-0">
                        <CountUp start={0} end={100} duration={10} />+ CLIENTS
                      </h3>
                      <p>
                        World wide country-focused and Industry specific App
                        solutions
                      </p>
                    </div>
                  </div>
                </div>

                <div className="our_rating mt-4">
                  <img
                    className="upwork"
                    src={upwork}
                    alt={upwork}
                    loading="lazy"
                  />
                  <img src={Google} alt={Google} loading="lazy" />
                  <img src={Clutch} alt={Clutch} loading="lazy" />
                  <img src={Aws} alt={Aws} loading="lazy" />
                  <img src={microsoft} alt={microsoft} loading="lazy" />
                </div>
              </div>
            </Col>

            <Col lg={6} className="mb-3">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <h1>Start Your Dream Project!</h1>
                    <Row>
                      <Col lg={12} className="mb-4">
                        <div className="input_box">
                          <label>Full Name*</label>
                          <Field
                            type="text"
                            name="fullName"
                            placeholder="Enter Your Full Name"
                          />
                          <ErrorMessage
                            name="fullName"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>

                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>Email*</label>
                          <Field
                            type="text"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>

                      <Col lg={6} className="mb-4">
                        <div className="input_box">
                          <label>Skype ID/Whatsapp No*</label>
                          <Field
                            type="text"
                            name="mobile"
                            placeholder="Skype ID/Whatsapp No"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>

                      <Col lg={12} className="mb-4">
                        <div className="input_box select_service">
                          <label>
                            <b>Select Type of Services*</b>
                          </label>

                          <div className="d-flex gap-4 mt-2 align-items-center">
                            <div className="d-flex g-2 align-items-center">
                              <Field
                                type="checkbox"
                                name="services"
                                id="WebApp"
                                value="Web App"
                                className="form-check-input"
                              />
                              <label for="WebApp" className="form-check-label">
                                Web App
                              </label>
                            </div>
                            <div className="d-flex g-2 align-items-center">
                              <Field
                                type="checkbox"
                                name="services  "
                                value="Mobile App"
                                id="MobileApp"
                                className="form-check-input"
                              />
                              <label
                                for="MobileApp"
                                className="form-check-label"
                              >
                                Mobile App
                              </label>
                            </div>
                          </div>
                          <ErrorMessage
                            name="services"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <div className="input_box">
                          <label>What's Your Project All About?</label>
                          <Field
                            as="textarea"
                            rows={5}
                            name="message"
                            placeholder="What's Your Project All About?"
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="input_box text-end">
                          {/* <ReCAPTCHA
                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                            ref={captchaRef}
                            onChange={handleRecaptchaChange}
                          /> */}
                          {captchaError && (
                            <div className="error-message">
                              Please complete the CAPTCHA.
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="mb-4 d-flex align-items-center justify-content-end"
                      >
                        <div className="input_box text-end">
                          <button
                            type="submit"
                            disabled={true}
                            className="submit disable_btn"
                            // disabled={!captchaToken || isSubmitting}
                            // className={`${!captchaToken ? "disable_btn" : ""}`}
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

          {showAddress && (
            <div>
              <hr className="my-3 " />
              <Row className=" pt-lg-5 justify-content-center">
                <Col lg={12}>
                  <div className="contact_info d-flex justify-content-center">
                    <Row className="w-100 justify-content-center">
                      <Col lg={6} className="mb-3">
                        <div className="box">
                          <div className="d-flex gap-md-3 gap-1 flex-wrap">
                            <FaPhone />
                            <a href="tel:+919879170954">+91-987-917-0954</a>/
                            <a href="tel:+918128996966">+91-812-899-6966</a>
                          </div>
                          <div className="d-flex gap-md-3 gap-1">
                            <MdEmail />
                            <a href="sales@skylineinfosys.com ">
                              sales@skylineinfosys.com
                            </a>
                          </div>

                          <div className="d-flex gap-md-3 gap-1">
                            <FaLocationDot />
                            <a
                              href="https://maps.app.goo.gl/HaZXP5hLfdLxvZ8t5"
                              className="d-flex gap-md-3 gap-1"
                            >
                              B-805 to 809, IT Park, Opp. Gajera International
                              School (Digital Valley), Surat, Gujarat India
                            </a>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div className="map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9326057730145!2d72.8712654!3d21.234520900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3b445a16ef%3A0x13ef9f24ae995a3c!2sSkyline%20Infosys!5e0!3m2!1sen!2sin!4v1732601799623!5m2!1sen!2sin"
                            style={{
                              width: "100%",
                              height: "100%",
                              border: 0,
                            }}
                            title="map"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContactConsultation;
