import React, { useRef, useState } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { MdPrivacyTip } from "react-icons/md";
import swal from "sweetalert";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../../../config/config";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Full Name is required."),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is required."),
  mobile: Yup.string()
    .matches(/^\d+$/, "Mobile number must be numeric.")
    .required("Mobile number is required."),
  requirement: Yup.string()
    .min(2, "Too Short!")
    .required("Requirement is required."),
});

const Form = () => {
  const captchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaError, setCaptchaError] = useState(false);

  const handleRecaptchaChange = () => {
    const token = captchaRef.current.getValue();
    if (token) {
      setCaptchaToken(token);
    }
    setCaptchaError(false);
  };

  const initialValues = {
    fullName: "",
    email: "",
    mobile: "",
    requirement: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    try {
      //   const response = await fetch(`${API_ENDPOINT}/auth/contact/createcontact`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ ...values, token: captchaToken }),
      //   });

      // const json = await response.json();

      const json = true;
      if (json) {
        swal({
          title: "Thank You",
          text: "Your Form Submitted Successfully",
          icon: "success",
          button: "Ok",
        });

        resetForm({ values: initialValues });
        captchaRef.current.reset();
        setCaptchaToken("");
      } else {
        console.error("Invalid Details");
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <FormikForm>
            <h2>40-hour Trial, 100% Risk Free</h2>

            <div className="input_box mt-3">
              <label>Full Name*</label>
              <Field
                type="text"
                name="fullName"
                placeholder="Enter Your Full Name"
              />
              <ErrorMessage name="fullName" component="div" className="error" />
            </div>

            <div className="input_box">
              <label>Email*</label>
              <Field type="text" name="email" placeholder="Enter Your Email" />
              <ErrorMessage name="email" component="div" className="error" />
            </div>

            <div className="input_box">
              <label>Mobile Number*</label>
              <Field
                type="text"
                name="mobile"
                placeholder="Enter Your Number"
              />
              <ErrorMessage name="mobile" component="div" className="error" />
            </div>

            <div className="input_box">
              <label>How Can We Help (Requirement)</label>
              <Field
                as="textarea"
                name="requirement"
                rows={4}
                placeholder="How Can We Help"
              />
              <ErrorMessage
                name="requirement"
                component="div"
                className="error"
              />
            </div>

            <div className="recaptcha input_box">
              {/* <ReCAPTCHA
                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                onChange={handleRecaptchaChange}
              /> */}
              {captchaError && (
                <div className="error">Please complete the CAPTCHA</div>
              )}
            </div>

            <div className="input_box">
              <button
                type="submit"
                disabled={true}
                className="submit disable_btn"
                // disabled={!captchaToken}
                // className={`${!captchaToken ? "disable_btn" : ""}`}
              >
                Submit
              </button>
            </div>

            <p>
              <MdPrivacyTip /> 100% Privacy Guaranteed
            </p>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Form;
