import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick/lib/slider";
import service1 from "../../../Images/AboutUs/service1.png";
import service2 from "../../../Images/AboutUs/service2.png";
import service3 from "../../../Images/AboutUs/service3.png";
import { Link } from "react-router-dom";

const OurServices = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    arrows: true,
    centerMode: true,
    slidesToScroll: 1,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  const serviceData = [
    {
      image: service1,
      title: "Software as a Service",
      description: `From strategizing, ideating to development and execution, Skyline Infosys offers comprehensive SaaS services.`,
    },
    {
      image: service2,
      title: "IT Product Engineering",
      description: `Our team develops useful and flexible IT products that meet international standards. Our main aim is to empower companies with superior IT solutions that set them apart from their competitors`,
    },
    {
      image: service3,
      title: "Software Consulting",
      description: `Solve your complicated business challenge with experts’ guidance. We understand your business and your ambitions to offer valuable guidance..`,
    },
    {
      image: service1,
      title: "Software as a Service",
      description: `From strategizing, ideating to development and execution, Skyline Infosys offers comprehensive SaaS services.`,
    },
    {
      image: service2,
      title: "IT Product Engineering",
      description: `Our team develops useful and flexible IT products that meet international standards. Our main aim is to empower companies with superior IT solutions that set them apart from their competitors`,
    },
    {
      image: service3,
      title: "Software Consulting",
      description: `Solve your complicated business challenge with experts’ guidance. We understand your business and your ambitions to offer valuable guidance..`,
    },
  ];

  return (
    <section className="our_services">
      <Container>
        <h1 className="heading">Our Services at a Glance</h1>
        <div className="content mt-5">
          <p>
            Skyline Infosys specializes in offering newest and impactful tech
            services, as per the expectations of the business. We enable
            companies to boost the efficiency of their IT operations and reduce
            operational costs with less effort. Our main focus is on reducing
            risks and boosting the usefulness of the new technology. Starting
            from software development, IT business consulting, web and
            application designing, system integration to IT support services,
            there is a lot we offer to global businesses.
          </p>
          <Row className="justify-content-center experience-content mb-5">
            <Col lg={8} className="text-center mb-4">
              <h6>
                Come, Partner with Us & Set Your Business Up for Success!{" "}
              </h6>
              <Link to="/contact" className="button mt-4">Let’s discuss now</Link>
            </Col>
          </Row>
          <div className="main_box my-5">
            <Slider {...settings}>
              {serviceData.map((obj, index) => {
                return (
                  <div className="box" key={index}>
                    <div className="img_box">
                      <img
                        src={obj.image}
                        className="img_fluid"
                        alt="service1"
                      />
                    </div>
                    <div className="content_box">
                      <h2> {obj.title} </h2>
                      <p>{obj.description}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OurServices;
