import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Laravel/laravel_banner.png";
import BannerImage1 from "../../../Images/Technology/Laravel/laravel_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const LaravelService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Expert Laravel Web Development Services | Skyline Infosys</title>
        <meta
          name="description"
          content="
Leverage Laravel's power with Skyline Infosys for smooth, result-oriented Laravel web development services."
        />
        <meta name="keywords" content="laravel web development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
           backGroundImg={CommonBg}
            heading="Laravel Development"
            content="Laravel is the best fit for creating mobile and web solutions for businesses of all sizes. And with the expertise of Skyline Infosys developers, the entire process becomes smoother and result-oriented."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Laravel development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Laravel Development"
          title="Laravel Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                At Skyline infosys, we are dedicated to providing our clients
                with the best possible package for PHP-based Laravel CMS
                development for web applications. We understand the importance
                of transforming your ideas into executable apps that utilize
                top-notch technology, and our team of experts is well-equipped
                to deliver just that. As the best Laravel development company,
                we take pride in offering highly secure, flexible, bug-free, and
                robust websites that cater to your specific needs. Our tech
                nerds are skilled in providing the most promising web
                development services in India, and Laravel development has
                become a popular choice for both developers and business owners
                due to its quicker development time. If you're looking for
                dynamic and fully customized Laravel applications, we provide
                the best solutions to meet your requirements.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"laravel"}
          heading="Why Should You Go For Laravel Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed
Laravel PHP Development"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                As the best web development company in Surat, we understand the
                importance of providing highly scalable, customizable, and
                integrated services to create the Laravel website of your
                dreams. Our agile methodology ensures a smooth checkout process
                and high-speed e-commerce experience for all visitors to your
                website. You don't have to worry about data migration when
                working with us for Laravel development because we take care of
                all safety protocols and ensure a convenient data transfer
                process. We also specialize in RESTful API or web service
                development, which is an architectural style used for
                communication between online services and web APIs. Our team
                pours in all their hands-on experience to create simple yet
                lucrative solutions for Laravel PHP development that meet your
                specific business needs.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default LaravelService;
