import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import AboutBanner from "../../../Images/Service/about_banner.png";

const AboutSkylineInfosys = () => {
  const countersData = [
    { count: 100, label: "Projects", title: "Good Deliver" },
    { count: 30, label: "Countries", title: "Globally Trusted" },
    { count: 4, label: "Award", title: "Recognized by" },
    { count: 55, label: "Customer Retention", title: "Blessed with" },
  ];

  return (
    <section className="skyline_service about_skyline_info">
      <Container>
        <h1 className="heading ">About Skyline Infosys</h1>

        <Row className="justify-content-center align-items-center my-3">
          <Col lg={5} md={6} className="d-flex justify-content-center">
            <div className="content">
              <h3 className="mb-3">10 Years of Trust & Hard Work</h3>
              <p>
                At Skyline Infosys, we've spent 10 years perfecting our craft,
                combining cutting-edge technology and affordable pricing to
                deliver customized solutions tailored to our client's needs. We
                focus on creating reliable, appealing, easy-to-use, and scalable
                deliverables. So join us to bring your vision to life with our
                exceptional technological expertise!
              </p>
              <p>
                Discover how Skyline is more than just a web development company
                - we're a problem-solving institution with a customer-centric
                vision. Our CIO discusses how we tackle challenges with
                technological advancements, innovative solutions, and our core
                values.
              </p>
            </div>
          </Col>
          <Col lg={5} md={6} className="d-flex justify-content-center">
            <img
              src={AboutBanner}
              alt={AboutBanner}
              className="img-fluid feature_img"
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-evenly my-5 flex-wrap gap-5 align-items-center">
          {countersData.map((item, index) => (
            <div
              key={index}
              className="box position-relative justify-content-center flex-column d-flex align-items-center"
            >
                <span className="title"> {item.title} </span>
              <h4 className="d-flex justify-content-center flex-wrap align-items-center">
                <CountUp start={0} end={item.count} duration={25} />+
              </h4>
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AboutSkylineInfosys;
