import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Partnership.css";
import ReferralPartner from "../../../Images/Partnership/ReferralPartner.png";
import partnership_sale from "../../../Images/Partnership/partnership_sale.png";

const PerfectPartner = () => {
  const [activeTab, setActiveTab] = useState("Referral Partnership");

  // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="partnership">
      <Container>
        <h1 className="heading">Skyline can be your Perfect Partner</h1>
        <p className="text-center mt-5">
          Here at Skyline Infosys, innovation and quality coexist. We've been
          drafting the standard for excellence in the IT sector for more than 8
          years by providing cutting-edge solutions. Our long-standing
          relationships with our clients are a tribute to the trust we've
          earned. We take satisfaction in being able to pioneer unique projects
          that have transformed the sector. Delivering the finest answers to our
          client's challenges is the main objective we have at Skyline Infosys.
          We are now moving ahead to the next phase of our growth and are
          inviting you to join us as a channel partner. As we move closer to our
          shared accomplishment, let's work together to redefine outcomes.
        </p>

        <Row className="justify-content-between my-5">
          <Col lg={12} className="mx-auto">
            <div className="tab_box w-100 d-flex justify-content-between">
              <button
                className={activeTab === "Referral Partnership" ? "active" : ""}
                onClick={() => handleTabChange("Referral Partnership")}
              >
                Referral Partnership
              </button>
              <button
                className={activeTab === "Sales Partnership" ? "active" : ""}
                onClick={() => handleTabChange("Sales Partnership")}
              >
                Sales Partnership
              </button>
            </div>
          </Col>
        </Row>
        <div className="tab_content mt-4">
          {activeTab === "Referral Partnership" && (
            <Row>
              <Col md={6}>
                <div className="content">
                  <h1>About Our Referral Partnership</h1>
                  <div className="mb-3">
                    <h2> Who Can Join Us?</h2>
                    <p>
                      Whether you are a professional consultant, freelancer,
                      sales professional or organization, as long as you can
                      refer potential customers to Skyline using various
                      channels, we welcome you to our referral partner program.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2>What Are We Looking For?</h2>
                    <p>
                      We are looking for individuals who have confidence in the
                      capabilities of Skyline Infosys and share our passion for
                      delivering outstanding work to our clients.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2>How Can You Benefit?</h2>
                    <p>
                      Our referral program offers a win-win situation where you
                      benefit from your referrals, and we reward your confidence
                      in us.
                    </p>
                    <ul className="mt-4 ">
                      <li>Earn attractive commission on each sale.</li>
                      <li>
                        Get 4-hour training and support to maximize the use of
                        our referral tools.
                      </li>
                      <li>
                        Access to a banner that you can easily publish on your
                        website or blog.
                      </li>
                      <li>
                        Email templates to help you refer Skyline services to
                        your contacts.
                      </li>
                      <li>
                        Social media message templates to make referring Skyline
                        services to your contacts quick and easy.
                      </li>
                    </ul>
                  </div>

                  <button className="button mt-3 px-3">
                    {" "}
                    Connect For Referral Partnership
                  </button>
                </div>
              </Col>
              <Col md={6}>
                <img
                  className="img-fluid"
                  src={ReferralPartner}
                  alt="ReferralPartner"
                />
              </Col>
            </Row>
          )}

          {activeTab === "Sales Partnership" && (
            <Row>
              <Col md={6}>
                <div className="content">
                  <h1>About Our Sales Partnership</h1>
                  <div className="mb-3">
                    <p>
                      Our cooperation with you is guaranteed to be fruitful and
                      stable. Our "Sales Partner" might be a value-added
                      reseller who takes care of the entire sales cycle, from
                      lead generation and requirements gathering to solution
                      creation and deployment.
                    </p>
                  </div>

                  <div className="mb-3">
                    <p>
                      A Strategic Partnership as a Sales Partner requires the
                      following:
                    </p>
                    <ul className="mt-4 ">
                      <li>
                        Know-how of cloud services and client-side deployment.
                      </li>
                      <li>
                        Powerful lead creation and market penetration
                        capabilities in your area.
                      </li>
                      <li>
                        Ability to navigate the software development life cycle
                        for web and mobile applications
                      </li>
                      <li>
                        One's ability to cater to a customer's needs improves
                        when one gains experience in a certain trade.
                      </li>
                      <li>
                        The ability to read consumers' needs is crucial for
                        weeding out unqualified prospects. A highly desirable
                        trait in a business associate.
                      </li>
                    </ul>
                  </div>

                  <button className="button mt-3 px-3">
                    {" "}
                    Connect For Sales Partnership
                  </button>
                </div>
              </Col>
              <Col md={6}>
                <img
                  className="img-fluid"
                  src={partnership_sale}
                  alt="partnership_sale"
                />
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </section>
  );
};

export default PerfectPartner;
