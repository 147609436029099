import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import VueImage from "../../../Images/Technology/VueJs/Vue_banner.svg";
import VueImage2 from "../../../Images/Technology/VueJs/vue_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"

const VueService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Professional Vue JS Development Services | Skyline Infosys
        </title>
        <meta
          name="description"
          content="

Skyline Infosys provides excellent Vue JS development services, building efficient, and intelligent single-page applications. Enhance your website now!"
        />
        <meta name="keywords" content="vuejs development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="Vue Js Development"
            content="VueJS is one of the widely used software technologies for web development. VueJS is an open-source Javascript framework to build fast, performant & smart single-page applications (SPAs) and user interfaces."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Vue Js development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Vue JS Development"
          title="Vue Js Development Services In all over India"
          image={VueImage}
          content={
            <>
              <p className="my-3">
                Our Vue JS development services in India offer a range of
                solutions that cater to the diverse needs of businesses across
                industries. Vue JS is a progressive JavaScript framework that is
                gaining popularity due to its ease of use, flexibility, and
                scalability. Our team of skilled Vue JS developers has extensive
                experience in building applications using this framework and can
                help you develop solutions that are customized to meet your
                specific requirements. We follow a collaborative approach to
                development, working closely with our clients to understand
                their business goals and develop solutions that align with their
                objectives. Our Vue JS development services cover everything
                from simple websites to complex web applications, and we ensure
                that our solutions are optimized for performance, security, and
                scalability. Whether you're a startup, SME, or large enterprise,
                we can help you unlock the full potential of Vue JS to take your
                business to the next leve
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"vuejs"}
          heading="Why Should You Go For Vue Js Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Create Dynamic React JS Development?"
          image={VueImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                Our React JS development team comprises skilled developers who
                have extensive experience in developing web applications using
                React JS. We understand that businesses require web applications
                that are not only visually appealing but also perform seamlessly
                across all devices. This is why we use the latest tools and
                technologies to ensure that our applications are responsive and
                optimized for maximum performance. We also prioritize
                communication with our clients to ensure that they are always
                up-to-date with the progress of their project. Our focus on
                teamwork, communication, and quality has helped us build
                long-lasting relationships with our clients, and we're committed
                to providing the same level of service to you. Partner with
                Skyline Infosys today and experience the power of React JS for
                yourself.
              </p>
              <p>
                Our team of React JS developers in India has a deep
                understanding of the latest technologies and techniques to build
                applications that are not only dynamic and interactive but also
                add significant financial value to your business. We work
                closely with our clients to understand their business goals and
                objectives to develop customized solutions that meet their
                specific needs. Our strategic approach to development ensures
                that we deliver solutions that are aligned with the aims and
                ambitions of your company. We focus on developing applications
                that engage and thrill users, providing them with a seamless
                user experience that enhances their overall experience..
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          data={ProjectPlanData}
          content="vuejs"
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default VueService;
