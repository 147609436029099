import React from "react";
import AboutSkyline from "./Components/AboutSkyline";
import "./About.css";
import OurServices from "./Components/OurServices";
import IndustriesServing from "../Home/Components/IndustriesServing ";
import { digitalDevelopment } from "../../data";
import BusinessOperation from "./Components/BusinessOperation";
import VariousTechnologies from "./Components/VariousTechnologies";
import TimeZones from "./Components/TimeZones";
import Banner from "../../Component/Banner";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import ContactForm from "../Contact/Components/ContactForm";
import { Helmet } from "react-helmet";
import AboutBg from "../../Images/AboutUs/aboutBg.png"


const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About us | IT consulting and services</title>
        <meta
          name="description"
          content="Solve your IT problems by outsourcing and boosting your business with our expert IT consulting and services, providing valuable software solutions"
        />
        <meta name="keywords" content="IT consulting and services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      
      <div className="about">
        <div className="home ">
          <Banner
          showInfo={true}
            backGroundImg={AboutBg}
            heading="  About Skyline Infosys"
            content="  Skyline Infosys is a leading IT company specializing in software development, web design, mobile app development, and IT consulting. With a team of experienced professionals, we are committed to delivering high-quality solutions tailored to the unique needs of our clients. Established in 10, we have successfully partnered with various industries to bring their digital visions to life."
            btnText=" Contact Us"
            title="Skyline Infosys' Passion for Development Innovation."
          />
        </div>
        <AboutSkyline />
        <OurServices />

        <IndustriesServing
          data={digitalDevelopment}
          title={` We deliver Digital Development Solution across
`}
          descriptio={``}
        />
        <BusinessOperation />
        <TimeZones />
        <hr />
        <VariousTechnologies />
        <MajorClient />
        <Testimonials />
        <ContactForm />
      </div>
    </>
  );
};

export default About;
