import React, { useState } from "react";
import { Container } from "react-bootstrap";
import TimeMaterial from "../../../Images/Service/time_material_diagram.png";
import fixedPriceImg from "../../../Images/Service/service_diagram.png";
import hiringModalImg from "../../../Images/Service/hiring_engagement.svg";

import EngagementTab from "./EngagementTab";

const FriendlyEngagementModel = () => {
  // Set up state for the active tab
  const [activeTab, setActiveTab] = useState("Dedicated Hiring Model");
  // Array of tab names for easy management
  const tabs = ["Fixed Price", "Dedicated Hiring Model", "Time and Material"];
  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [timeMaterial] = useState({
    description:
      "Our Time & Material model provides flexibility and adaptability to meet your project's ever-changing requirements. This is ideal for projects that require ongoing refinement and real-time progress tracking.",
    title: "Benefits of our Time & Material Model",
    benefits: [
      "Adaptability to fluctuating requirements and dynamic project scope",
      "Seamless resource scaling to ensure timely delivery and superior results",
      "Access to a dedicated Tech Lead to oversee project performance",
      "Flexible payment options to ensure cost-efficiency",
    ],
    image: TimeMaterial,
  });

  const [fixedPrice] = useState({
    description:
      "Opt for Skyline's fixed-priced model to explore an optimized web development services experience for projects with well-defined scope. The model pre-defines the project's objective and essential goals, along with its scope, and budget.",
    title: "Benefits of our Fixed Price Model",
    benefits: [
      "Before beginning a project, its resources, duration, and budget are laid out.",
      "less uncertainty due to fixed costs and schedules",
      "Payment is due at the end of each milestone",
      "Consent is required for any scope changes; we will never go above.",
      "We'll appoint a technical lead to assist you in overseeing your project with no additional cost.",
    ],
    image: fixedPriceImg,
  });
  const [hiringModal] = useState({
    description:
      "Our Dedicated Hiring Model is the ideal answer for companies looking to meet their digital transformation objectives. With top-tier personnel hand-picked to work on your project, you can rest assured that it is in the best hands possible.",
    title: "Benefits of hiring from Skyline Infosys Skyline Infosys",
    benefits: [
      "Empower Your Business with Skyline Infosys' Elite Talent Poo",
      "Rapid Resource Acquisition: Get the Right Team Members in Just 3 Days!",
      "Flexible Monthly and Hourly Pricing Models to Meet Your Budget",
      "Complete Transparent Communication with Your Dedicated Team",
      "Stay Informed and Confident with Daily Progress Reports",
    ],
    image: hiringModalImg,
  });

  const tabData = {
    "Fixed Price": fixedPrice,
    "Dedicated Hiring Model": hiringModal,
    "Time and Material": timeMaterial,
  };

  return (
    <section className="engagement_model">
      <Container>
        <h1 className="heading">
          We work with you with these Business Friendly Engagement Model
        </h1>

        <div className="tab_box my-5 d-flex gap-3 flex-wrap justify-content-between align-items-center">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={activeTab === tab ? "active" : ""}
            >
              {tab}
            </button>
          ))}
        </div>

        <EngagementTab data={tabData[activeTab]} />
      </Container>
    </section>
  );
};

export default FriendlyEngagementModel;
