import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import KotlinIcon from "../../../Images/icon/Kotlin.svg";
import SpringBootIcon from "../../../Images/icon/Spring.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/android_ic.webp";
import AndroidAppImg from "../../../Images/HireDeveloper/AndroidDeveloper.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const AndroidDeveloper = () => {

  const homeData = {
    title: "Hire <span>Android App</span> Developers in India",
    description: `Empower your business with skilled Android app developers delivering cutting-edge mobile solutions. 
        Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
        in Android Studio, Kotlin, Java, Firebase, and more, ensuring high-quality results. With flexible hiring models and a client-first 
        approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Android app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Android app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your intellectual property.",
      },
    ],
  };
  
  

  // hire-top-developer-data.ts
const hireTopDeveloperData = {
  heading: "Hire Top Android App Developers in India from Skyline Infosys",
  image: {
    src: AndroidAppImg, // Update the path to the Android app image
    alt: "AndroidAppImg",
  },
  content: {
    description: `Looking to create innovative, scalable, and high-performing mobile applications? At Skyline Infosys, 
      we offer expert Android app developers who specialize in crafting custom mobile solutions for businesses of all sizes. 
      From native Android development to integrating third-party APIs, our team delivers exceptional results tailored to your needs.`,
    expertiseHeading: "Our Android app developers excel in:",
    expertisePoints: [
      "Building feature-rich, scalable, and high-performance native Android applications using Java and Kotlin",
      "Creating user-friendly and intuitive UI/UX designs for mobile apps",
      "Integrating third-party services, APIs, and SDKs for extended functionality",
      "Optimizing Android apps for performance, speed, and battery efficiency",
      "Implementing Firebase solutions for real-time databases, push notifications, and analytics",
    ],
    closingNote: `Partner with us today to transform your mobile app ideas into reality. 
      Hire dedicated Android app developers from Skyline Infosys to create user-friendly, high-performing applications that elevate your business.`,
  },
};

  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Android Combinations",
    description: `At Skyline Infosys, we specialize in integrating Android development with powerful back-end and front-end technologies to deliver feature-rich, high-performance mobile applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "Android + Firebase",
        description: `A robust combination for real-time data synchronization, user authentication, and serverless architecture. Firebase powers the back-end with real-time database and cloud functions, while Android ensures a seamless user interface for an engaging mobile experience.`,
        icon: FirebaseIcon,
      },
      {
        title: "Android + Node.js",
        description: `A perfect stack for real-time, scalable applications. Node.js handles the back-end with event-driven architecture, while Android serves as the dynamic and user-friendly front-end for mobile users.`,
        icon: NodeJsIcon,
      },
      {
        title: "Android + Python/Django",
        description: `Ideal for apps requiring complex server-side logic, data analytics, or AI integration. Python's Django framework powers a secure and scalable back-end, while Android delivers an intuitive mobile interface.`,
        icon: PythonIcon,
      },
      {
        title: "Android + AWS",
        description: `Combine Android with Amazon Web Services to create scalable and reliable apps. AWS offers cloud hosting, storage, and serverless computing, making it a powerful back-end for Android-powered solutions.`,
        icon: AWSIcon,
      },
      {
        title: "Android + Kotlin Multiplatform",
        description: `Build cross-platform apps with shared codebases using Kotlin Multiplatform. Develop for both Android and iOS simultaneously, reducing time-to-market while maintaining a consistent user experience.`,
        icon: KotlinIcon,
      },
      {
        title: "Android + React Native",
        description: `Develop hybrid applications using React Native for a unified codebase across platforms. Pair it with native Android components for seamless performance and modern user interfaces.`,
        icon: ReactIcon,
      },
      {
        title: "Android + Java Spring Boot",
        description: `Integrate Android with Spring Boot for powerful back-end systems. This combination is excellent for enterprise-level apps requiring robust APIs and microservices architecture.`,
        icon: SpringBootIcon,
      },
      {
        title: "Android + GraphQL",
        description: `Leverage GraphQL to fetch precise data for your Android applications. Simplify communication between the front-end and back-end, reducing data overfetching and ensuring faster response times.`,
        icon: GraphQLIcon,
      },
    ],
  };
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default AndroidDeveloper;
