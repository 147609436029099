import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Ionic/ionic-service.png";
import BannerImage1 from "../../../Images/Technology/Ionic/ionic-banner.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const IonicService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top-Tier Ionic Development Services | Skyline Infosys</title>
        <meta
          name="description"
          content="
Skyline Infosys offers efficient Ionic app development, perfect for cost-effective and cross-platform solutions. Partner with us for your app success."
        />
        <meta name="keywords" content="Ionic development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="Ionic App Development"
            content="Looking to build a cost-effective and high-performing mobile app? Ionic is the perfect choice for cross-platform app development. At Skyline Infosys, we provide end-to-end Ionic app development services to bring your ideas to life."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Ionic App Development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Ionic Development"
          title="Ionic Development Services Across India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                As a leading Ionic app development company in India, we offer a
                comprehensive range of Ionic development services to help
                businesses unlock the potential of cross-platform mobile
                applications. Our team of skilled Ionic developers specializes
                in building high-performance, feature-rich, and visually
                captivating apps for both Android and iOS platforms.
              </p>
              <p>
                Our Ionic app development services include app design,
                development, testing, deployment, and maintenance. We leverage
                the latest tools and technologies to deliver innovative Ionic
                apps that ensure a seamless user experience across various
                devices and platforms.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"ionic"}
          heading="Why Should You Go For Ionic App Development?"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Develop Ionic Apps?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                As a leading Ionic app development company, we follow a
                streamlined process to create high-quality, feature-rich Ionic
                apps that align with our clients' business goals and exceed
                their expectations.
              </p>
              <p>
                Our team of expert Ionic developers leverages the power of web
                technologies like HTML, CSS, and JavaScript, along with Ionic's
                robust framework. By utilizing Ionic's extensive library of UI
                components and plugins, we deliver high-performance, secure, and
                scalable mobile applications for both Android and iOS platforms.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We Work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Framework",
            "Database",
            "Testing",
            "Project Management",
          ]}
          serviceName="Mobile Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work "
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default IonicService;
