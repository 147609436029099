import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/MySQL/mysql_banner.png";
import BannerImage1 from "../../../Images/Technology/MySQL/mysql_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import { ProjectPlanData, Technologies, technologyFeatureData } from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const MySQLService = () => {
  return (
   <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>
        MySQL Database Solutions | Skyline Infosys
        </title>
        <meta
          name="description"
          content="Streamline MySQL services. Boost efficiency with automated assignments, expedite app deployment, and optimize cloud-native database apps."
        />
        <meta name="keywords" content="MySQL Database" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    <div className="service technology">
      <div className="home ">
        <Banner
                 backGroundImg={CommonBg}

          heading="MySQL Database"
          content="Envision end-to-end MySQL services from consulting to implementation Improve efficiency via automating assignments, get applications into production quicker and convey current cloud local database applications with MySQL services."
          title="Development Company "
          btnText="Consult Skyline"
          subTitle="Explore MySQL Database Company"
        />
      </div>

      <DevelopmentInfo
        heading="MySQL Database"
        title="MySQL Database Services In all over India"
        image={BannerImage}
        content={
          <>
            <p className="my-3">
            MySQL is a popular open-source database management system used by many organizations across the globe. At our company, we provide comprehensive MySQL database services in all over India to help businesses manage their data effectively. Our team of experienced database administrators and developers can help you with everything from database design and installation to performance tuning, backup and recovery, and more.
            </p>
            <p>
            We understand that each business has unique data management requirements, and our MySQL experts work closely with you to understand your specific needs and deliver customized solutions. Our services include database design and implementation, database migration and integration, performance tuning and optimization, backup and recovery, security and compliance, and ongoing maintenance and support.
            </p>
          </>
        }
      />
      <TechnologiesFeatures
      technology={"mysql"}
        data={technologyFeatureData}
        heading="Why Should You Go For MySQL Database ?
"
      />

      <DevelopmentInfo
        heading=""
        title="How Do We Deliver MySql Database Development?"
        image={BannerImage1}
        order={2}
        content={
          <>
            <p className="my-3">
            At our company, we follow a comprehensive approach to deliver high-quality MySQL database development services. Our team of experienced database developers follows industry best practices and uses the latest tools and technologies to ensure that your database is optimized for performance, security, and scalability At our company, we follow a comprehensive approach to deliver high-quality MySQL database development services. Our team of experienced database developers follows industry best practices and uses the latest tools and technologies to ensure that your database is optimized for performance, security and scalability
            </p>
          </>
        }
      />

      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Database",
          "Frontend",
          "Backend",
          "Unit Testing",
          "Deployment",
          "Project Management",
          "CMS",
        ]}
        serviceName="Web Development"
      />
      <ServiceFromSkyline />
      <hr className="mb-0" />
      <AboutDevelopment heading="How we work" content="vuejs"   data={ProjectPlanData} />
      <hr className="mb-0" />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
   </>
  );
};

export default MySQLService;
