import React from 'react'
import Banner from '../../Component/Banner'
import TimeLine from './Components/TimeLine'
import "./lifeSkyline.css"
import MajorClient from '../Home/Components/MajorClient'
import Testimonials from '../Home/Components/Testimonials'
import { Helmet } from 'react-helmet'
import LigeBg from "../../Images/LifeSkyline/client_visit_team_lunch.webp"

const LifeSkyline = () => {
  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Skyline| Work, Celebrate, Grow</title>
        <meta
          name="description"
          content="We empower businesses & celebrate achievements. Explore our teamwork-driven culture, commitment to growth, and value-driven outsourcing services."
        />
        <meta name="keywords" content="skyline" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
           backGroundImg={LigeBg}
          heading="Life @ Skyline Infosys"
          content="At Skyline Infosys, we foster a vibrant and inclusive workplace where innovation thrives. Our culture encourages creativity, collaboration, and personal growth, ensuring every team member feels valued and inspired. From challenging projects to exciting team activities, life here is a perfect blend of learning and fun. We celebrate achievements, embrace diversity, and empower our people to reach their full potential. Join us and be part of a dynamic journey that shapes the future of technology!"
          title="Creative Plans."
          btnText="Consult Skyline"
        />
      </div>
      <TimeLine/>
      <MajorClient />
      <Testimonials />
    </div>
  )
}

export default LifeSkyline
