import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import AngularImage from "../../../Images/Technology/AngularJs/angular.svg";
import AngularImage2 from "../../../Images/Technology/AngularJs/angular_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"


const AngularService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom & Scalable AngularJS Solutions | Skyline Infosys</title>
        <meta
          name="description"
          content="
          Our AngularJS development services serve various industries like eCommerce & healthcare ensuring your product meets all requirements effectively. "
        />
        <meta name="keywords" content="angular js development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
             backGroundImg={CommonBg}
            heading="Angular Js Development"
            content="We provide AngularJS development services that span a variety of industries, from eCommerce to healthcare and learning platforms. Our specialized AngularJS Web Application Development services assist your product in not only satisfying all your requirements but also holding its own against the fierce competition."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Angular Js development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Angular Js Development"
          title="Angular Js Development Services In all over India"
          image={AngularImage}
          content={
            <>
              <p className="my-3">
                Angular Js Development Services In all over India
              </p>
              <p>
                Our experienced team of AngularJS developers works closely with
                clients to understand their unique business requirements and
                deliver customized solutions that meet their specific needs. We
                have a proven track record of developing high-quality, scalable,
                and feature-rich web and mobile applications using AngularJS.
                Our developers are well-versed in the latest trends and best
                practices in AngularJS development, and we use cutting-edge
                tools and technologies to deliver solutions that are secure,
                reliable, and easy to maintain. We follow a rigorous testing
                process to ensure that our applications are completely bug-free
                and meet the highest standards of quality. With our AngularJS
                development services, you can be assured of getting an
                application that is user-friendly, responsive, and helps you
                achieve your business goals. Contact us today to learn more
                about how we can help you take your business to the next level
                with our AngularJS development services.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology="angularjs"
          heading="Why Should You Go For Angular Js Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed AngularJS Development?"
          image={AngularImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                At skyline infosys, we understand that every business has unique
                requirements and challenges. That's why we offer customized
                AngularJS development services to help you meet your specific
                business needs. Our team of skilled developers has years of
                experience in developing robust, scalable, and feature-rich
                AngularJS web and mobile applications for a variety of
                industries. We follow a customer-centric approach to development
                and work closely with our clients to ensure that we deliver
                solutions that meet their exact requirements. Our developers
                stay up-to-date with the latest trends and best practices in
                AngularJS development to ensure that we deliver solutions that
                are cutting-edge and high-performing. With our AngularJS
                development services, you can be assured of getting a reliable,
                secure, and user-friendly application that helps you achieve
                your business goals. Contact us today to learn more about how we
                can help you leverage the power of AngularJS for your business.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work "
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default AngularService;
