import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div className="terms_conditions">
      <Container>
        <h1 className="heading mb-5">Privacy Policy </h1>
        <p>
          Thank you for choosing to be part of our community at{" "}
          <b>Skyline Infosys</b> ("Company," "we," "us," or "our"). We are
          dedicated to safeguarding your personal information and respecting
          your privacy rights.
        </p>
        <p>
          If you have any questions or concerns about this notice, or our
          practices regarding your personal information, please contact us at{" "}
          <b>info@skylineinfosys.com</b> .
        </p>
        <p>
          This Privacy Policy explains how we collect, use, and protect your
          data when you visit our website{" "}
          <a href="https://skylineinfosys.com"> https://skylineinfosys.com </a>{" "}
          or use any of our services ("Services"). By accessing our Website and
          Services, you agree to the terms outlined in this notice.
        </p>
        <div className="content mt-5">
          <h6 className="mb-3"> 1. Information We Collect </h6>
          <div className="ms-md-4 mt-2 ms-2">
            <b>Personal Information You Provide</b>
            <p>
              - Name, email address, phone number, and other details submitted
              via forms or inquiries.
            </p>
          </div>
          <div className="ms-md-4  mt-2  ms-2">
            <b>Automatically Collected Data</b>
            <p>
              - IP address, device details, browser type, and cookies used for
              analytics and improving user experience.
            </p>
          </div>
        </div>

        <div className="content ">
          <h6 className="mb-2"> 2. How We Use Your Information </h6>
          <div className="ms-md-4 mt-2 ms-2">
            <b>We process your information to: </b>
            <p>Provide and enhance our Services.</p>
            <p>Communicate updates, newsletters, or promotions (with your consent).  </p>
            <p>Maintain security and comply with legal obligations.  </p>
          </div>
        </div>

        <div className="content ">
          <h6 className="mb-2">  3. Sharing Your Information   </h6>
          <p>We do not sell your data. Information is shared only with trusted partners or during business transactions, ensuring confidentiality and lawful use.  </p>
        </div>

        <div className="content ">
          <h6 className="mb-2">4. Cookies and Tracking Technologies   </h6>
          <p>We use cookies to improve your browsing experience. You can adjust cookie preferences via your browser settings.   </p>
        </div>

        <div className="content ">
          <h6 className="mb-2"> 5. Data Retention    </h6>
          <p>We retain your personal information for as long as necessary to fulfill the purposes described in this notice or as required by law.   </p>
        </div>

        <div className="content ">
          <h6 className="mb-2"> 6. Data Security   </h6>
          <p>We implement robust measures to secure your information. However, no online system is entirely foolproof, and data transmission is at your own risk.   </p>
        </div>

        <div className="content ">
          <h6 className="mb-2">7. Your Privacy Rights   </h6>
          <p>You may have rights to access, correct, or delete your data, depending on your location. Contact us to exercise these rights </p>
        </div>

        <div className="content ">
          <h6 className="mb-2"> 8. Updates to This Policy   </h6>
          <p>This notice may be updated periodically. Please review it regularly to stay informed about how we protect your data.  </p>
        </div>

        <div className="content ">
          <h6 className="mb-2">  9. Contact Us   </h6>
          <div className="ms-md-4 mt-2 ms-2">
            <b> <i>For questions or concerns, please reach out to</i> : Skyline Infosys  </b>
           <p>   <b>  Address : </b> B-805 to 809, IT Park, Opp. Gajera International School (Digital Valley), Surat, Gujarat 394101  </p>
           <b> Email :</b>
           <a href="mailto:info@skylineinfosys.com"> info@skylineinfosys.com </a>
          </div>        </div>

      

      </Container>
    </div>
  );
};

export default PrivacyPolicy;
