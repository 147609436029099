import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import DotNetCoreIcon from "../../../Images/icon/dotnet_ic.webp";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import KubernetesIcon from "../../../Images/icon/Kubernetes.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/AWS.svg";
import AWSCloudImg from "../../../Images/HireDeveloper/AwsImage.jpg";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const AWS = () => {

  const homeData = {
    title: "Hire <span>AWS </span> Developers in India",
    description: `Empower your business with skilled AWS  developers delivering scalable, secure, and highly available cloud-based solutions. 
      Leverage Amazon Web Services (AWS) robust cloud platform to build high-performance, cost-effective applications tailored to your business needs. 
      Our developers specialize in AWS services, cloud computing, app hosting, serverless architectures, and integrating advanced features like AI, data analytics, and IoT.
      With flexible hiring models and a client-first approach, we guarantee seamless collaboration and timely delivery. 
      Transform your ideas into reality with our AWS app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our in-house team brings over 5 years of experience in AWS cloud app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure the highest standards of security, scalability, and cost-efficiency in your AWS cloud applications.",
      },
    ],
  };
  
  

  const hireTopDeveloperData = {
    heading: "Hire Top AWS Cloud Developers in India from Skyline Infosys",
    image: {
      src: AWSCloudImg, 
      alt: "AWSCloudImg",
    },
    content: {
      description: `Looking to leverage the power of AWS cloud for your business? At Skyline Infosys, we offer expert AWS Cloud developers who specialize in delivering scalable, secure, and high-performance cloud solutions tailored to your business needs. From cloud migration to optimizing your infrastructure, our team ensures seamless integration and robust management of your applications on the AWS platform.`,
      expertiseHeading: "Our AWS Cloud developers excel in:",
      expertisePoints: [
        "Designing and deploying scalable and secure cloud infrastructure using AWS services like EC2, S3, Lambda, and VPC",
        "Implementing cloud solutions with AWS Elastic Beanstalk, ECS, and AWS Fargate for containerized applications",
        "Migrating on-premise infrastructure to AWS cloud with minimal downtime and optimized cost-efficiency",
        "Integrating machine learning, data analytics, and AI using AWS SageMaker, Redshift, and other AWS services",
        "Optimizing cloud resources with AWS cost management tools and performance monitoring with CloudWatch",
        "Ensuring high levels of security and compliance using AWS Security Hub, IAM, and other security best practices",
      ],
      closingNote: `Partner with us today to build scalable cloud solutions using AWS. Hire dedicated AWS Cloud developers from Skyline Infosys to optimize your cloud infrastructure and drive your business forward with secure, efficient cloud services.`,
    },
  };
  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack AWS Combinations",
    description: `At Skyline Infosys, we specialize in integrating AWS Cloud services with powerful back-end and front-end technologies to deliver scalable, secure, and high-performance cloud solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "AWS + .NET Core",
        description: `Leverage the power of Microsoft’s .NET Core framework with AWS’s cloud services for building scalable, secure, and high-performance applications. This combination is ideal for enterprise-level applications that require a robust back-end architecture hosted on AWS. `,
        icon: DotNetCoreIcon,
      },
      {
        title: "AWS + React.js",
        description: `Integrate AWS cloud services with React.js for building modern web applications. AWS handles cloud hosting, databases, and serverless functions, while React.js powers the dynamic and responsive user interface for seamless experiences.`,
        icon: ReactIcon,
      },
      {
        title: "AWS + Node.js",
        description: `AWS provides the cloud infrastructure, while Node.js serves as the efficient back-end for real-time data processing, API handling, and scalable applications. This combination offers a powerful full-stack solution for building modern web apps.`,
        icon: NodeJsIcon,
      },
      {
        title: "AWS + Kubernetes",
        description: `Use Amazon EKS (Elastic Kubernetes Service) to manage and orchestrate containerized applications with AWS’s cloud infrastructure. This combination ensures seamless deployment, scalability, and management of microservices-based architectures.`,
        icon: KubernetesIcon,
      },
      {
        title: "AWS + Python/Django",
        description: `Build scalable, secure, and high-performance back-end systems using AWS cloud services integrated with Python’s Django framework. This combination is ideal for data-driven applications and AI-based solutions.`,
        icon: PythonIcon,
      },
      {
        title: "AWS + Angular",
        description: `Combine AWS’s cloud platform with Angular to build dynamic, enterprise-grade web applications. AWS offers reliable cloud hosting, storage, and databases, while Angular handles the interactive and modern front-end UI.`,
        icon: AngularIcon,
      },
      {
        title: "AWS + AI/ML",
        description: `Leverage AWS’s AI and machine learning services, such as SageMaker and Rekognition, to create intelligent, data-driven applications. Integrate these advanced capabilities with your application’s back-end, powered by AWS, for robust AI-driven functionalities.`,
        icon: AiIcon,
      },
      {
        title: "AWS + DevOps",
        description: `Automate your application lifecycle using AWS DevOps tools like CodePipeline, CodeBuild, and CodeDeploy. This combination enables fast, reliable deployments and continuous integration, ensuring streamlined management of cloud-based projects.`,
        icon: DevOpsIcon,
      },
    ],
  };
  
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default AWS;
