import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import KotlinIcon from "../../../Images/icon/Kotlin.svg";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import SpringBootIcon from "../../../Images/icon/Spring.png";
import RestApiIcon from "../../../Images/icon/RestApiIcon.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/ionic_ic.webp";
import IonicAppImg from "../../../Images/HireDeveloper/IonicDevelopment.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const IonicDeveloper = () => {
  const homeData = {
    title: "Hire <span>Ionic App</span> Developers in India",
    description: `Leverage the power of Ionic to build high-quality, cross-platform mobile applications that perform seamlessly across devices. 
            Our expert Ionic app developers deliver robust, scalable, and efficient solutions, tailored to meet your business needs. 
            With proficiency in Angular, React, Vue.js, TypeScript, and Cordova, we offer top-tier Ionic app development services. 
            Enjoy cost-effective solutions, flexible hiring models, and guaranteed on-time delivery with Skyline Infosys!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Try our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Build high-performance, cross-platform apps for iOS, Android, and the web with Ionic’s single codebase.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers have over 5 years of experience in Ionic app development across multiple platforms.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure responsive UI/UX design consistency across different devices and screen sizes.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Get clean, maintainable, and secure code with full protection of your intellectual property.",
      },
    ],
  };
  
  
  
  
  
  

  // hire-top-developer-data.ts
  const hireTopDeveloperData = {
    heading: "Hire Top Ionic App Developers in India from Skyline Infosys",
    image: {
      src: IonicAppImg, // Update the path to the Ionic app image
      alt: "IonicAppImg",
    },
    content: {
      description: `Looking to build high-performance, cross-platform mobile applications? At Skyline Infosys, 
        we offer expert Ionic app developers who specialize in creating custom mobile solutions for businesses of all sizes. 
        From creating beautiful UIs to integrating advanced features, our developers deliver results tailored to your needs, 
        leveraging Ionic's robust framework for iOS, Android, and web applications.`,
      expertiseHeading: "Our Ionic app developers excel in:",
      expertisePoints: [
        "Building cross-platform mobile applications using Ionic, Angular, React, and Vue.js",
        "Creating responsive, visually appealing, and consistent UI/UX designs",
        "Integrating APIs, Firebase, Cordova, and third-party SDKs for extended functionality",
        "Optimizing apps for performance, speed, and seamless cross-platform compatibility",
        "Using Ionic’s rich component library to reduce development time and enhance user experience",
      ],
      closingNote: `Partner with us to bring your Ionic app ideas to life. 
        Hire dedicated Ionic app developers from Skyline Infosys to create robust, high-performance applications that help your business grow.`,
    },
  };
  
  
  
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Ionic Combinations",
    description: `At Skyline Infosys, we specialize in combining Ionic app development with powerful back-end and front-end technologies to create scalable, high-performance cross-platform mobile applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "Ionic + Firebase",
        description: `A powerful combination for serverless applications with real-time data synchronization, user authentication, and push notifications. Firebase provides back-end infrastructure, while Ionic ensures a consistent UI across iOS, Android, and the web.`,
        icon: FirebaseIcon,
      },
      {
        title: "Ionic + Node.js",
        description: `Ideal for building real-time, scalable applications. Node.js handles server-side logic efficiently, while Ionic provides a responsive, smooth cross-platform mobile interface.`,
        icon: NodeJsIcon,
      },
      {
        title: "Ionic + Python/Django",
        description: `Perfect for complex applications that require robust back-end logic, data analysis, or AI integration. Django powers a secure, scalable back-end, while Ionic delivers a seamless cross-platform user experience.`,
        icon: PythonIcon,
      },
      {
        title: "Ionic + AWS",
        description: `Leverage AWS services for scalable cloud solutions, including hosting, storage, and serverless architecture. Combine with Ionic to build reliable, high-performance mobile applications.`,
        icon: AWSIcon,
      },
      {
        title: "Ionic + GraphQL",
        description: `Optimize data fetching with GraphQL for your Ionic apps. This stack ensures faster response times, reduced overfetching, and precise data handling for multi-platform development.`,
        icon: GraphQLIcon,
      },
      {
        title: "Ionic + REST APIs",
        description: `Integrate seamlessly with RESTful APIs for smooth data exchange and enhanced functionality. Ionic handles the UI, while REST APIs manage back-end communication to ensure app performance and reliability.`,
        icon: RestApiIcon,
      },
      {
        title: "Ionic + Kotlin Multiplatform",
        description: `Pair Ionic with Kotlin Multiplatform to share business logic between iOS and Android. This combination accelerates development and ensures a native-like experience on both platforms.`,
        icon: KotlinIcon,
      },
      {
        title: "Ionic + Java Spring Boot",
        description: `Combine Ionic with Java Spring Boot for enterprise-level apps. Spring Boot powers the back-end with secure APIs and microservices, while Ionic delivers a dynamic mobile experience for both iOS and Android.`,
        icon: SpringBootIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
        <div className="ios_page">
        <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
        </div>
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default IonicDeveloper;
