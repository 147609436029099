import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import WebDevelopmentImage from "../.../../../Images/Service/webApp-development.webp";
import { Technologies, WebAppDevelopment } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";

const WebDevelopment = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leading Web App Development Agency | Skyline Infosys</title>
        <meta
          name="description"
          content="
A leading web app development agency provides carefully tailored web application development services. Trust our expertise for your digital success."
        />
        <meta name="keywords" content="web app development agency" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service">
        <div className="home ">
          <Banner
            backGroundImg={ServiceBg}
            heading="Web App  Development"
            content=" Our custom web application development services are meticulously crafted to empower your brand. As a trusted web development company, we leverage cutting-edge technologies and tools to help you create your unique web application."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Web development Company"
          />
        </div>
        <DevelopmentInfo
          heading="Web App Development"
          title="Top Web App Development Company"
          image={WebDevelopmentImage}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we prioritize a customer-centric approach,
                keeping your needs at the forefront of our minds. We collaborate
                closely with you from start to finish, transforming your vision
                into reality with cutting-edge technology and innovative
                solutions.
              </p>
              <p>
                From brainstorming to final delivery, our top-notch web
                application development services are designed to help you
                achieve sustainable growth and success.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Web App Development ?"
          data={WebAppDevelopment}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default WebDevelopment;
