import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const HireTopDeveloper = ({data}) => {
  return (
    <section className="developer_info">
      <Container>
      <h1 className="heading">{data.heading}</h1>
    <Row className="my-5 align-items-center content_box">
      <Col lg={6}>
        <img src={data.image.src} alt={data.image.alt} className="img-fluid" />
      </Col>
      <Col lg={6}>
        <div className="developer_dis">
          <p>{data.content.description}</p>
          <h5>{data.content.expertiseHeading}</h5>
          <ul>
            {data.content.expertisePoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          <p>{data.content.closingNote}</p>
        </div>
      </Col>
    </Row>
      </Container>
    </section>
  );
};

export default HireTopDeveloper;
