import React from 'react'

const Loading = () => {
  return (
       <div className="main_loading">
        <div>
          <span className="loader"></span>
        </div>
      </div>
  )
}

export default Loading
