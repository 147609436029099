import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import MobileDevelopmentImage from "../.../../../Images/Service/MobileDevelopmentService/MobileDevelopment.svg";
import { MobileDevelopmentData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";


const MobileDevelopment = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Mobile App Development Company | Skyline Infosys</title>
        <meta
          name="description"
          content="
Skyline Infosys is the best mobile app development company, and delivers expertly crafted solutions globally. Trust us for your app success! "
        />
        <meta name="keywords" content="best mobile app development company" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="Mobile App Development"
            content="Looking for a reliable mobile app development company to help your business tap into the growing market? Look no further than Skyline Infosys! Our mobile app development services are second to none when executing and launching a successful app."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore Mobile App development Company"
          />
        </div>
        <DevelopmentInfo
          heading="Mobile App Development
"
          title="Top Mobile App Development Company"
          image={MobileDevelopmentImage}
          content={
            <>
              <p className="my-3">
                Welcome to Skyline Infosys, the finest mobile app development
                firm in the United States! We are a dedicated team of experts
                passionate about making your vision a reality. Whether you seek
                consumer-oriented software or an enterprise-level solution, we
                can oversee the entire development process, from the initial
                concept to delivery. Our expert developers will provide you with
                full bespoke mobile app development design, integration, and
                administration services, guaranteeing that your app is designed
                to perfection.
              </p>
              <p>
                Skyline Infosys takes pride in providing high-quality mobile app
                development services, and our portfolio demonstrates this. Our
                developers are competent in creating innovative and functional
                apps customised to match your specific needs, whether Android,
                iOS, or cross-platform
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Mobile App Development ?"
          data={MobileDevelopmentData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Framework",
            "Database",
            "Testing",
            "Project Management",
          ]}
          serviceName="Mobile Development"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default MobileDevelopment;
