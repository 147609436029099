import React from "react";
import { FaPhoneAlt, FaSkype } from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa6";
import { GiRotaryPhone } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import { PiOfficeChairFill } from "react-icons/pi";
import { Link } from "react-router-dom";

const Inquiry = () => {
  return (
    <div className="inquiry_detail main_drop_down">
      <div className="drop_down">
        <div className="content mt-2">
          <div className="head">
            <h4>
              <FaBusinessTime />
              For Business Inquiry
            </h4>
            <div className="contact_info">
              <a href="tel:+919879170954">
                <FaPhoneAlt /> +91-987-917-0954
              </a>

              <a href="mailto:sales@skylineinfosys.com">
                <MdEmail /> sales@skylineinfosys.com
              </a>
            </div>
          </div>
        </div>
        <div className="content mt-2">
          <div className="head">
            <h4>
              <PiOfficeChairFill />
              For HR Inquiry (Jobs)
            </h4>
            <div className="contact_info">
              <a href="tel:02614514875">
                <GiRotaryPhone /> 0261 4514875
              </a>

              <a href="tel:+918128996966">
                <FaPhoneAlt /> +91-812-899-6966
              </a>
              <a href="mailto:info@skylineinfosys.com">
                <MdEmail /> info@skylineinfosys.com
              </a>
              <a href="https://join.skype.com/invite/wyPUf0TASYbs">
                <FaSkype />
                Skyline.hr
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
