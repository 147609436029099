import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import Image from "../../../Images/Technology/HTML/html_banner.png";
import Image2 from "../../../Images/Technology/HTML/html_banner2.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"

const HTMLService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Seamless HTML5 Development for Your Project | Skyline Infosys</title>
        <meta
          name="description"
          content="Empower your online presence with Skyline Infosys' HTML5 development services. Enhance user experience and drive business growth with our expertise"
        />
        <meta name="keywords" content="html5 development services " />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
           backGroundImg={CommonBg}
            heading="HTML5 Development"
            content="Develop websites with enhanced performance, visuals, and user engagement Enable the creation of apps and websites with craved functionalities, peerless speed, rich UI, and a smooth user experience."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore HTML5  development Company"
          />
        </div>

        <DevelopmentInfo
          heading="HTML5 Development"
          title="HTML5 Development Services In all over India"
          image={Image2}
          content={
            <>
              <p className="my-3">
                HTML5 is the latest version of the HTML language and is widely
                used for developing modern and dynamic websites and web
                applications. At Skyline Infosys, we provide comprehensive HTML5
                development services that help businesses create engaging and
                interactive websites that can enhance user experience and drive
                business growth.
              </p>
              <p>
                With our HTML5 development services, you can be assured of
                getting a website that is optimized for search engines and
                provides a seamless user experience. Contact us today to learn
                more about our HTML5 development services and how we can help
                you take your online presence to the next level.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"html5"}
          heading="Why Should You Go For HTML5 Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Create DynamicHandsome HTML5 Web Designs?"
          image={Image}
          order={2}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we have a team of expert HTML5 developers
                who specialize in creating dynamic and visually appealing web
                designs that can enhance the user experience and drive business
                growth. Here's how we create handsome HTML5 web designs:
              </p>
              <p>
                Understanding Client Requirements: We start by understanding the
                client's requirements and goals for their website. This helps us
                to create a design that aligns with their business needs and
                objectives.
              </p>
              <p>
                Creating Wireframes: Next, we create wireframes that help us to
                map out the layout and structure of the website. This includes
                identifying the placement of different elements such as the
                header, footer, navigation menu, and content sections.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default HTMLService;
