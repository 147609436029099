import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import KubernetesIcon from "../../../Images/icon/Kubernetes.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import DockerIcon from "../../../Images/icon/Docker.svg";
import JenkinsIcon from "../../../Images/icon/jenkins.svg";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/CI-CD.svg";
import CICDImg from "../../../Images/HireDeveloper/cicd.jpg";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const Cicd = () => {
  const homeData = {
    title: "Hire <span>CICD</span> Developers in India",
    description: `Streamline your development process with our skilled CICD developers, delivering automated, scalable, and secure pipelines. 
        Leverage the power of CICD to accelerate your software delivery lifecycle, minimize manual intervention, and ensure consistent deployments. 
        Our developers specialize in creating robust pipelines using tools like Jenkins, GitLab CI/CD, CircleCI, and GitHub Actions, integrated seamlessly with your application architecture. 
        With flexible hiring models and a commitment to quality, we guarantee smooth collaboration and on-time delivery. 
        Transform your development process with our CICD experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for seamless collaboration.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Expertise in implementing CICD pipelines using tools like Jenkins, GitLab, CircleCI, and AWS CodePipeline.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure high-quality automated testing, deployment, and monitoring for reliable application delivery.",
      },
    ],
  };
  
  
  
  
  
  
  
  

  const hireTopDeveloperData = {
    heading: "Hire Top CI/CD Developers in India from Skyline Infosys",
    image: {
      src: CICDImg, 
      alt: "CICDImg",
    },
    content: {
      description: `Looking to enhance your software development lifecycle with CI/CD automation? At Skyline Infosys, our expert CI/CD developers help you build robust, automated pipelines that streamline integration, delivery, and deployment processes. From configuring build tools to setting up monitoring, we ensure faster releases, improved quality, and reduced time-to-market.`,
      expertiseHeading: "Our CI/CD developers excel in:",
      expertisePoints: [
        "Designing and implementing CI/CD pipelines with tools like Jenkins, GitLab CI/CD, CircleCI, and GitHub Actions",
        "Automating code integration, testing, and delivery for faster, error-free deployments",
        "Integrating CI/CD pipelines with Docker and Kubernetes for containerized applications",
        "Configuring continuous monitoring and feedback loops using tools like Prometheus, Grafana, and ELK stack",
        "Optimizing pipelines for multi-cloud and hybrid environments to achieve cost-efficiency and scalability",
        "Ensuring pipeline security with automated vulnerability scanning, role-based access, and compliance standards",
      ],
      closingNote: `Empower your software development process with CI/CD automation. Hire dedicated CI/CD developers from Skyline Infosys to accelerate releases, improve quality, and achieve seamless software delivery.`,
    },
  };
  


  const expertiseSection = {
    heading: "Our Expertise In CI/CD Technologies and Combinations",
    description: `At Skyline Infosys, we excel in creating and integrating CI/CD pipelines with leading technologies to automate and optimize the software development lifecycle. Our expertise includes:`,
    expertiseData: [
      {
        title: "CI/CD + Kubernetes",
        description: `Combine CI/CD pipelines with Kubernetes to streamline the automation of containerized application deployment and scaling. Kubernetes ensures seamless orchestration, while CI/CD enhances speed and efficiency in the delivery process.`,
        icon: KubernetesIcon,
      },
      {
        title: "CI/CD + Docker",
        description: `Integrate CI/CD pipelines with Docker for automated testing, building, and deployment of containerized applications. This combination ensures consistent environments across development, testing, and production stages.`,
        icon: DockerIcon,
      },
      {
        title: "CI/CD + Jenkins",
        description: `Leverage Jenkins to automate CI/CD pipelines for build, test, and deployment processes. Jenkins integrates seamlessly with various tools and platforms, enabling faster and more reliable delivery cycles.`,
        icon: JenkinsIcon,
      },
      {
        title: "CI/CD + React.js",
        description: `Use CI/CD pipelines to automate the testing, building, and deployment of React.js applications. This ensures quick updates to front-end features with minimal downtime, delivering a seamless user experience.`,
        icon: ReactIcon,
      },
      {
        title: "CI/CD + Python/Django",
        description: `Streamline the delivery of Python/Django applications with CI/CD pipelines. Automate testing, code integration, and deployment to ensure high-quality, scalable back-end solutions.`,
        icon: PythonIcon,
      },
      {
        title: "CI/CD + Node.js",
        description: `Automate the deployment of Node.js applications using CI/CD pipelines, enabling faster updates, reduced errors, and improved efficiency in managing server-side applications and APIs.`,
        icon: NodeJsIcon,
      },
      {
        title: "CI/CD + AI/ML",
        description: `Implement CI/CD pipelines to streamline the deployment and monitoring of machine learning models. Automate model training, validation, and deployment for faster and more efficient delivery of AI/ML solutions.`,
        icon: AiIcon,
      },
      {
        title: "CI/CD + DevOps",
        description: `Integrate CI/CD pipelines into your DevOps processes for seamless automation of the application lifecycle. This ensures faster delivery, improved quality, and better collaboration across teams.`,
        icon: DevOpsIcon,
      },
    ],
  };
  
  
  
  
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Cicd;
