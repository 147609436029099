import React, { useState } from "react";
import { caseStudiesProcess } from "./CaseStudyData";
import { Col, Container, Row } from "react-bootstrap";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import { PiArrowBendDownRightBold } from "react-icons/pi";
import Image1 from "../../Images/CaseStudies/Steereducation.jpg";
import mission from "../../Images/CaseStudies/vision-mission.png";

const SteerEducation = () => {
  const [tabName, setTabName] = useState("Product Modules");

  return (
    <div>
      <div className="case_study_details case_study">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11} className="case_content text-center">
              <h1>
                <span>Steer Education</span>
              </h1>
              <p>
                Developing and Implementing STEER: A Comprehensive Solution for
                Student Well-being
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col lg={12}>
            <div className="industrial">
              <img src={Image1} alt={Image1} className="img-fluid" />
              <div className="industrial_content">
                <p>
                  This case study details the development and implementation of
                  the STEER product range, a suite of applications designed to
                  measure, track, and improve student self-regulation, mental
                  health, and social-emotional learning. Built using Laravel and
                  React.js, the STEER project was launched to provide schools
                  with advanced tools to safeguard students and enhance their
                  well-being.
                </p>
                <div className="d-flex flex-wrap align-items-center justify-content-between ">
                  <div>
                    <h4>Industry</h4>
                    <p>
                      <b>Online Booking Service</b>
                    </p>
                  </div>
                  <div>
                    <h4>Country</h4>
                    <p>
                      <b>India</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr className="m-0" />
      <div className="case_img_box">
        <Container>
          <div className="case_box position-relative">
            <img src={Image1} alt={Image1} />
          </div>
        </Container>
      </div>

      <hr className="m-0" />

      <Container>
        <Row className="case_content_box">
          <Col lg={12} className="px-3">
            <div className="case_content px-3 pt-3">
              <h2 className="case_heading">About</h2>
              <p>
                STEER is the UK's longest-running tracker of student well-being,
                providing schools with peer-reviewed tools to improve student
                mental health since 2015. With nearly a decade of experience,
                STEER has become a trusted partner for schools across the
                country, helping educators identify mental health risks early
                and implement effective interventions.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <div className="process px-3">
          <h2 className="case_heading">Process</h2>
          <Row className="mt-4 justify-content-center">
            {caseStudiesProcess.map((step, index) => (
              <Col lg={3} md={4} sm={6} key={index} className="mb-3">
                <div className="card">
                  <span>{step.step}</span>
                  {step.icon}
                  <h3>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container className=" my-5">
        <Row className="my-4 mission_vision align-items-center ">
          <Col md={6}>
            <div className=" mission_content">
              <h2 className="case_heading mb-3">Our Mission</h2>
              <p>
                STEER was founded by Dr. Simon and Dr. Jo Walker, who brought
                together their expertise in education and psychology to address
                three critical challenges in student well-being:
              </p>
              <div className="process_tab_box">
                <div className="tab_content_box">
                  <div className="box">
                    <ol className="p-0 m-0">
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Early Identification
                        </h6>
                        <p>
                          To identify young people with mental health risks
                          early, before they require specialist support.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Impact Measurement
                        </h6>
                        <p>
                          To reliably measure the impact of schools on students'
                          social-emotional development.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Empowerment:
                        </h6>
                        <p>
                          To empower young people with the skills to navigate
                          adolescence independently and thrive in life.
                        </p>
                        <p>
                          These goals have driven the development of STEER's
                          suite of products, which are designed to equip young
                          people with the human skills they need to succeed
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <img src={mission} className="img-fluid" alt="mission" />
          </Col>
        </Row>

        <hr />

        <div className="my-4">
          <h2 className="case_heading">Our Contribution</h2>
          <p>
            STEER now manages the longest-running dataset of student
            social-emotional development in the UK, tracking, signposting, and
            safeguarding students aged 8-18 in mainstream, specialist schools,
            and elite sports academies. Their data, combined with over 3,000
            expert-developed strategies, uniquely equips them to support
            teachers.
          </p>
          <p>
            STEER now manages the longest-running dataset of student
            social-emotional development in the UK, tracking, signposting, and
            safeguarding students aged 8-18 in mainstream, specialist schools,
            and elite sports academies. Their data, combined with over 3,000
            expert-developed strategies, uniquely equips them to support
            teachers.
          </p>
          <p>
            STEER now manages the longest-running dataset of student
            social-emotional development in the UK, tracking, signposting, and
            safeguarding students aged 8-18 in mainstream, specialist schools,
            and elite sports academies. Their data, combined with over 3,000
            expert-developed strategies, uniquely equips them to support
            teachers.
          </p>

          <h2 className="case_heading">Background</h2>
          <p>
            The STEER project emerged in response to the growing need for
            schools to adopt a proactive approach to student mental health and
            well-being. Traditional methods of monitoring and supporting
            students were often insufficient, reactive, and lacked data-driven
            insights. The STEER suite was developed to address these issues by
            offering a comprehensive, scalable solution that integrates various
            aspects of student support, from tracking self-regulation to
            safeguarding.
          </p>
        </div>
        <hr />
        <div className="challenges my-5">
          <h2 className="case_heading">Challenges </h2>
          <Row className="mt-4 justify-content-center">
            <Col lg={4} className="">
              <div className="card position-relative">
                <span> 1</span>
                <p className="mb-1">
                  <b>Challenge</b>: Dividing and reporting student scores into
                  four distinct factors for STEER Tracking.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: A specialized algorithm was developed to
                  streamline the calculation and reporting process, making it
                  efficient and user-friendly for teachers.
                </p>
              </div>
            </Col>
            <Col lg={4} className="border-left  border-right">
              <div className="card position-relative">
                <span> 2</span>
                <p className="mb-1">
                  <b>Challenge</b>: Visualizing complex data in a meaningful way
                  for educators. accommodation listings while managing large
                  property datasets.
                </p>

                <p className="mb-1">
                  <b>Solution</b> Advanced data visualization techniques were
                  employed to create intuitive and informative graphical charts,
                  ensuring that teachers could easily interpret the data and
                  make informed decisions.
                </p>
              </div>
            </Col>
            <Col lg={4} className="">
              <div className="card position-relative">
                <span> 3</span>
                <p className="mb-1">
                  <b>Challenge</b>:: Advanced data visualization techniques were
                  employed to create intuitive and informative graphical charts,
                  ensuring that teachers could easily interpret the data and
                  make informed decisions.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Advanced data visualization techniques were
                  employed to create intuitive and informative graphical charts,
                  ensuring that teachers could easily interpret the data and
                  make informed decisions.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="process_tab_box mb-5">
          <Row className="justify-content-center">
            <Col lg={10} className="process_tab_list">
              <ul className="p-0">
                <li
                  onClick={() => setTabName("Product Modules")}
                  className={`${tabName === "Product Modules" ? "active" : ""}`}
                >
                  Product Modules
                </li>
                <li
                  onClick={() => setTabName("Objectives")}
                  className={`${tabName === "Objectives" ? "active" : ""}`}
                >
                  Objectives
                </li>
                <li
                  onClick={() => setTabName("Technologies")}
                  className={`${tabName === "Technologies" ? "active" : ""}`}
                >
                  Technologies
                </li>
              </ul>
            </Col>
            <Col lg={10} className="mt-4">
              {tabName === "Product Modules" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          STEER Tracking
                        </h6>
                        <p>
                          A core application to measure, track, and improve
                          student self-regulation and mental health. It enables
                          schools to identify hidden mental health risks,
                          prioritize interventions, and empower teachers to act
                          early.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          School Impact
                        </h6>
                        <p>
                          A tool to evaluate the social-emotional impact of
                          school culture, ensuring all students thrive by
                          tracking well-being across cohorts and providing
                          strategies to address vulnerabilities.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Safeguarding
                        </h6>
                        <p>
                          An application to detect hidden safeguarding risks,
                          using student-voice assessments to provide a holistic
                          view of potential concerns, both in and out of school.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          PSHE & Families
                        </h6>
                        <p>
                          A resource to support young people’s social-emotional
                          literacy, equipping them with the skills to make
                          informed decisions and offering personalized advice to
                          families.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          USTEER
                        </h6>
                        <p>
                          A CPDUK certified soft skill training course
                          specifically designed for Sixth Form students,
                          focusing on developing essential life skills for
                          success beyond school.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          STEER for School Groups
                        </h6>
                        <p>
                          A comprehensive technology and implementation service
                          to give school groups the ability to measure, track,
                          and improve well-being across their entire network.
                          This module provides a standardized, data-driven, and
                          impact-focused approach, offering group-wide insights
                          into student mental health and proactive safeguarding
                          efforts. It has been successfully implemented by large
                          school groups such as the Academies Enterprise Trust
                          and Cognita.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Free Access for Religious Organizations
                        </h6>
                        <p>
                          Religious institutions like temples and dharamshalas
                          can list properties for free, ensuring affordable
                          accommodation options for pilgrims.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Objectives" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <p className="p-0">
                          <b>Primary Goal</b> <br /> To develop a robust digital
                          platform that integrates multiple aspects of student
                          well-being, allowing schools to effectively monitor,
                          support, and improve student outcomes.
                        </p>
                      </li>
                      <li>
                        <p className="p-0">
                          <b>Key Performance Indicators (KPIs):</b> <br />
                          • Key Performance Indicators (KPIs):
                          <br /> • Increased teacher engagement in well-being
                          initiatives.
                          <br /> • Improved student outcomes in self-regulation
                          and social-emotional literacy contact access
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Technologies" && (
                <div className="tab_content_box">
                  <div className="box">
                    <h5>
                      The project followed a collaborative Agile approach,
                      involving continuous feedback from educators, mental
                      health professionals, and technical experts.
                    </h5>

                    <p className="p-0">
                      <b>Frontend</b> : React.js for dynamic user interfaces and
                      seamless interaction.
                    </p>
                    <p className="p-0">
                      <b>Backend</b> : React.js for dynamic user interfaces and
                      seamless interaction.
                    </p>
                    <p className="p-0">
                      <b> Data Visualization</b> : Custom algorithms for data
                      analysis, visualized through various graphical charts.
                    </p>

                    <h4>Implementation-</h4>

                    <p className="p-0">
                      <b>STEER Tracking</b>
                      <br />
                      • Developed to track student mental health by calculating
                      and presenting data on four distinct factors of
                      self-regulation
                      <br /> • A specialized algorithm was designed to handle
                      complex data calculations and present them in clear,
                      actionable formats, such as bar charts and heatmaps
                    </p>

                    <p className="p-0">
                      <b>School Impact:</b>
                      <br />
                      • Implemented to assess and visualize the impact of school
                      culture on different student cohorts.
                      <br />• Teachers were provided with insights and
                      strategies based on data-driven analyses to target support
                      where it was most needed.
                    </p>

                    <p className="p-0">
                      <b>Safeguarding</b> <br /> • Designed to proactively
                      detect safeguarding risks by analyzing student behavior
                      and responses.
                      <br />• The application enabled schools to initiate timely
                      interventions, ensuring comprehensive protection for all
                      students
                    </p>
                    <p className="p-0">
                      <b> PSHE & Families</b> <br /> • Focused on enhancing
                      social-emotional literacy through curriculum integration
                      and family engagement.
                      <br />• Provided personalized guidance to families,
                      helping them support their children’s development
                      effectively.
                    </p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <hr />

        <div className="analysis mt-5">
          <h2 className="case_heading"> Results and Analysis</h2>

          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Enhanced Mental Health Support
                    </h6>
                    <p>
                      The STEER Tracking module enabled early detection of
                      mental health risks in students, leading to timely and
                      effective interventions.
                    </p>
                  </li>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Improved School Environment
                    </h6>
                    <p>
                      The School Impact module provided schools with actionable
                      insights, leading to targeted efforts that improved the
                      overall school culture.
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Proactive Safeguarding
                    </h6>
                    <p>
                      The Safeguarding module ensured that no potential risks
                      went unnoticed, providing comprehensive protection for all
                      students
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Empowered Families
                    </h6>
                    <p>
                      The PSHE & Families module helped families actively
                      participate in their children’s development, fostering a
                      supportive home environment.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="">
          <h1 className="case_heading">Conclusion</h1>
          <p>
            The development and implementation of STEER's suite of products
            represent a significant step forward in the effort to improve
            student well-being in schools. By addressing the key challenges of
            early identification, impact measurement, and student empowerment,
            STEER is helping schools create safer, more supportive environments
            for all students. The ongoing mission of STEER, guided by the
            expertise of Dr. Simon and Dr. Jo Walker, continues to inspire the
            development of innovative tools that prepare young people to thrive
            in life.
          </p>
        </div>
        <hr />

        <div className="analysis">
          <h2 className="case_heading">Recommendations</h2>

          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Continuous Enhancement
                    </h6>
                    <p>
                      Regular updates and improvements should be made based on
                      user feedback and emerging needs.
                    </p>
                  </li>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Scalability
                    </h6>
                    <p>
                      Consider scaling the STEER platform to accommodate larger
                      educational networks or additional modules.
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Ongoing Training
                    </h6>
                    <p>
                      Provide continuous training for educators to maximize the
                      benefits of the STEER platform.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="analysis">
          <h2 className="case_heading">References</h2>
          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <p className="p-0">Internal project documentation and development logs</p>
                  </li>
                  <li>
                    <p className="p-0">Feedback from pilot schools and educational experts.</p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="analysis">
          <h2 className="case_heading">Appendices</h2>
          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <p className="p-0">
                      <b>Appendix A</b> : Screenshots and descriptions of the
                      STEER Tracking and School Impact dashboards.
                    </p>
                  </li>
                  <li>
                    <p className="p-0">
                      {" "}
                      <b>Appendix B</b> : Case studies highlighting the impact
                      of the STEER modules on individual students and schools.
                    </p>
                  </li>
                  <li>
                    <p className="p-0">
                      <b>Appendix C</b> : Technical documentation on the
                      algorithms used for data calculation and visualization.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <MajorClient />
      <Testimonials />
      {/* <ContactForm /> */}
    </div>
  );
};

export default SteerEducation;
