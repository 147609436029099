import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import StaffAugmentationImage from "../.../../../Images/Service/StaffAugmentation/StaffAugmentation.png";
import { StaffAugmentationData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";

const StaffAugmentation = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Staff Augmentation Services for IT Excellence | Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Struggling with skill gaps in your digital transformation? Our IT staff augmentation services can help you achieve your business goals."
        />
        <meta name="keywords" content="staff augmentation services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="Staff Augmentation"
            content="Do skill gaps continue to obstruct your digital transformation? We offer top-notch IT staff augmentation services to help you maximize your online potential and accomplish your business objectives."
            title="Augmentation Company"
            btnText="Consult Skyline"
            subTitle="Explore Staff Augmentation Company"
          />
        </div>
        <DevelopmentInfo
          heading="Staff Augmentation"
          title="Top Staff Augmentation Company"
          image={StaffAugmentationImage}
          content={
            <>
              <p className="my-3">
                Access top-tier IT professionals to achieve your business goals
                with one of the Top Staff Augmentation Companies in the world,
                Skyline Infosys! Our IT Staff Augmentation services provide
                rapid access to the expertise and skills needed to meet your
                immediate and long-term business needs. Our developers are the
                cream of the crop, with the knowledge and experience necessary
                to fill any gap in your team and get your project done with
                renewed vigour. By partnering with us, you'll cut down on
                development costs and end up with a top-notch digital product.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Staff Augmentation ?"
          data={StaffAugmentationData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={["Frontend", "Backend", "Mobile", "CMS", "Other"]}
          serviceName="Staff Augmentation"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default StaffAugmentation;
