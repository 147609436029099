import React from "react";
import { Container } from "react-bootstrap";

const Industries = ({ data, title, description }) => {
  return (
    <section className="service_industries">
      <Container>
        <h1 className="heading">{title}</h1>
        <p className="text-center mt-3 mb-5 ">{description}</p>

        <div className="scrolling-container mb-0">
          <div className="serving">
            {data.map((obj, index) => {
              return (
                <div className="box position-relative" key={index}>
                  <div className="img_box position-relative">
                    <img
                      src={obj.image}
                      className="img-fluid"
                      alt={obj.image}
                      loading="lazy"
                    />
                  </div>
                  <h5> {obj.name} </h5>
                </div>
              );
            })}

            {data.map((obj, index) => {
              return (
                <div className="box position-relative" key={index}>
                  <div className="img_box position-relative">
                    <img
                      src={obj.image}
                      className="img-fluid"
                      alt={obj.image}
                      loading="lazy"
                    />
                  </div>
                  <h5> {obj.name} </h5>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};




export default Industries;
