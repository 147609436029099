import React from "react";
import convrtx from "../../../Images/Client/convrtx.avif";
import ACR from "../../../Images/Client/ACR.png";
import pinaheart from "../../../Images/Client/pinaheart_logo.webp";
import realTime from "../../../Images/Client/realTime.png";
import realisticus from "../../../Images/Client/realisticus.png";
import STEER from "../../../Images/Client/STEER_Logo.svg";
import videmak from "../../../Images/Client/videmak.png";
import proaxiom from "../../../Images/Client/proaxiom.png";
import Atithi from "../../../Images/Client/atithi.png";
import enfinyexim from "../../../Images/Client/enfinyexim.svg";
import { Container } from "react-bootstrap";

const MajorClient = () => {
  const images = [
    { src: convrtx, alt: "clients" },
    { src: pinaheart, alt: "clients" },
    { src: realTime, alt: "clients" },
    { src: realisticus, alt: "clients" },
    { src: ACR, alt: "clients" },
    { src: STEER, alt: "clients" },
    { src: videmak, alt: "clients" },
    { src: proaxiom, alt: "clients" },
    { src: Atithi, alt: "clients" },
    { src: enfinyexim, alt: "clients" },
  ];

  return (
    <section className="our_client  ">
      <Container>
        <h1 className="heading"> Trusted by Industry Leaders Worldwide</h1>
        <p className="mt-3 text-center">
        Skyline Infosys is proud to partner with some of the most renowned names across diverse industries. Our commitment to delivering innovative solutions has earned us the trust of global leaders in technology, healthcare, finance, and retail. By tailoring our services to their unique needs, we help drive their success and growth. Join our network of satisfied clients and experience the difference!</p>
        <div className="client_img_box mt-5">
          {images.map((image, index) => (
            <div className="img_box" key={index}>
              <img src={image.src} alt={image.alt} loading="lazy" />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default MajorClient;
