import React from "react";

import "../FrontEnd/style/style.css";
import HomeSection from "./Components/HomeSection";
import HireTopDeveloper from "./Components/HireTopDeveloper";
import Testimonials from "../../Home/Components/Testimonials";
import Pricing from "./Components/Pricing";
import TechnologyCombinations from "./Components/TechnologyCombinations";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import NodeIcon from "../../../Images/icon/node_js_ic.webp";
import ExpressIcon from "../../../Images/icon/expressJs.png";
import PythonIcon from "../../../Images/icon/Python.svg";
import RailsIcon from "../../../Images/icon/Rails.svg";
import laravelIcon from "../../../Images/icon/laravel_ic.webp";
import mongoIcon from "../../../Images/icon/mongo_db_ic.webp";
import technologyIcon from "../../../Images/icon/vue_js_ic.webp";
import VueImg from "../../../Images/HireDeveloper/VueJS.avif";

const VueDeveloper = () => {
  const homeData = {
    title: "Hire <span>Vue.js </span> Developers in India",
    description: `Empower your business with skilled Vue.js developers delivering cutting-edge web solutions. 
    Enjoy cost-effective, scalable, and user-friendly applications tailored to your needs. Our developers specialize 
    in Vue.js, Vuex, Nuxt.js, and more, ensuring high-quality results. With flexible hiring models and a client-first 
    approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Vue.js experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Vue.js development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };

  const hireTopDeveloperData = {
    heading:
      "Hire Top Vue.js Developers in India from Skyline Infosys",
    image: {
      src: VueImg, 
      alt: "VueImg",
    },
    content: {
      description: `Looking to build high-performing, scalable, and dynamic web applications? At Skyline Infosys, 
      we offer expert Vue.js developers who specialize in creating seamless, interactive user interfaces. 
      With years of experience and deep technical expertise, our developers are equipped to deliver 
      cutting-edge solutions tailored to your business needs.`,
      expertiseHeading: "Our Vue.js developers excel in:",
      expertisePoints: [
        "Building responsive, efficient, and optimized web applications",
        "Customizing user interfaces with modern tools like Vue CLI, Vuex, and Nuxt.js",
        "Ensuring smooth, high-performance applications across all devices",
      ],
      closingNote: `Partner with us today to leverage the full potential of Vue.js and elevate your web development projects. 
      Get in touch and hire dedicated Vue.js developers who can bring your ideas to life!`,
    },
  };

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Vue.js Combinations",
    description: `At Skyline Infosys, we specialize in leveraging Vue.js with a variety of back-end technologies 
    to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Vue.js + Node.js",
        description: `A robust combination for building scalable and high-performance web applications. Ensures smooth 
        front-end and back-end integration. Ideal for real-time apps like chats and dashboards. Perfect for startups and enterprises alike.`,
        icon: NodeIcon,
      },
      {
        title: "Vue.js + Express.js",
        description: `Best suited for lightweight, fast, and efficient applications. Enables seamless creation of RESTful APIs. 
        Ideal for single-page applications and microservices. A great choice for optimizing development speed.`,
        icon: ExpressIcon,
      },
      {
        title: "Vue.js + Python/Django",
        description: `Delivers secure, data-driven platforms with a robust back-end. Excellent for machine learning or AI-powered apps. 
        Ensures rapid development with Django’s powerful framework. Ideal for data-heavy or analytics-driven solutions.`,
        icon: PythonIcon,
      },
      {
        title: "Vue.js + Ruby on Rails",
        description: `Combines modern front-end capabilities with a clean back-end architecture. Speeds up development with Ruby on Rails' 
        convention over configuration approach. Perfect for eCommerce, SaaS, and MVPs. Ensures smooth deployment and scaling.`,
        icon: RailsIcon,
      },
      {
        title: "Vue.js + PHP (Laravel)",
        description: `A versatile combination for dynamic front-ends and reliable back-ends. Offers extensive customization options 
        with Laravel’s MVC framework. Ensures secure, scalable, and feature-rich applications. Perfect for enterprise and CMS platforms.`,
        icon: laravelIcon,
      },
      {
        title: "Vue.js + MongoDB",
        description: `Perfect for full-stack applications with a NoSQL database. Provides high flexibility for managing complex data. 
        Ideal for real-time apps and cloud-based solutions. Ensures smooth integration with modern frameworks.`,
        icon: mongoIcon,
      },
    ],
  };

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default VueDeveloper;
