import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import SvelteIcon from "../../../Images/icon/Svelte.webp";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import BootstrapIcon from "../../../Images/icon/boostrap_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import HTMLIcon from "../../../Images/icon/html_5.webp";
import technologyIcon from "../../../Images/icon/codeginator_ic.webp";
import CodeIgniterImg from "../../../Images/HireDeveloper/CodeIgniteDeveloper.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const CodeigniterDeveloper = () => {
  const homeData = {
    title: "Hire <span>CodeIgniter</span> Developers in India",
    description: `Empower your business with skilled CodeIgniter developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in CodeIgniter, PHP, MySQL, MVC architecture, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our CodeIgniter experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in CodeIgniter development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  

  // hire-top-developer-data.ts
  const hireTopDeveloperData = {
    heading: "Hire Top CodeIgniter Developers in India from Skyline Infosys",
    image: {
      src: CodeIgniterImg, // Update the path to the CodeIgniter image
      alt: "CodeIgniterImg",
    },
    content: {
      description: `Looking to build robust, scalable, and secure web applications? At Skyline Infosys, 
        we offer expert CodeIgniter developers who specialize in building dynamic server-side solutions. 
        With years of experience and deep technical expertise, our developers are equipped to deliver 
        cutting-edge solutions tailored to your business needs.`,
      expertiseHeading: "Our CodeIgniter developers excel in:",
      expertisePoints: [
        "Building secure, scalable, and high-performance web applications using CodeIgniter framework",
        "Developing RESTful APIs with CodeIgniter",
        "Ensuring smooth database integration using MySQL and PostgreSQL",
        "Optimizing CodeIgniter applications for performance and scalability",
      ],
      closingNote: `Partner with us today to leverage the full potential of CodeIgniter and elevate your web development projects. 
        Get in touch and hire dedicated CodeIgniter developers who can bring your ideas to life!`,
    },
  };
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack CodeIgniter Combinations",
    description: `At Skyline Infosys, we specialize in leveraging CodeIgniter with a variety of front-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "CodeIgniter + React.js",
        description: `A modern combination for building full-stack applications. React.js handles the dynamic front-end, offering a fast, component-based UI, while CodeIgniter powers the back-end, handling routing, authentication, and database interactions. This stack is ideal for building scalable, high-performance applications with real-time capabilities.`,
        icon: ReactIcon,
      },
      {
        title: "CodeIgniter + Vue.js",
        description: `A combination for building responsive and dynamic user interfaces. Vue.js offers a reactive, component-based UI, while CodeIgniter provides a structured back-end for handling database interactions, business logic, and authentication. This stack is perfect for scalable and interactive applications.`,
        icon: VueIcon,
      },
      {
        title: "CodeIgniter + HTML",
        description: `A foundational combination for building traditional server-rendered web applications. CodeIgniter provides a powerful back-end environment for handling routing, authentication, database management, and more, while HTML is used for structuring static front-end pages. Ideal for simpler applications, CMS, and websites requiring minimal front-end interaction.`,
        icon: HTMLIcon,
      },
      {
        title: "CodeIgniter + Angular",
        description: `A robust stack for building large-scale enterprise applications. Angular provides a structured, two-way data binding front-end framework, while CodeIgniter handles the back-end, including database management, authentication, and business logic. Ideal for complex, high-traffic applications.`,
        icon: AngularIcon,
      },
      {
        title: "CodeIgniter + Next.js",
        description: `A powerful combination that leverages the server-side rendering and SEO-friendly features of Next.js for the front-end, with CodeIgniter handling the back-end logic and database management. This stack is perfect for building high-performance, SEO-optimized applications.`,
        icon: NextJsIcon,
      },
      {
        title: "CodeIgniter + Firebase",
        description: `An excellent choice for serverless applications requiring real-time data syncing and user authentication. Firebase handles authentication, storage, and real-time database, while CodeIgniter powers the back-end with custom server-side logic and APIs. Ideal for modern, scalable applications.`,
        icon: FirebaseIcon,
      },
      {
        title: "CodeIgniter + Svelte",
        description: `A modern combination where Svelte handles the front-end by compiling components to efficient vanilla JavaScript, while CodeIgniter powers the back-end with its lightweight framework. This stack is great for creating fast, interactive, and lightweight web applications.`,
        icon: SvelteIcon,
      },
      {
        title: "CodeIgniter + Bootstrap",
        description: `A classic combination for building responsive websites. CodeIgniter provides the back-end with powerful tools for routing, authentication, and database management, while Bootstrap offers a front-end framework for building responsive, mobile-first UI designs quickly. Ideal for projects requiring rapid development with a clean, standardized UI.`,
        icon: BootstrapIcon,
      },
    ],
  };
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default CodeigniterDeveloper;
