import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import SoftwareMaintenanceImage from "../.../../../Images/Service/SoftwareMaintenance/SoftwareMaintenance.png";
import { SoftwareMaintenanceData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";


const SupportMaintenance = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Professional Software Maintenance Services| Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Ensure your business runs smoothly with our unmatched software maintenance services. Our skilled team keeps your program error-free and efficient."
        />
        <meta name="keywords" content="software maintenance services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="Software Maintenance Services"
            content="Skyline takes pride in providing unmatched software maintenance services to ensure your business runs effectively. Your program will remain 100% error-free, conveniently accessible, and efficient, thanks to our skilled team."
            title="Maintenance Services"
            btnText="Consult Skyline"
            subTitle="Explore Support Maintenance Company"
          />
        </div>
        <DevelopmentInfo
          heading="Software Support and Maintenance"
          title="Top Software Support and Maintenance Development Company"
          image={SoftwareMaintenanceImage}
          content={
            <>
              <p className="my-3">
                Are you tired of working with subpar software maintenance
                companies that charge exorbitant rates without providing any
                tangible benefits? That is why you need us. Skyline Infosys is
                dedicated to ensuring the continuous availability of your
                software around-the-clock.
              </p>
              <p>
                We offer comprehensive software maintenance services, including
                but not limited to administrative management, hosting and server
                configuration, technical advising, third-party integration, and
                website and web-based software enhancements.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Software Support and Maintenance ?"
          data={SoftwareMaintenanceData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={["Frontend", "Backend", "Mobile", "CMS", "Other"]}
          serviceName="Staff Augmentation"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default SupportMaintenance;
