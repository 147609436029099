import React from 'react'
import error404 from "../Images/Common/404.svg"
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className='error_page'>
      <Link to="/" className='back_home shake-animate'>  back To Home </Link>
      <img className='img-fluid' src={error404} alt={error404} />
    </div>
  )
}

export default ErrorPage
