import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import DevOpsEngineering from "../.../../../Images/Service/DevOps/DevOpsEngineeringServices.svg";
import { DevOpsData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";


const DevOps = () => {
  return (

   <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Leading DevOps Service Providers | Skyline Infosys</title>
    <meta
      name="description"
      content="
    Strengthen your organization's productivity by hiring a DevOps engineering service company like Skyline Infosys to take it to the next level"
    />
    <meta name="keywords" content="devops service providers" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </Helmet>
    <div className="service">
      <div className="home ">
        <Banner
         backGroundImg={ServiceBg}
          heading="DevOps"
          content="if you want to take the productivity at your organization a notch higher, it's time to consider hiring a DevOps Engineering service company like Skyline."
          title="Development Company"
          btnText="Consult Skyline"
          subTitle="Explore DevOps Company"
        />
      </div>
      <DevelopmentInfo
        heading="DevOps Engineering Services"
        title="Top DevOps Engineering Services Company"
        image={DevOpsEngineering}
        content={
          <>
            <p className="my-3">
              Because of our unique position as DevOps Engineering service
              providers, we are competent enough to bring together expertise in
              software engineering and automation to provide a more streamlined
              and effective method for both. As a result, our DevOps engineering
              services provide numerous advantages to a business, including
              faster project completion with lower costs, more dependability,
              stability, scalability, and security. At Skyline Infotech, our
              DevOps service providers use efficient tools to speed up the
              process of optimization, monitoring, and making necessary
              modifications to the functioning.
            </p>
          </>
        }
      />
      <AboutDevelopment
        heading="What’s special about DevOps Engineering Services Company ?"
        data={DevOpsData}
      />
      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Source Code Mgmt",
          "Cont.Integration",
          "Configuration Mgmt",
          "Deployment",
          "Containers",
          "Web Server",
          "Infra. As a Code",
          "Server Management",
          "Cloud",
          "Highly Available Infrastructure",
        ]}
        serviceName="Cloud"
      />
      <ServiceFromSkyline />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
   </>
  );
};

export default DevOps;
