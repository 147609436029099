import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import WebDevelopment from "../Pages/Services/WebDevelopment";
import MobileDevelopment from "../Pages/Services/MobileDevelopment";
import CustomSoftware from "../Pages/Services/CustomSoftware";
import QATesting from "../Pages/Services/QATesting";
import StaffAugmentation from "../Pages/Services/StaffAugmentation";
import SupportMaintenance from "../Pages/Services/SupportMaintenance";
import Cloud from "../Pages/Services/Cloud";
import DevOps from "../Pages/Services/DevOps";
import ArtificialIntelligence from "../Pages/Services/ArtificialIntelligence";
import ReactService from "../Pages/Technology/FrontEnd/ReactService";
import VueService from "../Pages/Technology/FrontEnd/VueService";
import AngularService from "../Pages/Technology/FrontEnd/AngularService";
import HTMLService from "../Pages/Technology/FrontEnd/HTMLService";
import JavascriptService from "../Pages/Technology/FrontEnd/JavascriptService";
import CSSService from "../Pages/Technology/FrontEnd/CSSService";
import NodeService from "../Pages/Technology/BackEnd/NodeService";
import LaravelService from "../Pages/Technology/BackEnd/LaravelService";
import DotNetService from "../Pages/Technology/BackEnd/DotNetService";
import PhpService from "../Pages/Technology/BackEnd/PhpService";
import CodeigniterService from "../Pages/Technology/BackEnd/CodeigniterService";
import JavaService from "../Pages/Technology/BackEnd/JavaService";
import AndroidService from "../Pages/Technology/Mobile/AndroidService";
import IosService from "../Pages/Technology/Mobile/IosService";
import FlutterService from "../Pages/Technology/Mobile/FlutterService";
import MongodbService from "../Pages/Technology/Database/MongodbService";
import PostgresqlService from "../Pages/Technology/Database/PostgresqlService";
import MySQLService from "../Pages/Technology/Database/MySQLService";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Blog from "../Pages/Blog/Blog";
import BlogDetails from "../Pages/Blog/BlogDetails";
import LifeSkyline from "../Pages/LifeSkyline/LifeSkyline";
import Partnership from "../Pages/Partnership/Partnership";
// import CaseStudies from "../Pages/CaseStudies/CaseStudies";
import Career from "../Pages/Career/Career";
import Contact from "../Pages/Contact/Contact";
import Consultation from "../Pages/Consultation/Consultation";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../Pages/TermsandConditions/TermsConditions";
import Faq from "../Pages/FAQ/Faq";

import ReactJsDeveloper from "../Pages/HireDevelopers/FrontEnd/ReactJsDeveloper";
import AngularDeveloper from "../Pages/HireDevelopers/FrontEnd/AngularDeveloper";
import VueDeveloper from "../Pages/HireDevelopers/FrontEnd/VueDeveloper";
import NextDeveloper from "../Pages/HireDevelopers/FrontEnd/NextDeveloper";
import JavascriptDeveloper from "../Pages/HireDevelopers/FrontEnd/JavascriptDeveloper";
import NuxtDeveloper from "../Pages/HireDevelopers/FrontEnd/NuxtDeveloper";
import NodeDeveloper from "../Pages/HireDevelopers/BackEnd/NodeDeveloper";
import LaravelDeveloper from "../Pages/HireDevelopers/BackEnd/LaravelDeveloper";
import PythonDeveloper from "../Pages/HireDevelopers/BackEnd/PythonDeveloper";
import DotNetDeveloper from "../Pages/HireDevelopers/BackEnd/DotNetDeveloper";
import PHPDeveloper from "../Pages/HireDevelopers/BackEnd/PHPDeveloper";
import CodeigniterDeveloper from "../Pages/HireDevelopers/BackEnd/CodeigniterDeveloper";
import JavaDeveloper from "../Pages/HireDevelopers/BackEnd/JavaDeveloper";
import AndroidDeveloper from "../Pages/HireDevelopers/MobileApp/AndroidDeveloper";
import IosDeveloper from "../Pages/HireDevelopers/MobileApp/IosDeveloper";
import FlutterDeveloper from "../Pages/HireDevelopers/MobileApp/FlutterDeveloper";
import ReactNativeDeveloper from "../Pages/HireDevelopers/MobileApp/ReactNativeDeveloper";
import IonicDeveloper from "../Pages/HireDevelopers/MobileApp/IonicDeveloper";
import KotlinDeveloper from "../Pages/HireDevelopers/MobileApp/KotlinDeveloper";
import Azure from "../Pages/HireDevelopers/DevOps/Azure.jsx";
import AWS from "../Pages/HireDevelopers/DevOps/AWS.jsx";
import GoogleCloud from "../Pages/HireDevelopers/DevOps/GoogleCloud.jsx";
import Docker from "../Pages/HireDevelopers/DevOps/Docker.jsx";
import Kubernetes from "../Pages/HireDevelopers/DevOps/Kubernetes.jsx";
import Cicd from "../Pages/HireDevelopers/DevOps/Cicd.jsx";
import CaseStudies from "../Pages/CaseStudies/CaseStudies.jsx";
import PythonService from "../Pages/Technology/BackEnd/PythonService.jsx";
import ReactNativeService from "../Pages/Technology/Mobile/ReactNativeService.jsx";
import IonicService from "../Pages/Technology/Mobile/IonicService.jsx";
import KotlinService from "../Pages/Technology/Mobile/KotlinService.jsx";
import SQLService from "../Pages/Technology/Database/SQLService.jsx";
import FirebaseService from "../Pages/Technology/Database/FirebaseService.jsx";
import SQLiteService from "../Pages/Technology/Database/SQLiteService.jsx";
import RealtimeConveyancer from "../Pages/CaseStudies/RealtimeConveyancer.jsx";
import AtithiBhavan from "../Pages/CaseStudies/AtithiBhavan.jsx";
import SteerEducation from "../Pages/CaseStudies/SteerEducation.jsx";


const routes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },

  { path: "/services/web-development", element: <WebDevelopment /> },
  { path: "/services/mobile-development", element: <MobileDevelopment /> },
  { path: "/services/custom-software", element: <CustomSoftware /> },
  { path: "/services/qa-testing", element: <QATesting /> },
  { path: "/services/staff-augmentation", element: <StaffAugmentation /> },
  { path: "/services/support-maintenance", element: <SupportMaintenance /> },
  { path: "/services/cloud", element: <Cloud /> },
  { path: "/services/devOps", element: <DevOps /> },
  {
    path: "/services/artificial-intelligence",
    element: <ArtificialIntelligence />,
  },

  {
    path: "/technology/reactjs-development-services",
    element: <ReactService />,
  },
  { path: "/technology/Vuejs-development-services", element: <VueService /> },
  {
    path: "/technology/angularjs-development-services",
    element: <AngularService />,
  },
  { path: "/technology/html5-development-services", element: <HTMLService /> },
  {
    path: "/technology/Javascript-development-services",
    element: <JavascriptService />,
  },
  {
    path: "/technology/CSS3-web-development-services",
    element: <CSSService />,
  },
  { path: "/technology/nodejs-development-services", element: <NodeService /> },
  {
    path: "/technology/laravel-web-development-services",
    element: <LaravelService />,
  },
  {
    path: "/technology/dot-net-development-services",
    element: <DotNetService />,
  },
  { path: "/technology/php-web-development-services", element: <PhpService /> },
  {
    path: "/technology/codeigniter-development-services",
    element: <CodeigniterService />,
  },
  { path: "/technology/java-development-services", element: <JavaService /> },
  { path: "/technology/python-development-services", element: <PythonService /> },
  {
    path: "/technology/android-development-services",
    element: <AndroidService />,
  },
  { path: "/technology/ios-development-services", element: <IosService /> },
  {
    path: "/technology/flutter-development-services",
    element: <FlutterService />,
  },
  {
    path: "/technology/react-native-development-services",
    element: <ReactNativeService />,
  },
  {
    path: "/technology/ionic-development-services",
    element: <IonicService />,
  },

  {
    path: "/technology/kotlin-development-services",
    element: <KotlinService />,
  },

  { path: "/technology/mongodb-services", element: <MongodbService /> },
  { path: "/technology/postgresql-services", element: <PostgresqlService /> },
  { path: "/technology/mysql-services", element: <MySQLService /> },
  { path: "/technology/sql-services", element: <SQLService /> },
  { path: "/technology/firebase-services", element: <FirebaseService /> },
  { path: "/technology/sql-lite-services", element: <SQLiteService /> },




  { path: "/portfolio", element: <Portfolio /> },
  { path: "/blog", element: <Blog /> },
  { path: "/blog/:id", element: <BlogDetails /> },
  { path: "/life-skyline", element: <LifeSkyline /> },
  { path: "/partnership", element: <Partnership /> },
  // { path: "/case-studies", element: <CaseStudies /> },
  { path: "/career", element: <Career /> },
  { path: "/contact", element: <Contact /> },
  { path: "/free-consultation", element: <Consultation /> },
  { path: "/privacy-policy", element: <PrivacyPolicy/> },
  { path: "/terms-and-conditions", element: <TermsConditions/> },
  { path: "/faq", element: <Faq/> },

  // hire developers 
  { path: "/hire-react-developer", element: <ReactJsDeveloper/> },
  { path: "/hire-angular-developer", element: <AngularDeveloper/> },
  { path: "/hire-vue-developer", element: <VueDeveloper/> },
  { path: "/hire-next-developer", element: <NextDeveloper/> },
  { path: "/hire-javascript-developer", element: <JavascriptDeveloper/> },
  { path: "/hire-nuxt-developer", element: <NuxtDeveloper/> },
  { path: "/hire-node-developer", element: <NodeDeveloper/> },
  { path: "/hire-laravel-developer", element: <LaravelDeveloper/> },
  { path: "/hire-python-developer", element: <PythonDeveloper/> },
  { path: "/hire-dot-net-developer", element: <DotNetDeveloper/> },
  { path: "/hire-php-developer", element: <PHPDeveloper/> },
  { path: "/hire-codeigniter-developer", element: <CodeigniterDeveloper/> },
  { path: "/hire-java-developer", element: <JavaDeveloper/> },
  { path: "/hire-android-developer", element: <AndroidDeveloper/> },
  { path: "/hire-ios-developer", element: <IosDeveloper/> },
  { path: "/hire-flutter-developer", element: <FlutterDeveloper/> },
  { path: "/hire-react-native-developer", element: <ReactNativeDeveloper/> },
  { path: "/hire-ionic-developer", element: <IonicDeveloper/> },
  { path: "/hire-kotlin-developer", element: <KotlinDeveloper/> },
  { path: "/hire-azure-developer", element: <Azure/> },
  { path: "/hire-aws-developer", element: <AWS/> },
  { path: "/hire-google-cloud-developer", element: <GoogleCloud/> },
  { path: "/hire-docker-developer", element: <Docker/> },
  { path: "/hire-kubernetes-developer", element: <Kubernetes/> },
  { path: "/hire-cicd-developer", element: <Cicd/> },
  { path: "/case-studies", element: <CaseStudies/> },
  { path: "/case-studies/realtime-conveyancer", element: <RealtimeConveyancer/> },
  { path: "/case-studies/atithi-bhavan", element: <AtithiBhavan/> },
  { path: "/case-studies/steer-education", element: <SteerEducation/> },












  





















];

export default routes;
