import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import DotNetCoreIcon from "../../../Images/icon/dotnet_ic.webp";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import Docker from "../../../Images/icon/Docker.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import AiIcon from "../../../Images/icon/ArtifactRegistry.svg";
import DevOpsIcon from "../../../Images/icon/DevOpsEngineer.svg";
import technologyIcon from "../../../Images/icon/Kubernetes.svg";
import KubernetesImg from "../../../Images/HireDeveloper/Kubernetes.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const Kubernetes = () => {

  const homeData = {
    title: "Hire <span>Kubernetes</span> Developers in India",
    description: `Unlock the power of Kubernetes with our expert developers, delivering scalable, secure, and highly available containerized solutions. 
      Leverage Kubernetes orchestration to automate deployment, scaling, and management of containerized applications. 
      Our developers specialize in Kubernetes clusters, container orchestration, microservices architecture, and seamless integration across cloud platforms. 
      With flexible hiring models and a client-first approach, we guarantee smooth collaboration and timely delivery. 
      Let our Kubernetes experts optimize your infrastructure and scale your applications seamlessly!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our in-house team brings over 5 years of experience in Kubernetes and container orchestration.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure the highest standards of security, scalability, and cost-efficiency in your Kubernetes-managed applications.",
      },
    ],
  };
  

  const hireTopDeveloperData = {
    heading: "Hire Top Kubernetes Developers in India from Skyline Infosys",
    image: {
      src: KubernetesImg, 
      alt: "KubernetesImg",
    },
    content: {
      description: `Looking to leverage the power of Kubernetes for your business? At Skyline Infosys, we offer expert Kubernetes developers who specialize in delivering scalable, secure, and highly efficient container orchestration solutions. From deploying and managing microservices to ensuring high availability and scalability, our team guarantees seamless integration and robust management of your Kubernetes applications.`,
      expertiseHeading: "Our Kubernetes developers excel in:",
      expertisePoints: [
        "Designing and deploying highly scalable and available containerized applications using Kubernetes clusters",
        "Implementing container orchestration with Kubernetes to automate deployment, scaling, and management of containerized applications",
        "Configuring Kubernetes environments for efficient resource management, load balancing, and network policy",
        "Integrating Kubernetes with CI/CD pipelines for continuous deployment and monitoring",
        "Optimizing multi-cloud and hybrid cloud deployments using Kubernetes clusters for cost-effective and flexible infrastructure",
        "Ensuring high levels of security with Kubernetes RBAC, Network Policies, and secrets management",
      ],
      closingNote: `Partner with us today to build scalable and secure Kubernetes-based solutions. Hire dedicated Kubernetes developers from Skyline Infosys to streamline your containerized application lifecycle and optimize your infrastructure with Kubernetes' powerful orchestration tools.`,
    },
  };
  
  
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Kubernetes Combinations",
    description: `At Skyline Infosys, we specialize in integrating Kubernetes with powerful back-end and front-end technologies to deliver scalable, secure, and high-performance containerized solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Kubernetes + .NET Core",
        description: `Leverage Kubernetes to orchestrate .NET Core applications for building scalable, secure, and high-performance microservices. This combination allows for seamless deployment and management of containerized .NET Core applications across cloud environments.`,
        icon: DotNetCoreIcon,
      },
      {
        title: "Kubernetes + React.js",
        description: `Integrate Kubernetes with React.js to manage and scale front-end applications in a containerized environment. Kubernetes ensures that your app is consistently deployed and scaled, while React.js provides dynamic user interfaces for rich user experiences.`,
        icon: ReactIcon,
      },
      {
        title: "Kubernetes + Node.js",
        description: `Utilize Kubernetes to orchestrate your containerized Node.js applications, enabling seamless scaling, resource management, and efficient deployment for modern web applications and microservices.`,
        icon: NodeJsIcon,
      },
      {
        title: "Kubernetes + Docker",
        description: `Combine Kubernetes with Docker to manage containerized applications at scale. Kubernetes automates deployment, scaling, and management, while Docker ensures portability and consistent environments for your applications.`,
        icon: Docker,
      },
      {
        title: "Kubernetes + Python/Django",
        description: `Use Kubernetes to orchestrate Python/Django applications in containers, enabling fast, secure, and scalable back-end systems. Kubernetes ensures high availability and efficient scaling across distributed cloud environments.`,
        icon: PythonIcon,
      },
      {
        title: "Kubernetes + Angular",
        description: `Leverage Kubernetes to manage containerized Angular applications. Kubernetes orchestrates the deployment and scaling of containers, while Angular powers the dynamic front-end for seamless and responsive user experiences.`,
        icon: AngularIcon,
      },
      {
        title: "Kubernetes + AI/ML",
        description: `Use Kubernetes to manage and scale machine learning models built with popular frameworks like TensorFlow and PyTorch. Kubernetes ensures efficient resource allocation and seamless deployment of AI/ML solutions.`,
        icon: AiIcon,
      },
      {
        title: "Kubernetes + DevOps",
        description: `Integrate Kubernetes with DevOps tools for streamlined automation of the application lifecycle. Kubernetes supports continuous integration, continuous deployment, and scaling, ensuring faster and more reliable delivery.`,
        icon: DevOpsIcon,
      },
    ],
  };
  

  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Kubernetes;
