import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Map from "../../../Images/Common/map.png";
import Upwork from "../../../Images/Common/upwork.svg";
import TopRatedIcon from "../../../Images/Common/top_rated_icon.png";
import Google from "../../../Images/Common/google.png";
import { FaUsers } from "react-icons/fa6";
import { GiBullseye } from "react-icons/gi";

const TopRated = () => {
  return (
    <section className="top_rated">
      <Container>
        <h1 className="heading">Read our reviews on        </h1>

        <Row className="mt-5 align-items-center">
          <Col xl={5} lg={4}>
            <div className="innovate">
              <div className="img_box">
                <img src={Map} alt="Map" className="img_fluid"   loading="lazy"/>
              </div>
              <div className="content">
                <h6>Want to stay ahead of the technology curve?</h6>
                <p>
                  Let’s innovate and Co-create Benchmarks in the realm of Web
                  and Mobile App Development.
                </p>
              </div>
            </div>
          </Col>
          <Col xl={7} lg={8}>
            <div className="rated_box">
              <div className="box">
                <img src={Upwork} alt="Upwork"  loading="lazy"/>
                <h4>100% Job Success</h4>
                <h3 className="d-flex align-items-center gap-2">
                  {" "}
                  <img src={TopRatedIcon} alt="TopRatedIcon"  loading="lazy"/> TOP RATED PLUS
                </h3>
              </div>
              <div className="box google">
                <img src={Google} alt="Google"   loading="lazy"/>
              </div>
              <div className="box export text-center ">
                <div className="d-flex justify-content-center align-items-center">
                  <FaUsers />
                  <h5>50+</h5>
                </div>
                <h4>In House IT Experts</h4>
              </div>
              <div className="box export text-center ">
                <div className="d-flex justify-content-center align-items-center">
                  <GiBullseye />
                  <h5>108+</h5>
                </div>
                <h4>Projects Accomplished</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TopRated;
