import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/SQLite/SQLite-service.png";
import BannerImage1 from "../../../Images/Technology/SQLite/SQLite-banner.jpg";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const SQLiteService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SQLite Database Solutions | Skyline Infosys</title>
        <meta
          name="description"
          content="Optimize your app's backend with SQLite database solutions. Efficiently manage local databases with high-performance and low-overhead solutions."
        />
        <meta name="keywords" content="SQLite Database Development Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="SQLite Database"
            content="Skyline Infosys offers efficient SQLite database solutions for lightweight, fast, and local data management in apps. Optimize your database performance with SQLite's simple yet powerful features."
            title="Development Company"
            btnText="Consult Skyline"
            subTitle="Explore SQLite Database Development Company"
          />
        </div>
        <DevelopmentInfo
          heading="SQLite Database"
          title="SQLite Database Services Across India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                SQLite is a self-contained, serverless, and zero-configuration
                database engine commonly used for mobile and embedded
                applications. At Skyline Infosys, we provide end-to-end SQLite
                database development services, offering businesses optimized
                local database management solutions.
              </p>
              <p>
                Our SQLite services include database design, implementation,
                performance tuning, integration with mobile and desktop
                applications, and more. We specialize in building efficient and
                lightweight database solutions that are easy to deploy and
                maintain.
              </p>
            </>
          }
        />

        <TechnologiesFeatures
          technology={"mysql"}
          data={technologyFeatureData}
          heading="Why Should You Choose SQLite Database?"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Deliver SQLite Database Development?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                Skyline Infosys follows a systematic approach to delivering
                high-quality SQLite database solutions. Our team ensures that
                your app benefits from SQLite's powerful yet lightweight
                database management, offering efficient storage solutions with
                minimal overhead. Whether you're developing mobile apps or
                desktop software, we ensure that SQLite works seamlessly for
                your needs.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Database",
            "Frontend",
            "Backend",
            "Unit Testing",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default SQLiteService;
