import Php from "./Images/Common/php.png";
import NET from "./Images/icon/dotnet_ic.webp";
import Laravel from "./Images/icon/laravel_ic.webp";
import CodeIgniter from "./Images/icon/codeginator_ic.webp";
import Wordpress from "./Images/icon/wordpress_ic.webp";
import Zend from "./Images/icon/zend_ic.webp";
import CakePHP from "./Images/icon/cake_php_ic.webp";
import Yii from "./Images/icon/yii_ic.webp";
import Python from "./Images/icon/python_ic.webp";
import BDE from "./Images/icon/BDE.png";

import Magento from "./Images/icon/magneto_ic.webp";
import Chrome from "./Images/icon/chrome_ic.webp";
import HTML5 from "./Images/icon/html_5.webp";
import CSS3 from "./Images/icon/css_3_ic.webp";
import Bootstrap from "./Images/icon/boostrap_ic.webp";
import VTiger from "./Images/icon/v_tinger_ic.webp";
import Sapper from "./Images/icon/Sapper.webp";
import Youtube from "./Images/icon/youtube_ic.webp";
import Facebook from "./Images/icon/facebook_ic.webp";
import Twitter from "./Images/icon/twitter_ic.webp";
import Linkedin from "./Images/icon/linkedin_ic.webp";
import Paypal from "./Images/icon/paypal_ic.webp";
import Authorize from "./Images/icon/Authorize.png";
import Stripe from "./Images/icon/stripe_ic.webp";
import Paddel from "./Images/icon/paddel_ic.webp";
import Google from "./Images/icon/google_ic.webp";
import Amazon from "./Images/icon/amazon_ic.webp";
import Angular from "./Images/icon/angular_js_ic.webp";
import Vue from "./Images/icon/vue_js_ic.webp";
import Node from "./Images/icon/node_js_ic.webp";
import Ralis from "./Images/icon/Rails.svg";
import Nest from "./Images/icon/Nest.svg";
import React from "./Images/icon/react_native_ic.webp";
import Next from "./Images/icon/NextJs.svg";
import Nuxt from "./Images/icon/NuxtJS.svg";
import Jquery from "./Images/icon/j_quary_ic.webp";
import Javascript from "./Images/icon/Javascript.png";
import Solid from "./Images/icon/solid_js.webp";
import Svelte from "./Images/icon/Svelte.webp";
import MySql from "./Images/icon/mysqul_ic.webp";
import MySql1 from "./Images/icon/MySQL1.svg";
import MsSql from "./Images/icon/MS_SQL.svg";
import SQL from "./Images/icon/sql.webp";
import SqlLight from "./Images/icon/sql_light_ic.webp";
import MongoDB from "./Images/icon/mongo_db_ic.webp";
import PostgreSql from "./Images/icon/Postgresql.webp";
import Android from "./Images/icon/android_ic.webp";
import PhoneGap from "./Images/icon/phone_gap_ic.webp";
import Ionic from "./Images/icon/ionic_ic.webp";
import Flutter from "./Images/icon/flutter_ic.webp";
import iOS from "./Images/icon/ios_ic.webp";
import Java from "./Images/icon/Java.svg";
import Selenium from "./Images/icon/Selenium.svg";
import Jasmine from "./Images/icon/Jasmine.svg";
import Jest from "./Images/icon/Jest.svg";
import Mocha from "./Images/icon/Mocha.svg";
import Appium from "./Images/icon/Appium.svg";
import Realm from "./Images/icon/Realm.svg";
import SQLite from "./Images/icon/SQLite.svg";
import Firebase from "./Images/icon/Firebase.svg";
import Aws from "./Images/icon/AWS.svg";
import Azure from "./Images/icon/Azure.svg";
import GCP from "./Images/icon/GCP.svg";
import DigitalOcean from "./Images/icon/Digital-Ocean.svg";
import Docker from "./Images/icon/Docker.svg";
import Vercel from "./Images/icon/Vercel.svg";
import Netlify from "./Images/icon/Netlify.svg";
// import Slack from "./Images/icon/Slack.svg";
import Jira from "./Images/icon/Jira.svg";
import MicrosoftTem from "./Images/icon/Microsoft-Team.svg";
import Asana from "./Images/icon/Asana.svg";
import Pivotal from "./Images/icon/Pivotal.svg";
import ClickUp from "./Images/icon/ClickUp.svg";
import MondayDotCom from "./Images/icon/MondayDotCom.svg";
import Trello from "./Images/icon/Trello.svg";
import WordPress from "./Images/icon/wordpress_ic.webp";
import Codeigniter from "./Images/icon/Codeigniter.svg";
import Swift from "./Images/icon/Swift.svg";
import ObjectiveC from "./Images/icon/Objective-C.svg";
import Storyboard from "./Images/icon/Storyboard.svg";
import Kotlin from "./Images/icon/Kotlin.svg";
import JavaScript from "./Images/icon/Javascript.png";
import TypeScript from "./Images/icon/TypeScript.svg";
import Dart from "./Images/icon/Dart.svg";
import MVC from "./Images/icon/MVC.svg";
import MVVM from "./Images/icon/MVVM.svg";
import Swift_Ui from "./Images/icon/Swift_Ui.svg";
import Alamofire from "./Images/icon/Alamofire.svg";
import Retrofit from "./Images/icon/Retrofit.svg";
import Volley from "./Images/icon/Volley.svg";
import Redux from "./Images/icon/Redux.svg";
import ReduxSaga from "./Images/icon/Redux-Saga.svg";
import ReduxPersist from "./Images/icon/Redux-Persist.svg";
import CoreData from "./Images/icon/CoreData.svg";
import Room from "./Images/icon/Room.svg";
import Watermelon from "./Images/icon/Watermelon.svg";
import Drift from "./Images/icon/Drift.svg";
import Xctest from "./Images/icon/Xctest.svg";
import Postman from "./Images/icon/Postman.svg";
import UIAutomator from "./Images/icon/UI-Automator.svg";
import UnitTesting from "./Images/icon/Unit-Testing.svg";
import Financesolutions from "./Images/IndustriesServing/Financesolutions.jpg";
import SocialMedia from "./Images/IndustriesServing/SocialMedia.avif";
import Pharmacy from "./Images/IndustriesServing/pharmacists.jpg";
import HomeDecor from "./Images/IndustriesServing/HomeDeco.jpg";
import OnDemand from "./Images/IndustriesServing/Ondemand.png";
import PivotalTracker from "./Images/icon/Pivotal-Tracker.svg";

import Terraform from "./Images/icon/Terraform.svg";
import CICD from "./Images/icon/CI-CD.svg";
import Jenkins from "./Images/icon/Jenkins..svg";
import TestNg from "./Images/icon/TestNg.svg";
import Maven from "./Images/icon/Maven.svg";
import Github from "./Images/icon/Github.svg";
import SeleniumGrid from "./Images/icon/Selenium-Grid.svg";
import Junit from "./Images/icon/Junit.svg";
import TestRail from "./Images/icon/TestRail.svg";
import Swagger from "./Images/icon/Swagger.svg";
import Browserstack from "./Images/icon/Browserstack.svg";
import TestLink from "./Images/icon/TestLink.svg";
import Bugzilla from "./Images/icon/Bugzilla.svg";
import ImmuniWeb from "./Images/icon/ImmuniWeb.svg";
import Intruder from "./Images/icon/Intruder.svg";
import Sucuri from "./Images/icon/Sucuri.svg";
import Vega from "./Images/icon/Vega.svg";
import Xenu from "./Images/icon/Xenu.svg";
import AndroidStudio from "./Images/icon/AndroidStudio.svg";
import Genymotion from "./Images/icon/Genymotion.svg";
import Xcode from "./Images/icon/Xcode.svg";
import Jmeter from "./Images/icon/Jmeter.svg";
import Lighthouse from "./Images/icon/Lighthouse.svg";
import LoadRunner from "./Images/icon/LoadRunner.svg";
import Blazemeter from "./Images/icon/Blazemeter.svg";
import DevOpsEngineer from "./Images/icon/DevOpsEngineer.svg";
import MachineLearning from "./Images/icon/MachineLearning.svg";
import SoftwareTesters from "./Images/icon/SoftwareTesters.svg";
import Salesforce from "./Images/icon/Salesforce.svg";
import AWSEC2 from "./Images/icon/AWSEC2.svg";
import S3Buckets from "./Images/icon/S3Buckets.svg";
import VPC from "./Images/icon/VPC.svg";
import AWSCostExplorer from "./Images/icon/AWSCostExplorer.svg";
import IAM from "./Images/icon/IAM.svg";
import WAFAndShield from "./Images/icon/WAFAndShield.svg";
import AzureVirtual from "./Images/icon/AzureVirtual.svg";
import AzureBlobs from "./Images/icon/AzureBlobs.svg";
import VirtualNetwork from "./Images/icon/VirtualNetwork.svg";
import AzureSQL from "./Images/icon/AzureSQL.svg";
import AzureCost from "./Images/icon/AzureCost.svg";
import AzureContainer from "./Images/icon/AzureContainer.svg";
import WebAppfor from "./Images/icon/WebAppfor.svg";
import MicrosoftDefender from "./Images/icon/MicrosoftDefender.svg";
import AzureInformation from "./Images/icon/AzureInformation.svg";
import Droplets from "./Images/icon/Droplets.svg";
import DomainsAndDNS from "./Images/icon/DomainsAndDNS.svg";
import IPv6 from "./Images/icon/IPv6.svg";
import PriceEstimateCalculator from "./Images/icon/PriceEstimateCalculator.svg";
import DigitalOceanContainer from "./Images/icon/DigitalOceanContainer.svg";
import Snapshots from "./Images/icon/Snapshots.svg";
import Firewall from "./Images/icon/Firewall.svg";
import ComputeEngine from "./Images/icon/ComputeEngine.svg";
import PersistentDisk from "./Images/icon/PersistentDisk.svg";
import CloudSQL from "./Images/icon/CloudSQL.svg";
import ArtifactRegistry from "./Images/icon/ArtifactRegistry.svg";
import CloudIdentity from "./Images/icon/CloudIdentity.svg";
import Git from "./Images/icon/Git.svg";
import Gitlab from "./Images/icon/Gitlab.svg";
import Bitbucket from "./Images/icon/Bitbucket.svg";
import GithubActions from "./Images/icon/GithubActions.svg";
import Puppet from "./Images/icon/Puppet.svg";
import Ansible from "./Images/icon/Ansible.svg";
import Kubernetes from "./Images/icon/Kubernetes.svg";
import Nginx from "./Images/icon/Nginx.svg";
import Apache from "./Images/icon/Apache.svg";
import IIS from "./Images/icon/IIS.svg";
import Tomcat from "./Images/icon/Tomcat.svg";
import AWSCloudformation from "./Images/icon/AWSCloudformation.svg";
import LinuxServer from "./Images/icon/LinuxServer.svg";
import WindowsServer from "./Images/icon/WindowsServer.svg";
import ADSetup from "./Images/icon/ADSetup.svg";
import Loadbalancing from "./Images/icon/ADSetup.svg";
import Autoscalling from "./Images/icon/Autoscalling.svg";
import DataBackup from "./Images/icon/DataBackup.svg";
import ZeroDowntimeSetup from "./Images/icon/ZeroDowntimeSetup.svg";
import SupervisedLearning from "./Images/icon/SupervisedLearning.svg";
import EnsembleTechniques from "./Images/icon/EnsembleTechniques.svg";
import UnsupervisedLearning from "./Images/icon/UnsupervisedLearning.svg";
import FeaturizationTuning from "./Images/icon/FeaturizationTuning.svg";
import RecommendationSystems from "./Images/icon/RecommendationSystems.svg";
import AppliedStatistics from "./Images/icon/AppliedStatistics.svg";
import ArtificialNetworks from "./Images/icon/ArtificialNetworks.svg";
import ComputerVision from "./Images/icon/ComputerVision.svg";
import TimePrediction from "./Images/icon/TimePrediction.svg";
import ConvolutionalNetwork from "./Images/icon/ConvolutionalNetwork.svg";
import TensorFlow from "./Images/icon/TensorFlow.svg";
import LSTM from "./Images/icon/LSTM.svg";
import RNN from "./Images/icon/RNN.svg";
import Glove from "./Images/icon/Glove.svg";
import Numpy from "./Images/icon/Numpy.svg";
import Pandas from "./Images/icon/Pandas.svg";
import Pyplot from "./Images/icon/Pyplot.svg";
import Seaborn from "./Images/icon/Seaborn.svg";
import Matplotlib from "./Images/icon/Matplotlib.svg";
import Flask from "./Images/icon/Flask.svg";
import Heroku from "./Images/icon/Heroku.svg";

import Events from "./Images/IndustriesServing/EventsTickets.jpg";
import RealEstate from "./Images/IndustriesServing/RealEstateProperty.jpeg";
import IOT from "./Images/IndustriesServing/IOT.jpeg";
import Taxi from "./Images/IndustriesServing/Taxi.jpg";
import Education from "./Images/IndustriesServing/Education.jpg";
import Social from "./Images/IndustriesServing/Social-Networking.jpg";
import HealthFitness from "./Images/IndustriesServing/HealthFitness.png";
import ERPCRP from "./Images/IndustriesServing/ERPCRP.png";
import PickDelivery from "./Images/IndustriesServing/PickDelivery.png";
import News from "./Images/IndustriesServing/News.jpg";
import VirtualReality from "./Images/IndustriesServing/VirtualReality.jpg";

// Portfolio
import SteerEducation from "./Images/Portfolio/SteerEducation.jpg";
import RealtimeConveyancer from "./Images/Portfolio/RealtimeConveyancer.jpg";
import Pinaheart from "./Images/Portfolio/Pinaheart.jpg";
import Zends from "./Images/Portfolio/Zends.jpeg";
import Proaxiom from "./Images/Portfolio/Proaxiom.jpeg";
import Raymond from "./Images/Portfolio/Raymond.jpeg";
import ChimbraBusiness from "./Images/Portfolio/ChimbraBusiness.jpeg";
import Giftiti from "./Images/Portfolio/Giftiti.jpeg";
import Videmak from "./Images/Portfolio/Videmak.jpeg";
import George from "./Images/Portfolio/George.jpeg";
import Realisticus from "./Images/Portfolio/Realisticus.jpeg";
import Pilotjobs from "./Images/Portfolio/Pilotjobs.jpeg";
import EcotricitySMS from "./Images/Portfolio/EcotricitySMS.jpeg";
import PMTools from "./Images/Portfolio/PMTools.jpeg";
import Roosters from "./Images/Portfolio/Roosters.jpeg";
import TheVault from "./Images/Portfolio/TheVault.jpeg";
import SeniorCitizren from "./Images/Portfolio/SeniorCitizren.jpeg";
import VillaTamanar from "./Images/Portfolio/VillaTamanar.jpeg";
import TPANewsPortal from "./Images/Portfolio/TPANewsPortal.jpeg";
import ALJamharah from "./Images/Portfolio/ALJamharah.jpeg";
import Scanner from "./Images/Portfolio/Scanner.jpeg";
import LiveVideo from "./Images/Portfolio/LiveVideo.jpeg";
import GalleryPlayer from "./Images/Portfolio/GalleryPlayer.jpeg";
import BirthdaySong from "./Images/Portfolio/BirthdaySong.jpeg";
import FamilyPhotoFrame from "./Images/Portfolio/FamilyPhotoFrame.jpeg";
import QrCode from "./Images/Portfolio/QrCode.jpeg";
import DataRecovery from "./Images/Portfolio/DataRecovery.jpeg";
import SelfieCamera from "./Images/Portfolio/SelfieCamera.jpeg";
import Wallpaper from "./Images/Portfolio/Wallpaper.jpeg";
import WhatsWebScan from "./Images/Portfolio/WhatsWebScan.jpeg";
import BrainTrainer from "./Images/Portfolio/BrainTrainer.jpeg";
import SplitMoney from "./Images/Portfolio/SplitMoney.jpeg";
import Onehaircut from "./Images/Portfolio/OneHaircut.png";
import DC from "./Images/Portfolio/DC.jpg";
import Iiigel from "./Images/Portfolio/iiigel.jpg";

import AZS from "./Images/Portfolio/AZS.jpg";
import AUXILIO from "./Images/Portfolio/AUXILIO.jpg";
import AtithiBhavan from "./Images/Portfolio/Atithibhavan.png";

// country images
import Singapore from "./Images/Flags/Singapore.png";
import Netherlands from "./Images/Flags/Netherlands.png";
import USA from "./Images/Flags/USA.png";
import Canada from "./Images/Flags/Canada.png";
import UK from "./Images/Flags/UK.png";
import Germany from "./Images/Flags/Germany.png";
import France from "./Images/Flags/France.png";
import Israel from "./Images/Flags/Israel.png";
import Italy from "./Images/Flags/Italy.png";
import Japan from "./Images/Flags/Japan.png";
import Australia from "./Images/Flags/Australlia.png";
import Sweden from "./Images/Flags/Sweden.png";
import Norway from "./Images/Flags/Norway.png";

// Web Development service
import WebDevelopmentImage from "./Images/Service/WebDevelopmentServices/web-devlopment.svg";
import ECommerceDevelopment from "./Images/Service/WebDevelopmentServices/E-Commerce-Web-App-Development.svg";
import SoftwareDevelopment from "./Images/Service/WebDevelopmentServices/Software-Development.svg";
import WebApplicationSupport from "./Images/Service/WebDevelopmentServices/Web-Application-Support-and-Maintenance.svg";
import WebApplicationTest from "./Images/Service/WebDevelopmentServices/web-application-testing.svg";
import ProgressiveWeb from "./Images/Service/WebDevelopmentServices/Progressive-web-App.svg";

// mobile Development service
import AndroidAppDevelopment from "./Images/Service/MobileDevelopmentService/MobileDevelopment.svg";
import iOSAppDevelopment from "./Images/Service/MobileDevelopmentService/iOSAppDevelopment.svg";
import ReactNativeDevelopment from "./Images/Service/MobileDevelopmentService/ReactNativeDevelopent.svg";
import FlutterAppDevelopment from "./Images/Service/MobileDevelopmentService/FlutterAppDevelopment.svg";
import MobileAppTesting from "./Images/Service/MobileDevelopmentService/MobileTesting.svg";
import MobileAppSupportMaintenance from "./Images/Service/MobileDevelopmentService/MobileAppSupportMaintenance.svg";

// Custom Software
import ProductEngineering from "./Images/Service/CustomSoftware/CustomSoftware.svg";
import DigitalTransformation from "./Images/Service/CustomSoftware/DigitalTransformation.svg";
import MVPDevelopment from "./Images/Service/CustomSoftware/MVPDevelopment.png";
import EnterpriseApplications from "./Images/Service/CustomSoftware/EnterpriseApplications.png";
import IntegrationServices from "./Images/Service/CustomSoftware/IntegrationServices.png";

// Software Testing

import AutomationTesting from "./Images/Service/QATesting/AutomationTesting.png";
import ManualTesting from "./Images/Service/QATesting/ManualTesting.png";
import SecuritySoftwareTesting from "./Images/Service/QATesting/SecuritySoftwareTesting.png";
import PerformanceTesting from "./Images/Service/QATesting/PerformanceTesting.png";
import CompatibilityTesting from "./Images/Service/QATesting/CompatibilityTesting.png";
import ApiTesting from "./Images/Service/QATesting/ApiTesting.png";

// Staff AugmentationData

import HireTeam from "./Images/Service/StaffAugmentation/HireTeam.png";
import HireDeveloper from "./Images/Service/StaffAugmentation/HireDeveloper.png";

// SoftwareMaintenance

import ModificationsSoftware from "./Images/Service/SoftwareMaintenance/ModificationsSoftware.png";
import BugGlitchRemoval from "./Images/Service/SoftwareMaintenance/BugGlitchRemoval.png";
import PerformanceImprovements from "./Images/Service/SoftwareMaintenance/PerformanceImprovements.png";

// clould

import CloudMigration from "./Images/Service/Cloud/Cloud-Migration.svg";
import CloudAutomation from "./Images/Service/Cloud/CloudAutomation.svg";
import CloudSecurity from "./Images/Service/Cloud/Cloud-Security.svg";
import HighlyAvailable from "./Images/Service/Cloud/HighlyAvailable.svg";
import CostOptimization from "./Images/Service/Cloud/CostOptimization.svg";
import ContinuousMonitoring from "./Images/Service/Cloud/ContinuousMonitoring.svg";

// DevOps

import ManagedDevOps from "./Images/Service/DevOps/ManagedDevOpsServices.svg";
import DevOpsAutomation from "./Images/Service/DevOps/DevOpsAutomation.svg";
import CloudComputing from "./Images/Service/DevOps/CloudComputing.svg";
import DeploymentCICD from "./Images/Service/DevOps/ContinuousCICD.svg";
import DevOpsAutomationService from "./Images/Service/DevOps/DevOpsAutomationService.svg";
import DevOpsImplementation from "./Images/Service/DevOps/DevOpsImplementation.svg";
import MicroservicesinDevOps from "./Images/Service/DevOps/MicroservicesinDevOps.svg";
import SecurityCompliance from "./Images/Service/DevOps/Security&Compliance.svg";

// Artificial Intelligence
import BusinessAnalytics from "./Images/Service/ArtificialIntelligence/BusinessAnalytics.svg";
import DataAnalytics from "./Images/Service/ArtificialIntelligence/DataAnalytics.png";
import PredictiveAnalytics from "./Images/Service/ArtificialIntelligence/PredictiveCognitiveAnalytics.svg";
import RecommendationEngine from "./Images/Service/ArtificialIntelligence/RecommendationEngine.svg";
import Chatbots from "./Images/Service/ArtificialIntelligence/Chatbots.svg";
import NaturalProcessing from "./Images/Service/ArtificialIntelligence/NaturalLanguageProcessing.svg";

import VisualSearch from "./Images/Service/ArtificialIntelligence/VisualRecognition.svg";
import VoiceBasedAI from "./Images/Service/ArtificialIntelligence/Voice-Based AI.svg";

// TechnologiesFeatures
import image1 from "./Images/Technology/1.png";
import image2 from "./Images/Technology/2.png";
import image3 from "./Images/Technology/3.png";
import image4 from "./Images/Technology/4.png";
import image5 from "./Images/Technology/5.png";
import image6 from "./Images/Technology/6.png";

// ProjectPlan  Technologies
import createProject from "./Images/Technology/create_project.png";
import makingPlans from "./Images/Technology/making-plans.png";
import flowChart from "./Images/Technology/flow-chart.png";

export const TechnicalLanguageData = [
  {
    image: Php,
    name: "php",
    category: "Web Development",
  },
  {
    image: NET,
    name: ".NET",
    category: "Web Development",
  },
  {
    image: Laravel,
    name: "Laravel",
    category: "Web Development",
  },
  {
    image: CodeIgniter,
    name: "CodeIgniter",
    category: "Web Development",
  },
  {
    image: Wordpress,
    name: "Wordpress",
    category: "Web Development",
  },
  {
    image: Zend,
    name: "Zend",
    category: "Web Development",
  },
  {
    image: CakePHP,
    name: "CakePHP",
    category: "Web Development",
  },
  {
    image: Yii,
    name: "Yii",
    category: "Web Development",
  },
  {
    image: Python,
    name: "Python",
    category: "Web Development",
  },
  {
    image: Magento,
    name: "Magento",
    category: "Web Development",
  },
  {
    image: Chrome,
    name: "Chrome Extension",
    category: "Web Development",
  },
  {
    image: HTML5,
    name: "HTML5",
    category: "Web Development",
  },
  {
    image: CSS3,
    name: "CSS3",
    category: "Web Development",
  },
  {
    image: Bootstrap,
    name: "Bootstrap",
    category: "Web Development",
  },
  {
    image: VTiger,
    name: "VTiger",
    category: "Web Development",
  },
  {
    image: Sapper,
    name: "Sapper",
    category: "Web Development",
  },
  {
    image: Youtube,
    name: "Youtube",
    category: "Api Integration",
  },
  {
    image: Facebook,
    name: "Facebook",
    category: "Api Integration",
  },
  {
    image: Twitter,
    name: "Twitter",
    category: "Api Integration",
  },
  {
    image: Linkedin,
    name: "Linkedin",
    category: "Api Integration",
  },

  {
    image: Paypal,
    name: "Paypal",
    category: "Api Integration",
  },

  {
    image: Authorize,
    name: "Authorize.net",
    category: "Api Integration",
  },
  {
    image: Stripe,
    name: "Stripe",
    category: "Api Integration",
  },
  {
    image: Paddel,
    name: "Paddel",
    category: "Api Integration",
  },
  {
    image: Google,
    name: "Google",
    category: "Api Integration",
  },

  {
    image: Amazon,
    name: "Amazon",
    category: "Api Integration",
  },

  {
    image: Angular,
    name: "Angular.js",
    category: "JavaScript",
  },
  {
    image: Vue,
    name: "Vue.js",
    category: "JavaScript",
  },
  {
    image: Node,
    name: "Node.js",
    category: "JavaScript",
  },
  {
    image: React,
    name: "React.js",
    category: "JavaScript",
  },
  {
    image: Jquery,
    name: "Jquery",
    category: "JavaScript",
  },
  {
    image: Javascript,
    name: "Javascript",
    category: "JavaScript",
  },
  {
    image: Solid,
    name: "Solid",
    category: "JavaScript",
  },
  {
    image: Svelte,
    name: "Svelte",
    category: "JavaScript",
  },

  {
    image: MySql,
    name: "My Sql",
    category: "DataBase",
  },

  {
    image: SqlLight,
    name: "Sql Light",
    category: "DataBase",
  },
  {
    image: MongoDB,
    name: "Mongo DB",
    category: "DataBase",
  },
  {
    image: PostgreSql,
    name: "Postgre Sql ",
    category: "DataBase",
  },

  {
    image: Android,
    name: "Android ",
    category: "App Development",
  },
  {
    image: PhoneGap,
    name: "Phone Gap ",
    category: "App Development",
  },

  {
    image: Ionic,
    name: "Ionic ",
    category: "App Development",
  },
  {
    image: Flutter,
    name: "Flutter  ",
    category: "App Development",
  },

  {
    image: iOS,
    name: "iOS   ",
    category: "App Development",
  },
  {
    image: Java,
    name: "Java   ",
    category: "App Development",
  },
];

export const Serving = [
  {
    image: Financesolutions,
    name: "Finance solutions   ",
  },
  {
    image: SocialMedia,
    name: "Social Media  ",
  },
  {
    image: Pharmacy,
    name: "Pharmacy and Medicines  ",
  },
  {
    image: HomeDecor,
    name: "Home Decor  ",
  },
  {
    image: OnDemand,
    name: "On Demand ",
  },
  {
    image: Events,
    name: "Events & Tickets",
  },
  {
    image: RealEstate,
    name: "Real Estate & Property",
  },
  {
    image: IOT,
    name: "IOT",
  },
  {
    image: Taxi,
    name: "Taxi",
  },
  {
    image: Education,
    name: "Education",
  },
  {
    image: Social,
    name: "Social Networking",
  },
  {
    image: HealthFitness,
    name: "Health & Fitness",
  },
  {
    image: ERPCRP,
    name: "ERP & CRP",
  },
  {
    image: PickDelivery,
    name: "Pick & Delivery",
  },
  {
    image: News,
    name: "News",
  },
  {
    image: VirtualReality,
    name: "Virtual Reality",
  },
];

export const Portfolio = [
  {
    image: RealtimeConveyancer,
    name: "Realtime Conveyancer",
    description: ` The digital conveyancing breakthrough built by practitioners for practitioners Address the key problems conveyancers face every day, reduce business risk, increase human connection`,
    Technology: "MERN",
    Year: "2021",
    link: "https://www.realtimeconveyancer.com.au/",
    category: "Web Development",
  },

  {
    image: SteerEducation,
    name: "Steer Education",
    description: `  A whole-school mental health platform empowering teachers to
                measure, track and improve the self-regulation and mental
                wellbeing of young people education.`,
    Technology: "Laravel + ReactJS + AngularJS",
    Year: "2016",
    link: "https://steer.education/",
    category: "Web Development",
  },

  {
    image: AtithiBhavan,
    name: "Atithi Bhavan ",
    description: `Atithibhavn.com is proudly associated with major temple committees and state tourism bodies, including TTDC, APTDC, Gujarat Tourism, Telangana Tourism, as well as prominent religious and cultural communities such as Swaminarayan, Hare Krishna, Gujarati, Marwadi, Maheshwari, Gaudiya, Udupi, and Vasavi, among many others. `,
    Technology: "Laravel + VueJS ",
    Year: "2024 ",
    link: "https://www.atithibhavan.com/",
    category: "Web Development",
  },

  {
    image: AUXILIO,
    name: "Auxilio Network",
    description: `Auxilio Network is a platform for managing emergencies and public safety, bringing together users, police officers, admins, and departments. It lets you create and track emergencies in real time, shows crime data with a third-party map, and provides dashboards to manage cases easily. `,
    Technology: "Laravel + VueJS ",
    Year: "2024 ",
    link: "https://auxilionetwork.com/",
    category: "Web Development",
  },

  {
    image: Pinaheart,
    name: "Pinaheart",
    description: `Pinaheart provides a FREE, safe, user-friendly platform that’s accessible for all with a passion for bringing foreigners to their suitable Filipina match. This website is a haven for Foreigners.`,
    Technology: "Laravel + ReactJS",
    Year: "2020",
    link: "https://www.pinaheart.com/",
    category: "Web Development",
  },

  {
    image: DC,
    name: "DC",
    description: `DC platform designed for managing and editing profiles related to imaging petrol stations. It likely offers managing other profile-related information. it  provide a service for delivering petrol, which could involve features for placing orders, tracking deliveries, and managing delivery schedules`,
    Technology: "Laravel + jquery ",
    Year: "2024 ",
    link: "#",
    category: "Web Development",
  },

  {
    image: Iiigel,
    name: "Iiigel",
    description: `This platform is designed to make learning intuitive, rewarding, and enjoyable. By aligning quizzes with courses, it ensures targeted knowledge enhancement while fostering a sense of accomplishment through gamified rewards. Suitable for educational institutions, training programs, or self-paced learners, this project bridges the gap between learning and motivation.`,
    Technology: "php + vue.js ",
    Year: "2024 ",
    link: "https://iiigel.de/login",
    category: "Web Development",
  },

  {
    image: AZS,
    name: "AZS",
    description: `The website is a dashboard for a project that involves assigning petrol stations to jobbers. This dashboard likely provides a user interface for managing these assignments, allowing administrators or users to view, assign, or update petrol station assignments for jobbers. It may include features such as list of available petrol stations, a list of jobbers, and the ability to assign or reassign jobbers to specific petrol stations and it is a management platform tailored for petrol stations, offering specialized tools for various roles within the organization`,
    Technology: "Laravel + Jquery ",
    Year: "2024 ",
    link: "#",
    category: "Web Development",
  },
  {
    image: Onehaircut,
    name: "One haircut",
    description: `  The website allows customers to browse nearby salons, select a salon, and book haircuts through the platform. It provides a convenient way for users to find and book appointments at their preferred salons for haircuts.`,
    Technology: "Laravel + NextJS",
    Year: "2024 ",
    link: "#",
    category: "Web Development",
  },

  {
    image: Zends,
    name: "Zends",
    description: `Send SMS to the Customer for Bill pending and amount Due through System And also Mini Support System Managed by this Application.`,
    Technology: "Laravel + VueJs2",
    Year: "2022",
    link: "https://www.zenyth.net/",
    category: "Web Development",
  },
  {
    image: Proaxiom,
    name: "Proaxiom",
    description: `Simple Project for Partner Company to manage their portfolios and clients. We are working with them for last one year`,
    Technology: "PHP",
    Year: "2022",
    link: "https://www.proaxiom.com/",
    category: "Web Development",
  },
  {
    image: Raymond,
    name: "Raymond Woo",
    description: `If there’s anything famous architects tried to teach us over the years is to never underestimate the power of design. Iconic architects such as Frank Gehry.`,
    Technology: "Laravel",
    Year: "2015",
    link: "https://www.raymondwoo.com/",
    category: "Web Development",
  },
  {
    image: ChimbraBusiness,
    name: "Chimbra Business Software",
    description: `People who are really serious about software should make their own hardware. Computers themselves, and software yet to be developed will revolutionize the way.`,
    Technology: "Laravel",
    Year: "2018",
    link: "https://chimbra.com/",
    category: "Web Development",
  },
  {
    image: Giftiti,
    name: "Giftiti",
    description: `As long as you want anything very much, especially more than you want God, it is an idol. There's a certain level of pageantry with Idol, and in order to work the show`,
    Technology: "Laravel + Angular",
    Year: "2020",
    link: "https://giftiti.com/",
    category: "Web Development",
  },
  {
    image: Videmak,
    name: "Videmak Tech Industry",
    description: `With the use of technology, there is so much that we can accomplish together. But it’s important not to forget that people are at the epicenter of it all Technology is best when it brings people together. `,
    Technology: "Laravel",
    Year: "2019",
    link: "https://videmak.business.site/",
    category: "Web Development",
  },
  {
    image: George,
    name: "George Rewards",
    description: `Welcome to George Rewards, a platform for Travel Consultants. Points earned can be redeemed for a great selection of products and services. Join today! with Here. `,
    Technology: "Laravel + Jquery",
    Year: "2022",
    link: "https://georgerewards.com/home",
    category: "Web Development",
  },
  {
    image: Realisticus,
    name: "Realisticus Art Academy",
    description: `I went to Glenalmond and got the piss taken out of me for my Glasgow accent. Then I spent five years at this very posh school came out sounding like Prince Charles `,
    Technology: "Codeigniter + Jquery",
    Year: "2018",
    link: "https://realisticusartacademy.co.nz/",
    category: "Web Development",
  },
  {
    image: Pilotjobs,
    name: "Pilotjobs",
    description: `Pilots are a rare kind of human. They leave the ordinary surface of the world to purify their soul in the sky, and they come down to the earth only after receiving the communion of the infinite.`,
    Technology: "Laravel",
    Year: "2014",
    link: "https://www.pilotjobs.io/",
    category: "Web Development",
  },
  {
    image: EcotricitySMS,
    name: "Ecotricity - SMS Management",
    description: `Send SMS to the Customer for Bill pending and amount Due through System And also Mini Support System Managed by this Application.`,
    Technology: "Laravel + VueJS",
    Year: "2022",
    link: "https://www.ecotricity.co.uk/",
    category: "Web Development",
  },
  {
    image: PMTools,
    name: "PM Tools",
    description: `Manage all the timesheets for resources, leave, Documents, all the corporate integrations, Resource Manager, Invoice, Quote, and many more 20+ Feature.`,
    Technology: "Laravel + VueJS",
    Year: "2022",
    link: "https://pem.proaxiom.in/",
    category: "Web Development",
  },
  {
    image: Roosters,
    name: "Roosters",
    description: `Rostering System is Mutli Tenant Cloud Based Software which manages Shifts of Doctors, Swap Shifts, Schedule SMS, Manage Emailing, Reporting.`,
    Technology: "ReactJS + NodeJS",
    Year: "2020",
    link: "https://rooster.nz/",
    category: "Web Development",
  },
  {
    image: TheVault,
    name: "The Vault",
    description: `Property Software which stores all the property in the Vault and displays accordingly with all the features and amenities.`,
    Technology: "Laravel + VueJS",
    Year: "2022",
    link: "https://vault.onealabs.com/",
    category: "Web Development",
  },
  {
    image: SeniorCitizren,
    name: "Senior Citizren Programme",
    description: `CMS system for managing their data from admin and display on Frontend with the third party Document Storing System.`,
    Technology: "Laravel + VueJS",
    Year: "2022",
    link: "#",
    category: "Web Development",
  },
  {
    image: VillaTamanar,
    name: "Villa Tamanar",
    description: `Simple Static Responsive Website for All Devices with High Resolution Images & Video for Client's Showcase.`,
    Technology: "PHP",
    Year: "2022",
    link: "#",
    category: "Web Development",
  },
  {
    image: TPANewsPortal,
    name: "TPA News Portal",
    description: `Worpress Project Theme Development Which manages and displays all the categories of blogs and also AD Spaces managed from the theme.`,
    Technology: "WordPress",
    Year: "2022",
    link: "#",
    category: "Web Development",
  },

  {
    image: ALJamharah,
    name: "AL Jamharah",
    description: `AL Jawarah software is for teachers. This software provides tuition management services.`,
    Technology: "Laravel + Jquery",
    Year: "2022",
    link: "https://imufasser.com/",
    category: "Web Development",
  },
  {
    image: Scanner,
    name: "Cam Scanner",
    description: `Try CamScanner! CamScanner is an all-in-one scanner app. It turns your mobile device into a powerful portable scanner that recognizes text automatically (OCR) and improves.`,
    Technology: "Android + SQLite",
    Year: "2021",
    link: "#",
    category: "App Development",
  },

  {
    image: LiveVideo,
    name: "Live Video Call",
    description: `Video call has certainly saved many of us.Every call is memorable and very special for us, right?This is why we take cute and lovely screenshots while having a conversation over a video call`,
    Technology: "Android + SQLite",
    Year: "2019",
    link: "#",
    category: "App Development",
  },
  {
    image: GalleryPlayer,
    name: "Gallery HD Player",
    description: `Co Video Player - HD player all formats is a powerful video player with advanced hardware acceleration. It is a highly efficient video playback tool that uses your device's default decoder for decoding`,
    Technology: "Android + SQLite",
    Year: "2021",
    link: "#",
    category: "App Development",
  },

  {
    image: BirthdaySong,
    name: "Birthday Song With Name",
    description: `Birthday app provides birthday song with name that you can make a song for your special in your life. Bday Wish card having 100+ card designs for making a personalized card for bday person. `,
    Technology: "Android + SQLite",
    Year: "2022",
    link: "#",
    category: "App Development",
  },

  {
    image: FamilyPhotoFrame,
    name: "Family Photo Frame",
    description: `With Family Photo Frame application, you can stitch up to 15 photos together with more than 300 beautiful layout templates. With more than 1000 photo frames of the most popular. `,
    Technology: "Android + SQLite",
    Year: "2023",
    link: "#",
    category: "App Development",
  },
  {
    image: QrCode,
    name: "Qr Code Creator",
    description: `QR Code app is a useful and convenient tool for creating your own custom QR Codes with logo. QRTiger is Product Hunt's Product of the Day for two consecutive weeks following thousands of upvotes from users all over the world!  `,
    Technology: "Android + SQLite",
    Year: "2021",
    link: "#",
    category: "App Development",
  },

  {
    image: DataRecovery,
    name: "Data Recovery",
    description: `Deleted Data Recovery - Have your old photos, videos been deleted, and you want to bring them back, then this app will solve your every problem very quickly`,
    Technology: "Kotin Android + SQLite",
    Year: "2022",
    link: "#",
    category: "App Development",
  },
  {
    image: SelfieCamera,
    name: "Selfie Camera",
    description: `Selfie Camera & Beauty Camera is the best all-in-one Beauty camera & photo/video editing app. Beauty Camera & Sweet Camera is the best selfie editor that contains professional editing tools and various effects..`,
    Technology: "Kotin Android + SQLite",
    Year: "2018",
    link: "#",
    category: "App Development",
  },
  {
    image: Wallpaper,
    name: "4k Wallpaper",
    description: `4K Wallpapers (4K Backgrounds) - Live Wallpapers | Auto Wallpaper Changer is a free app that has a large verities of 4K (UHD | Ultra HD) as well as Full HD (High Definition) wallpapers | backgrounds.`,
    Technology: "Kotin Android + SQLite",
    Year: "2022",
    link: "#",
    category: "App Development",
  },

  {
    image: WhatsWebScan,
    name: "Whats Web Scan",
    description: `Whats Web: Would you like to open two accounts on the same device or the same account on multiple devices then this app is for you. Now you can use the same account on multiple devices`,
    Technology: "Kotin Android + SQLite",
    Year: "2020",
    link: "#",
    category: "App Development",
  },

  {
    image: BrainTrainer,
    name: "Brain Trainer",
    description: `A collection of brain puzzles IQ challenge in a cool design, a lot of addictive games in one game, Improve your skills, mental capacities, calculus, memory, analysis, sharpness and perception etc with brain training games`,
    Technology: "Kotin Android + SQLite",
    Year: "2023",
    link: "#",
    category: "App Development",
  },
  {
    image: SplitMoney,
    name: "Split Money",
    description: `SplitMoney is your ultimate companion for managing expenses, both personal and group expenses.Whether you're using it as a personal finance manager or an IOU tracker, SplitMoney makes it simple and easy.`,
    Technology: "Kotin Android + SQLite",
    Year: "2022",
    link: "#",
    category: "App Development",
  },
];

export const digitalDevelopment = [
  {
    image: Singapore,
    name: "Singapore   ",
  },
  {
    image: Netherlands,
    name: "Netherlands  ",
  },
  {
    image: USA,
    name: "USA  ",
  },
  {
    image: Canada,
    name: "Canada  ",
  },
  {
    image: UK,
    name: "UK ",
  },
  {
    image: Germany,
    name: "Germany",
  },
  {
    image: France,
    name: "France",
  },
  {
    image: Israel,
    name: "Israel",
  },
  {
    image: Italy,
    name: "Italy",
  },
  {
    image: Japan,
    name: "Japan",
  },
  {
    image: Australia,
    name: "Australia",
  },

  {
    image: Sweden,
    name: "Sweden",
  },

  {
    image: Norway,
    name: "Norway",
  },

  {
    image: Germany,
    name: "Germany",
  },
  {
    image: France,
    name: "France",
  },
  {
    image: Israel,
    name: "Israel",
  },
  {
    image: Italy,
    name: "Italy",
  },
  {
    image: Japan,
    name: "Japan",
  },
  {
    image: Australia,
    name: "Australia",
  },

  {
    image: Sweden,
    name: "Sweden",
  },

  {
    image: Norway,
    name: "Norway",
  },
];

export const WebAppDevelopment = [
  {
    image: WebDevelopmentImage,
    title: "Website Development",
    description:
      "We understand a website is a reflection of your business, that's why we emphasize on creating user-friendly, glitch-free websites that provide an exceptional user experience. From modern designs to intuitive navigation, we provide special care to every detail of your web development services.",
  },
  {
    image: ECommerceDevelopment,
    title: "E-Commerce Web App Development",
    description:
      "We're passionate about helping businesses create an online presence, and our eCommerce development services are no exception. Our software development services are made to assist you in maximizing web-based marketing channels for company expansion. We ensure your web app is search engine and social media friendly.",
  },
  {
    image: SoftwareDevelopment,
    title: "Software Development",
    description:
      "At Skyline Infosys, we're dedicated to helping businesses harness the power of technology to achieve their goals. Our web application development services are varied and adaptable, offering anything from cutting-edge mobile apps to innovative web development services.",
  },
  {
    image: WebApplicationTest,
    title: "Web Application Testing",
    description:
      "Our skilled testers at Skyline employ the most up-to-date methodologies to undertake rigorous testing on your web application development services, discovering any flaws that may influence its performance, usability, or security. We also offer manual and automated testing alternatives to ensure unmatched industry standards.",
  },
  {
    image: WebApplicationSupport,
    title: "Web Application Support and Maintenance",
    description:
      "Creating a web application is Skyline's first step toward digital dominance. We provide first-rate web application maintenance and support services so that your company can have trouble-free operations. Ensure your web development services are always up-to-date, safe, and bug-free with our professional developers and technicians.",
  },
  {
    image: ProgressiveWeb,
    title: "Progressive Web Apps",
    description:
      "Join the likes of Pinterest, Tinder, Bookmyshow, Trivago and other successful brands by embracing the power of Progressive Web Apps with Skyline Infosys. Our Progressive Web Application development services are aimed to give a native app-like experience with lightning-fast loading, seamless performance and offline capabilities.",
  },
];

export const MobileDevelopmentData = [
  {
    image: AndroidAppDevelopment,
    title: "Android App Development",
    description:
      "Let our Android app development services help you stand out from the 3 million+ apps in the Google Play Store! Our expert team equips applications with cutting-edge features and UI/UX designs to elevate user experience. From seamless navigation to stunning graphics, we ensure your app is designed to captivate your audience.",
  },
  {
    image: iOSAppDevelopment,
    title: "iOS App Development",
    description:
      "Looking to create a show-stopping iOS app that's compatible with all of Apple's devices and services? Look no further than Skyline Infosys' iOS app development process! With our team of experts by your side, our mobile application development services cover every aspect of the app development process, from ideation to launch.",
  },
  {
    image: ReactNativeDevelopment,
    title: "React Native Cross Platform App Development",
    description:
      "Accept nothing but the very best. The success of our mobile app development firm depends on our team's knowledge and creativity. Hence, we have developed the optimal mobile app solution by combining the expertise of our team with the benefits of React Native cross-platform app development..",
  },
  {
    image: FlutterAppDevelopment,
    title: "Flutter Cross Platform App Development",
    description:
      "Elevate your mobile app game! With our mobile app development services, we know that the key to creating a successful app lies in both functionality and aesthetics. That's why we use Flutter cross-platform app development to create apps with a native look and feel that users will adore.",
  },
  {
    image: MobileAppSupportMaintenance,
    title: "Mobile App Support & Maintenance",
    description:
      "Don't let your mobile app fall behind- At Skyline Infosys, we know that building an app is just the beginning. That's why we offer comprehensive mobile app support and maintenance services to ensure your app stays up-to-date and continues to provide a seamless user experience.",
  },
  {
    image: MobileAppTesting,
    title: "Mobile App Testing",
    description:
      "Don't let a poorly tested app hinder your success. At Skyline Infosys, we offer top-notch mobile app testing services to ensure your app is of the highest quality. Our performance-oriented testing approach, whether automated or manual, guarantees that your app will deliver the best user experience possible..",
  },
];

export const CustomSoftwareData = [
  {
    image: ProductEngineering,
    title: "Product Engineering",
    description:
      "Searching for an end-to-end custom software product engineering partner who can take your product from ideation to launch? Our custom software development company covers every area of the product lifecycle, producing best-in-market solutions that are wholly aligned with your long-term strategic goal, ensuring your product stays ahead of the curve.",
  },
  {
    image: DigitalTransformation,
    title: "Digital Transformation",
    description:
      "Are you prepared to revolutionalize the way your company operates? Understand the complete potential of your business with our digital transformation services. Our innovative technological expertise assists in increasing consumer interaction, making wiser decisions, and streamlining your processes for optimal effectiveness.",
  },
  {
    image: MVPDevelopment,
    title: "MVP Development",
    description:
      "Welcome to our full-cycle custom software development company, where we take pride in providing high-quality MVP development services. Our team of experts prioritize your business goals to craft an effective solution that showcases your idea's true potential. With our deep expertise in custom software development services, we understand the right proportion of basic functionality and unique features for your product's success.",
  },
  {
    image: EnterpriseApplications,
    title: "Enterprise Applications",
    description:
      "Skyline is more than a company that provides expensive enterprise software; we're a group of creative thinkers that aim to simplify difficult business procedures. We use reliable safety protocols to build up practical facets of your organization. To help you become the undisputed leader, we also prioritize maximizing your ROI.",
  },
  {
    image: IntegrationServices,
    title: "Integration Services",
    description:
      "We at Skyline know that completing an app's development is simply the first step in a lengthy and fruitful process. Keeping your app up-to-date with the most recent versions and features is a top priority for our custom software application development team. Additionally, we offer complete support and maintenance services.",
  },
];

export const SoftwareTestingData = [
  {
    image: AutomationTesting,
    title: "Automation Testing",
    description:
      "At Skyline, precision and efficiency are paramount. Our software testing service company understands the importance of empowering businesses with bug-free software. Say hello to rapid and seamless testing with our advanced automated testing services. Our team uses web & mobile automation testing to develop automated test scripts that ensure highest quality products.",
  },
  {
    image: ManualTesting,
    title: "Manual Testing",
    description:
      "Our software testing service company excels in the area of manual testing. Performance, API, and security testing are just some of our team's manual testing services for web and mobile apps. Additionally, we provide UI/UX testing for mobile apps in addition to our standard web application testing, cross-browser testing, and GUI testing.",
  },
  {
    image: SecuritySoftwareTesting,
    title: "Security Software Testing",
    description:
      "As we get further into the digital age, cyberattacks become more frequent. So, it is essential to collaborate with a software testing services company that can protect your systems from these dangers. Skyline Infotech offers innovative penetration software testing services to guarantee the safety of your application from data phishing and other forms of cybercrime.",
  },
  {
    image: PerformanceTesting,
    title: "Performance Testing",
    description:
      "Don't let sluggish performance slow down your business. Use Skyline's inventive performance testing solution. Our QA Testing Company India analyzes the stability of your application, including load and stress testing, to ensure that it can handle even the heaviest user traffic",
  },
  {
    image: CompatibilityTesting,
    title: "Compatibility Software Testing",
    description:
      "Looking for a software testing company that goes beyond the basics? Our compatibility testing solutions ensure that your software excels in any run-time environment. From network & version compatibility to forward & backward compatibility testing, we'll put your software through its paces.",
  },
  {
    image: ApiTesting,
    title: "API Testing",
    description:
      "Achieve maximum app performance and user engagement with our innovative QA software testing services company. Our API testing solutions cover Functionality, Reliability, Load, Security, and Negative Testing to optimize your app's performance under any scenario. Don't settle for subpar app performance.",
  },
];

export const StaffAugmentationData = [
  {
    image: HireTeam,
    title: "Hire a Dedicated Team",
    description:
      "Bid adieu to the hassle of managing multiple vendors. With Skyline, you'll have a single point of contact to manage all of your IT Staff Augmentation Services. Our IT professionals provide technical consultation throughout the development lifecycle, ensuring your projects are completed to the highest standard.",
  },
  {
    image: HireDeveloper,
    title: "Hire a Developer",
    description:
      "Looking to add some serious firepower to your team? Try Skyline's top-notch team of programmers, coders and testers. Our carefully selected team of experts includes some of the brightest brains in Staff augmentation companies with a history of providing cutting-edge solutions.",
  },
];

export const SoftwareMaintenanceData = [
  {
    image: ModificationsSoftware,
    title: "Modifications & Technical Improvements",
    description:
      "Our expert developers know software maintenance services like the back of their hands. We conduct thorough code reviews, examine technical functionalities, and ensure modules are interacting seamlessly. From fixing issues to adding new features that enhance your UX, we also manage code readability for future needs and keep you updated with the latest version control.",
  },
  {
    image: BugGlitchRemoval,
    title: "Bug & Glitch Removal",
    description:
      "To maintain the efficiency of your business, our software maintenance company swiftly and effectively identify bugs. We go to lengths to fix problems from the backend to spare you time and needless version changes. To quickly restore your program to full functionality, we diligently investigate the underlying source of the problem and implement the best fix.",
  },

  {
    image: PerformanceImprovements,
    title: "Performance Improvements",
    description:
      "Every second counts in today's fast-paced world. To test your app's performance on various devices and browsers, we employ cutting-edge tools such as GT Matrix and Google services. Then, to ensure lightning-fast software speed, our software maintenance and support services team removes extraneous code and images, minimizes API replies, and decreases load time.",
  },
];

export const CloudData = [
  {
    image: CloudMigration,
    title: "Cloud Migration Services",
    description:
      "Improve scalability and content management by consolidating and updating the architecture of existing Cloud Transformation Consulting solutions to meet the needs of the business. Additionally, we can help you switch between clouds, migrate workloads, and validate and test your application's performance.",
  },
  {
    image: CloudAutomation,
    title: "Cloud Automation",
    description:
      "Looking to streamline your cloud management and boost productivity? We specialize in deploying cloud transformation consulting solutions across all major cloud platforms, from AWS to Azure. As your cloud transformation company, you can unlock new levels of innovation and productivity for your business.",
  },

  {
    image: CloudSecurity,
    title: "Cloud Security",
    description:
      "In today's cyber ecosystem, safeguarding your company's cloud-based data is essential. Don't take chances with your cloud security; rely on the specialists at our USA cloud transformation company instead. Our data protection, security risk assessment, and 24/7 monitoring make finding and fixing security flaws a breeze.",
  },

  {
    image: HighlyAvailable,
    title: "Highly Available and Fault Tolerant Infra",
    description:
      "Say goodbye to application downtime and experience maximum uptime with our cloud transformation consulting solutions designed for enterprises seeking the ultimate reliability and scalability! Our innovative technology provides a highly available, fault-tolerant infrastructure to accommodate your growing traffic and business demands effortlessly.",
  },
  {
    image: CostOptimization,
    title: "Cost Optimization",
    description:
      "Our cloud transformation company in the USA takes your budget constraints seriously and turns them into a competitive advantage. That's why we're dedicated to helping you minimize your cloud costs without sacrificing the performance and reliability that your most critical IT workloads demand.",
  },

  {
    image: ContinuousMonitoring,
    title: "Continuous Monitoring",
    description:
      "Our cloud-based application monitoring service is an all-inclusive tool for keeping tabs on your app in real-time. We monitor the KPIs that matter to you by tailoring our tracking parameters to your unique needs and finding any performance issues before they affect users.",
  },
];

export const DevOpsData = [
  {
    image: ManagedDevOps,
    title: "Managed DevOps Services",
    description:
      "Skyline specializes in smoothly integrating software and IT to help your organization grow. With our DevOps engineering services, you can concentrate on what matters most: your customers. We handle your software development needs, giving you more time and resources to grow your business.",
  },
  {
    image: DevOpsAutomation,
    title: "DevOps Infrastructure Automation",
    description:
      "We'll help you streamline the software development and integration process with our DevOps engineering services. Also, we keep a close eye on the overall approach to determine and imply any adjustments needed to boost efficiency and effectiveness across the board.",
  },

  {
    image: CloudComputing,
    title: "Cloud Computing",
    description:
      "Our DevOps engineering services company in the USA facilitates enhanced cloud resource utilization. We shorten product development cycles without compromising quality. Any and all tasks associated with moving to, optimizing, developing, and maintaining a cloud environment can be handled by our team.",
  },

  {
    image: DeploymentCICD,
    title: "Continuous Integration and Continuous Deployment (CI/CD",
    description:
      "As U.S.-based DevOps services providers, we use industry-leading continuous integration and delivery (CI/CD) solutions to streamline deploying code to production. Our DevOps engineering services company take part in everything from prototyping to QA to final product distribution.",
  },

  {
    image: DevOpsAutomationService,
    title: "DevOps Automation Service",
    description:
      "Automation of multiple procedures results in consistency and software stability. In addition to accelerating processes, scaling environments, and enabling quicker and safer rollbacks and iterations using CI/CD workflows, our DevOps service providers automate manual activities like deployments, test procedures, configuration management, and more.",
  },
  {
    image: DevOpsImplementation,
    title: "DevOps Implementation service",
    description:
      "As part of our DevOps engineering services, we provide a delivery system that facilitates high-quality software development from inception to completion. At Skyline, we do everything from analyzing growth to designing and developing and automating and then implementing the solution.",
  },
  {
    image: MicroservicesinDevOps,
    title: "Microservices in DevOps",
    description:
      "As part of our DevOps engineering services, we provide a delivery system that facilitates high-quality software development from inception to completion. At Skyline, we do everything from analyzing growth to designing and developing and automating and then implementing the solution.",
  },
  {
    image: SecurityCompliance,
    title: "Security & Compliance",
    description:
      "Our AWS DevOps engineering services company understands that your network's safety and regulatory compliance are paramount. At Skyline, we give special attention to ensuring our products conform to and go beyond all applicable standards and regulations.",
  },
];

export const ArtificialIntelligenceData = [
  {
    image: BusinessAnalytics,
    title: "Business Analytics",
    description:
      "Our machine learning development company can build interactive dashboards and visualizations in real-time to create solutions in an effort to solve complex problems and track them.",
  },
  {
    image: DataAnalytics,
    title: "Data Analytics & Engineering",
    description:
      "Our machine learning consulting services deliver business value to enterprises trying to infuse data science within products, attempting to uncover data-driven decisions.",
  },

  {
    image: PredictiveAnalytics,
    title: "Predictive & Cognitive Analytics",
    description:
      "We offer one of the best machine learning solutions in the USA by designing, building, training, and deploying machine learning and deep learning models to identify underlying patterns.",
  },

  {
    image: RecommendationEngine,
    title: "Recommendation Engine",
    description:
      "Our machine learning consulting service helps us create a personalized experience for every user by developing an accurate recommendation system.",
  },

  {
    image: Chatbots,
    title: "Chatbots",
    description:
      "The best machine learning company will surely add an interactive touch to your software application. And that is exactly what we do. We make your application engaging for your audience with chatbot development.",
  },
  {
    image: NaturalProcessing,
    title: "Natural Language Processing",
    description:
      "Machine learning consulting and development can help you identify the best tool for your business. We can build natural interactions with your users and identify patterns in unstructured data with the help of NLP.",
  },
  {
    image: VisualSearch,
    title: "Visual Search & Image Recognition",
    description:
      "Our machine learning developing company can leverage ML and Deep Learning techniques in tandem to extract relevant and actionable information from images and videos.",
  },
  {
    image: VoiceBasedAI,
    title: "Voice-Based AI",
    description:
      "We collaborate with businesses to deploy their own personalized voice-based assistants to assist customers and keep them engaged..",
  },
];

export const webDevelopment = "Web Development";
export const mobileDevelopment = "Mobile Development";
export const customSoftware = "Custom Software";
export const staffAugmentation = "Staff Augmentation";
export const QATesting = "QA & Testing";
export const supportMaintenance = "Support & Maintenance";
export const cloud = "Cloud";
export const devOps = "DevOps";
export const artificialIntelligence = "Artificial Intelligence";

export const Technologies = [
  {
    image: HTML5,
    name: " HTML / HTML5",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },

  {
    image: CSS3,
    name: "CSS / CSS3",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Javascript,
    name: "Javascript",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: React,
    name: "React",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Angular,
    name: "Angular",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Vue,
    name: "Vue",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Next,
    name: "Next",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Jquery,
    name: "Jquery",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Nuxt,
    name: "NuxtJs",
    category: "Frontend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },

  {
    image: Node,
    name: "Node",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Java,
    name: "Java",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Python,
    name: "Python",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: NET,
    name: "Dot NET",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Php,
    name: "Php",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Laravel,
    name: "Laravel",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: CodeIgniter,
    name: "CodeIgniter",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },

  {
    image: Ralis,
    name: "Ralis",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Nest,
    name: "Nest",
    category: "Backend",
    servicesName: [webDevelopment, customSoftware, staffAugmentation],
  },
  {
    image: Selenium,
    name: "Selenium",
    category: "Unit Testing",
    servicesName: [webDevelopment, mobileDevelopment],
  },
  {
    image: Jasmine,
    name: "Jasmine",
    category: "Unit Testing",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: Jest,
    name: "Jest",
    category: "Unit Testing",
    servicesName: [webDevelopment],
  },
  {
    image: Mocha,
    name: "Mocha",
    category: "Unit Testing",
    servicesName: [webDevelopment],
  },
  {
    image: Appium,
    name: "Appium",
    category: "Unit Testing",
    servicesName: [webDevelopment],
  },
  {
    image: Realm,
    name: "Realm",
    category: "Database",
    servicesName: [webDevelopment],
  },
  {
    image: MySql1,
    name: "MySQL",
    category: "Database",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: SQL,
    name: "SQL",
    category: "Database",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: MsSql,
    name: "MS-SQL",
    category: "Database",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: MongoDB,
    name: "MongoDB",
    category: "Database",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: SQLite,
    name: "SQLite",
    category: "Database",
    servicesName: [webDevelopment, mobileDevelopment, customSoftware],
  },
  {
    image: PostgreSql,
    name: "PostgreSQL",
    category: "Database",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: Firebase,
    name: "Firebase",
    category: "Database",
    servicesName: [webDevelopment, mobileDevelopment, customSoftware],
  },
  {
    image: Aws,
    name: "AWS",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  {
    image: Azure,
    name: "Azure",
    category: "Deployment",
    servicesName: [webDevelopment],
  },

  {
    image: GCP,
    name: "GCP",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  {
    image: DigitalOcean,
    name: "Digital-Ocean",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  {
    image: Docker,
    name: "Docker",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  {
    image: Vercel,
    name: "Vercel",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  {
    image: Netlify,
    name: "Netlify",
    category: "Deployment",
    servicesName: [webDevelopment],
  },
  // {
  //   image: Slack,
  //   name: "Slack",
  //   category: "Project Management",
  //   servicesName: [webDevelopment],
  // },
  {
    image: Jira,
    name: "Jira",
    category: "Project Management",
    servicesName: [webDevelopment, mobileDevelopment, customSoftware],
  },
  {
    image: MicrosoftTem,
    name: "Microsoft-Team",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: Asana,
    name: "Asana",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: Pivotal,
    name: "Pivotal",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: ClickUp,
    name: "Click-Up",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: MondayDotCom,
    name: "MondayDotCom",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },
  {
    image: Trello,
    name: "Trello",
    category: "Project Management",
    servicesName: [webDevelopment, customSoftware],
  },

  {
    image: WordPress,
    name: "WordPress",
    category: "CMS",
    servicesName: [webDevelopment],
  },
  {
    image: Codeigniter,
    name: "Codeigniter",
    category: "CMS",
    servicesName: [webDevelopment],
  },
  {
    image: Swift,
    name: "Swift",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: ObjectiveC,
    name: "Objective-C",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Storyboard,
    name: "Storyboard",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Java,
    name: "Java",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Kotlin,
    name: "Kotlin",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Flutter,
    name: "Flutter ",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Ionic,
    name: "Ionic ",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: React,
    name: "React-Native",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: JavaScript,
    name: "JavaScript",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: TypeScript,
    name: "TypeScript",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: Dart,
    name: "Dart",
    category: "Frontend",
    servicesName: [mobileDevelopment],
  },
  {
    image: MVC,
    name: "MVC",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: MVVM,
    name: "MVVM",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Swift_Ui,
    name: "Swift_Ui",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Alamofire,
    name: "Alamofire",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Retrofit,
    name: "Retrofit",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Volley,
    name: "Volley",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Redux,
    name: "Redux",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: ReduxSaga,
    name: "Redux-Saga",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: ReduxPersist,
    name: "ReduxPersist",
    category: "Framework",
    servicesName: [mobileDevelopment],
  },
  {
    image: Realm,
    name: "Realm",
    category: "Database",
    servicesName: [mobileDevelopment, customSoftware],
  },
  {
    image: CoreData,
    name: "CoreData",
    category: "Database",
    servicesName: [mobileDevelopment],
  },
  {
    image: Room,
    name: "Room",
    category: "Database",
    servicesName: [mobileDevelopment],
  },
  {
    image: Watermelon,
    name: "Watermelon",
    category: "Database",
    servicesName: [mobileDevelopment],
  },
  {
    image: Drift,
    name: "Drift",
    category: "Database",
    servicesName: [mobileDevelopment],
  },
  {
    image: SQL,
    name: "SQL",
    category: "Database",
    servicesName: [mobileDevelopment],
  },

  {
    image: Xctest,
    name: "Xctest",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: Postman,
    name: "Postman",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: UIAutomator,
    name: "UI-Automator",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: UnitTesting,
    name: "Unit-Testing",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: Selenium,
    name: "Selenium",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: Appium,
    name: "Appium",
    category: "Testing",
    servicesName: [mobileDevelopment],
  },
  {
    image: Trello,
    name: "Trello",
    category: "Project Management",
    servicesName: [mobileDevelopment],
  },
  {
    image: Asana,
    name: "Asana",
    category: "Project Management",
    servicesName: [mobileDevelopment],
  },
  {
    image: PivotalTracker,
    name: "PivotalTracker",
    category: "Project Management",
    servicesName: [mobileDevelopment],
  },
  {
    image: Terraform,
    name: "Terraform",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: CICD,
    name: "CI-CD",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: Aws,
    name: "AWS",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: Jenkins,
    name: "Jenkins",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: Jenkins,
    name: "Jenkins",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: Docker,
    name: "Docker",
    category: "DevOps & Cloud",
    servicesName: [customSoftware],
  },
  {
    image: Android,
    name: "Android",
    category: "Mobile",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: iOS,
    name: "iOS",
    category: "Mobile",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: React,
    name: "React-Native",
    category: "Mobile",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: Flutter,
    name: "Flutter",
    category: "Mobile",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: WordPress,
    name: "WordPress",
    category: "CMS",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: Codeigniter,
    name: "Codeigniter",
    category: "CMS",
    servicesName: [customSoftware, staffAugmentation],
  },
  {
    image: Java,
    name: "Java",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Selenium,
    name: "Selenium",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Appium,
    name: "Appium",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: TestNg,
    name: "TestNg",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Maven,
    name: "Maven",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Github,
    name: "Github",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: SeleniumGrid,
    name: "Selenium-Grid",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Junit,
    name: "Junit",
    category: "Automation Testing",
    servicesName: [QATesting],
  },
  {
    image: Asana,
    name: "Asana",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Trello,
    name: "Trello",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Jira,
    name: "Jira",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: TestRail,
    name: "TestRail",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: ClickUp,
    name: "Click-Up",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Pivotal,
    name: "Pivotal",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Postman,
    name: "Postman",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Swagger,
    name: "Swagger",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Browserstack,
    name: "Swagger",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: TestLink,
    name: "TestLink",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: TestLink,
    name: "TestLink",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: Bugzilla,
    name: "Bugzilla",
    category: "Manual Testing",
    servicesName: [QATesting],
  },
  {
    image: ImmuniWeb,
    name: "ImmuniWeb",
    category: "Security Testing",
    servicesName: [QATesting],
  },
  {
    image: Intruder,
    name: "Intruder",
    category: "Security Testing",
    servicesName: [QATesting],
  },
  {
    image: Sucuri,
    name: "Sucuri",
    category: "Security Testing",
    servicesName: [QATesting],
  },
  {
    image: Vega,
    name: "Vega",
    category: "Security Testing",
    servicesName: [QATesting],
  },
  {
    image: Xenu,
    name: "Xenu",
    category: "Security Testing",
    servicesName: [QATesting],
  },
  {
    image: AndroidStudio,
    name: "Android-Studio",
    category: "Mobile App Testing",
    servicesName: [QATesting],
  },
  {
    image: Genymotion,
    name: "Genymotion",
    category: "Mobile App Testing",
    servicesName: [QATesting],
  },
  {
    image: Xcode,
    name: "Xcode",
    category: "Mobile App Testing",
    servicesName: [QATesting],
  },
  {
    image: Jmeter,
    name: "Jmeter",
    category: "Performance Testing",
    servicesName: [QATesting],
  },
  {
    image: Lighthouse,
    name: "Lighthouse",
    category: "Performance Testing",
    servicesName: [QATesting],
  },
  {
    image: LoadRunner,
    name: "Load-Runner",
    category: "Performance Testing",
    servicesName: [QATesting],
  },
  {
    image: Blazemeter,
    name: "Blazemeter",
    category: "Performance Testing",
    servicesName: [QATesting],
  },
  {
    image: Postman,
    name: "Postman",
    category: "API Testing",
    servicesName: [QATesting],
  },
  {
    image: Swagger,
    name: "Swagger",
    category: "API Testing",
    servicesName: [QATesting],
  },
  {
    image: DevOpsEngineer,
    name: "DevOpsEngineer",
    category: "Other",
    servicesName: [staffAugmentation],
  },
  {
    image: MachineLearning,
    name: "Machine-Learning",
    category: "Other",
    servicesName: [staffAugmentation],
  },
  {
    image: SoftwareTesters,
    name: "Software-Testers",
    category: "Other",
    servicesName: [staffAugmentation],
  },
  {
    image: Salesforce,
    name: "Salesforce-Administrator",
    category: "Other",
    servicesName: [staffAugmentation],
  },
  {
    image: AWSEC2,
    name: "AWS-EC2",
    category: "AWS",
    servicesName: [cloud],
  },

  {
    image: S3Buckets,
    name: "S3-Bucket",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: VPC,
    name: "VPC",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: MySql,
    name: "MySQL",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: AWSCostExplorer,
    name: "AWS-Cost-Explorer",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: IAM,
    name: "IAM",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: WAFAndShield,
    name: "WAF-And-Shield",
    category: "AWS",
    servicesName: [cloud],
  },
  {
    image: AzureVirtual,
    name: "Azure-Virtual-Machines",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: AzureBlobs,
    name: "Azure-Blobs",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: VirtualNetwork,
    name: "Virtual-Network",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: AzureSQL,
    name: "Azure-SQL-Database",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: AzureCost,
    name: "Azure-Cost-Management-And-Billing",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: AzureContainer,
    name: "Azure-Container-Instances",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: WebAppfor,
    name: "Web-App-for-Containers",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: MicrosoftDefender,
    name: "Microsoft-Defender-for-Cloud",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: AzureInformation,
    name: "Azure-Information-Protection",
    category: "Azure",
    servicesName: [cloud],
  },
  {
    image: Droplets,
    name: "Droplets",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: DomainsAndDNS,
    name: "Domains-And-DNS",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: IPv6,
    name: "IPv6",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: MongoDB,
    name: "MongoDB",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: PriceEstimateCalculator,
    name: "Price-Estimate-Calculator",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: DigitalOceanContainer,
    name: "DigitalOcean-Container-Registry-DOCR",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: Snapshots,
    name: "Snapshots",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: Firewall,
    name: "Firewall",
    category: "Digital Ocean",
    servicesName: [cloud],
  },
  {
    image: ComputeEngine,
    name: "Compute-Engine",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: PersistentDisk,
    name: "Persistent-Disk",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: VPC,
    name: "VPC-Disk",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: CloudSQL,
    name: "Cloud-SQL",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: ArtifactRegistry,
    name: "Artifact-Registry",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: CloudIdentity,
    name: "Cloud-Identity",
    category: "GCP",
    servicesName: [cloud],
  },
  {
    image: Git,
    name: "Git",
    category: "Source Code Mgmt",
    servicesName: [cloud],
  },
  {
    image: Github,
    name: "Github",
    category: "Source Code Mgmt",
    servicesName: [cloud],
  },
  {
    image: Gitlab,
    name: "Gitlab",
    category: "Source Code Mgmt",
    servicesName: [cloud],
  },
  {
    image: Bitbucket,
    name: "Bitbucket",
    category: "Source Code Mgmt",
    servicesName: [cloud],
  },
  {
    image: Jenkins,
    name: "Jenkins",
    category: "Cont.Integration",
    servicesName: [cloud],
  },
  {
    image: Gitlab,
    name: "GItlab-CI",
    category: "Cont.Integration",
    servicesName: [cloud],
  },
  {
    image: GithubActions,
    name: "Github-Actions",
    category: "Cont.Integration",
    servicesName: [cloud],
  },
  {
    image: Bitbucket,
    name: "Bitbucket-Pipeline",
    category: "Cont.Integration",
    servicesName: [cloud],
  },
  {
    image: Puppet,
    name: "Puppet",
    category: "Configuration Mgmt",
    servicesName: [cloud],
  },
  {
    image: Ansible,
    name: "Ansible",
    category: "Configuration Mgmt",
    servicesName: [cloud],
  },

  {
    image: Jenkins,
    name: "Jenkins",
    category: "Deployment",
    servicesName: [cloud],
  },
  {
    image: Docker,
    name: "Docker",
    category: "Containers",
    servicesName: [cloud],
  },
  {
    image: Kubernetes,
    name: "Kubernetes",
    category: "Containers",
    servicesName: [cloud],
  },
  {
    image: Nginx,
    name: "Nginx",
    category: "Web Server",
    servicesName: [cloud],
  },
  {
    image: Apache,
    name: "Apache",
    category: "Web Server",
    servicesName: [cloud],
  },
  {
    image: IIS,
    name: "IIS",
    category: "Web Server",
    servicesName: [cloud],
  },
  {
    image: Tomcat,
    name: "Tomcat",
    category: "Web Server",
    servicesName: [cloud],
  },

  {
    image: Terraform,
    name: "Terraform",
    category: "Infra. As a Code",
    servicesName: [cloud],
  },
  {
    image: AWSCloudformation,
    name: "AWSCloudformation",
    category: "Infra. As a Code",
    servicesName: [cloud],
  },
  {
    image: LinuxServer,
    name: "Linux-Server",
    category: "Server Management",
    servicesName: [cloud],
  },
  {
    image: WindowsServer,
    name: "Windows-Server",
    category: "Server Management",
    servicesName: [cloud],
  },
  {
    image: ADSetup,
    name: "AD-Setup",
    category: "Server Management",
    servicesName: [cloud],
  },
  {
    image: Aws,
    name: "AWS",
    category: "Cloud",
    servicesName: [cloud],
  },
  {
    image: Azure,
    name: "Azure",
    category: "Cloud",
    servicesName: [cloud],
  },
  {
    image: DigitalOcean,
    name: "Digital-Ocean",
    category: "Cloud",
    servicesName: [cloud],
  },
  {
    image: GCP,
    name: "GCP",
    category: "Cloud",
    servicesName: [cloud],
  },
  {
    image: Loadbalancing,
    name: "Load-balancing",
    category: "Highly Available Infrastructure",
    servicesName: [cloud],
  },
  {
    image: Autoscalling,
    name: "Auto-scalling",
    category: "Highly Available Infrastructure",
    servicesName: [cloud],
  },
  {
    image: DataBackup,
    name: "DataBackup",
    category: "Highly Available Infrastructure",
    servicesName: [cloud],
  },
  {
    image: ZeroDowntimeSetup,
    name: "Zero-Downtime-Setup",
    category: "Highly Available Infrastructure",
    servicesName: [cloud],
  },
  {
    image: SupervisedLearning,
    name: "Supervised-Learning",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: EnsembleTechniques,
    name: "Ensemble-Techniques",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: UnsupervisedLearning,
    name: "Unsupervised-Learning",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: FeaturizationTuning,
    name: "Featurization-And-Model-Tuning",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: RecommendationSystems,
    name: "Recommendation-Systems",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: AppliedStatistics,
    name: "Applied-Statistics",
    category: "Machine Learning Techniques",
    servicesName: [artificialIntelligence],
  },
  {
    image: ArtificialNetworks,
    name: "Artificial-Neural-Networks",
    category: "Deep Learning",
    servicesName: [artificialIntelligence],
  },
  {
    image: ComputerVision,
    name: "Computer-Vision",
    category: "Deep Learning",
    servicesName: [artificialIntelligence],
  },
  {
    image: TimePrediction,
    name: "Time-Series-Prediction",
    category: "Deep Learning",
    servicesName: [artificialIntelligence],
  },
  {
    image: ConvolutionalNetwork,
    name: "Convolutional-Neural-Network",
    category: "Deep Learning",
    servicesName: [artificialIntelligence],
  },
  {
    image: Python,
    name: "Python",
    category: "Programming Language",
    servicesName: [artificialIntelligence],
  },
  {
    image: TensorFlow,
    name: "Tensor-Flow",
    category: "Packages and Framework",
    servicesName: [artificialIntelligence],
  },
  {
    image: LSTM,
    name: "LSTM",
    category: "Packages and Framework",
    servicesName: [artificialIntelligence],
  },
  {
    image: RNN,
    name: "RNN",
    category: "Packages and Framework",
    servicesName: [artificialIntelligence],
  },
  {
    image: Glove,
    name: "Glove",
    category: "Packages and Framework",
    servicesName: [artificialIntelligence],
  },
  {
    image: Numpy,
    name: "Numpy",
    category: "Data Analytics",
    servicesName: [artificialIntelligence],
  },
  {
    image: Pandas,
    name: "Pandas",
    category: "Data Analytics",
    servicesName: [artificialIntelligence],
  },
  {
    image: Pyplot,
    name: "Pyplot",
    category: "Visualization",
    servicesName: [artificialIntelligence],
  },
  {
    image: Seaborn,
    name: "Seaborn",
    category: "Visualization",
    servicesName: [artificialIntelligence],
  },
  {
    image: Matplotlib,
    name: "Matplotlib",
    category: "Visualization",
    servicesName: [artificialIntelligence],
  },
  {
    image: Flask,
    name: "Flask",
    category: "Deployment",
    servicesName: [artificialIntelligence],
  },
  {
    image: Heroku,
    name: "Heroku",
    category: "Deployment",
    servicesName: [artificialIntelligence],
  },
];

// Technologie
export const technologyFeatureData = [
  {
    image: image1,
    reactjs: "SEO Friendly",
    vuejs: "Easily Understood",
    angularjs: "Supported by Google",
    html5: "Supports Geolocation",
    Javascript: "Compatible & Interoperational",
    CSS3: "Offline Browsing",
    nodejs: "Scalable Baked into Node JS",
    laravel: "Open Source & Powerful Community",
    dotnet: "Free and Open Source",
    php: "No Pre-Configuration Required",
    codeigniter: "Highly Secure Framework",
    java: "Open Source Programming Language",
    android: "Easily Customizable",
    ios: "Ultimate Security of Your Data",
    flutter: "Time effective",
    mongodb: "Completely Scalable",
    postgresql: "Limitless Usage",
    mysql: "Open Source Platform",
  },
  {
    image: image2,
    reactjs: "Quick Speed",
    vuejs: "Very Flexible",
    angularjs: "Very Easy to Customize",
    html5: "Offline Web Cache",
    Javascript: "Very Powerful Frameworks",
    CSS3: "Loads Faster",
    nodejs: "MVP Development",
    laravel: "High Security with Enhanced Performance",
    dotnet: "Portable Across Operating Systems",
    php: "Open Source Platform",
    codeigniter: "User Friendly Interface",
    java: "Secure & Robust Development",
    android: "Target Different Platforms",
    ios: "Easily Tested and Low Fragmentation",
    flutter: "Reduced Cost",
    mongodb: "Quicker Updates",
    postgresql: "No Vendor Lock-In",
    mysql: "Compatible",
  },
  {
    image: image3,
    reactjs: "Higher Performance",
    vuejs: "Relatively Small in Size",
    angularjs: "Better Plug & Play Components",
    html5: "No More Cookies",
    Javascript: "Platform Independent",
    CSS3: "Platform Independent",
    nodejs: "Speediest with High Performance",
    laravel: "Blade Templating Engine",
    dotnet: "Ideal for Prototypes",
    php: "Easily Extendable",
    codeigniter: "Test-Driven Development",
    java: "Lower Investment",
    android: "Open Source",
    ios: "Tech-Savvy Audience",
    flutter: "Quicker Time to Market",
    mongodb: "Shrading",
    postgresql: "Unrestricted License",
    mysql: "Reliable and Quick",
  },
  {
    image: image4,
    reactjs: "Faster Rendering",
    vuejs: "Simple to Integrate",
    angularjs: "Supports Single Page Application Features",
    html5: "Compatible with Every Web Browser",
    Javascript: "Easily Tested & Debugged",
    CSS3: "Speedy Development",
    nodejs: "Admiringly Flexible",
    laravel: "Future Ready Applications",
    dotnet: "Opportunities of IoT (Internet of Things)",
    php: "MVC (Modern View Controller) Pattern",
    codeigniter: "Powerful Validation Framework Method",
    java: "Compatible with A Wide Range of Devices",
    android: "Faster Deployment",
    ios: "Higher Revenue",
    flutter: "Customizable User Interface",
    mongodb: "Schemaless",
    postgresql: "Can be used by Anyone",
    mysql: "Available Round the Clock",
  },
  {
    image: image5,
    reactjs: "Guarantees Stable Code",
    vuejs: "MVVM Architecture",
    angularjs: "Illustrative User Interface",
    html5: "Allows Game Development",
    Javascript: "Quick in Speed",
    CSS3: "SEO Friendly",
    nodejs: "Compatible with Multiple Platforms",
    laravel: "Flexible & Cost Effective",
    dotnet: "Ample Support Libraries",
    php: "Reuse the Code",
    codeigniter: "Model View Controller (MVC) based System",
    java: "High Security",
    android: "Higher Return on Investment",
    ios: "Safe and Secure Transactions",
    flutter: "Easily Integrated with Existing Applications",
    mongodb: "No SQL Injection",
    postgresql: "Scalable & Reliable",
    mysql: "Secure",
  },
  {
    image: image6,
    reactjs: "Provides Reusable Components",
    vuejs: "User Friendly",
    angularjs: "Has a 2 Way Binding Feature",
    html5: "In-Built Audio and Video Playback",
    Javascript: "Cyber Secured",
    CSS3: "Improves Web Presentation",
    nodejs: "Preferable for Microservices",
    laravel: "Automated Testing",
    dotnet: "User Friendly Data Structures",
    php: "No-Pre Installation Required",
    codeigniter: "Has Built-In Libraries",
    java: "High Performance",
    android: "Versatile and Scalable",
    ios: "Supreme Quality Standards",
    flutter: "Impressive Native Performance",
    mongodb: "Flexible Document Schemas",
    postgresql: "Low Support Costs",
    mysql: "Scalable",
  },
];

export const ProjectPlanData = [
  {
    image: createProject,
    title: "Create Project",
    reactjs:
      "Elevate your brand's digital presence and reach potential customers with Skyline Infosys' expert project creation services.",
    vuejs:
      "With digital presence becoming a necessity for brands to expand and gain exposure among potential customers.",
  },
  {
    image: makingPlans,
    title: "Making Plans",
    reactjs:
      "Just as a trained architect methodically develops and constructs a building, we craft unique strategies and tailor-made solutions for each project, assuring success from start to finish.",
    vuejs:
      "Ask any successful sports coach how they win so many games, and they’ll tell you they have a unique plan for every single web.",
  },
  {
    image: flowChart,
    title: "Custom Task View",
    reactjs:
      "Optimize your productivity and accelerate your workflow with Smart IT's special Task view, which provides a subset of fields for a simpler task record creation procedure.",
    vuejs:
      "To speed up the process of creating a task record, the Task view in Smart IT provides a subset of the fields that are available.",
  },
];

export const Vacancy = [
  {
    technology: "Laravel",
    role: "Laravel Developer",
    experience: "Fresher to 2 Year",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a Laravel Developer to develop and maintain web applications using the Laravel framework.",
    Requirements: [
      "Experience with Laravel framework.",
      "Knowledge of PHP, MySQL, and MVC architecture.",
      "Familiarity with front-end technologies such as JavaScript, HTML, and CSS.",
      "Experience with RESTful APIs and version control tools.",
    ],
  },
  {
    technology: "MERN",
    role: "MERN Stack Developer",
    experience: "2 Years",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a MERN Stack Developer to build modern web applications using the MERN stack (MongoDB, Express.js, React.js, and Node.js).",
    Requirements: [
      "Proven experience with MongoDB, Express.js, React.js, and Node.js.",
      "Strong knowledge of JavaScript and front-end frameworks.",
      "Experience with RESTful API development and integration.",
      "Understanding of version control tools such as Git.",
    ],
  },
  {
    technology: "MEAN",
    role: "MEAN Stack Developer",
    experience: "2 Years",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a MEAN Stack Developer to design and develop scalable applications using the MEAN stack (MongoDB, Express.js, Angular, and Node.js).",
    Requirements: [
      "Proven experience with MongoDB, Express.js, Angular, and Node.js.",
      "Strong knowledge of JavaScript and TypeScript.",
      "Experience with front-end development using Angular.",
      "Proficiency with back-end development and API integration.",
    ],
  },
  {
    technology: "React",
    role: "React Developer",
    experience: "1+ Year",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a React Native Developer to build mobile applications using React Native framework.",
    Requirements: [
      "Experience with React Native and JavaScript.",
      "Familiarity with mobile development and API integration.",
      "Experience with front-end technologies like HTML, CSS, and JavaScript.",
      "Understanding of version control tools such as Git.",
    ],
  },
  {
    technology: "BDE",
    role: "BDE",
    experience: "1 Year",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a Business Development Executive responsible for driving business growth and establishing client relationships.",
    Requirements: [
      "Excellent communication skills.",
      "Strong problem-solving abilities.",
      "Experience in business development or sales is a plus.",
      "Ability to build relationships and trust with clients.",
    ],
  },
  {
    technology: "Python",
    role: "Python Developer",
    experience: "2 Years",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a Python Developer with experience in building high-performing, scalable, enterprise-grade applications.",
    Requirements: [
      "Proven experience as a Python Developer.",
      "Knowledge of Django/Flask.",
      "Understanding of databases and SQL.",
      "Strong problem-solving skills.",
    ],
  },
  {
    technology: ".NET",
    role: ".NET Developer",
    experience: "2 Years",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a .NET Developer responsible for building .NET applications using the latest technologies.",
    Requirements: [
      "Experience with ASP.NET framework.",
      "Familiarity with the .NET Core framework.",
      "Knowledge of SQL Server.",
      "Strong communication skills.",
    ],
  },

  {
    technology: "Flutter",
    role: "Flutter Developer",
    experience: "6 Month to 1 Year",
    position: "1",
    Type: "Full Time",
    description:
      "We are looking for a Flutter Developer to create high-performance mobile apps with Flutter framework.",
    Requirements: [
      "Experience with Flutter framework.",
      "Familiarity with mobile development concepts.",
      "Experience with REST APIs and third-party libraries.",
      "Strong debugging and problem-solving skills.",
    ],
  },
];

export const FaqData = [
  {
    category: "General Questions",
    questions: [
      {
        question: "What services does Skyline Infosys offer?",
        answer:
          "We provide a wide range of IT services, including web and mobile app development, custom software development, cloud solutions, DevOps, artificial intelligence, QA and testing, and IT staff augmentation.",
      },
      {
        question: "Where is Skyline Infosys located?",
        answer:
          "We are headquartered in <b> B-805 to 809, IT Park, Opp. Gajera International School (Digital Valley), Surat, Gujarat 394101 </b> , with services offered globally.",
      },
      {
        question: "Who are your major clients?",
        answer:
          "We have collaborated with a variety of clients across multiple industries, including e-commerce, healthcare, finance, education, and technology. Our portfolio reflects our commitment to quality and client satisfaction.",
      },
      {
        question: "How can I contact Skyline Infosys?",
        answer:
          'You can reach us via our contact page or email us at <a href="mailto:info@skylineinfosys.com">info@skylineinfosys.com</a>. We are also available via phone at <a href="tel:+918128996966">+91-812-899-6966</a>.',
      },
    ],
  },
  {
    category: "Services & Solutions",
    questions: [
      {
        question: "Do you offer custom software development?",
        answer:
          "Yes, we specialize in creating tailored software solutions to meet your business needs and goals.",
      },
      {
        question: "What technologies do you work with?",
        answer:
          "Our expertise includes React.js, Angular.js, Vue.js, Node.js, PHP, .NET, Java, MongoDB, PostgreSQL, MySQL, and more.",
      },
      {
        question: "Can you develop mobile apps for both iOS and Android?",
        answer:
          "Absolutely! We provide native and cross-platform app development services using technologies like Flutter and React Native.",
      },
      {
        question: "Do you offer cloud services?",
        answer:
          "Yes, we provide cloud migration, integration, and management services for platforms like AWS, Microsoft Azure, and Google Cloud.",
      },
    ],
  },
  {
    category: "Working with Us",
    questions: [
      {
        question: "How do you ensure the quality of your projects?",
        answer:
          "We follow a structured development process and perform rigorous QA testing to ensure the highest quality standards. Transparency and timely communication are integral to our workflow.",
      },
      {
        question: "Can Skyline Infosys handle large-scale enterprise projects?",
        answer:
          "Yes, our team has experience in managing and delivering enterprise-grade projects for clients globally.",
      },
      {
        question: "What is your project delivery timeline?",
        answer:
          "The timeline depends on the complexity and scope of the project. After understanding your requirements, we provide a detailed project plan with estimated timelines.",
      },
    ],
  },
  {
    category: "Support & Maintenance",
    questions: [
      {
        question: "Do you offer ongoing support and maintenance?",
        answer:
          "Yes, we provide post-launch support and maintenance to ensure your systems run smoothly and efficiently.",
      },
      {
        question:
          "What happens if I need upgrades or changes after project completion?",
        answer:
          "We offer flexible maintenance contracts and can accommodate any changes or upgrades as per your evolving business needs.",
      },
    ],
  },
  {
    category: "Careers at Skyline Infosys",
    questions: [
      {
        question: "How can I apply for a job at Skyline Infosys?",
        answer:
          "Visit our Careers Page to view the latest job openings and submit your application.",
      },
      {
        question: "Do you offer internships for students or freshers?",
        answer:
          "Yes, we provide internships to students and freshers to help them gain real-world experience in the IT industry.",
      },
    ],
  },
  {
    category: "Miscellaneous",
    questions: [
      {
        question: "What industries do you serve?",
        answer:
          "We serve multiple sectors, including healthcare, finance, education, e-commerce, travel, and logistics.",
      },
      {
        question:
          "Can you work with startups as well as established enterprises?",
        answer:
          "Yes, we work with both startups and established companies, tailoring our solutions to meet their specific needs.",
      },
      {
        question: "How do you price your services?",
        answer:
          "Pricing depends on the complexity and requirements of the project. After an initial consultation, we provide a transparent, no-obligation quote.",
      },
    ],
  },
];
