import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

const Pricing = () => {
  return (
    <section className="pricing">
      <Container>
        <h1 className="heading">Choose the Right  Development Partner</h1>
        <p className="text-center mt-4">
          Collaborate with experts who deliver scalable and dynamic 
          solutions tailored to your needs. Leverage our proven track record of
          building high-performance, user-friendly applications. Experience
          seamless communication and a customer-first approach for long-term
          success.
        </p>
        <Row className="justify-content-center ">
          <Col lg={10} className="pricing_box" >
            <Table className="mt-4"  bordered >
              <thead>
                <tr>
                  <th className="first_heading">Factor</th>
                  <th className="self_pricing">Skyline Infosys</th>
                  <th>Freelancer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Time to get right developers</td>
                  <td  className="self_pricing">Within 24 hours</td>
                  <td>2 - 4 Weeks</td>
                </tr>
                
                <tr>
                  <td>Time to start a project</td>
                  <td  className="self_pricing">Within 24 hours</td>
                  <td>2 - 4 Weeks</td>
                </tr>
                <tr>
                  <td>TRecurring cost</td>
                  <td  className="self_pricing">0</td>
                  <td>0</td>
                </tr>
               
                <tr>
                  <td>Project failure risk</td>
                  <td  className="self_pricing">Minimal</td>
                  <td>High</td>
                </tr>

                <tr>
                  <td>Dedicated resources</td>
                  <td  className="self_pricing">Yes</td>
                  <td>No</td>
                </tr>

                <tr>
                  <td>Communications</td>
                  <td  className="self_pricing">Seamless</td>
                  <td>Uncertain</td>
                </tr>
               

              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
