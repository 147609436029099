import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import CloudMigration from "../.../../../Images/Service/ArtificialIntelligence/ArtificialIntelligence.svg";
import { ArtificialIntelligenceData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";

const ArtificialIntelligence = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Advanced AI Development Services| Skyline Infosys</title>
        <meta
          name="description"
          content="
         Discover AI development services providing advanced, customized machine learning solutions for data-driven decision-making in unexplored industries."
        />
        <meta name="keywords" content="ai development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
            backGroundImg={ServiceBg}
            heading="Artificial Intelligence"
            content="Advanced innovative custom machine learning solutions to help enterprises achieve data-driven decision-making serving the unexplored industries and technologies."
            title=" Machine Learning"
            btnText="Consult Skyline"
            subTitle="Explore ArtificialI Machine Learning Company"
          />
        </div>
        <DevelopmentInfo
          heading="Artificial Intelligence & Machine Learning"
          title="Top Artificial Intelligence & Machine Learning Company"
          image={CloudMigration}
          content={
            <>
              <p className="my-3">
                Vision of Skyline's AI development services is to bring out
                innovative, unpredictable solutions that can solve complex
                business challenges beyond the capabilities of humans. We offer
                advanced machine learning solutions development to help
                enterprises achieve data-driven decision-making serving
                unexplored industries and technologies. You can hire the machine
                learning team of our company which is well versed with the
                required frameworks, algorithms, tools, programming languages,
                and other technology stacks necessary to develop
                industry-specific machine learning use cases.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Artificial Intelligence & Machine Learning ?"
          data={ArtificialIntelligenceData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Machine Learning Techniques",
            "Deep Learning",
            "Programming Language",
            "Packages and Framework",
            "Data Analytics",
            "Visualization",
            "Deployment",
          ]}
          serviceName="Artificial Intelligence"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default ArtificialIntelligence;
