import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Codeigniter/codeigniter_banner.png";
import BannerImage2 from "../../../Images/Technology/Codeigniter/codeigniter_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const CodeigniterService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Services for CodeIgniter Development | Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Revolutionize your industry with Skyline Infosys' top-notch CodeIgniter development services. Get highly functional web apps for your business."
        />
        <meta name="keywords" content="codeigniter development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="Codeigniter Web Development"
            content="If a highly-functional and feature-rich web application is what you are looking for, your search stops with CodeIgniter development services. Along with our expert developers, you can revolutionize your industry using CodeIgniter."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore Codeigniter web development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Codeigniter Development"
          title="Codeigniter Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                CodeIgniter is a popular PHP framework used for developing web
                applications quickly and efficiently. It offers a rich set of
                libraries and tools to simplify the development process and
                reduce the time-to-market for web applications. Many businesses
                and developers prefer CodeIgniter for its speed, simplicity, and
                flexibility.
              </p>
              <p>
                If you are looking for CodeIgniter development services in
                India, you can start by searching online for companies that
                offer these services. Make sure to read reviews and ratings from
                previous clients to get an idea of the quality of their work and
                customer service.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"codeigniter"}
          heading="Why Should You Go For Codeigniter Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Codeigniter Development?"
          image={BannerImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                At our web development company in Surat, we understand the
                importance of delivering comprehensive solutions that meet our
                clients' specific requirements. Our skilled developers are
                well-versed in CodeIgniter development services and work hard to
                ensure transparency at every phase of the project. We value our
                clients' time and strive to deliver exceptional results within
                the specified timeframe. Our ultimate goal is to ensure 100%
                customer satisfaction, and we always keep that in mind while
                strategizing and executing projects. Our application development
                system is designed to be flexible and expandable to meet the
                needs of high-end performance. We strive to create intuitive
                websites that provide a hassle-free experience for both our
                clients and their customers.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default CodeigniterService;
