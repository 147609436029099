import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { FaUserTie } from "react-icons/fa";
import parse from "html-react-parser";
// icon/developer.svg
import developerImg from "../../../../Images/icon/developer.svg";
import support from "../../../../Images/icon/suports24.svg";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import Form from "./Form";

const HomeSection = ({ homeData }) => {
  return (
    <section className="hire_developer">
      <div className="home_page">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={7}>
              <div className="hire_content">
                <h1>{parse(homeData.title)}</h1>
                <p>{homeData.description}</p>

                <ul className="p-0 m-0">
                  {homeData.features.map((item , index) => {
                    return (
                      <li key={index}>
                        <RiArrowRightDoubleLine />
                        {item.text}{" "}
                      </li>
                    );
                  })}
                </ul>

                <div className="our_support ">
                  <div className="box">
                    <img src={support} alt="developerimg" />
                    <h4>
                      {" "}
                      <CountUp start={0} end={24} duration={5} />*
                      <CountUp start={0} end={7} duration={7} /> Technical
                      Support
                    </h4>
                  </div>
                  <div className="box">
                    <img src={developerImg} alt="developerimg" />
                    <h4>
                      {" "}
                      <CountUp start={0} end={50} duration={5} />+ Developers
                      Team
                    </h4>
                  </div>
                  <div className="box">
                    <FaUserTie />
                    <h4>
                      {" "}
                      <CountUp start={0} end={100} duration={5} />+ Clients{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <Form />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default HomeSection;
