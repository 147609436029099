import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import australia from "../../../Images/Flags/Australlia.png";
import India from "../../../Images/Flags/India.png";
import Newyork from "../../../Images/Flags/Newyork.png";
import Ottawa from "../../../Images/Flags/ottawa.png";
import London from "../../../Images/Flags/London.png";

const TimeZones = () => {
  const [value, setValue] = useState(new Date());

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="time_zones">
      <Container>
        <h1 className="heading">We’re Working in Global Time Zones</h1>
        <p className="content_p my-5">
          At Skyline Infosys, we work across global time zones to provide 24/7
          support and seamless collaboration. No matter where you're located,
          our team stays in sync with your schedule to ensure smooth project
          progress and timely communication. We're always available, whenever
          you need us.
        </p>

        <div className="main_grid">
          <div className="clock_box">
            <Clock
              value={convertTZ(value, "Asia/Kolkata")}
              renderNumbers={true}
            />
            <div className="content mt-4">
              <img src={India} alt="Australlia"  loading="lazy" />
              <h4>India</h4>
            </div>
          </div>
          <div className="clock_box">
            <Clock
              value={convertTZ(value, "Australia/Lord_Howe")}
              renderNumbers={true}
            />
            <div className="content mt-4">
              <img src={australia} alt="Australlia"  renderNumbers={true}  loading="lazy"/>
              <h4>australia</h4>

            </div>
          </div>
          <div className="clock_box">
            <Clock value={convertTZ(value, "America/New_York")}  renderNumbers={true} />
            <div className="content mt-4">
              <img src={Newyork} alt="Newyork"  loading="lazy"/>
              <h4> New York</h4>
            </div>
          </div>
          <div className="clock_box">
            <Clock value={convertTZ(value, "Etc/GMT+5")}  renderNumbers={true} />
            <div className="content mt-4">
              <img src={Ottawa} alt="Ottawa" />
              <h4>Ottawa</h4>
            </div>
          </div>
          <div className="clock_box">
            <Clock value={convertTZ(value, "Europe/London")}  renderNumbers={true} />
            <div className="content mt-4">
              <img src={London} alt="London"  loading="lazy"/>
              <h4>London</h4>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TimeZones;
