import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Postgresql/postgresql_banner.png";
import BannerImage1 from "../../../Images/Technology/Postgresql/postgresql_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const PostgresqlService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PostgreSQL Database Experts | Skyline Infosys</title>
        <meta
          name="description"
          content="
Unleash robust PostgreSQL database solutions with Skyline Infosys. We design, develop, deploy & support."
        />
        <meta name="keywords" content="postgresql database" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="PostgreSQL  Database"
            content="Access precise database implementations, optimization and tuning with PostgreSQL Deliver optimal performance with PostgreSQL DBA’s for ease of operations and reliability without sacrificing security"
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore PostgreSQL  Database Company"
          />
        </div>

        <DevelopmentInfo
          heading="PostgreSQL Database"
          title="PostgreSQL Database Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                As a leading PostgreSQL database services provider in India, we
                offer comprehensive solutions for businesses of all sizes and
                domains. Our team of certified PostgreSQL developers has
                extensive experience in designing, developing, and deploying
                PostgreSQL-based solutions that are tailored to our clients'
                business requirements.
              </p>
              <p>
                We provide a wide range of PostgreSQL database services,
                including database design, installation and configuration,
                migration, maintenance, and support. Our experts work closely
                with our clients to understand their business requirements, and
                design and implement PostgreSQL-based solutions that are
                optimized for performance, scalability, and security.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"postgresql"}
          heading="Why Should You Go For PostgreSQL Database ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed PostgreSQL Database?"
          image={BannerImage1}
          order={2}
          content={
            <>
              <p className="my-3">
                Developing a PostgreSQL database involves several steps, and it
                requires expertise and experience to design and implement a
                robust, scalable, and secure database solution Requirements
                gathering: The first step in developing a PostgreSQL database is
                to gather and analyze the business requirements. This involves
                understanding the data model, the size of the data, the expected
                transaction volume, and the security and scalability
                requirements
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default PostgresqlService;
