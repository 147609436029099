import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import HTMLIcon from "../../../Images/icon/html_5.webp";
import technologyIcon from "../../../Images/icon/laravel_ic.webp";
import LaravelImg from "../../../Images/HireDeveloper/LaravelHire.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const LaravelDeveloper = () => {

  const homeData = {
    title: "Hire <span>Laravel</span> Developers in India",
    description: `Empower your business with skilled Laravel developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in Laravel, PHP, MySQL, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Laravel experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Laravel development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  


  const hireTopDeveloperData = {
    heading: "Hire Top Laravel Developers in India from Skyline Infosys",
    image: {
      src: LaravelImg, 
      alt: "LaravelImg",
    },
    content: {
      description: `Looking to build robust, scalable, and secure web applications? At Skyline Infosys, 
        we offer expert Laravel developers who specialize in building dynamic server-side solutions. 
        With years of experience and deep technical expertise, our developers are equipped to deliver 
        cutting-edge solutions tailored to your business needs.`,
      expertiseHeading: "Our Laravel developers excel in:",
      expertisePoints: [
        "Building secure, scalable, and high-performance web applications",
        "Developing RESTful APIs with Laravel’s powerful features",
        "Ensuring smooth database integration using Eloquent ORM and MySQL",
        "Optimizing Laravel applications for performance and scalability",
      ],
      closingNote: `Partner with us today to leverage the full potential of Laravel and elevate your web development projects. 
        Get in touch and hire dedicated Laravel developers who can bring your ideas to life!`,
    },
  };
  
  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Laravel Combinations",
    description: `At Skyline Infosys, we specialize in leveraging Laravel with a variety of front-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Laravel + React.js",
        description: `A modern combination for building full-stack applications. React.js handles the dynamic front-end, offering a fast, component-based UI, while Laravel powers the back-end with its robust PHP framework, handling routing, authentication, and ORM (Eloquent). This stack is ideal for building scalable, high-performance applications with real-time capabilities.`,
        icon: ReactIcon,
      },
      {
        title: "Laravel + Vue.js",
        description: `A combination for building responsive and dynamic user interfaces. Vue.js offers a reactive, component-based UI while Laravel provides a structured, full-featured backend. This stack is great for applications requiring simplicity, scalability, and an interactive front-end.`,
        icon: VueIcon,
      },
      {
        title: "Laravel + HTML",
        description: `A foundational combination for building traditional server-rendered web applications. Laravel provides a robust back-end environment for handling routing, authentication, database management, and more, while HTML is used for building static pages on the front-end. This combination is ideal for simpler applications, content management systems (CMS), and websites that require minimal front-end interaction.`,
        icon: HTMLIcon,
      },
      {
        title: "Laravel + Angular",
        description: `A powerful stack for building large-scale enterprise applications. Angular provides a structured, two-way data binding front-end framework, while Laravel serves as the back-end, handling business logic, database interactions, and authentication. Ideal for complex, high-traffic applications.`,
        icon: AngularIcon,
      },
      {
        title: "Laravel + Next.js",
        description: `A combination that leverages the server-side rendering and SEO-friendly features of Next.js for the front-end, with Laravel handling the back-end logic and database management. This stack is ideal for building high-performance, SEO-optimized applications.`,
        icon: NextJsIcon,
      },
      {
        title: "Laravel + Firebase",
        description: `An excellent choice for serverless applications requiring real-time data syncing and user authentication. Firebase handles authentication, storage, and real-time database while Laravel provides the back-end API and custom server-side logic. Perfect for building scalable and modern applications.`,
        icon: FirebaseIcon,
      },
    ],
  };
  
  

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default LaravelDeveloper;
