import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ExpressIcon from "../../../Images/icon/expressJs.png";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import mongoIcon from "../../../Images/icon/mongo_db_ic.webp";
import technologyIcon from "../../../Images/icon/node_js_ic.webp";
import NodeImg from "../../../Images/HireDeveloper/NodeHire.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const NodeDeveloper = () => {
  const homeData = {
    title: "Hire <span>Node.js </span> Developers in India",
    description: `Empower your business with skilled Node.js developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in Node.js, Express.js, MongoDB, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Node.js experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Node.js development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  


  const hireTopDeveloperData = {
    heading: "Hire Top Node.js Developers in India from Skyline Infosys",
    image: {
      src: NodeImg,
      alt: "NodeImg",
    },
    content: {
      description: `Looking to build high-performing, scalable, and dynamic web applications? At Skyline Infosys, 
        we offer expert Node.js developers who specialize in building fast and scalable server-side solutions. 
        With years of experience and deep technical expertise, our developers are equipped to deliver 
        cutting-edge solutions tailored to your business needs.`,
      expertiseHeading: "Our Node.js developers excel in:",
      expertisePoints: [
        "Building scalable, high-performance server-side applications",
        "Creating RESTful APIs with Express.js and seamless integration with databases",
        "Optimizing real-time applications like chats, notifications, and dashboards",
      ],
      closingNote: `Partner with us today to leverage the full potential of Node.js and elevate your web development projects. 
        Get in touch and hire dedicated Node.js developers who can bring your ideas to life!`,
    },
  };
  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Node.js Combinations",
    description: `At Skyline Infosys, we specialize in leveraging Node.js with a variety of back-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Node.js + Express.js",
        description: `A powerful combination for building fast, scalable web applications. Express.js makes it easy to create RESTful APIs and handle HTTP requests, 
          while Node.js provides a robust environment for handling asynchronous I/O operations. Ideal for lightweight, efficient, and real-time apps.`,
        icon: ExpressIcon,
      },
      {
        title: "Node.js + React.js",
        description: `A popular combination for building full-stack applications. React.js handles the front-end, while Node.js takes care of back-end development. 
          This combo ensures a fast, dynamic, and responsive user experience, ideal for modern web applications and SPAs.`,
        icon: ReactIcon,
      },
      {
        title: "Node.js + MongoDB",
        description: `A versatile stack for creating full-stack applications using a NoSQL database. Node.js provides the back-end services, while MongoDB handles 
          complex and unstructured data. Ideal for scalable, real-time applications that require high flexibility.`,
        icon: mongoIcon,
      },
     
      {
        title: "Node.js + Vue.js",
        description: `A modern stack for building fast, single-page applications. Vue.js offers reactive and component-driven UI development, while Node.js provides the back-end 
          with real-time capabilities. Ideal for building interactive and scalable applications.`,
        icon: VueIcon,
      },

      {
        title: "Node.js + Angular",
        description: `A powerful combination for building large-scale enterprise applications. Angular provides a structured framework for the front-end, while Node.js handles the back-end logic. 
          This stack is ideal for applications requiring high scalability and maintainability, such as enterprise software and complex dashboards.`,
        icon: AngularIcon,
      },
      {
        title: "Node.js + Next.js",
        description: `A modern stack for building scalable and SEO-friendly full-stack applications. Next.js offers server-side rendering and static site generation, while Node.js powers the back-end. 
          This combination is perfect for web applications that require both fast rendering and dynamic server-side functionalities.`,
        icon: NextJsIcon,
      },
      {
        title: "Node.js + Firebase",
        description: `Perfect for serverless applications that require real-time data syncing and authentication. Firebase handles user authentication, storage, and real-time database, 
          while Node.js enables custom server-side logic and API development.`,
        icon: FirebaseIcon,
      },
    ],
  };
  

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default NodeDeveloper;
