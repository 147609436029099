import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import KotlinIcon from "../../../Images/icon/Kotlin.svg";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import DartIcon from "../../../Images/icon/Dart.svg";
import RestApiIcon from "../../../Images/icon/RestApiIcon.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/flutter_ic.webp";
import FlutterAppImg from "../../../Images/HireDeveloper/FlutterDeveloper.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const FlutterDeveloper = () => {

  const homeData = {
    title: "Hire <span>Flutter App</span> Developers in India",
    description: `Empower your business with skilled Flutter app developers delivering innovative cross-platform mobile solutions. 
            Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
            in Dart, Flutter SDK, Firebase, REST APIs, and more, ensuring top-notch results. With flexible hiring models and a client-first 
            approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Flutter app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Develop visually stunning apps for iOS and Android with a single codebase using Flutter.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers bring over 5 years of experience in cross-platform app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We ensure seamless UI/UX design consistency across platforms.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Get clean, maintainable code with full protection of your intellectual property.",
      },
    ],
  };
  
  
  
  

  // hire-top-developer-data.ts
  const hireTopDeveloperData = {
    heading: "Hire Top Flutter App Developers in India from Skyline Infosys",
    image: {
      src: FlutterAppImg, // Update the path to the Flutter app image
      alt: "FlutterAppImg",
    },
    content: {
      description: `Looking to create versatile, scalable, and high-performing cross-platform applications? At Skyline Infosys, 
        we offer expert Flutter app developers who specialize in building custom solutions for businesses across industries. 
        From crafting visually stunning UIs to integrating complex functionalities, our developers deliver exceptional results tailored to your goals.`,
      expertiseHeading: "Our Flutter app developers excel in:",
      expertisePoints: [
        "Developing cross-platform mobile applications using Flutter and Dart",
        "Creating visually stunning, responsive, and consistent UI/UX designs",
        "Integrating APIs, Firebase, RESTful services, and third-party SDKs",
        "Optimizing apps for performance, speed, and seamless multi-platform compatibility",
        "Leveraging Flutter’s hot reload and widget library for faster development cycles",
      ],
      closingNote: `Collaborate with us to bring your Flutter app ideas to life. 
        Hire dedicated Flutter app developers from Skyline Infosys to create high-quality, feature-rich applications that empower your business.`,
    },
  };
  
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Flutter Combinations",
    description: `At Skyline Infosys, we excel in integrating Flutter development with advanced back-end and front-end technologies to build cross-platform, high-performance applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "Flutter + Firebase",
        description: `An ideal stack for serverless applications with real-time data synchronization, secure user authentication, and push notifications. Firebase powers the back-end, while Flutter ensures consistent UI across platforms.`,
        icon: FirebaseIcon,
      },
      {
        title: "Flutter + Node.js",
        description: `A robust combination for scalable and real-time apps. Node.js efficiently handles server-side operations, while Flutter delivers a smooth and interactive cross-platform interface.`,
        icon: NodeJsIcon,
      },
      {
        title: "Flutter + Python/Django",
        description: `Perfect for apps requiring complex logic, data analysis, or AI integration. Django provides a scalable back-end, while Flutter creates stunning and responsive multi-platform UIs.`,
        icon: PythonIcon,
      },
      {
        title: "Flutter + AWS",
        description: `Leverage AWS services for scalable cloud solutions, including hosting, storage, and serverless architecture. Combine with Flutter to build reliable, high-performance mobile apps.`,
        icon: AWSIcon,
      },
      {
        title: "Flutter + Dart Backend",
        description: `Build full-stack Flutter apps using Dart for both front-end and back-end. This combination simplifies development and ensures seamless communication between app components.`,
        icon: DartIcon,
      },
      {
        title: "Flutter + GraphQL",
        description: `Optimize data fetching with GraphQL for your Flutter applications. This stack ensures reduced overfetching, faster response times, and precise data handling for cross-platform apps.`,
        icon: GraphQLIcon,
      },
      {
        title: "Flutter + REST APIs",
        description: `Easily integrate with RESTful APIs for data exchange and enhanced functionality. Flutter handles the UI with precision while REST APIs manage back-end communication.`,
        icon: RestApiIcon,
      },
      {
        title: "Flutter + Kotlin Multiplatform",
        description: `Combine Flutter’s UI capabilities with Kotlin Multiplatform for shared business logic across iOS and Android. This stack accelerates development while maintaining robust cross-platform functionality.`,
        icon: KotlinIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
        <div className="ios_page">
        <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
        </div>
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default FlutterDeveloper;
