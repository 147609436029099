import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import Image from "../../../Images/Technology/Javascript/javascript_banner.png";
import Image2  from "../../../Images/Technology/Javascript/javascript_banner.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import { ProjectPlanData, Technologies, technologyFeatureData } from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"

const JavascriptService = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Latest JavaScript Development Services | Skyline Infosys</title>
        <meta
          name="description"
          content="Skyline Infosys offers top-notch and custom JavaScript development services, creating scalable, reliable web applications. Explore our expertise!"
        />
        <meta name="keywords" content="Javascript Development Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

    <div className="service technology">
      <div className="home ">
        <Banner
         backGroundImg={CommonBg}
          heading="Javascript Development"
          content="Create next-gen web and mobile solutions with custom JavaScript development Craft bespoke JavaScript applications for your enterprise with clear structure, intuitive site architecture and easy-to-recognize links."
          title="Development Company "
          btnText="Consult Skyline"
          subTitle="Explore Javascript development Company"
        />
      </div>

      <DevelopmentInfo
        heading="Javascript Development"
        title="Javascript Development Services In all over India"
        image={Image}
        content={
          <>
            <p className="my-3">
            At Skyline Infosys, we offer comprehensive JavaScript development services to businesses of all sizes and domains. Our team of expert developers has years of experience and is adept at using the latest tools and technologies to deliver high-quality web applications that meet the specific needs of our clients. From developing interactive web interfaces to creating complex applications, we can handle it all. We use a collaborative approach to work closely with our clients to understand their requirements and offer customized solutions that help them achieve their business goals. Our JavaScript development services are designed to help our clients leverage the full potential of this powerful programming language and build robust, scalable, and high-performance web applications.
            </p>
            
          </>
        }
      />
      <TechnologiesFeatures
        technology={"Javascript"}
        data={technologyFeatureData}
        heading="Why Should You Go For Javascript Development ?
"
      />

      <DevelopmentInfo
        heading=""
        title="How Do We Build Powerful JavaScript Applications?"
        image={Image2}
        order={2}
        content={
          <>
            <p className="my-3">
            Javascript is a versatile programming language that is widely used in web development. It can be used to create interactive and dynamic user interfaces, as well as to manipulate and modify website content in real time. Its ease of use and flexibility make it a popular choice for developers who want to create web applications that work seamlessly across different platforms and devices. At Skyline Infosys, our team of experienced developers is well-versed in the latest javascript technologies and frameworks. We offer a range of javascript development services, including front-end and back-end development, web application development, and custom javascript programming. Contact us today to learn more about how we can help you with your javascript development needs.
            </p>
          </>
        }
      />

      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Frontend",
          "Backend",
          "Unit Testing",
          "Database",
          "Deployment",
          "Project Management",
          "CMS",
        ]}
        serviceName="Web Development"
      />
      <ServiceFromSkyline />
      <hr className="mb-0" />
      <AboutDevelopment heading="How we work" content="vuejs"  data={ProjectPlanData} />
      <hr className="mb-0" />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
    </>
  );
};

export default JavascriptService;
