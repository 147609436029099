import React from "react";
import "./CaseStudies.css";
import { Col, Container, Row } from "react-bootstrap";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import CaseStudiesList from "./Components/CaseStudiesList";

const CaseStudies = () => {
  return (
    <>
      <div className="case_study">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center case_content">
              <h1>
                Real Success Stories <br />{" "}
                <span>Skyline Infosys Case Studies</span>{" "}
              </h1>
              <p>
                At Skyline Infosys, our work speaks for itself. Dive into our
                case studies to explore how we’ve transformed businesses across
                industries with cutting-edge IT solutions. From empowering
                startups to streamlining operations for global enterprises, our
                tailored strategies have delivered measurable outcomes,
                enhancing growth and innovation for our clients.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <CaseStudiesList />
      <MajorClient />
      <Testimonials />
      {/* <ContactForm /> */}
    </>
  );
};

export default CaseStudies;
