import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/SQL/SQL-service.webp";
import BannerImage1 from "../../../Images/Technology/SQL/sql-banner.avif";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import { ProjectPlanData, Technologies, technologyFeatureData } from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const SQLService = () => {
  return (
   <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>SQL Database Solutions | Skyline Infosys</title>
  <meta
    name="description"
    content="Optimize SQL services with tailored solutions for seamless database management, performance enhancement, and cloud integration."
  />
  <meta name="keywords" content="SQL Database Development Services" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
    <div className="service technology">
      <div className="home ">
      <Banner
      backGroundImg={CommonBg}
      heading="SQL Database"
      content="Streamline database operations with Skyline Infosys' SQL services. From database design and implementation to optimization and integration, we deliver customized SQL solutions to meet your business needs."
      title="Development Company"
      btnText="Consult Skyline"
      subTitle="Explore SQL Database Development Company"
    />
      </div>
      <DevelopmentInfo
    heading="SQL Database"
    title="SQL Database Services Across India"
    image={BannerImage}
    content={
      <>
        <p className="my-3">
          SQL is one of the most widely used database management systems,
          essential for efficient and reliable data management. Skyline Infosys
          offers comprehensive SQL database development services tailored to
          suit businesses of all sizes and industries. Our expertise spans from
          database design to implementation, ensuring data is managed
          effectively.
        </p>
        <p>
          Our SQL database services include database architecture design, data
          migration, performance tuning, integration, and maintenance. We focus
          on creating robust, scalable, and secure database solutions while
          adhering to industry standards and best practices.
        </p>
      </>
    }
  />

      <TechnologiesFeatures
      technology={"mysql"}
        data={technologyFeatureData}
    heading="Why Should You Choose SQL Database?"

      />

      <DevelopmentInfo
        heading=""
    title="How Do We Deliver SQL Database Development?"
        image={BannerImage1}
        order={2}
        content={
          <>
            <p className="my-3">
            At our company, we follow a comprehensive approach to deliver high-quality MySQL database development services. Our team of experienced database developers follows industry best practices and uses the latest tools and technologies to ensure that your database is optimized for performance, security, and scalability At our company, we follow a comprehensive approach to deliver high-quality MySQL database development services. Our team of experienced database developers follows industry best practices and uses the latest tools and technologies to ensure that your database is optimized for performance, security and scalability
            </p>
          </>
        }
      />

      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Database",
          "Frontend",
          "Backend",
          "Unit Testing",
          "Deployment",
          "Project Management",
          "CMS",
        ]}
        serviceName="Web Development"
      />
      <ServiceFromSkyline />
      <hr className="mb-0" />
      <AboutDevelopment heading="How we work" content="vuejs"   data={ProjectPlanData} />
      <hr className="mb-0" />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
   </>
  );
};

export default SQLService;
