import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import Image from "../../../Images/Technology/Css3/css_banner.png";
import Image1 from "../../../Images/Technology/Css3/css_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import  CommonBg from "../../../Images/Common/CommonBg.jpeg"

const CSSService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leading CSS3 Development Company | Skyline Infosys</title>
        <meta
          name="description"
          content="Skyline Infosys offers top-notch CSS3 development services to create visually stunning and responsive websites. Contact us for custom solutions!"
        />
        <meta name="keywords" content="css web development" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="CSS3 Web Development"
            content="Powerful and fully optimized CSS3 development to effectively craft custom web pages Acquire top-notch CSS3 development capabilities to create functional and style specified web development solutions compatible across browsers and devices"
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore CSS3 Web development Company"
          />
        </div>

        <DevelopmentInfo
          heading="CSS3 Development"
          title="CSS3 Development Services In all over India"
          image={Image}
          content={
            <>
              <p className="my-3">
                Skyline Infosys is a renowned CSS3 development company that
                offers top-notch CSS3 development services to clients worldwide.
                Our team of skilled developers has extensive experience in
                working with CSS3, and they utilize their knowledge to deliver
                the best possible solutions to our clients. We understand that
                the look and feel of a website are crucial, and that's why we
                pay utmost attention to detail in CSS3 development to ensure
                that your website looks stunning and is optimized for all
                devices. Our CSS3 development services include custom CSS3
                development, responsive design implementation, cross-browser
                compatibility, and much more. Contact us to learn more about how
                we can help you create a visually appealing and user-friendly
                website with CSS3.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"CSS3"}
          heading="Why Should You Go For CSS3 Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Fashion CSS3 Applications?"
          image={Image1}
          order={2}
          content={
            <>
              <p className="my-3">
                CSS3 is an essential part of any modern web development project.
                It allows for the creation of stunning visual designs and
                engaging user interfaces that capture the attention of visitors.
                CSS3 is also designed to be backward compatible, ensuring that
                your website or application works seamlessly across a wide range
                of devices and platforms. At our web development company in
                Surat, we have a team of experienced web designers who
                specialize in CSS3 development. We take pride in delivering the
                best CSS3 development services in India, and we work closely
                with our clients to understand their unique needs and goals.
                Contact us today to create a spectacular website or app that
                stands out from the crowd.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Frontend",
            "Backend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default CSSService;
