import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Portfolio } from "../../../data";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

const OurPortfolio = ({ isViewMore }) => {
  const [visibleItems, setVisibleItems] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 9);
  };

  useEffect(() => {
    if (isViewMore) {
      setVisibleItems(3);
    }
  }, [isViewMore]);

  // Filtered portfolio based on selected category
  const filteredPortfolio = Portfolio?.filter(
    (item) => selectedCategory === "All" || item?.category === selectedCategory
  );

  return (
    <section className="our_portfolio mt-5">
      <Container>
        <h1 className="heading">Our Portfolio</h1>

        {/* Category Tabs */}
        {!isViewMore && (
          <div className="tab_box d-flex justify-content-center flex-wrap gap-3 align-align-items-center mt-5 mb-4">
            {["All", "Web Development", "App Development"].map((category) => (
              <button
                key={category}
                onClick={() => {
                  setSelectedCategory(category);
                }}
                className={`tab_button ${
                  selectedCategory === category ? "active" : ""
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        )}

        {/* Portfolio Items */}
        <div className="portfolio_box my-5">
          {filteredPortfolio?.slice(0, visibleItems).map((obj, index) => (
            <Tilt key={index}>
              <a href={obj.link}  target="_blank" className="text-decoration-none">
                <div className="box">
                  <div className="img_box position-relative">
                    <img src={obj.image} alt={obj.name} className="img-fluid"   loading="lazy"/>
                  </div>
                  <div className="content mt-2 pt-2">
                    <h2>{obj.name}</h2>
                    <p className="mb-0">{obj.description}</p>
                     <div className="d-flex mt-3 bottom_content justify-content-between align-items-center">
                        <div> 
                          <h5> Technology </h5>
                          <p> {obj.Technology} </p>
                        </div>
                        <div> 
                          <h5> Year </h5>
                          <p> {obj.Year} </p>
                        </div>
                     </div>
                  </div>
                </div>
              </a>
            </Tilt>
          ))}
        </div>

        {/* View More Button */}
        {isViewMore ? (
          <Link  to="/portfolio" className="text-decoration-none">
            <button className="button d-block m-auto"> View More </button>
          </Link>
        ) : (
          visibleItems < filteredPortfolio.length && (
            <button onClick={handleViewMore} className="button d-block m-auto">
              View More
            </button>
          )
        )}
      </Container>
    </section>
  );
};

export default OurPortfolio;
