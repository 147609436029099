import React from "react";
import { Container } from "react-bootstrap";
import Tilt from "react-parallax-tilt";

const TechnologiesFeatures = ({ heading, data, technology }) => {

  return (
    <section className="technologies_features">
      <Container>
        <h1 className="heading">{heading}</h1>

        <div className="features_box gap-3 mt-5 d-flex justify-content-center align-items-center flex-wrap">
          {data.map((obj, index) => {
            return (
              <Tilt  key={index}>
                <div className="box  text-center">
                  <img src={obj.image} alt={obj.image}  loading="lazy" />
                  <h5>{obj[technology]}</h5>
                </div>
              </Tilt>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default TechnologiesFeatures;
