import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { FaqData } from "../../../data";
import parse from "html-react-parser";
import { FiMinus, FiPlus } from "react-icons/fi";

const Questions = () => {
  return (
    <section className="faq_page">
      <Container>
        <h1 className="heading"> How Can Skyline Infosys Help You?</h1>
        <p className="text-center mt-4">
          Here is a list of answers to all the frequently asked questions
          regarding services and solutions offered by Skyline Infosys.
        </p>

        <Row className="justify-content-center mt-5">
          <Col lg={10}>
            {FaqData.map((obj, index) => {
              return (
                <div className="faq_box mb-4">
                  <h5 className="mb-4"> <i>{obj.category}</i>  </h5>
                  <Accordion className="dropdownlist">
                    {obj.questions.map((item, index) => {
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <div>{item.question}</div>

                              <FiPlus className="plus" />
                              <FiMinus className="minus"  />
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="mb-0">
                                {parse(item?.answer)}
                              </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Questions;
