import React from "react";
import convrtx from "../../../Images/Client/convrtx.avif";
import ACR from "../../../Images/Client/ACR.png";
import pinaheart from "../../../Images/Client/pinaheart_logo.webp";
import realTime from "../../../Images/Client/realTime.png";
import realisticus from "../../../Images/Client/realisticus.png";
import STEER from "../../../Images/Client/STEER_Logo.svg";
import videmak from "../../../Images/Client/videmak.png";
import proaxiom from "../../../Images/Client/proaxiom.png";
import Atithi from "../../../Images/Client/atithi.png";
import enfinyexim from "../../../Images/Client/enfinyexim.svg";

const ClientSlider = () => {
  const images = [
    { src: convrtx, alt: "clients" },
    { src: pinaheart, alt: "clients" },
    { src: realTime, alt: "clients" },
    { src: realisticus, alt: "clients" },
    { src: ACR, alt: "clients" },
    { src: STEER, alt: "clients" },
    { src: videmak, alt: "clients" },
    { src: proaxiom, alt: "clients" },
    { src: Atithi, alt: "clients" },
    { src: enfinyexim, alt: "clients" },
   
  ];

  return (
    <div className="clientSlider industries_serving " data-aos="fade-up">
      <div className="scrolling-container d-flex m-0">
      {
        [1 ,2].map((index) =>{
          return(
            <div className="serving" key={index}>
            {images.map((obj, index) => {
              return (
                <div className="box" key={index}>
                  <div className="img_box">
                    <img src={obj.src} alt={obj.src} loading="lazy" />
                  </div>
                </div>
              );
            })}
            {images.map((obj, index) => {
              return (
                <div className="box" key={index}>
                  <div className="img_box">
                    <img src={obj.src} alt={obj.src} loading="lazy" />
                  </div>
                </div>
              );
            })}
             {images.map((obj, index) => {
              return (
                <div className="box" key={index}>
                  <div className="img_box">
                    <img src={obj.src} alt={obj.src} loading="lazy" />
                  </div>
                </div>
              );
            })}
            
          </div>
          )
        })
      }
      </div>
    </div>
  );
};

export default ClientSlider;
