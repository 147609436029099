import React from "react";
import { Container } from "react-bootstrap";

const TermsConditions = () => {
  return (
    <div className="terms_conditions">
      <Container>
        <h1 className="heading mb-5">Terms and Conditions</h1>
        <p>
          Skyline Infosys ("SI") maintains this site to provide information
          about our products and services. Please feel free to browse the site.
        </p>
        <p>
          Your access to and use of the site are subject to the following Terms
          and Conditions (and any future updates, collectively referred to as
          "Terms and Conditions") and all applicable laws. By accessing and
          browsing the site, you agree to these Terms and Conditions.
        </p>
        <div className="content mt-5">
          <h6>Disclaimer of Warranties</h6>
          <p>
            The site and all its content, materials, information, software,
            products, and services are provided on an "as is" and "as available"
            basis. SI expressly disclaims all warranties, whether express or
            implied, including but not limited to implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>
        </div>
        <div className="content">
          <h6>Content Changes</h6>
          <p>
            The content and projects described or depicted on the site are
            subject to change without notice. Copyright may apply to any future
            materials. Unauthorized reproduction of such materials is prohibited
            and may violate copyright laws.
          </p>
        </div>
        <div className="content">
          <h6>Liability Disclaimer</h6>
          <p>
            SI assumes no responsibility and will not be liable for any damage
            or viruses that may affect your computer, equipment, or other
            property resulting from your access to, use of, or browsing of the
            site, or from downloading any materials, data, text, images, video,
            or audio from the site.
          </p>
        </div>
        <div className="content">
          <h6>Intellectual Property</h6>
          <p>
            Materials displayed on the site are either the property of SI or
            used with permission. Unauthorized use of these materials is
            strictly prohibited unless expressly permitted by these Terms and
            Conditions or with prior written approval from SI. Such unauthorized
            use may violate copyright, trademark, privacy, or publicity laws.
          </p>
        </div>
        <div className="content">
          <h6>External Links</h6>
          <p>
            SI is not responsible for the content of any external sites linked
            to or from this site. Accessing any such links is entirely at your
            own risk. Links provided on this site are for your convenience and
            should not be interpreted as endorsements of the site owner,
            sponsor, or its content. SI disclaims all warranties, express or
            implied, regarding the accuracy, legality, or validity of materials
            or information on these external sites.
          </p>
        </div>
        <div className="content">
          <h6>Prohibited Content</h6>
          <p>
            Users are prohibited from posting or transmitting any material that
            is unlawful, threatening, harassing, defamatory, obscene,
            pornographic, or otherwise objectionable. Posting material that
            could constitute a criminal offense, give rise to civil liability,
            or violate any applicable law is strictly prohibited.
          </p>
        </div>
        <div className="content">
          <h6>Revisions to Terms</h6>
          <p>
            SI reserves the right to revise these Terms and Conditions at any
            time by updating this page. As you are bound by these Terms and
            Conditions, we encourage you to review this page periodically to
            stay informed of any updates.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default TermsConditions;
