import React, { useState } from "react";
import { Container } from "react-bootstrap";

const TechnologiesPlatforms = ({
  heading,
  technologies,
  data,
  serviceName,
}) => {
  const [activeTab, setActiveTab] = useState(technologies[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const developmentTechnologies = data?.filter((tech) =>
    tech.servicesName?.includes(serviceName)
  );

  return (
    <section className="technical_language">
      <Container className="">
        <h1 className="heading"> {heading} </h1>

        <div className="tab_list my-5">
          {technologies.map((item, index) => {
            return (
              <button
                key={index}
                className={activeTab === item ? "active" : ""}
                onClick={() => handleTabClick(item)}
              >
                {item}
              </button>
            );
          })}
        </div>

        <div className="tab_content">
          {developmentTechnologies
            .filter((obj) => obj.category === activeTab)
            .map((obj, index) => (
              <div className="box" key={index}>
                <div className="img_box">
                  <img src={obj.image} alt={obj.name} loading="lazy" />
                </div>
                <h6>{obj.name}</h6>
              </div>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default TechnologiesPlatforms;
