import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const DevelopmentInfo = ({ heading, title, content, image , order }) => {
  return (
    <section>
      <Container>
        {heading.length > 0 && <h1 className="heading"> {heading} </h1>}

        <Row className="mb-5 mt-5 align-items-center developmentinfo  ">
          <Col lg={6} className={`order-${order}`}>
            <div className="img_box">
              <img
                src={image}
                width={"100%"}
                className="object-fit-contain"
                height={"500px"}
                alt={image}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="content">
              <h3>{title}</h3>
              {content}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DevelopmentInfo;
