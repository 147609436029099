import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import HTMLIcon from "../../../Images/icon/html_5.webp";
import technologyIcon from "../../../Images/icon/dotnet_ic.webp";
import DotNetImg from "../../../Images/HireDeveloper/dotNet.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const DotNetDeveloper = () => {


  const homeData = {
    title: "Hire <span>.NET</span> Developers in India",
    description: `Empower your business with skilled .NET developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in .NET, C#, ASP.NET, SQL Server, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our .NET experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in .NET development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  
  

  const hireTopDeveloperData = {
    heading: "Hire Top .NET Developers in India from Skyline Infosys",
    image: {
      src: DotNetImg, 
      alt: ".NETImg",
    },
    content: {
      description: `Looking to build robust, scalable, and secure web applications? At Skyline Infosys, 
        we offer expert .NET developers who specialize in building dynamic server-side solutions. 
        With years of experience and deep technical expertise, our developers are equipped to deliver 
        cutting-edge solutions tailored to your business needs.`,
      expertiseHeading: "Our .NET developers excel in:",
      expertisePoints: [
        "Building secure, scalable, and high-performance web applications using .NET Core and ASP.NET",
        "Developing RESTful APIs with ASP.NET Web API and MVC",
        "Ensuring smooth database integration using Entity Framework and SQL Server",
        "Optimizing .NET applications for performance and scalability",
      ],
      closingNote: `Partner with us today to leverage the full potential of .NET and elevate your web development projects. 
        Get in touch and hire dedicated .NET developers who can bring your ideas to life!`,
    },
  };
  
  

  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack .NET Combinations",
    description: `At Skyline Infosys, we specialize in leveraging .NET with a variety of front-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: ".NET + React.js",
        description: `A modern combination for building full-stack applications. React.js handles the dynamic front-end, offering a fast, component-based UI, while .NET powers the back-end with its robust framework, handling routing, authentication, and ORM. This stack is ideal for building scalable, high-performance applications with real-time capabilities.`,
        icon: ReactIcon,
      },
      {
        title: ".NET + Vue.js",
        description: `A combination for building responsive and dynamic user interfaces. Vue.js offers a reactive, component-based UI, while .NET provides a structured back-end with powerful features for handling database interactions, business logic, and authentication. This stack is ideal for building scalable and interactive applications.`,
        icon: VueIcon,
      },
      {
        title: ".NET + HTML",
        description: `A foundational combination for building traditional server-rendered web applications. .NET provides a powerful back-end environment for handling routing, authentication, database management, and more, while HTML is used to structure static front-end pages. Ideal for simpler applications, CMS, and websites requiring minimal front-end interaction.`,
        icon: HTMLIcon, 
      },
      {
        title: ".NET + Angular",
        description: `A powerful stack for building large-scale enterprise applications. Angular provides a structured, two-way data binding front-end framework, while .NET handles the back-end, including database management, authentication, and business logic. Ideal for complex, high-traffic applications.`,
        icon: AngularIcon,
      },
      {
        title: ".NET + Next.js",
        description: `A combination that leverages the server-side rendering and SEO-friendly features of Next.js for the front-end, with .NET handling the back-end logic and database management. This stack is perfect for building high-performance, SEO-optimized applications.`,
        icon: NextJsIcon,
      },
      {
        title: ".NET + Firebase",
        description: `An excellent choice for serverless applications requiring real-time data syncing and user authentication. Firebase handles authentication, storage, and real-time database, while .NET powers the back-end with custom server-side logic and APIs. Ideal for modern, scalable applications.`,
        icon: FirebaseIcon,
      },
    ],
  };
  
  
  

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default DotNetDeveloper;
