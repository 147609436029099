import React from "react";
import Banner from "../../Component/Banner";
import "./service.css";
import DevelopmentInfo from "./Components/DevelopmentInfo";
import CloudMigration from "../.../../../Images/Service/Cloud/Cloud-Migration.svg";
import { CloudData, Technologies } from "../../data";
import AboutDevelopment from "./Components/AboutDevelopment";
import TechnologiesPlatforms from "./Components/TechnologiesPlatforms";
import ServiceFromSkyline from "./Components/ServiceFromSkyline";
import FriendlyEngagementModel from "./Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "./Components/AboutSkylineInfosys";
import Testimonials from "../Home/Components/Testimonials";
import MajorClient from "../Home/Components/MajorClient";
import { Helmet } from "react-helmet";
import ServiceBg from "../../Images/Common/serviceBg.jpeg";

const Cloud = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Cloud Transformation Services for Business Growth|Skyline Infosys
        </title>
        <meta
          name="description"
          content="
   Experience affordable cloud transformation services at Skyline Infosys, driving growth and efficiency for your business."
        />
        <meta name="keywords" content="cloud transformation services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service">
        <div className="home ">
          <Banner
           backGroundImg={ServiceBg}
            heading="Cloud"
            content="Come join hands with Skyline Infosys. Our cloud transformation services are a promising budget-friendly endeavour for your business and operations growth"
            title="Transformation Service"
            btnText="Consult Skyline"
            subTitle="Explore Cloud Company"
          />
        </div>
        <DevelopmentInfo
          heading="Cloud Transformation Company"
          title="Top Cloud Transformation Company"
          image={CloudMigration}
          content={
            <>
              <p className="my-3">
                Skyline Infosys, a cloud transformation company and provider of
                cloud transformation consulting solutions, is here to assist
                businesses of all sizes to modernise their IT infrastructure at
                a reasonable price. Our state-of-the-art cloud computing
                consulting services have helped companies to increase their
                output by staggering margins. Using our cloud migration and
                automation services, you may improve your infrastructure for
                more remarkable advancements and business success. In addition,
                you may rest easy knowing that your digital infrastructure and
                data are safe from cyberattacks, all because of the security
                improvements we brought.
              </p>
            </>
          }
        />
        <AboutDevelopment
          heading="What’s special about Cloud Transformation Company ?"
          data={CloudData}
        />
        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={["AWS", "Azure", "Digital Ocean", "GCP"]}
          serviceName="Cloud"
        />
        <ServiceFromSkyline />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default Cloud;
