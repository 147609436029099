import React from "react";

import "../FrontEnd/style/style.css";
import HomeSection from "./Components/HomeSection";
import HireTopDeveloper from "./Components/HireTopDeveloper";
import Testimonials from "../../Home/Components/Testimonials";
import Pricing from "./Components/Pricing";
import TechnologyCombinations from "./Components/TechnologyCombinations";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import NodeIcon from "../../../Images/icon/node_js_ic.webp";
import ExpressIcon from "../../../Images/icon/expressJs.png";
import PythonIcon from "../../../Images/icon/Python.svg";
import RailsIcon from "../../../Images/icon/Rails.svg";
import laravelIcon from "../../../Images/icon/laravel_ic.webp";
import mongoIcon from "../../../Images/icon/mongo_db_ic.webp";
import technologyIcon from "../../../Images/icon/java_script_ic.webp"
import Angular from "../../../Images/icon/angular_js_ic.webp"
import GraphQL from "../../../Images/icon/GraphQL.png"
import Firebase from "../../../Images/icon/Firebase.svg"
import ReactIcon from "../../../Images/icon/react_native_ic.webp"
import Next from "../../../Images/icon/NextJs.svg"
import MySQL from "../../../Images/icon/mysql.svg"

import JavascriptImg from "../../../Images/HireDeveloper/javascript.png";

const JavascriptDeveloper = () => {
const homeData = {
  title: 'Hire <span> JavaScript </span> Developers in India',
  description: `Empower your business with skilled JavaScript developers delivering cutting-edge web solutions. 
    Enjoy cost-effective, scalable, and user-friendly applications tailored to your needs. Our developers specialize 
    in JavaScript, React.js, TypeScript, and more, ensuring high-quality results. With flexible hiring models and a client-first 
    approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our JavaScript experts!`,
  features: [
    {
      icon: 'RiArrowRightDoubleLine',
      text: 'Experience our services with a 7-day trial, completely risk-free.',
    },
    {
      icon: 'RiArrowRightDoubleLine',
      text: 'Our developers work in sync with your preferred language and time zone for smooth communication.',
    },
    {
      icon: 'RiArrowRightDoubleLine',
      text: 'Our highly skilled in-house team brings over 5 years of experience in JavaScript development.',
    },
    {
      icon: 'RiArrowRightDoubleLine',
      text: 'We deliver clean, maintainable code with full protection of your source code.',
    },
  ],
};

const hireTopDeveloperData = {
  heading: 'Hire Top JavaScript Developers in India from Skyline Infosys',
  image: {
    src: JavascriptImg ,
    alt: 'JavascriptImg',
  },
  content: {
    description: `Looking to build high-performing, scalable, and dynamic web applications? At Skyline Infosys, 
      we offer expert JavaScript developers who specialize in creating seamless, interactive user interfaces. 
      With years of experience and deep technical expertise, our developers are equipped to deliver 
      cutting-edge solutions tailored to your business needs.`,
    expertiseHeading: 'Our JavaScript developers excel in:',
    expertisePoints: [
      'Building responsive, efficient, and optimized web applications',
      'Customizing user interfaces with modern tools like Vue.js, React.js, Angular, and more',
      'Ensuring smooth, high-performance applications across all devices',
    ],
    closingNote: `Partner with us today to leverage the full potential of JavaScript and elevate your web development projects. 
      Get in touch and hire dedicated JavaScript developers who can bring your ideas to life!`,
  },
};


const expertiseSection = {
  heading: 'Our Expertise In Various Full-Stack JavaScript Combinations',
  description: `At Skyline Infosys, we specialize in leveraging JavaScript with a variety of back-end technologies 
    to deliver powerful full-stack solutions. Our expertise includes:`,
  expertiseData: [
    {
      title: 'JavaScript + Node.js',
      description: `A robust combination for building scalable and high-performance web applications. Ensures smooth 
        front-end and back-end integration. Ideal for real-time apps like chats and dashboards. Perfect for startups and enterprises alike.`,
      icon:NodeIcon,
    },
    {
      title: 'JavaScript + Express.js',
      description: `Best suited for lightweight, fast, and efficient applications. Enables seamless creation of RESTful APIs. 
        Ideal for single-page applications and microservices. A great choice for optimizing development speed.`,
      icon:ExpressIcon,
    },
    {
      title: 'JavaScript + Python/Django',
      description: `Delivers secure, data-driven platforms with a robust back-end. Excellent for machine learning or AI-powered apps. 
        Ensures rapid development with Django’s powerful framework. Ideal for data-heavy or analytics-driven solutions.`,
      icon: PythonIcon,
    },
    {
      title: 'JavaScript + Ruby on Rails',
      description: `Combines modern front-end capabilities with a clean back-end architecture. Speeds up development with Ruby on Rails' 
        convention over configuration approach. Perfect for eCommerce, SaaS, and MVPs. Ensures smooth deployment and scaling.`,
      icon: RailsIcon,
    },
    {
      title: 'JavaScript + PHP (Laravel)',
      description: `A versatile combination for dynamic front-ends and reliable back-ends. Offers extensive customization options 
        with Laravel’s MVC framework. Ensures secure, scalable, and feature-rich applications. Perfect for enterprise and CMS platforms.`,
      icon: laravelIcon,
    },
    {
      title: 'JavaScript + MongoDB',
      description: `Perfect for full-stack applications with a NoSQL database. Provides high flexibility for managing complex data. 
        Ideal for real-time apps and cloud-based solutions. Ensures smooth integration with modern frameworks.`,
      icon: mongoIcon,
    },

    {
      title: 'JavaScript + Angular',
      description: `Integrating Angular for the front-end with Node.js or Express.js for the back-end creates a highly dynamic and 
        scalable application. Ideal for enterprise-level solutions and dynamic web apps. Angular’s two-way data binding enhances app performance.`,
      icon: Angular,
    },
    {
      title: 'JavaScript + React.js + Node.js',
      description: `Combines React for building interactive UIs with Node.js to handle the server-side logic. This full-stack solution 
        is ideal for creating high-performance, single-page applications with a powerful back-end.`,
      icon: ReactIcon,
    },
    {
      title: 'JavaScript + GraphQL + Apollo',
      description: `Combines the power of GraphQL with Apollo Client for querying the server-side, creating highly efficient and flexible 
        APIs for the front-end. Ideal for apps that require complex queries and real-time updates.`,
      icon: GraphQL,
    },
    {
      title: 'JavaScript + Vue.js + Firebase',
      description: `This combination leverages Vue.js on the front-end with Firebase as the real-time database and serverless backend. 
        Perfect for building fast, scalable web apps with real-time features like chat and notifications.`,
      icon: Firebase,
    },
  
  
    {
      title: 'JavaScript + Next.js + MongoDB',
      description: `A perfect stack for building static and dynamic web applications with server-side rendering (SSR). 
        Next.js integrates smoothly with MongoDB, ideal for building fast, SEO-friendly apps with complex data handling.`,
      icon:Next,
    },
    {
      title: 'JavaScript + Sails.js + MySQL',
      description: `Sails.js is a full-featured MVC framework for Node.js, perfect for building enterprise-grade applications with complex back-ends. 
        Paired with MySQL, it’s ideal for data-heavy, relational databases for apps like dashboards or CRM systems.`,
      icon:MySQL,
    },
  ],
};




  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default JavascriptDeveloper;
