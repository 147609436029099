import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Java/java_banner.png";
import BannerImage2 from "../../../Images/Technology/Java/java_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const JavaService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trustworthy Java Development Service| Skyline Infosys</title>
        <meta
          name="description"
          content="
Experience superior Java development services at Skyline Infosys, where we deliver robust and scalable solutions tailored to your business."
        />
        <meta name="keywords" content="java development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="service technology">
        <div className="home ">
          <Banner
            backGroundImg={CommonBg}
            heading="java Development"
            content="Java Software Development Services include the creation of enterprise apps, software products, and SaaS solutions using Java frameworks that are secure, portable, and scalable. Since 2005, we have been helping clients rapidly and cost-effectively develop sustainable Java-based software."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore java development Company"
          />
        </div>

        <DevelopmentInfo
          heading="Java Development"
          title="Java Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we have a tried and tested approach to
                building robust and scalable Java apps that deliver high-quality
                mobile apps to a wide range of industries worldwide. Java
                programming language has always been one of the most successful
                and in-demand software platforms due to its high flexibility and
                security. We offer superior enterprise Java Development Services
                that enhance the agility, usability, and quality of your
                products. We also provide complete maintenance and support
                services whenever you need to add new features and modules to
                your Java-based mobile application. Our team of experienced Java
                developers is always ready to assist you in creating innovative
                and reliable solutions that meet your business needs.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          data={technologyFeatureData}
          technology={"java"}
          heading="Why Should You Go For Java Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Java App Development?"
          image={BannerImage2}
          order={2}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we have a team of expertise in Java
                development, which combines modern UI/UX architecture with
                strategic product development to serve clients across various
                domains in the market. Our proficient Java developers and
                designers have the skills and experience to create customized
                solutions for small, medium, and large-scale businesses in
                industries such as banking, insurance, telecommunications,
                healthcare, and others. We use well-proven approaches to offer
                cost-effective Java/J2EE solutions that meet our clients'
                specific needs. Our team is dedicated to delivering high-quality
                solutions that exceed our clients' expectations, and we strive
                to maintain long-term relationships with our clients by
                providing exceptional service and support.
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default JavaService;
