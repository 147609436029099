import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/PHP/php_banner.png";
import Bannerimage1 from "../../../Images/Technology/PHP/php_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";

const PhpService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        PHP Web Dev for Businesses | Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Grow your business with custom PHP website development. Skyline Infosys offers expert solutions & free quotes. Secure, scalable & affordable."
        />
        <meta name="keywords" content="php website development services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service technology">
        <div className="home ">
          <Banner
           backGroundImg={CommonBg}
            heading="PHP Web Development"
            content="LSkyline leverages its 17 years of PHP development experience to deliver robust web portals, B2C & B2B web apps, CMSs, and other web-based solutions. Our PHP Web Development Services can help you create dynamic and powerful PHP solutions to help your business succeed and lead in the market."
            title="Development Company "
            btnText="Consult Skyline"
            subTitle="Explore PHP web development Company"
          />
        </div>

        <DevelopmentInfo
          heading="PHP Development"
          title="PHP Development Services In all over India"
          image={BannerImage}
          content={
            <>
              <p className="my-3">
                At Skyline Infosys, we offer top-notch PHP development services
                throughout India. Our team of expert PHP developers has years of
                experience in developing customized, scalable, and secure PHP
                applications that meet the specific business requirements of our
                clients. We use the latest technologies and tools to deliver
                high-quality PHP solutions that help our clients stay ahead of
                the competition.
              </p>
              <p>
                Our PHP development services include web development, e-commerce
                development, CMS development, CRM development, and custom PHP
                development. We use agile methodology to ensure that the
                development process is smooth and efficient, and we keep our
                clients updated at every stage of the project. We also provide
                ongoing support and maintenance to ensure that your PHP
                application continues to meet your evolving business needs.
              </p>
            </>
          }
        />
        <TechnologiesFeatures
          technology={"php"}
          data={technologyFeatureData}
          heading="Why Should You Go For PHP Development ?
"
        />

        <DevelopmentInfo
          heading=""
          title="How Do We Developed Php Development?"
          image={Bannerimage1}
          order={2}
          content={
            <>
              <p className="my-3">
                Our approach to PHP development is highly collaborative, and we
                work closely with our clients throughout the project to ensure
                that their objectives are met. We use agile methodology to
                ensure that the development process is smooth, efficient, and
                transparent. Our focus is always on delivering high-quality PHP
                solutions that meet the specific business requirements of our
                clients and exceed their expectations.
              </p>
              <p>
                Our team of React JS developers in India has a deep
                understanding of the latest technologies and techniques to build
                applications that are not only dynamic and interactive but also
                add significant financial value to your business. We work
                closely with our clients to understand their business goals and
                objectives to develop customized solutions that meet their
                specific needs. Our strategic approach to development ensures
                that we deliver solutions that are aligned with the aims and
                ambitions of your company. We focus on developing applications
                that engage and thrill users, providing them with a seamless
                user experience that enhances their overall experience..
              </p>
            </>
          }
        />

        <TechnologiesPlatforms
          heading="Technologies & Platforms We work With"
          data={Technologies}
          technologies={[
            "Backend",
            "Frontend",
            "Unit Testing",
            "Database",
            "Deployment",
            "Project Management",
            "CMS",
          ]}
          serviceName="Web Development"
        />
        <ServiceFromSkyline />
        <hr className="mb-0" />
        <AboutDevelopment
          heading="How we work"
          content="vuejs"
          data={ProjectPlanData}
        />
        <hr className="mb-0" />
        <FriendlyEngagementModel />
        <AboutSkylineInfosys />
        <MajorClient />
        <Testimonials />
      </div>
    </>
  );
};

export default PhpService;
