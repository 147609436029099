import React from "react";
import { Container } from "react-bootstrap";

const IndustriesServing = ({ data, title, description }) => {
  return (
    <section className="industries_serving">
      <Container>
        <h1 className="heading">{title}</h1>
        <p className="text-center mt-3 mb-5 ">{description}</p>

        <div className="scrolling-container">
          <div className="serving">
            {[0, 2, 3, 4].map(() => {
              return (
                <>
                  {data.map((obj, index) => {
                    return (
                      <div className="box" key={index}>
                        <div className="img_box">
                          <img src={obj.image} alt={obj.image} loading="lazy" />
                        </div>
                        <h5> {obj.name} </h5>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default IndustriesServing;
