import React, { useState } from "react";
import { caseStudiesProcess } from "./CaseStudyData";
import { Col, Container, Row } from "react-bootstrap";
import MajorClient from "../Home/Components/MajorClient";
import Testimonials from "../Home/Components/Testimonials";
import { PiArrowBendDownRightBold } from "react-icons/pi";
import Image1 from "../../Images/CaseStudies/Atithibhavan.jpg";
import mission from "../../Images/CaseStudies/vision-mission.png";

const AtithiBhavan = () => {
  const [tabName, setTabName] = useState("Platform Features");

  return (
    <div>
      <div className="case_study_details case_study">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11} className="case_content text-center">
              <h1>
                <span>Atithi Bhavan</span>
              </h1>
              <p> Enhancing Community Accommodation Bookings with Atithi Bhavan</p>

            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col lg={12}>
            <div className="industrial">
              <img src={Image1} alt={Image1} className="img-fluid" />
              <div className="industrial_content">
                <p>
                  This case study explores the development and implementation of
                  Atithi Bhavan, a platform designed to streamline contact and
                  information access for hotels, temples, Samaj vadi, and
                  Dharamshalas, along with their facilities. Atithi Bhavan
                  enables users to easily obtain accommodation details and
                  generate exclusive discount codes, which can be redeemed
                  during booking for cost savings. The platform simplifies the
                  process of finding and contacting these accommodations,
                  promoting affordable and efficient booking experience
                </p>
                <div className="d-flex flex-wrap align-items-center justify-content-between ">
                  <div>
                    <h4>Industry</h4>
                    <p>
                      <b>Online Booking Service</b>
                    </p>
                  </div>
                  <div>
                    <h4>Country</h4>
                    <p>
                      <b>India</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr className="m-0" />
      <div className="case_img_box">
        <Container>
          <div className="case_box position-relative">
            <img src={Image1} alt={Image1} />
          </div>
        </Container>
      </div>

      <hr className="m-0" />

      <Container>
        <Row className="case_content_box">
          <Col lg={12} className="px-3">
            <div className="case_content px-3 pt-3">
              <h2 className="case_heading">About</h2>
              <p>
                Atithi Bhavan connects travelers with religious and community
                accommodations like hotels, temples, Samaj Wadis, and
                Dharamshalas. We simplify bookings by providing direct property
                contacts and discount codes, ensuring affordable stays. Through
                a structured backend with admin and super admin roles, we ensure
                accurate listings and seamless communication between travelers
                and property owners, making bookings easier and more
                cost-effective.
              </p>
            </div>
          </Col>
          <Col lg={12} className="px-3">
            <div className="case_content px-3">
              <h2 className="case_heading text-text-uppercase">
                Multi Repositories Approach
              </h2>
              <p> Atithi Bhavan operates with two repositories:</p>
              <p className="mb-0">
                <b>Frontend</b> – Allows users to access accommodation details,
                generate discount codes, and view their history seamlessly.
              </p>
              <p className="mb-1">
                <b>Backend</b> – Manages accommodation listings, administrative
                functions, and discount code generation
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className=" my-5">
        <div className="process px-3">
        <h2 className="case_heading">Process</h2>
        <Row className="mt-4 justify-content-center">
          {caseStudiesProcess.map((step, index) => (
            <Col lg={3} md={4} sm={6} key={index} className="mb-3">
              <div className="card">
                <span>{step.step}</span>
                {step.icon}
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </Col>
          ))}
        </Row>
        </div>
      </Container>

      <Container className=" my-5">
        <Row className="my-4 align-items-center mission_vision ">
          <Col md={6}>
            <div className=" mission_content">
              <h2 className="case_heading mb-3">Our Mission</h2>
              <p>
                Atithi Bhavan aims to address three critical needs in the
                community accommodation sector:
              </p>
              <div className="process_tab_box">
                <div className="tab_content_box">
                  <div className="box">
                    <ol className="p-0 m-0">
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Accessibility
                        </h6>
                        <p>
                          Simplifying access to hotel, temple, and dharamshala
                          contact details.
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Affordability
                        </h6>
                        <p>
                          Providing users with exclusive discounts through
                          platform-generated codes.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Efficiency:
                        </h6>
                        <p>
                          Streamlining inquiries and bookings with a
                          user-friendly interface and organized backend
                          management
                        </p>
                        <p>
                          The primary goal of Atithi Bhavan is to efficiently
                          match users with property owners to facilitate
                          discounts and drive increased bookings for property
                          owners. These goals guide the continuous development
                          of Atithi Bhavan, enhancing user engagement and
                          increasing property visibility.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <img src={mission} className="img-fluid" alt="mission" />
          </Col>
        </Row>

        <hr />

        <div className="my-4">
          <h2 className="case_heading">Our Contribution</h2>
          <p>
            Atithi Bhavan offers a centralized hub for accommodation contact
            details, facilities, and discount codes. By providing this
            information, the platform enhances user convenience, promoting
            direct engagement with accommodation providers. Through the
            generation of exclusive discount codes, we contribute to a more
            affordable and efficient experience for travelers, while also
            boosting the visibility of property owners.
          </p>

          <h2 className="case_heading">Background</h2>
          <p>
            Atithi Bhavan emerged in response to the challenges faced by
            travelers seeking spiritual or community stays. Many users struggle
            to find reliable contact details and negotiate discounts, resulting
            in higher costs and time-consuming booking processes. Atithi Bhavan
            bridges this gap by offering a single platform for inquiries and
            discounts.
          </p>
        </div>
        <hr />
        <div className="challenges my-5">
          <h2 className="case_heading">Challenges </h2>
          <Row className="mt-4 justify-content-center">
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 1</span>
                <p className="mb-1">
                  <b>Challenge</b> Generating discount codes for users and
                  efficiently managing them on the admin side
                </p>

                <p className="mb-1">
                  <b>Solution</b>: The system was designed with a dual-layer
                  process to ensure smooth experiences for both users and
                  admins. On the user side, users can easily generate and
                  retrieve discount codes through an intuitive interface, and
                  these codes can be sent via email or SMS. The admin side
                  features a central dashboard for managing and approving
                  discount codes, allowing admins to verify their legitimacy and
                  ensure validity
                </p>
              </div>
            </Col>
            <Col lg={4} className="border-left border_bottom border-right">
              <div className="card position-relative">
                <span> 2</span>
                <p className="mb-1">
                  <b>Challenge</b>: Ensuring accurate and up-to-date
                  accommodation listings while managing large property datasets.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: To address this, the admin team performs
                  regular updates and verifications of accommodation listings.
                  This ensures that the information is current and accurate,
                  improving the platform’s reliability.
                </p>
              </div>
            </Col>
            <Col lg={4} className="border_bottom">
              <div className="card position-relative">
                <span> 3</span>
                <p className="mb-1">
                  <b>Challenge</b>: Encouraging user engagement and retention.
                </p>

                <p className="mb-1">
                  <b>Solution</b>: Exclusive discount codes and promotional
                  offers were introduced to incentivize user interaction and
                  return visits. This created a cycle of engagement where users
                  were motivated to use the platform for future bookings.
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card position-relative">
                <span> 4</span>
                <p className="mb-1">
                  <b>Challenge</b> : In admin side A significant challenge in
                  the platform's development was implementing a system that
                  allowed property owners to add rooms based on their selected
                  premium subscription plan. Each subscription tier needed to
                  offer different levels of access, with higher-tier plans
                  enabling property owners to add more rooms. Managing these
                  room additions in line with subscription renewals and ensuring
                  the appropriate access for each premium level was crucial. The
                  system needed to ensure that property owners were granted room
                  addition capabilities only according to the limits of their
                  active subscription plan
                </p>

                <p className="mb-1">
                  <b>Solution</b>: To address this challenge, the following
                  solution was implemented:
                </p>
                <div className="mb-1">
                  <p className="mb-1">
                    <b>Subscription-Based Room Limits:</b> <b>1</b>. Each
                    premium subscription plan (e.g., Basic, Silver, Gold,
                    Platinum) was associated with a specific room limit. For
                    instance, a Basic plan may allow up to 5 rooms, while a
                    Platinum plan could allow up to 50 rooms
                  </p>
                  <p>
                    <b>2</b>. Property owners can view and manage their room
                    count based on their active plan. If their subscription is
                    upgraded or renewed, the system automatically updates the
                    room limit accordingly
                  </p>
                </div>
                <div className="mb-1">
                  <p className="mb-1">
                    <b> Dynamic Room Management:</b> <b>1</b>. A flexible room
                    management system was created, where property owners could
                    easily add, edit, or remove rooms within the constraints of
                    their plan. The system checks the current subscription tier
                    and ensures that any attempt to add more rooms than the
                    allotted limit is prevented.
                  </p>
                  <p>
                    <b>2</b>. A visual indicator or notification was added to
                    alert property owners of their current room count and
                    available capacity based on their plan.
                  </p>
                </div>
                <div className="mb-1">
                  <p className="mb-1">
                    <b>Subscription Renewal and Room Access:</b> <b>1</b>.When a
                    property owner's subscription expires or is renewed, the
                    system automatically adjusts their room limit based on the
                    new subscription tier. For example, if a property owner on a
                    Basic plan upgrades to a Silver plan, they are automatically
                    granted the ability to add more rooms in line with the
                    Silver plan’s limit
                  </p>
                  <p>
                    <b>2</b>.If a subscription is downgraded or not renewed, the
                    system prevents access to additional rooms beyond the new or
                    expired limit, ensuring the owner cannot exceed their
                    allowed room count.
                  </p>
                </div>

                <div className="mb-1">
                  <p className="mb-1">
                    <b>Notifications and Alerts:</b> <b>1</b>.To ensure that
                    property owners stay informed, they are notified when they
                    approach their room limit or when they can add more rooms
                    after a subscription upgrade. Additionally, reminders about
                    subscription renewals and the impact on room limits were
                    incorporated to prevent interruptions in their ability to
                    manage rooms.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="process_tab_box mb-5">
          <Row className="justify-content-center">
            <Col lg={10} className="process_tab_list">
              <ul className="p-0">
                <li
                  onClick={() => setTabName("Platform Features")}
                  className={`${
                    tabName === "Platform Features" ? "active" : ""
                  }`}
                >
                  Platform Features
                </li>
                <li
                  onClick={() => setTabName("Objectives")}
                  className={`${tabName === "Objectives" ? "active" : ""}`}
                >
                  Objectives
                </li>
                <li
                  onClick={() => setTabName("Technologies")}
                  className={`${tabName === "Technologies" ? "active" : ""}`}
                >
                  Technologies
                </li>
              </ul>
            </Col>
            <Col lg={10} className="mt-4">
              {tabName === "Platform Features" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Contact Listings:
                        </h6>
                        <p>
                          Provides direct access to contact details of hotels,
                          temples, and dharamshala
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Discount Codes
                        </h6>
                        <p>
                          Users generate discount codes for bookings,
                          encouraging direct engagement with properties
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Facility Overview
                        </h6>
                        <p>
                          Detailed breakdowns of property facilities allow users
                          to make informed decisions.
                        </p>
                      </li>

                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Role Management
                        </h6>
                        <p>
                          Super Admins oversee property listings, while Admins
                          manage specific accommodations
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Responsive Design
                        </h6>
                        <p>
                          The frontend interface is mobile-friendly, ensuring
                          accessibility across device
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Subscription-Based Property Listing:
                        </h6>
                        <p>
                          subscription to enhance their listings, allowing for
                          additional features such as priority placement, larger
                          visibility, and room management options based on their
                          selected plan Property owners can register for a
                          premium
                        </p>
                      </li>
                      <li>
                        <h6>
                          <PiArrowBendDownRightBold />
                          Free Access for Religious Organizations
                        </h6>
                        <p>
                          Religious institutions like temples and dharamshalas
                          can list properties for free, ensuring affordable
                          accommodation options for pilgrims.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Objectives" && (
                <div className="tab_content_box">
                  <div className="box">
                    <ol>
                      <li>
                        <p className="p-0">
                          <b>Primary Goal</b> <br /> Develop an intuitive
                          platform that facilitates accommodation inquiries and
                          offers discounts to enhance the booking experience.
                        </p>
                      </li>
                      <li>
                        <p className="p-0">
                          <b> Key Performance Indicators (KPIs)</b> <br /> •
                          Increased user engagement with property listings.{" "}
                          <br /> • Higher discount code utilization rates.{" "}
                          <br /> • Improved booking efficiency through direct
                          contact access
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              )}

              {tabName === "Technologies" && (
                <div className="tab_content_box">
                  <div className="box">
                    <h5>
                      Atithi Bhavan follows an iterative development process,
                      incorporating feedback from users and property owners to
                      refine platform features and enhance user experience.
                    </h5>

                    <p>
                      <b>Frontend</b> : Vue.js for dynamic, responsive
                      interfaces
                    </p>
                    <p>
                      <b>Backend</b> : : Laravel for robust data management and
                      role-based access.
                    </p>
                    <p>
                      <b>Database</b> :: MySQL for secure storage of user data
                      and property information
                    </p>

                    <h4>Implementation-</h4>

                    <p>
                      <b>Contact Listings</b> : A searchable directory of
                      accommodation details.
                    </p>
                    <p>
                      <b>Discount Code System</b> : Users request discount codes
                      directly from the platform
                    </p>
                    <p>
                      <b>Facility Details</b> :: Listings display available
                      amenities and property highlights.
                    </p>
                    <p>
                      <b>Role-Based Access</b> : Admins manage individual
                      property listings, while Super Admins oversee
                      platform-wide functions.
                    </p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <hr />

        <div className="analysis mt-5">
          <h2 className="case_heading"> Results and Analysis</h2>

          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Increased Property Engagement
                    </h6>
                    <p>
                      Property owners listed on Atithi Bhavan experienced higher
                      engagement due to enhanced visibility on the platform. The
                      detailed listings, which include property information,
                      amenities, and contact details, facilitated direct
                      communication between property owners and potential
                      guests. This direct interaction has led to more inquiries
                      and increased interest in listed properties.
                    </p>
                  </li>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Better Property Visibility
                    </h6>
                    <p>
                      By featuring properties on a centralized platform, Atithi
                      Bhavan significantly boosted the visibility of
                      accommodations, especially those in the religious and
                      community sectors. The platform’s searchability, along
                      with detailed descriptions and accessible contact
                      information, made it easier for travelers to find suitable
                      accommodations.Properties with high-quality listings were
                      able to attract a larger audience, improving their reach
                      and increasing the likelihood of successful bookings or
                      inquiries.
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Enhanced Discount Code Utilization
                    </h6>
                    <p>
                      The introduction of discount codes provided a clear
                      incentive for users to engage with the platform. Users
                      appreciated the simplicity and convenience of generating
                      promo codes, which they could use directly during their
                      interaction with property owners. As a result, there was a
                      noticeable increase in the redemption of these codes,
                      leading to better value for users and encouraging them to
                      explore more listings. This also helped property owners
                      attract more customers while offering a cost-effective way
                      to incentivize bookings.
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Improved User Experience
                    </h6>
                    <p>
                      The streamlined process for generating discount codes,
                      along with easy access to property details and amenities,
                      significantly improved the user experience. Users found
                      the platform intuitive and helpful, particularly for
                      obtaining contact information and negotiating discounts
                      directly with property owners. This user-friendly
                      interface encouraged repeat usage and fostered higher
                      engagement on the platform.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="">
          <h1 className="case_heading">Conclusion</h1>
          <p>
            Atithi Bhavan has successfully revolutionized the community
            accommodation sector by providing a simple yet effective platform
            for travelers to connect with property owners. By addressing common
            pain points such as difficulty in finding reliable property contact
            details and navigating complex booking processes, Atithi Bhavan has
            created a more accessible and affordable solution for users. The
            platform not only benefits travelers by offering them direct contact
            and discount opportunities but also empowers property owners by
            increasing their exposure and helping them attract more inquiries.
            Through these efforts, Atithi Bhavan delivers value to both sides of
            the market, enhancing the overall accommodation experience for all
            involved.
          </p>
        </div>
        <hr />

        <div className="analysis">
          <h2 className="case_heading">Recommendations</h2>

          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Online Booking
                    </h6>
                    <p>
                      Expand the platform to facilitate direct bookings instead
                      of relying solely on contact listings
                    </p>
                  </li>
                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      User Reviews
                    </h6>
                    <p>
                      Integrate a review system to build trust and improve
                      property credibility
                    </p>
                  </li>

                  <li>
                    <h6>
                      <PiArrowBendDownRightBold />
                      Scalability:
                    </h6>
                    <p>
                      Expand the platform to include more regions and
                      properties.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="analysis">
          <h2 className="case_heading">References</h2>
          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <p className="p-0">Internal development documentation</p>
                  </li>
                  <li>
                    <p  className="p-0">User feedback and surveys</p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="analysis">
          <h2 className="case_heading">Appendices</h2>
          <div className="process_tab_box">
            <div className="tab_content_box">
              <div className="box">
                <ol>
                  <li>
                    <p  className="p-0">
                      <b>Appendix A 
                        </b> : Platform screenshots and feature descriptions
                    </p>
                  </li>
                  <li>
                    <p  className="p-0"> <b>Appendix B</b> : User testimonials and case examples.</p>
                  </li>
                  <li>
                    <p  className="p-0">
                      <b>Appendix C</b> : Technical documentation on backend processes
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <MajorClient />
      <Testimonials />
      {/* <ContactForm /> */}
    </div>
  );
};

export default AtithiBhavan;
