import React from "react";
import ServiceFromSkyline from "../../Services/Components/ServiceFromSkyline";
import FriendlyEngagementModel from "../../Services/Components/FriendlyEngagementModel";
import AboutSkylineInfosys from "../../Services/Components/AboutSkylineInfosys";
import MajorClient from "../../Home/Components/MajorClient";
import Testimonials from "../../Home/Components/Testimonials";
import Banner from "../../../Component/Banner";
import DevelopmentInfo from "../../Services/Components/DevelopmentInfo";
import BannerImage from "../../../Images/Technology/Android/android_banner.png";
import BannerImage1 from "../../../Images/Technology/Android/android_banner1.png";
import TechnologiesPlatforms from "../../Services/Components/TechnologiesPlatforms";
import {
  ProjectPlanData,
  Technologies,
  technologyFeatureData,
} from "../../../data";
import TechnologiesFeatures from "../Components/TechnologiesFeatures";
import "../Technology.css";
import AboutDevelopment from "../../Services/Components/AboutDevelopment";
import { Helmet } from "react-helmet";
import CommonBg from "../../../Images/Common/CommonBg.jpeg";


const AndroidService = () => {
  return (
   <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>
        Experienced Android App Developers| Skyline Infosys
        </title>
        <meta
          name="description"
          content="
Skyline Infosys, your trusted Android app development company, delivers high-performance, reliable apps for your business success."
        />
        <meta name="keywords" content="android development agency " />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    <div className="service technology">
      <div className="home ">
        <Banner
         backGroundImg={CommonBg}
          heading="Android App Development"
          content="Custom Android app development is the way to go if you want a high-performance and reliable app to power your business towards the path to success. For this, Skyline Infosys, an Android app development company, is committed to being your trustworthy tech partner in this journey."
          title="Development Company "
          btnText="Consult Skyline"
          subTitle="Explore Android App development Company"
        />
      </div>

      <DevelopmentInfo
        heading="Android Development"
        title="Android Development Services In all over India"
        image={BannerImage}
        content={
          <>
            <p className="my-3">
            We are a leading Android development company providing top-notch Android app development services across India. Our team of experienced Android developers has expertise in creating customized Android applications for various industries, including healthcare, education, finance, and e-commerce. Our Android app development services are designed to meet the specific needs of our clients, and we ensure that the apps we create are user-friendly, scalable, and feature-rich.
            </p>
           
          </>
        }
      />
      <TechnologiesFeatures
        data={technologyFeatureData}
        technology={"android"}
        heading="Why Should You Go For Android Development ?
"
      />

      <DevelopmentInfo
        heading=""
        title="How Do We Developed Attractive Android App Development?"
        image={BannerImage1}
        order={2}
        content={
          <>
            <p className="my-3">
            Developing an attractive Android app requires a combination of creativity, technical expertise, and user-centered design. At our Android app development company, we follow a comprehensive approach to create visually appealing and engaging Android apps.
            </p>
            <p>
            We start by understanding our clients' requirements and their target audience. We conduct extensive research on the market and the competition to create a unique app concept that stands out from the crowd. Our team of experienced designers then creates a visually appealing UI/UX design that reflects the brand identity and is intuitive and easy to use.
            </p>
          </>
        }
      />

      <TechnologiesPlatforms
        heading="Technologies & Platforms We work With"
        data={Technologies}
        technologies={[
          "Frontend",
          "Framework",
          "Database",
          "Testing",
          "Project Management",
        ]}
        serviceName="Mobile Development"
      />
      <ServiceFromSkyline />
      <hr className="mb-0" />
      <AboutDevelopment
        heading="How we work"
        content="vuejs"
        data={ProjectPlanData}
      />
      <hr className="mb-0" />
      <FriendlyEngagementModel />
      <AboutSkylineInfosys />
      <MajorClient />
      <Testimonials />
    </div>
   </>
  );
};

export default AndroidService;
