import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NextJsIcon from "../../../Images/icon/NextJs.svg";
import AngularIcon from "../../../Images/icon/angular_js_ic.webp";
import VueIcon from "../../../Images/icon/vue_js_ic.webp";
import HTMLIcon from "../../../Images/icon/html_5.webp";
import technologyIcon from "../../../Images/icon/java_ic.webp";
import JavaImg from "../../../Images/HireDeveloper/javaDeveloper.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const JavaDeveloper = () => {

  const homeData = {
    title: "Hire <span>Java</span> Developers in India",
    description: `Empower your business with skilled Java developers delivering cutting-edge web solutions. 
      Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
      in Java, Spring Boot, Hibernate, MySQL, and more, ensuring high-quality results. With flexible hiring models and a client-first 
      approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our Java experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in Java development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your source code.",
      },
    ],
  };
  
  
  


  const hireTopDeveloperData = {
    heading: "Hire Top Java Developers in India from Skyline Infosys",
    image: {
      src: JavaImg, 
      alt: "JavaImg",
    },
    content: {
      description: `Are you looking to build highly scalable, robust, and secure applications with Java? At Skyline Infosys, 
        we offer expert Java developers with years of experience in building dynamic server-side applications, 
        RESTful APIs, and enterprise-level solutions. Our developers are highly skilled in Java frameworks like Spring Boot and Hibernate, 
        and work with popular databases like MySQL, PostgreSQL, and Oracle to deliver solutions that meet your business requirements. 
        Whether you need custom web applications, real-time data processing systems, or cloud-based solutions, 
        our team of experts can help turn your ideas into reality. We offer scalable, maintainable, and high-performance solutions 
        that are tailored to your specific needs.`,
      expertiseHeading: "Our Java developers excel in:",
      expertisePoints: [
        "Building secure, scalable, and high-performance web applications using Spring Boot and Hibernate",
        "Developing RESTful APIs with Java frameworks to support dynamic and efficient communication",
        "Optimizing Java applications for speed, scalability, and robustness, ensuring optimal performance",
        "Integrating Java applications with various databases, including MySQL, PostgreSQL, and Oracle",
        "Implementing cloud-based solutions using AWS, Azure, and Java-based microservices architecture",
      ],
      closingNote: ``,
    },
  };
  
  
  
  


  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack Java Combinations",
    description: `At Skyline Infosys, we specialize in leveraging Java with a variety of front-end technologies 
      to deliver powerful full-stack solutions. Our expertise includes:`,
    expertiseData: [
      {
        title: "Java + React.js",
        description: `A modern combination for building full-stack applications. React.js handles the dynamic front-end, offering a fast, component-based UI, while Java powers the back-end with frameworks like Spring Boot, handling routing, authentication, and database interactions. This stack is ideal for building scalable, high-performance applications with real-time capabilities.`,
        icon: ReactIcon,
      },
      {
        title: "Java + Angular",
        description: `A powerful stack for building large-scale enterprise applications. Angular provides a structured, two-way data binding front-end framework, while Java handles the back-end with frameworks like Spring Boot, managing database interactions, authentication, and business logic. This stack is ideal for complex, high-traffic applications.`,
        icon: AngularIcon, 
      },
      {
        title: "Java + Vue.js",
        description: `A combination for building responsive and dynamic user interfaces. Vue.js offers a reactive, component-based UI, while Java provides a robust back-end with frameworks like Spring Boot and Hibernate, handling business logic and database interactions. This stack is perfect for building scalable and interactive applications.`,
        icon: VueIcon, 
      },
      {
        title: "Java + HTML/CSS",
        description: `A foundational combination for building traditional server-rendered web applications. Java provides the back-end environment with frameworks like Spring MVC, handling routing, authentication, and database management, while HTML/CSS is used to structure and style static front-end pages. Ideal for simpler applications, CMS, and websites requiring minimal front-end interaction.`,
        icon: HTMLIcon, 
      },
      {
        title: "Java + Next.js",
        description: `A powerful combination for building modern web applications. Next.js handles the server-side rendering and SEO-friendly features for the front-end, while Java manages the back-end with frameworks like Spring Boot, ensuring smooth database management and API integration. This stack is perfect for building high-performance, SEO-optimized applications.`,
        icon: NextJsIcon, 
      },
      {
        title: "Java + Firebase",
        description: `An excellent combination for building serverless applications with real-time data syncing and user authentication. Firebase handles authentication, storage, and real-time databases, while Java powers the back-end with Spring Boot, ensuring smooth data handling and API integration. Ideal for modern, scalable applications.`,
        icon: FirebaseIcon,
      },
    ],
  };
  
  
  
  

  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
      <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default JavaDeveloper;
