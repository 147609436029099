import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

const EngagementTab = ({ data }) => {
  return (
    <div>
      <Row className="justify-content-center">
        <Col lg={6}>
          <div className="content skyline_service">
            <p>{data.description}</p>
            <h3 className="my-4"> {data.title} </h3>
            <ul className="p-0 m-0">
              {data.benefits.map((benefit, index) => (
                <li key={index}>
                  {" "}
                  <IoCheckmarkCircleSharp /> {benefit}
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Col lg={5} className="d-flex justify-content-center">
          <img
            src={data.image}
            alt={data.image}
            className="img-fluid content_img"
          />
        </Col>
      </Row>
    </div>
  );
};

export default EngagementTab;
