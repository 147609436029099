import React from "react";
import { Col, Container, Row } from "react-bootstrap";







const TechnologyCombinations = ({data , icon}) => {
  return (
    <section className="technology_combinations">
      <Container>
        <h1 className="heading">
        {data.heading}
        </h1>
        <p className="my-4">
         {data.description}
        </p>
        <Row className="mt-5 justify-content-center">
          {data.expertiseData.map((item, index) => (
            <Col lg={4} className="mb-4" key={index}>
              <div className="box position-relative">
                <div>
                <div className="img mb-3 flex gap-2 text-center justify-content-center">
                  <img src={icon} alt="ReactIcon" className="common_icon"  />
                  {
                    item.icon &&  <img src={item.icon} alt="item.icon" />
                  }
                 
                </div>
                <div className="content text-center">
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default TechnologyCombinations;



