import React from "react";

import "../FrontEnd/style/style.css";
import Testimonials from "../../Home/Components/Testimonials";
import { Container } from "react-bootstrap";
import ContactForm from "../../Contact/Components/ContactForm";
import ReactIcon from "../../../Images/icon/react_native_ic.webp";
import FirebaseIcon from "../../../Images/icon/Firebase.svg";
import NodeJsIcon from "../../../Images/icon/node_js_ic.webp";
import PythonIcon from "../../../Images/icon/python_ic.webp";
import AWSIcon from "../../../Images/icon/AWS.svg";
import SwiftUIIcon from "../../../Images/icon/Swift-UI.svg";
import SpringBootIcon from "../../../Images/icon/Spring.png";
import GraphQLIcon from "../../../Images/icon/GraphQL.png";
import technologyIcon from "../../../Images/icon/ios_ic.webp";
import iOSAppImg from "../../../Images/HireDeveloper/IOS.png";
import HireTopDeveloper from "../FrontEnd/Components/HireTopDeveloper";
import TechnologyCombinations from "../FrontEnd/Components/TechnologyCombinations";
import Pricing from "../FrontEnd/Components/Pricing";
import HomeSection from "../FrontEnd/Components/HomeSection";

const IosDeveloper = () => {

  const homeData = {
    title: "Hire <span>iOS App</span> Developers in India",
    description: `Empower your business with skilled iOS app developers delivering cutting-edge mobile solutions. 
          Enjoy cost-effective, scalable, and high-performance applications tailored to your needs. Our developers specialize 
          in Swift, Objective-C, Xcode, Core Data, and more, ensuring high-quality results. With flexible hiring models and a client-first 
          approach, we guarantee seamless collaboration and timely delivery. Transform your ideas into reality with our iOS app development experts!`,
    features: [
      {
        icon: "RiArrowRightDoubleLine",
        text: "Experience our services with a 7-day trial, completely risk-free.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our developers work in sync with your preferred language and time zone for smooth communication.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "Our highly skilled in-house team brings over 5 years of experience in iOS app development.",
      },
      {
        icon: "RiArrowRightDoubleLine",
        text: "We deliver clean, maintainable code with full protection of your intellectual property.",
      },
    ],
  };
  
  
  

  // hire-top-developer-data.ts
  const hireTopDeveloperData = {
    heading: "Hire Top iOS App Developers in India from Skyline Infosys",
    image: {
      src: iOSAppImg, // Update the path to the iOS app image
      alt: "iOSAppImg",
    },
    content: {
      description: `Looking to create sleek, scalable, and high-performing iOS applications? At Skyline Infosys, 
        we offer expert iOS app developers who specialize in building custom mobile solutions for businesses across industries. 
        From crafting engaging native apps to integrating advanced features, our developers deliver exceptional results tailored to your goals.`,
      expertiseHeading: "Our iOS app developers excel in:",
      expertisePoints: [
        "Developing native iOS applications using Swift and Objective-C",
        "Creating user-friendly and visually appealing UI/UX designs",
        "Integrating third-party services, APIs, and SDKs for added functionality",
        "Ensuring app optimization for performance, speed, and memory usage",
        "Leveraging Core Data, CloudKit, and other Apple frameworks for advanced app features",
      ],
      closingNote: `Collaborate with us to bring your iOS app ideas to life. 
        Hire dedicated iOS app developers from Skyline Infosys to create high-quality, feature-rich applications that drive business success.`,
    },
  };
  
  

  // expertise-section.ts
  const expertiseSection = {
    heading: "Our Expertise In Various Full-Stack iOS Combinations",
    description: `At Skyline Infosys, we specialize in integrating iOS development with powerful back-end and front-end technologies to craft intuitive, high-performing mobile applications. Our expertise includes:`,
    expertiseData: [
      {
        title: "iOS + Firebase",
        description: `A strong combination for building serverless apps with real-time data synchronization and secure user authentication. Firebase provides back-end infrastructure, while iOS delivers a smooth and engaging user interface.`,
        icon: FirebaseIcon,
      },
      {
        title: "iOS + Node.js",
        description: `A perfect combination for real-time, scalable apps. Node.js handles server-side operations efficiently, while iOS ensures a responsive and sleek mobile interface for users.`,
        icon: NodeJsIcon,
      },
      {
        title: "iOS + Python/Django",
        description: `Ideal for apps requiring complex server-side processing, analytics, or AI integration. Django offers a secure and scalable back-end while iOS provides a refined and user-friendly experience.`,
        icon: PythonIcon,
      },
      {
        title: "iOS + AWS",
        description: `Integrate iOS apps with Amazon Web Services for scalable cloud hosting, storage, and serverless architecture. This combination is perfect for reliable and secure mobile applications.`,
        icon: AWSIcon,
      },
      {
        title: "iOS + SwiftUI",
        description: `Harness the power of SwiftUI to create declarative and interactive user interfaces. Combine it with robust back-end systems for a seamless and modern app experience.`,
        icon: SwiftUIIcon,
      },
      {
        title: "iOS + React Native",
        description: `Build hybrid apps with React Native for cross-platform consistency. Pair it with native iOS components to ensure high performance and a premium user experience.`,
        icon: ReactIcon,
      },
      {
        title: "iOS + Java Spring Boot",
        description: `Combine iOS with Spring Boot for powerful back-end solutions. This stack is ideal for enterprise-level apps needing scalable APIs and secure data handling.`,
        icon: SpringBootIcon,
      },
      {
        title: "iOS + GraphQL",
        description: `Simplify data fetching for your iOS apps using GraphQL. This combination reduces overfetching, accelerates response times, and optimizes communication between the app and back-end.`,
        icon: GraphQLIcon,
      },
    ],
  };
  
  
  
  return (
    <>
      <HomeSection homeData={homeData} />
      <HireTopDeveloper data={hireTopDeveloperData} />
        <div className="ios_page">
        <TechnologyCombinations icon={technologyIcon} data={expertiseSection} />
        </div>
      <Pricing />
      <Container>
        <hr />
      </Container>
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default IosDeveloper;
