import React from "react";
import "./home.css";
import OurSpecializations from "./Components/OurSpecializations";
import TechnicalLanguage from "./Components/TechnicalLanguage";
import TopRated from "./Components/TopRated";
import OurPortfolio from "./Components/OurPortfolio";
import Testimonials from "./Components/Testimonials";
import MajorClient from "./Components/MajorClient";
import { Serving } from "../../data";
import Banner from "../../Component/Banner";
import ContactForm from "../Contact/Components/ContactForm";
import { Helmet } from "react-helmet";
import bannerBg from "../../Images/Common/bannerBg.jpg"
import ClientSlider from "./Components/ClientSlider";
import Industries from "./Components/Industries";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Skyline infosys | Premium Web Development Agency</title>
        <meta
          name="description"
          content="Transform your vision into reality with our web development agency, specializing in web design & mobile app development with a client-first approach."
        />
        <meta name="keywords" content="Web Development Agency" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="home ">
        <Banner
        showInfo={true}
          backGroundImg={bannerBg}
          heading="Reliable Software Solutions Provider"
          content="We enable businesses to thrive by delivering user-friendly and
                  impactful web and mobile applications. With a strong emphasis
                  on innovation, collaboration, and technical excellence, we
                  help our clients grow and succeed."
          title="Skyline Infosys' Passion for Development Innovation."
          btnText="Consult Skyline"
        />
      </div>
     
      <ClientSlider/>
    
      <OurSpecializations />
     
      <TechnicalLanguage />
      <Industries
        data={Serving}
        title={`Serving Multiple Sectors with Tailored Solutions`}
        description={` Industries big and small, we've got you covered`}
      />
      <MajorClient />
    
      <OurPortfolio isViewMore={true} />
      <TopRated />
      <Testimonials />
      <ContactForm />
    </>
  );
};

export default Home;
